import firebase from 'firebase/app';
import 'firebase/analytics';
import '@firebase/auth';
import '@firebase/firestore';
import '@firebase/storage';
import '@firebase/functions';
import 'firebase/performance';
import appConfig from '../appConfig';
import { authExceptionHandler } from '../common/exceptions/exceptionHanlder';
import { handle } from '../common/helpers/authHelper';

class Firebase {
    auth: firebase.auth.Auth;
    emailAuthProvider: any;
    firestore: firebase.firestore.Firestore;
    functions: firebase.functions.Functions;
    storage: firebase.storage.Storage;
    analytics: firebase.analytics.Analytics;
    performance: firebase.performance.Performance;

    public tenantId?: string;
    public searchKey?: string;

    constructor() {
        const app = firebase.initializeApp(appConfig);

        this.analytics = app.analytics();
        this.emailAuthProvider = firebase.auth.EmailAuthProvider;
        this.auth = app.auth();
        this.firestore = app.firestore();
        this.functions = app.functions('europe-west1');
        this.storage = app.storage();
        this.performance = app.performance();
    }

    public signInWithEmailAndPassword = async (email: string, password: string): Promise<any> => {
        try {
            await this.auth.signInWithEmailAndPassword(email, password);

            if (!this.auth.currentUser!.emailVerified) {
                this.auth.signOut();
                return {
                    data: {
                        code: 'email-not-verified',
                        message: 'email-not-verified',
                        details: 'email-not-verified',
                    },
                };
            }

            const idTokenResult = await this.auth.currentUser!.getIdTokenResult();
            const tenantId = idTokenResult.claims.tenantId;
            const tenant: any = await this.getTenant(tenantId);

            handle(tenant);
        } catch (error) {
            return authExceptionHandler(error);
        }
    };

    signOut = () => this.auth.signOut();

    async getTenant(tenantId: string) {
        const doc = await this.firestore.collection('tenants').doc(tenantId).get();
        if (doc.exists) {
            return doc.data() as any;
        }
        return undefined;
    }
}

export default new Firebase();
