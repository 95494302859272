import * as React from 'react';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

export interface TopProgressProps {
    inProgress: boolean;
    style?: any;
}

export const styles = (theme: Theme) =>
    createStyles({
        root: {
            height: '5px',
            overflow: 'hidden',
            position: 'fixed',
            top: 0,
            right: 0,
            left: 0
        }
    });

type Props = TopProgressProps & WithStyles<typeof styles>;

class TopProgress extends React.Component<Props> {
    render() {
        const { inProgress, classes, style } = this.props;

        return (
            <div className={classes.root} style={style}>
                {inProgress && <LinearProgress color="primary" />}
            </div>
        );
    }
}

const styledComponent = withStyles(styles, { withTheme: true })(TopProgress);

export default styledComponent;
