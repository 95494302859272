import { NoteDto } from './dtos/generated/dtos.generated';

import Firebase from '../api/firebase';
import { AppError } from '../common/models/appError';
import { notesCollectionName } from '../common/helpers/collectionNames';
import baseService from './baseService';

export const noteService = {
    getNotesByDocId: async (id: string, rootCollectionName: string): Promise<NoteDto[]> => {
        try {
            const querySnapshot = await Firebase.firestore
                .collection('tenants')
                .doc(Firebase.tenantId)
                .collection(rootCollectionName)
                .doc(id)
                .collection(notesCollectionName)
                .orderBy('createdAt', 'desc')
                .get();
            const items: NoteDto[] = [];
            if (!querySnapshot.empty) {
                for (const doc of querySnapshot.docs) {
                    const model = doc.data() as NoteDto;
                    items.push(model);
                }
                return items;
            } else {
                return items;
            }
        } catch (error) {
            return Promise.reject(new AppError('notes-not-found'));
        }
    },
    upsertNote: async (noteDto: NoteDto, rootEntityId: string, rootCollectionName: string) => {
        await baseService.upsertEntityDeep(rootEntityId, noteDto, rootCollectionName, notesCollectionName);
    },
    deleteNote: async (noteId: string, rootEntityId: string, rootCollectionName: string) => {
        await baseService.deleteEntityDeep(rootEntityId, noteId, rootCollectionName, notesCollectionName);
    },
};
