import '@firebase/auth';

const authErrors = (error: any): { data: { code: string; message: string; details: string } } | void => {
    if (error && error.code) {
        if (error.code.includes('auth/wrong-password')) {
            return {
                data: { code: 'auth/wrong-password', message: 'auth/wrong-password', details: 'auth/wrong-password' }
            };
        }
        if (error.code.includes('auth/user-not-found')) {
            return {
                data: { code: 'auth/user-not-found', message: 'auth/user-not-found', details: 'auth/user-not-found' }
            };
        }
    }
};

export const authExceptionHandler = (error: any): { data: { code: any; message: string; details?: any } } => {
    const authError = authErrors(error);

    if (authError) {
        return authError;
    }

    return { data: { code: error.code, message: error.message, details: error.message } };
};
