import * as colors from '@material-ui/core/colors';

export function hexToRGB(hex: string, alpha?: string) {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
        return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    } else {
        return `rgba(${r}, ${g}, ${b})`;
    }
}

export function getContrastColor(hex: string): string {
    let rgb = hexToRGB(hex);

    rgb = rgb.split(/\(([^)]+)\)/)[1].replace(/ /g, '');
    const r = parseInt(rgb.split(',')[0], 10);
    const g = parseInt(rgb.split(',')[1], 10);
    const b = parseInt(rgb.split(',')[2], 10);

    /*
    let a = undefined;
    if (rgb.split(',')[3] !== null) {
        a = parseInt(rgb.split(',')[3], 10);
    }
    */

    // calculate contrast of color (standard grayscale algorithmic formula)
    var contrast = (Math.round(r * 299) + Math.round(g * 587) + Math.round(b * 114)) / 1000;

    return contrast >= 150 ? 'black' : 'white';
}

export const getRandomColor = (): string => {
    const colors = getColors();
    const random = Math.floor(Math.random() * colors.length - 1);
    return colors[random];
};
export const getColors = (): string[] => [
    colors.red['900'],
    colors.red['700'],
    colors.red['500'],
    colors.red['300'],
    colors.red['100'],
    colors.pink['900'],
    colors.pink['700'],
    colors.pink['500'],
    colors.pink['300'],
    colors.pink['100'],
    colors.purple['900'],
    colors.purple['700'],
    colors.purple['500'],
    colors.purple['300'],
    colors.purple['100'],
    colors.deepPurple['900'],
    colors.deepPurple['700'],
    colors.deepPurple['500'],
    colors.deepPurple['300'],
    colors.deepPurple['100'],
    colors.indigo['900'],
    colors.indigo['700'],
    colors.indigo['500'],
    colors.indigo['300'],
    colors.indigo['100'],
    colors.blue['900'],
    colors.blue['700'],
    colors.blue['500'],
    colors.blue['300'],
    colors.blue['100'],
    colors.lightBlue['900'],
    colors.lightBlue['700'],
    colors.lightBlue['500'],
    colors.lightBlue['300'],
    colors.lightBlue['100'],
    colors.cyan['900'],
    colors.cyan['700'],
    colors.cyan['500'],
    colors.cyan['300'],
    colors.cyan['100'],
    colors.teal['900'],
    colors.teal['700'],
    colors.teal['500'],
    colors.teal['300'],
    colors.teal['100'],
    '#194D33',
    colors.green['700'],
    colors.green['500'],
    colors.green['300'],
    colors.green['100'],
    colors.lightGreen['900'],
    colors.lightGreen['700'],
    colors.lightGreen['500'],
    colors.lightGreen['300'],
    colors.lightGreen['100'],
    colors.lime['900'],
    colors.lime['700'],
    colors.lime['500'],
    colors.lime['300'],
    colors.lime['100'],
    colors.yellow['900'],
    colors.yellow['700'],
    colors.yellow['500'],
    colors.yellow['300'],
    colors.yellow['100'],
    colors.amber['900'],
    colors.amber['700'],
    colors.amber['500'],
    colors.amber['300'],
    colors.amber['100'],
    colors.orange['900'],
    colors.orange['700'],
    colors.orange['500'],
    colors.orange['300'],
    colors.orange['100'],
    colors.deepOrange['900'],
    colors.deepOrange['700'],
    colors.deepOrange['500'],
    colors.deepOrange['300'],
    colors.deepOrange['100'],
    colors.brown['900'],
    colors.brown['700'],
    colors.brown['500'],
    colors.brown['300'],
    colors.brown['100'],
    colors.blueGrey['900'],
    colors.blueGrey['700'],
    colors.blueGrey['500'],
    colors.blueGrey['300'],
    colors.blueGrey['100'],
    '#000000',
    '#525252',
    '#969696',
    '#D9D9D9',
    '#FFFFFF'
];
