import { useRef } from 'react';

/**
 * Create ref to the functional component (It will not re-run on each render)
 * @param VmConstructor
 * @param args context | obj | array
 * @return VmConstructor
 */
export function useVm(VmConstructor: any, args?: any): any {
    let vmRef = useRef(null);
    if (!vmRef.current) {
        vmRef.current = args ? new VmConstructor(args) : new VmConstructor();
    }
    return vmRef.current;
}
