import { BaseModel } from '../../../common/stores/model/baseModel';
import { action, computed, observable } from 'mobx';
import { ReferencedCustomerDto } from '../../../api/dtos/generated/dtos.generated';
import i18n from 'i18next';

export class ProjectFilter extends BaseModel {
    @observable states: string[] = ['open'];
    @observable customerRef?: ReferencedCustomerDto;

    constructor(states?: string[]) {
        super();
        if (states) {
            this.states = states;
        }
    }

    @computed get customerDisplayName() {
        return this.customerRef ? this.customerRef.displayName : '';
    }

    @computed get statesText() {
        if (this.states.length < 1) {
            return i18n.t('state');
        }

        const states = this.states.map((x) => i18n.t(x.toString()));
        return states.join(' | ');
    }

    @computed get customerText() {
        return this.customerRef ? this.customerDisplayName : i18n.t('customer');
    }

    @action
    public setCustomer(ref?: ReferencedCustomerDto) {
        this.customerRef = ref;
    }

    @action
    public addState(state: string) {
        if (this.states.indexOf(state) < 0) {
            this.states.push(state);
        }
    }

    @action
    public removeState(state: string) {
        this.states = this.states.filter((x) => x !== state);
    }

    @action
    public setStates(states: string[]) {
        this.states = states;
    }

    isStateFiltered(state: string) {
        return this.states.findIndex((x) => x === state) > -1;
    }
}
