import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import { createStyles, Theme } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { CircularProgress } from '@material-ui/core';
import DeleteIcon from 'mdi-material-ui/Delete';

export interface DefaultEditActionsProps {
    isDeleteVisible?: boolean;
    isCancelVisible?: boolean;
    isSaveVisible?: boolean;
    isDeleteActive: boolean;
    isCancelActive: boolean;
    isSaveActive?: boolean;
    inProgress: boolean;
    disableLoader?: boolean;
    onCancel?: any;
    onDelete?: any;
    onSave: any;
}

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            [theme.breakpoints.down('xs')]: {
                width: 'auto',
            },
        },
    })
);

const DefaultEditActions = ({
    isDeleteVisible = true,
    isCancelVisible = true,
    isSaveVisible = true,
    isDeleteActive,
    isCancelActive,
    inProgress,
    disableLoader,
    onCancel,
    onDelete,
    onSave,
    isSaveActive = true,
}: DefaultEditActionsProps) => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Grid container justify="space-between" spacing={1}>
                <Grid item>
                    {isDeleteVisible && isDeleteActive && (
                        <Button
                            size={'large'}
                            variant={'text'}
                            startIcon={<DeleteIcon />}
                            onClick={onDelete}
                            disabled={!isDeleteActive || inProgress}
                        >
                            {t('delete')}
                        </Button>
                    )}
                </Grid>
                <Grid item>
                    <Grid container spacing={1}>
                        {isCancelVisible && onCancel && (
                            <Grid item>
                                <Button size={'large'} variant={'text'} disabled={!isCancelActive} onClick={onCancel}>
                                    {t(isSaveVisible ? 'cancel' : 'close')}
                                </Button>
                            </Grid>
                        )}
                        {isSaveVisible && (
                            <Grid item>
                                <Button
                                    size={'large'}
                                    variant={'contained'}
                                    color="primary"
                                    onClick={onSave}
                                    disabled={!isSaveActive || inProgress}
                                >
                                    {!inProgress || disableLoader ? (
                                        t('save')
                                    ) : (
                                        <CircularProgress size={32} color="primary" />
                                    )}
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export default DefaultEditActions;
