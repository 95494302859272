import * as React from 'react';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

const styles = (theme: Theme) =>
    createStyles({
        container: {
            [theme.breakpoints.up('sm')]: {
                height: '100vh'
            },
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '100vh'
        },
        paper: {
            paddingLeft: theme.spacing(8),
            paddingRight: theme.spacing(8),
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(4),
            position: 'relative',
            overflow: 'hidden',
            [theme.breakpoints.down('xs')]: {
                padding: theme.spacing(2),
                width: '100%',
                boxShadow: 'none !important',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '100vh',
                background: 'transparent'
            }
        }
    });

type Props = WithStyles<typeof styles>;

export function centerPaper(Comp: React.ComponentClass | React.FunctionComponent): any {
    class WrappedComponent extends React.Component<Props> {
        public render() {
            const { classes, ...other } = this.props;
            return (
                <div className={classes.container}>
                    <Paper elevation={0} className={classes.paper}>
                        <Comp {...other} />
                    </Paper>
                </div>
            );
        }
    }

    const styledComponent = withStyles(styles, { withTheme: true })(WrappedComponent);

    return styledComponent;
}
