import React, { useContext, useEffect, useState } from 'react';
import { navigate, RouteComponentProps } from '@reach/router';
import { createStyles, Paper, Tab, Tabs, Theme, Hidden } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import TenantForm from './TenantForm';
import { observer } from 'mobx-react';
import ScreenToolbar from '../../../common/components/ScreenToolbar';
import MobileScreenToolbar from '../../../common/components/MobileScreenToolbar';
import DefaultEditActions from '../../../common/components/DefaultEditActions';
import { Tenant } from '../models/tenant';
import AvatarChooser from '../../../common/components/AvatarChooser';
import defaultTenantAvatar from '../../../assets/icons/defaultCompanyAvatar.svg';
import { ImageType } from '../../../api/dtos/generated/dtos.generated';
import appConfig from '../../../appConfig';
import LoadingInfo from '../../../common/components/LoadingInfo';
import SubscriptionScreen from './SubscriptionScreen';
import { StoreContext } from '../../../App';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { parse } from 'query-string';

export enum TenantScreenTab {
    General,
    Subscription,
}

interface TenantScreenProps extends RouteComponentProps {
    t?: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: `${theme.spacing(1)}px ${theme.spacing(4)}px`,
            [theme.breakpoints.up('md')]: {
                padding: theme.spacing(1),
                paddingLeft: theme.spacing(4),
                paddingRight: theme.spacing(4),
            },
            [theme.breakpoints.down('xs')]: {
                padding: 0,
            },
        },
        paper: {
            position: 'relative',
            padding: '1rem',
            minHeight: '90vh',
        },
        tabs: {
            marginBottom: theme.spacing(4),
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            width: 200,
        },
        menu: {
            width: 200,
        },
    })
);

const TenantScreen = observer((props: TenantScreenProps) => {
    const { tenantStore, uiStore } = useContext(StoreContext);
    const classes = useStyles();
    const { t } = useTranslation();

    const [tabSelection, setTabSelection] = useState<TenantScreenTab>(TenantScreenTab.General);
    const [tenant, setTenant] = useState<Tenant>();

    const handleSelectItemById = (id: string, indexName: any) => {
        if (indexName === appConfig.algolia.index.task) {
            uiStore.setSelectedTaskId(id);
        } else {
            uiStore.setSelectedProjectId(id);
        }
    };

    const handleImageChange = (image: string, tenant: Tenant) => {
        tenant.updateProperty('changedImage', image);
    };

    const handleTabSelection = (event: any, selection: TenantScreenTab) => {
        setTabSelection(selection);
        navigate!(`/mytenant?t=${selection}`);
    };

    const handleSave = (e: any, tenant: Tenant) => {
        tenant.save();
    };

    useEffect(() => {
        setTenant(tenantStore.tenant?.cloneTenant());

        if (props.location?.search) {
            const params = parse(props.location.search);

            if (params?.t) {
                const value = parseInt(params?.t as string, 10);
                setTabSelection(value);
            }
        }
    }, []);

    return (
        <>
            <Hidden smDown={true}>
                <ScreenToolbar handleSelectItem={handleSelectItemById} title={t('organization')} />
            </Hidden>
            <Hidden mdUp={true}>
                <MobileScreenToolbar inProgress={tenantStore!.inProgress} title={t('organization')} />
            </Hidden>

            <div className={classes.root}>
                <Paper elevation={1} className={classes.paper}>
                    {tenantStore!.inProgress && <LoadingInfo />}
                    <div style={{ paddingBottom: '2rem' }}>
                        <Tabs
                            centered
                            className={classes.tabs}
                            value={tabSelection}
                            onChange={handleTabSelection}
                            indicatorColor="primary"
                        >
                            <Tab
                                label={t('general')}
                                value={TenantScreenTab.General}
                                disabled={tenantStore!.isLoading}
                            />
                            <Tab
                                label={t('subscription')}
                                disabled={tenantStore!.isLoading}
                                value={TenantScreenTab.Subscription}
                            />
                        </Tabs>
                        {tabSelection === TenantScreenTab.General && tenant && (
                            <>
                                <AvatarChooser
                                    defaultImage={defaultTenantAvatar}
                                    imagePath={tenant ? tenant.imagePath : undefined}
                                    imageHash={tenant ? tenant.imageHash : undefined}
                                    imageChanged={(image: string) => handleImageChange(image, tenant)}
                                    imageType={ImageType.Png}
                                    inProgress={tenantStore!.inProgress!}
                                />
                                <TenantForm
                                    tenant={tenant}
                                    twentyHoursFormat={true}
                                    inProgress={tenantStore!.inProgress!}
                                />
                                <div style={{ paddingTop: '2rem' }}>
                                    <DefaultEditActions
                                        isCancelActive={false}
                                        isDeleteActive={false}
                                        inProgress={tenantStore!.inProgress!}
                                        disableLoader={true}
                                        onSave={(e: any) => handleSave(e, tenant)}
                                    />
                                </div>
                            </>
                        )}
                        {tabSelection === TenantScreenTab.Subscription && <SubscriptionScreen />}
                    </div>
                </Paper>
            </div>
        </>
    );
});

export default TenantScreen;
