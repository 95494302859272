import React, { ReactNode, useContext } from 'react';
import {
    AppBar,
    createStyles,
    Grid,
    IconButton,
    Theme,
    Toolbar,
    Typography,
    useScrollTrigger
} from '@material-ui/core';
import { observer } from 'mobx-react';
import { UiStore } from '../../stores/uiStore';
import TopProgress from './TopProgress';
import { runInAction } from 'mobx';
import MenuIcon from 'mdi-material-ui/Menu';
import { AuthStore } from '../../modules/auth/stores/authStore';
import { makeStyles } from '@material-ui/core/styles';
import { StoreContext } from '../../App';
import SearchIcon from 'mdi-material-ui/Magnify';

const useStyles = makeStyles(({ zIndex, transitions, palette }: Theme) =>
    createStyles({
        appBar: {
            zIndex: zIndex.drawer + 1,
            backgroundColor: 'transparent',
            transition: transitions.create(['width', 'margin'], {
                easing: transitions.easing.sharp,
                duration: transitions.duration.leavingScreen
            })
        },
        leftContent: {
            flexGrow: 1
        },
        grid: {
            display: 'flex'
        },
        title: {
            fontWeight: 400,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: palette.primary.dark,
            '& svg': {
                marginRight: '0.3rem'
            }
        },
        toolbar: {
            padding: '0 5px',
            minHeight: '56px'
        }
    })
);

interface AdminToolbarProps {
    uiStore?: UiStore;
    authStore?: AuthStore;
    title: string;
    toolbarItems?: ReactNode;
    inProgress?: boolean;
    window?: () => Window;
}

const MobileScreenToolbar = observer(({ title, toolbarItems, inProgress, window }: AdminToolbarProps, props: any) => {
    const classes = useStyles();
    const { uiStore } = useContext(StoreContext);

    const handleSearchOpen = () => {
        runInAction(() => (uiStore!.isModalOpen = !uiStore!.isModalOpen));
    };

    const handleDrawerOpen = () => {
        runInAction(() => (uiStore!.isMobileMenuOpen = !uiStore!.isMobileMenuOpen));
    };

    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
        disableHysteresis: true,
        threshold: 1
    });

    return (
        <AppBar
            position={'fixed'}
            className={classes.appBar}
            color="inherit"
            elevation={0}
            style={trigger ? { background: '#fff', borderBottom: '1px solid #5fc1f5' } : undefined}
        >
            <TopProgress inProgress={inProgress!} />
            <Toolbar classes={{ regular: classes.toolbar }}>
                <Grid container className={classes.leftContent} alignItems={'center'}>
                    <Grid item xs={3}>
                        <IconButton onClick={handleDrawerOpen}>
                            <MenuIcon style={{ fill: '#0058cb' }} />
                        </IconButton>
                    </Grid>
                    <Grid container item xs={6} justify="center">
                        <Typography component="h4" noWrap className={classes.title}>
                            {toolbarItems} {title}
                        </Typography>
                    </Grid>
                    <Grid container item xs={3} justify="flex-end">
                        <IconButton onClick={handleSearchOpen}>
                            <SearchIcon style={{ fill: '#0058cb' }} />
                        </IconButton>
                    </Grid>
                </Grid>
            </Toolbar>
            {props.children}
        </AppBar>
    );
});

export default MobileScreenToolbar;
