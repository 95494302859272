import { action, observable, toJS } from 'mobx';
import { AuthStore } from '../stores/authStore';
import { validate } from '../../../common/helpers/validationHelper';
import i18n from 'i18next';
import Firebase from '../../../api/firebase';
import { BaseViewModel } from '../../../common/models/baseViewModel.vm';
import { getLanguage } from '../../../common/utils/languageHelper';

export class ForgotPasswordScreenVm extends BaseViewModel {
    private authStore: AuthStore;
    @observable public email: string = '';

    constructor(authStore: AuthStore) {
        super();
        this.authStore = authStore;
    }

    @action
    public setEmail(email: string) {
        this.email = email;
    }

    @action
    private validateAll(data: any) {
        let rules = { email: 'required|email|max:255' };
        let attributeNames = { email: i18n.t('email') };
        const validator = ForgotPasswordScreenVm.getValidator(data, rules, attributeNames);
        this.validationErrors = validate(validator);
    }

    @action
    public async sendForgotPasswordEmail() {
        this.validateAll(toJS(this));

        if (this.isValid) {
            const forgotPassword = Firebase.functions.httpsCallable('forgotPassword');
            const result = forgotPassword({
                email: this.email,
                lang: getLanguage(i18n.language)
            });
            const resp = await this.handle(result);
            if (resp !== undefined) {
                this.successMessage = resp.details;
            }
        }
    }
}
