import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { observer } from 'mobx-react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Tenant } from '../models/tenant';
import { Typography, FormControlLabel, Checkbox } from '@material-ui/core';
import { KeyboardTimePicker } from '@material-ui/pickers';
import { ClockIcon } from '../../../common/components/SvgIcons';
import CountryAutocomplete from '../../../common/components/CountryAutocomplete';
import { CountryDto } from '../../../api/dtos/generated/dtos.generated';

interface ITenantFormProps {
    inProgress: boolean;
    tenant: Tenant;
    twentyHoursFormat: boolean;
}

export const styles = (theme: Theme) =>
    createStyles({
        viewRoot: {
            paddingTop: theme.spacing(1),
        },
        formControl: {
            width: '100%',
        },
        button: {
            margin: theme.spacing(1),
        },
    });

type Props = ITenantFormProps & WithStyles<typeof styles> & WithTranslation;

@observer
class TenantForm extends React.Component<Props> {
    render() {
        const { classes, t, inProgress, tenant, twentyHoursFormat } = this.props;
        return (
            <div className={classes.viewRoot}>
                <form>
                    <Grid container>
                        <Grid container spacing={3}>
                            <Grid item={true} xs={12}>
                                <TextField
                                    id="name"
                                    name="name"
                                    variant={'outlined'}
                                    className={classes.formControl}
                                    label={t('name')}
                                    disabled={inProgress}
                                    error={tenant ? !!tenant.validationErrors.name : undefined}
                                    helperText={tenant ? tenant.validationErrors.name : undefined}
                                    value={tenant ? tenant.name : ''}
                                    onChange={(e) => this.handleChange(e)}
                                    required={true}
                                    autoFocus={true}
                                />
                            </Grid>
                            <Grid item={true} xs={12} md={6}>
                                <TextField
                                    id="street"
                                    name="street"
                                    variant={'outlined'}
                                    className={classes.formControl}
                                    label={t('street')}
                                    disabled={inProgress}
                                    error={tenant ? !!tenant.address.validationErrors.street : undefined}
                                    helperText={tenant ? tenant.address.validationErrors.street : undefined}
                                    value={tenant ? tenant!.address.street : ''}
                                    onChange={(e) => this.handleAddressChange(e)}
                                    required={true}
                                />
                            </Grid>
                            <Grid item={true} xs={12} md={6}>
                                <TextField
                                    id="addition"
                                    name="addition"
                                    variant={'outlined'}
                                    className={classes.formControl}
                                    label={t('addition')}
                                    disabled={inProgress}
                                    error={tenant ? !!tenant.address.validationErrors.addition : undefined}
                                    helperText={tenant ? tenant.address.validationErrors.addition : undefined}
                                    value={tenant ? tenant!.address.addition : ''}
                                    onChange={(e) => this.handleAddressChange(e)}
                                />
                            </Grid>
                            <Grid item={true} xs={12} md={6}>
                                <TextField
                                    id="zip"
                                    name="zip"
                                    variant={'outlined'}
                                    className={classes.formControl}
                                    label={t('zip')}
                                    disabled={inProgress}
                                    error={tenant ? !!tenant.address.validationErrors.zip : undefined}
                                    helperText={tenant ? tenant.address.validationErrors.zip : undefined}
                                    value={tenant ? tenant!.address.zip : ''}
                                    onChange={(e) => this.handleAddressChange(e)}
                                    required={true}
                                />
                            </Grid>
                            <Grid item={true} xs={12} md={6}>
                                <TextField
                                    id="city"
                                    name="city"
                                    variant={'outlined'}
                                    className={classes.formControl}
                                    label={t('city')}
                                    disabled={inProgress}
                                    error={tenant ? !!tenant.address.validationErrors.city : undefined}
                                    helperText={tenant ? tenant.address.validationErrors.city : undefined}
                                    value={tenant ? tenant!.address.city : ''}
                                    onChange={(e) => this.handleAddressChange(e)}
                                    required={true}
                                />
                            </Grid>
                            <Grid item={true} xs={12} md={6}>
                                <CountryAutocomplete
                                    required
                                    countryCode={tenant?.address?.countryCode}
                                    onChange={this.handleAddressCountry}
                                    disabled={inProgress}
                                    error={tenant ? !!tenant.address.validationErrors.country : undefined}
                                    helperText={tenant ? tenant.address.validationErrors.country : undefined}
                                />
                            </Grid>
                            <Grid item={true} xs={12}>
                                <div>
                                    <div>
                                        <div>
                                            <Typography variant="body1" gutterBottom={true} color="textSecondary">
                                                {t('presence')}
                                            </Typography>
                                        </div>
                                    </div>
                                </div>

                                <Grid container={true} spacing={3}>
                                    <Grid item={true} xs={12}>
                                        <Typography variant="body2" gutterBottom={true} color="textSecondary">
                                            {t('workWeek')}
                                        </Typography>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={tenant ? tenant.presence.isMondayActive : false}
                                                    onChange={this.handleCheckboxChange('isMondayActive')}
                                                    color="primary"
                                                    disabled={inProgress}
                                                />
                                            }
                                            label={t('mondayShort')}
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={tenant ? tenant.presence.isTuesdayActive : false}
                                                    onChange={this.handleCheckboxChange('isTuesdayActive')}
                                                    color="primary"
                                                    disabled={inProgress}
                                                />
                                            }
                                            label={t('tuesdayShort')}
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={tenant ? tenant.presence.isWednesdayActive : false}
                                                    onChange={this.handleCheckboxChange('isWednesdayActive')}
                                                    color="primary"
                                                    disabled={inProgress}
                                                />
                                            }
                                            label={t('wednesdayShort')}
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={tenant ? tenant.presence.isThursdayActive : false}
                                                    onChange={this.handleCheckboxChange('isThursdayActive')}
                                                    color="primary"
                                                    disabled={inProgress}
                                                />
                                            }
                                            label={t('thursdayShort')}
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={tenant ? tenant.presence.isFridayActive : false}
                                                    onChange={this.handleCheckboxChange('isFridayActive')}
                                                    color="primary"
                                                    disabled={inProgress}
                                                />
                                            }
                                            label={t('fridayShort')}
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={tenant ? tenant.presence.isSaturdayActive : false}
                                                    onChange={this.handleCheckboxChange('isSaturdayActive')}
                                                    color="primary"
                                                    disabled={inProgress}
                                                />
                                            }
                                            label={t('saturdayShort')}
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={tenant ? tenant.presence.isSundayActive : false}
                                                    onChange={this.handleCheckboxChange('isSundayActive')}
                                                    color="primary"
                                                    disabled={inProgress}
                                                />
                                            }
                                            label={t('sundayShort')}
                                        />
                                    </Grid>
                                    <Grid item={true} xs={12}>
                                        <Typography variant="body1" gutterBottom={true} color="textSecondary">
                                            {t('morning')}
                                        </Typography>
                                    </Grid>
                                    <Grid item={true} xs={12} md={6}>
                                        <KeyboardTimePicker
                                            required
                                            inputVariant="outlined"
                                            id="upTimeFrom"
                                            name="upTimeFrom"
                                            className={classes.formControl}
                                            label={t('upTimeFrom')}
                                            clearLabel={t('reset')}
                                            cancelLabel={t('cancel')}
                                            disabled={inProgress}
                                            ampm={!twentyHoursFormat}
                                            value={tenant ? tenant.presence.upTimeFrom! : null}
                                            mask={'__:__'}
                                            onChange={(e) => this.handleDateChange('upTimeFrom', e)}
                                            invalidDateMessage={t('invalid-time')}
                                            error={tenant ? !!tenant.presence.validationErrors.upTimeFrom : undefined}
                                            helperText={
                                                tenant ? tenant.presence.validationErrors.upTimeFrom : undefined
                                            }
                                            keyboardIcon={<ClockIcon />}
                                        />
                                    </Grid>

                                    <Grid item={true} xs={12} md={6}>
                                        <KeyboardTimePicker
                                            required
                                            inputVariant="outlined"
                                            id="upTimeTo"
                                            name="upTimeTo"
                                            clearLabel={t('reset')}
                                            cancelLabel={t('cancel')}
                                            className={classes.formControl}
                                            label={t('upTimeTo')}
                                            disabled={inProgress}
                                            ampm={!twentyHoursFormat}
                                            value={tenant ? tenant.presence.upTimeTo! : null}
                                            mask={'__:__'}
                                            onChange={(e) => this.handleDateChange('upTimeTo', e)}
                                            invalidDateMessage={t('invalid time')}
                                            error={tenant ? !!tenant.presence.validationErrors.upTimeTo : undefined}
                                            helperText={tenant ? tenant.presence.validationErrors.upTimeTo : undefined}
                                            keyboardIcon={<ClockIcon />}
                                        />
                                    </Grid>

                                    <Grid item={true} xs={12}>
                                        <Typography variant="body1" gutterBottom={true} color="textSecondary">
                                            {t('afternoon')}
                                        </Typography>
                                    </Grid>

                                    <Grid item={true} xs={12} md={6}>
                                        <KeyboardTimePicker
                                            required
                                            inputVariant="outlined"
                                            id="afternoonUpTimeFrom"
                                            name="afternoonUpTimeFrom"
                                            className={classes.formControl}
                                            label={t('upTimeFrom')}
                                            disabled={inProgress}
                                            clearLabel={t('reset')}
                                            cancelLabel={t('cancel')}
                                            ampm={!twentyHoursFormat}
                                            value={tenant ? tenant.presence.afternoonUpTimeFrom! : null}
                                            mask={'__:__'}
                                            onChange={(e) => this.handleDateChange('afternoonUpTimeFrom', e)}
                                            invalidDateMessage={t('invalid time')}
                                            error={
                                                tenant
                                                    ? !!tenant.presence.validationErrors.afternoonUpTimeFrom
                                                    : undefined
                                            }
                                            helperText={
                                                tenant
                                                    ? tenant.presence.validationErrors.afternoonUpTimeFrom
                                                    : undefined
                                            }
                                            keyboardIcon={<ClockIcon />}
                                        />
                                    </Grid>

                                    <Grid item={true} xs={12} md={6}>
                                        <KeyboardTimePicker
                                            required
                                            inputVariant="outlined"
                                            className={classes.formControl}
                                            id="afternoonUpTimeTo"
                                            name="afternoonUpTimeTo"
                                            label={t('upTimeTo')}
                                            clearLabel={t('reset')}
                                            cancelLabel={t('cancel')}
                                            disabled={inProgress}
                                            ampm={!twentyHoursFormat}
                                            value={tenant ? tenant.presence.afternoonUpTimeTo! : null}
                                            mask={'__:__'}
                                            onChange={(date) => this.handleDateChange('afternoonUpTimeTo', date)}
                                            invalidDateMessage={t('invalid time')}
                                            error={
                                                tenant
                                                    ? !!tenant.presence.validationErrors.afternoonUpTimeTo
                                                    : undefined
                                            }
                                            helperText={
                                                tenant ? tenant.presence.validationErrors.afternoonUpTimeTo : undefined
                                            }
                                            keyboardIcon={<ClockIcon />}
                                        />
                                    </Grid>
                                    <Grid />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </div>
        );
    }

    handleCheckboxChange = (name: string) => (event: any) => {
        this.props.tenant!.presence.updateProperty(name, event.target.checked);
    };

    handleChange = (event: any): void => {
        const { name, value } = event.target;
        this.props.tenant!.updateProperty(name, value);
    };

    handleAddressChange = (event: any): void => {
        const { name, value } = event.target;
        this.props.tenant!.address!.updateProperty(name, value);
    };

    handleAddressCountry = (country: CountryDto | undefined): void => {
        this.props.tenant!.address!.updateCountry(country);
    };

    handleDateChange = (name: string, date: Date | null): void => {
        this.props.tenant!.presence.updateDateProperty(name, date);
    };
}

const styledComponent = withStyles(styles, { withTheme: true })(TenantForm);
const translatedComponent = withTranslation()(styledComponent);

export default translatedComponent;
