import React, { useEffect, useState } from 'react';
import {
    Theme,
    Grid,
    Button,
    Typography,
    createStyles,
    Card,
    CardContent,
    CardActions,
    List,
    ListItem,
    ListItemText,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import LoadingInfo from '../../../common/components/LoadingInfo';
import { makeStyles } from '@material-ui/core/styles';
import Firebase from '../../../api/firebase';
import { RouteComponentProps } from '@reach/router';

const useStyles = makeStyles(({ spacing, palette }: Theme) =>
    createStyles({
        root: {
            backgroundImage: 'linear-gradient(to bottom, #b0def6, #cfeaf9 12%, #e0f0fb 16%, #f3f7fd 26%)',
            background: 'transparent',
            padding: spacing(3),
        },
        plan: {
            maxWidth: 360,
            width: '100%',
            padding: spacing(3),
        },
        planTitle: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            '&::after': {
                height: '2px',
                width: '100px',
                content: "''",
                backgroundColor: palette.primary.main,
                display: 'flex',
                margin: '1rem',
            },
        },
        card: {
            transition: 'all 0.2s linear 0s',
            '&:hover': {
                boxShadow: 'rgba(149, 189, 225, 0.79) 0px 22px 44px 0px',
                backgroundImage: 'linear-gradient(rgb(149, 212, 245), rgb(110, 173, 248))',
                transform: 'scale(1.02)',
                transition: 'all 0.2s linear 0s',
                '& h5': {
                    color: '#fff !important',
                    fontWeight: 600,
                },
                '& h2': {
                    color: '#fff !important',
                    fontWeight: 600,
                },
                '& span': {
                    color: '#fff !important',
                },
                '& a': {
                    borderColor: '#fff !important',
                },
            },
        },
        cardContent: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
        },
        cardAction: {
            justifyContent: 'center',
            padding: `${spacing(4)}px 0`,
        },
        trialText: {
            fontWeight: 600,
        },
    })
);

const getSubscriptionPrices = async () => {
    const result = await Firebase.functions.httpsCallable('getSubscriptionPrices')();
    if (result !== undefined) {
        const allPrices: any[] = result.data?.data?.map((plan: any) => {
            return {
                id: plan.id,
                name: plan.nickname,
                amount: parseInt(plan.unit_amount),
                currency: plan.currency,
                trialPeriodDays: plan.recurring.trial_period_days,
                interval: plan.recurring.interval,
                unit: parseInt(plan.metadata.unit),
            };
        });
        allPrices.sort((a: any, b: any) => a.unit - b.unit);
        return allPrices;
    }

    return [];
};

const SubscriptionsScreen = observer((props: RouteComponentProps) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [inProgress, setInProgress] = useState<boolean>(true);
    const [subscriptionPlans, setSubscriptionPlans] = useState<any>();

    useEffect(() => {
        async function loadPrices() {
            try {
                const prices = await getSubscriptionPrices();
                setSubscriptionPlans(prices);
            } finally {
                setInProgress(false);
            }
        }
        loadPrices();
    }, []);

    if (inProgress) {
        return <LoadingInfo />;
    }

    return (
        <Grid container classes={{ root: classes.root }} justify="center">
            <Grid container item xs={12} justify="center" direction="column" alignItems="center">
                <Typography variant="h4" color="primary" gutterBottom className={classes.planTitle}>
                    {t('abos')}
                </Typography>
                <Typography>{t('priceExclMwSt')}</Typography>
                <Typography>{t('abos-description')}</Typography>
            </Grid>
            {subscriptionPlans.map((plan: any, index: number) => {
                return (
                    <Grid item key={index} classes={{ root: classes.plan }}>
                        <Card classes={{ root: classes.card }}>
                            <CardContent classes={{ root: classes.cardContent }}>
                                <Typography
                                    variant="h5"
                                    color="primary"
                                    gutterBottom
                                    style={{ textTransform: 'uppercase' }}
                                >
                                    {plan.name}
                                </Typography>
                                <Typography variant="h2" component="h2" color="primary" gutterBottom>
                                    {plan.amount / 100 + ' ' + plan.currency.toUpperCase()} /{' '}
                                    <span style={{ fontSize: '18px' }}>{t(plan.interval)}</span>
                                </Typography>
                                <List>
                                    <ListItem style={{ textAlign: 'center' }}>
                                        <ListItemText primary={t('project-planning')} />
                                    </ListItem>
                                    <ListItem style={{ textAlign: 'center' }}>
                                        <ListItemText primary={t('resource-planning')} />
                                    </ListItem>
                                    <ListItem style={{ textAlign: 'center' }}>
                                        <ListItemText primary={t('task-management')} />
                                    </ListItem>
                                    <ListItem style={{ textAlign: 'center' }}>
                                        <ListItemText primary={`Max. ${plan.unit} ${t('employees')}`} />
                                    </ListItem>
                                    <ListItem style={{ textAlign: 'center' }}>
                                        <ListItemText
                                            classes={{ primary: classes.trialText }}
                                            primary={`${plan.trialPeriodDays} ${t('days-free-trial')}`}
                                        />
                                    </ListItem>
                                </List>
                            </CardContent>
                            <CardActions classes={{ root: classes.cardAction }}>
                                <Button variant="outlined" color="primary" href={`/register?p=${plan.name}`}>
                                    {t('select')}
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                );
            })}
        </Grid>
    );
});

export default SubscriptionsScreen;
