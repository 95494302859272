import { runInAction, action, observable, computed } from 'mobx';
import { AppError } from './appError';
import Validator from 'validatorjs';
import { Note } from './note';
import { Image } from './image';
import { imageService } from '../../api/imageService';
import { noteService } from '../../api/noteService';
import { sessionStorageService } from '../../sessionStorageService';
import { mapTrackingFields } from '../utils/generated/trackingFields.generated';
import { ImageCategoryType } from '../../api/dtos/generated/dtos.generated';
import { storageService } from '../../api/storageService';
import { notesCollectionName } from '../helpers/collectionNames';
import baseService from '../../api/baseService';

export class BaseViewModel {
    @observable public error?: AppError = undefined;
    @observable public isLoading: boolean = false;
    @observable public images: Image[] = [];
    @observable public notes: Note[] = [];
    @observable public successMessage?: string = '';
    @observable public validationErrors: { [index: string]: any } = {};

    @action
    public async handle(resp: Promise<any>, showSuccess?: boolean) {
        try {
            this.isLoading = true;
            this.error = undefined;
            const result = await resp;
            if (result !== undefined) {
                const data = result.data;
                if (data.code === '200') {
                    if (data['message'] !== undefined) {
                        runInAction(() => (this.successMessage = data.message));
                    }
                    return data;
                } else {
                    throw data;
                }
            }
        } catch (error) {
            runInAction(() => {
                if (error.details != null) {
                    this.error = {
                        key: error.details,
                        name: error.details,
                        message: error.message,
                    };
                } else {
                    this.error = {
                        key: error.code,
                        name: error.code,
                        message: error.message,
                    };
                }
            });
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    }

    @computed
    public get isValid() {
        return Object.keys(this.validationErrors).length === 0;
    }

    public static getValidator(data: any, rules: any, attributeNames: any) {
        const validator = new Validator(data, rules);
        validator.setAttributeNames(attributeNames);

        return validator;
    }

    @action
    public async loadImages(id: string) {
        this.isLoading = true;

        try {
            const images = await imageService.getImagesByRef(id);
            if (images.length > 0) {
                for (let i = 0; i < images.length; i++) {
                    const img = Image.createFromDto(images[i]);
                    this.images.push(img);
                }
            }
        } catch (error) {
            runInAction(() => (this.error = error));
        } finally {
            runInAction(() => (this.isLoading = false));
        }
    }

    @action
    public async loadNotes(id: string, collectionName: string) {
        this.isLoading = true;
        this.error = undefined;

        try {
            const noteDtos = await noteService.getNotesByDocId(id, collectionName);
            if (noteDtos.length > 0) {
                for (let i = 0; i < noteDtos.length; i++) {
                    const note = Note.createFromDto(noteDtos[i]);
                    this.notes.push(note);
                }
            }
        } catch (error) {
            this.error = error;
        } finally {
            this.isLoading = false;
        }
    }

    @action
    public async addOrUpdateImage(image: Image, refId: string, imgCategoryType: ImageCategoryType, tenantId: string) {
        const existingImage = this.images.find((x) => x.id === image.id);

        if (existingImage) {
            await this.updateImage(existingImage, image.description, tenantId);
        } else {
            await this.addImage(image, refId, imgCategoryType, tenantId);
        }
    }

    @action
    public async addImage(image: Image, refId: string, imgCategoryType: ImageCategoryType, tenantId: string) {
        const loginId = sessionStorageService.getLoginId();
        image.isLoading = true;
        try {
            image.imageCategoryType = imgCategoryType;
            image.refId = refId;
            mapTrackingFields(image, loginId!, true);
            this.images.push(image);
            await storageService.uploadImageToGallery(
                image.id,
                image.changedImage!,
                image.description,
                refId,
                imgCategoryType,
                image.imageType
            );

            const img = await imageService.getImage(image.id);
            if (img && img.imageHash) {
                image.imageHash = img.imageHash;
            }
        } catch (error) {
            image.error = error;
            this.images = this.images.filter((x) => x.id !== image.id);
        } finally {
            image.isLoading = false;
        }
    }

    @action
    public async updateImage(image: Image, description: string, tenantId: string) {
        const loginId = sessionStorageService.getLoginId();
        try {
            image.description = description;
            mapTrackingFields(image, loginId!, false);
            await imageService.upsertImage(image.toDto(tenantId));
        } catch (error) {
            image.error = error;
        }
    }

    @action
    public async deleteImage(image: Image) {
        const index = this.images.findIndex((x) => x.id === image.id);
        if (index > -1) {
            try {
                image.isLoading = true;
                await storageService.deleteImageFromGallery(image.id);
            } catch (error) {
                image.error = error;
            } finally {
                this.images.splice(index, 1);
                image.isLoading = false;
            }
        }
    }

    @action
    public async addOrUpdateNote(note: Note, id: string, collectionName: string, tenantId: string) {
        const existingNote = this.notes.find((x) => x.id === note.id);
        const loginId = sessionStorageService.getLoginId();
        note.isLoading = true;
        try {
            if (existingNote) {
                existingNote.description = note.description;
                existingNote.title = note.title;
                mapTrackingFields(existingNote, loginId!, false);
                await noteService.upsertNote(existingNote.toDto(tenantId), id, collectionName);
            } else {
                mapTrackingFields(note, loginId!, true);
                this.notes.push(note);
                await noteService.upsertNote(note.toDto(tenantId), id, collectionName);
            }
        } catch (error) {
            runInAction(() => {
                note.error = error;
                this.notes = this.notes.filter((x) => x.id !== note.id);
            });
        } finally {
            note.isLoading = false;
        }
    }

    @action
    public async deleteNote(note: Note, id: string, collectionName: string) {
        const index = this.notes.findIndex((x) => x.id === note.id);
        if (index > -1) {
            try {
                note.isLoading = true;
                await baseService.deleteEntityDeep(id, note.id, collectionName, notesCollectionName);
            } catch (error) {
                note.error = error;
            } finally {
                this.notes.splice(index, 1);
                note.isLoading = false;
            }
        }
    }
}
