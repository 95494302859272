import React, { useEffect, useState } from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { Avatar, Tooltip } from '@material-ui/core';
import imageCache from '../../imageCache';

export interface IAvatarItemProps {
    imagePath?: string;
    imageHash?: string | null;
    className?: string;
    label?: string;
    defaultImage?: any;
    size?: 'small' | 'medium' | 'large';
}

export const useStyles = makeStyles<Theme, any>(({ spacing }: Theme) =>
    createStyles({
        avatar: {
            width: (props: any) => spacing(getSpacing(props.size)),
            height: (props: any) => spacing(getSpacing(props.size)),
        },
    })
);

const getSpacing = (size: string) => {
    return size === 'small' ? 3.5 : size === 'medium' ? 5 : 6;
};

const AvatarItem = ({ imagePath, imageHash, className, label, defaultImage, size = 'medium' }: IAvatarItemProps) => {
    const classes = useStyles({ size });

    const [imageUrl, setImageUrl] = useState<string>();
    const [imageUrlLoaded, setImageUrlLoaded] = useState<boolean>();

    useEffect(() => {
        async function getImageUrlFromCache(imagePath: string, imageHash: string) {
            const url = await imageCache.getUrl(imagePath, imageHash);
            setImageUrl(url);
            setImageUrlLoaded(true);
        }

        if (imagePath && imageHash) {
            getImageUrlFromCache(imagePath, imageHash);
        } else {
            setImageUrl(undefined);
            setImageUrlLoaded(false);
        }
    }, [imageHash, imagePath]);

    const shortLabel = () => {
        if (label) {
            const parts = label.split(' ');

            let newLabel = '';
            parts.forEach((p) => {
                newLabel = newLabel + p.substring(0, 1);
            });

            if (newLabel.length > 2) {
                newLabel = newLabel.substring(0, 2);
            }

            return newLabel;
        }

        return null;
    };

    if (!!imagePath && !imageUrlLoaded) {
        return null;
    }

    const avatar =
        !!imageUrl || !!defaultImage ? (
            <Avatar
                alt="image"
                src={imageUrl ? imageUrl : defaultImage}
                className={className ? className : classes.avatar}
            />
        ) : (
            <Avatar className={className ? className : classes.avatar}>{shortLabel()}</Avatar>
        );

    return <div>{label ? <Tooltip title={label}>{avatar}</Tooltip> : avatar}</div>;
};

export default AvatarItem;
