import { Drawer, Theme, createStyles, makeStyles, Box } from '@material-ui/core';
import classNames from 'classnames';
import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import NavItem from './NavItem';
import MenuList from '@material-ui/core/MenuList';
import Divider from '@material-ui/core/Divider';
import {
    DashboardIcon,
    PlanningIcon,
    ProjectsIcon,
    TasksIcon,
    CustomersIcon,
    EmployeesIcon,
    ResourceIcon,
} from '../../common/components/SvgIcons';
import { StoreContext } from '../../App';
import { useTranslation } from 'react-i18next';
import TrialSection from './TrialSection';
import SidebarHeader from './SidebarHeader';

const useStyles = makeStyles(({ transitions, palette, mixins, spacing }: Theme) =>
    createStyles({
        innerFixed: {
            position: 'fixed',
            height: '100%',
            width: drawerWidth,
            transition: transitions.create('width', {
                easing: transitions.easing.sharp,
                duration: transitions.duration.enteringScreen,
            }),
        },
        innerFixedClose: {
            position: 'fixed',
            width: spacing(9),
            height: '100%',
        },
        innerScrollable: {
            overflowY: 'auto',
            overflowX: 'hidden',
            height: '100%',
        },
        drawerPaper: {
            width: drawerWidth,
            position: 'relative',
            whiteSpace: 'nowrap',
            transition: transitions.create('width', {
                easing: transitions.easing.sharp,
                duration: transitions.duration.enteringScreen,
            }),
            color: palette.primary.main,
            border: 'none',
            boxShadow: '0 3px 15px 0 rgba(180, 192, 210, 0.26)',
        },
        drawerPaperClose: {
            overflowX: 'hidden',
            transition: transitions.create('width', {
                easing: transitions.easing.sharp,
                duration: transitions.duration.leavingScreen,
            }),

            width: spacing(9),
        },
    })
);

const drawerWidth = 270;

const Sidebar = observer(() => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { uiStore, authStore } = useContext(StoreContext);

    return (
        <Drawer
            variant="permanent"
            classes={{
                paper: classNames(classes.drawerPaper, !uiStore.isDrawerOpen && classes.drawerPaperClose),
            }}
            open={uiStore.isDrawerOpen}
        >
            <div className={uiStore.isDrawerOpen ? classes.innerFixed : classes.innerFixedClose}>
                <div className={classes.innerScrollable}>
                    <SidebarHeader />
                    <Divider variant={'middle'} light />
                    <MenuList>
                        <NavItem
                            small={!uiStore.isDrawerOpen}
                            path={'/'}
                            icon={<DashboardIcon />}
                            text={t('dashboard')}
                        />
                        <NavItem
                            small={!uiStore.isDrawerOpen}
                            path={'/planning'}
                            icon={<PlanningIcon />}
                            text={t('planning')}
                        />
                        <NavItem small={!uiStore.isDrawerOpen} path={'/task'} icon={<TasksIcon />} text={t('tasks')} />
                        <Box marginBottom={1} marginTop={1}>
                            <Divider variant={'middle'} light />
                        </Box>
                        {authStore.isAdmin && (
                            <NavItem
                                small={!uiStore.isDrawerOpen}
                                path={'/projects'}
                                icon={<ProjectsIcon />}
                                text={t('projects')}
                            />
                        )}
                        <NavItem
                            small={!uiStore.isDrawerOpen}
                            path={'/customers'}
                            icon={<CustomersIcon />}
                            text={t('customers')}
                        />
                        {authStore.isAdmin && (
                            <NavItem
                                small={!uiStore.isDrawerOpen}
                                path={'/employee'}
                                icon={<EmployeesIcon />}
                                text={t('employees')}
                            />
                        )}
                        <NavItem
                            small={!uiStore.isDrawerOpen}
                            path={'/resource'}
                            icon={<ResourceIcon />}
                            text={t('resources')}
                        />
                    </MenuList>
                    {authStore.isAdmin && <TrialSection />}
                </div>
            </div>
        </Drawer>
    );
});

export default Sidebar;
