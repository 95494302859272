import React, { useContext } from 'react';
import LoginScreen from './components/SignInScreen';
import SignUpScreen from './components/SignUpScreen';
import SubscriptionScreen from './components/SubscriptionsScreen';
import ForgotPasswordScreen from './components/ForgotPasswordScreen';
import { Router } from '@reach/router';
import { createStyles, Theme } from '@material-ui/core';
import ToastNotifications from '../../common/components/ToastNotifications';
import UnactiveTenantScreen from './components/UnactiveTenantScreen';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { StoreContext } from '../../App';
import { observer } from 'mobx-react-lite';
import LogoAnimate from '../../common/components/LogoAnimate';
import CanceledTenantScreen from './components/CanceledTenantScreen';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            backgroundImage: 'linear-gradient(to bottom, #b0def6, #cfeaf9 12%, #e0f0fb 16%, #f3f7fd 26%)',
            background: 'transparent',
            minHeight: '100vh',
        },
    })
);

interface IAuthProps {
    children(): JSX.Element;
}

const AuthModule = observer((props: IAuthProps) => {
    const classes = useStyles();
    const { authStore, tenantStore } = useContext(StoreContext);

    if (
        authStore.isAuthenticated &&
        authStore.isEmailVerified &&
        tenantStore.tenant &&
        tenantStore.tenant.subscription.status !== 'canceled' &&
        tenantStore.tenant.subscription.status !== 'past_due'
    ) {
        return props.children();
    } else if (tenantStore.tenant && tenantStore.tenant.subscription.status === 'past_due') {
        return (
            <div className={classes.root}>
                <ToastNotifications />
                <UnactiveTenantScreen path={'/'} />
            </div>
        );
    } else if (tenantStore.tenant && tenantStore.tenant.subscription.status === 'canceled') {
        return (
            <div className={classes.root}>
                <ToastNotifications />
                <CanceledTenantScreen />
            </div>
        );
    }

    return (
        <div className={classes.root}>
            {authStore!.initialLoginAttemptInProgress ? (
                <LogoAnimate />
            ) : (
                <Router>
                    <LoginScreen path={'/'} default />
                    <SubscriptionScreen path={'/subscriptions'} />
                    <SignUpScreen path={'/register/*'} />
                    <ForgotPasswordScreen path={'/forgotpassword'} />
                </Router>
            )}
            <ToastNotifications />
        </div>
    );
});

export default AuthModule;
