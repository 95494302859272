export const tenantsCollectionName = 'tenants';
export const projectsCollectionName = 'projects';
export const customersCollectionName = 'customers';
export const employeesCollectionName = 'employees';
export const resourcesCollectionName = 'resources';
export const tasksCollectionName = 'tasks';
export const notesCollectionName = 'notes';
//export const customerAddressCollectionName = 'addresses';
export const imagesCollectionName = 'images';
export const planningBoardItemsCollectionName = 'planningBoardItems';
export const planningSerieCollectionName = 'planningSeries';
export const employeeRefsCollectionName = 'employeeRefs';
export const resourceRefsCollectionName = 'resourceRefs';
