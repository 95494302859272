import React, { SyntheticEvent, useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { IconButton, InputAdornment, Theme, Typography, CircularProgress, createStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Link, RouteComponentProps } from '@reach/router';
import VisibilityOff from 'mdi-material-ui/EyeOffOutline';
import VisibilityOn from 'mdi-material-ui/EyeOutline';
import Button from '@material-ui/core/Button';
import PlanuxLogo from '../../../assets/planux_logo.svg';
import Zoom from '@material-ui/core/Zoom';
import green from '@material-ui/core/colors/green';
import ErrorMessage from '../../../common/components/ErrorMessage';
import { SignInScreenVm } from './signInScreen.vm';
import { centerPaper } from '../../../common/hocs/centerPaper';
import TopProgress from '../../../common/components/TopProgress';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { observer } from 'mobx-react-lite';
import { StoreContext } from '../../../App';
import { useDocumentSubTitle } from '../../../common/hooks/useDocumentTitle';
import { useVm } from '../../../common/hooks/useVm';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '370px',
            [theme.breakpoints.down('xs')]: {
                width: '100%',
            },
        },
        logo: {
            width: '100%',
            maxWidth: '154px',
            alignSelf: 'center',
            margin: theme.spacing(2),
        },
        buttonProgress: {
            color: green[500],
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -12,
            marginLeft: -12,
        },
    })
);

type Props = RouteComponentProps;

const SignInScreen = observer((props: Props) => {
    const { authStore } = useContext(StoreContext);
    const { t } = useTranslation();
    const classes = useStyles();
    useDocumentSubTitle(t('login'));

    const vm = useVm(SignInScreenVm, authStore) as SignInScreenVm;

    if (vm.initialLoginAttemptInProgress) {
        return <CircularProgress />;
    }

    const handlerUsernameChange = (event: any): void => {
        const { value } = event.target;
        vm.setEmail(value);
    };

    const handlerPasswordChange = (event: any): void => {
        const { value } = event.target;
        vm.setPassword(value);
    };

    const handleLoginClick = (event: SyntheticEvent<any>) => {
        event.preventDefault();
        vm.signIn();
    };

    return (
        <div className={classes.root}>
            <TopProgress
                inProgress={vm.isLoading}
                style={{ position: 'absolute', top: '0px', left: '0', right: '0' }}
            />
            <form noValidate onSubmit={handleLoginClick}>
                <Grid container={true} direction={'column'} spacing={3} wrap={'nowrap'}>
                    <Grid item container justify={'center'}>
                        <Zoom in timeout={500}>
                            <img className={classes.logo} src={PlanuxLogo} alt="logo" />
                        </Zoom>
                    </Grid>
                    <Grid item>
                        <TextField
                            variant={'outlined'}
                            fullWidth={true}
                            id="email"
                            label={t('email')}
                            disabled={vm.isLoading}
                            error={!!vm.validationErrors.email}
                            helperText={vm.validationErrors.email}
                            value={vm.email}
                            onChange={handlerUsernameChange}
                            required={true}
                            autoFocus={true}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            id="password"
                            fullWidth={true}
                            variant={'outlined'}
                            type={vm.showPassword ? 'text' : 'password'}
                            label={t('password')}
                            value={vm.password}
                            onChange={handlerPasswordChange}
                            disabled={vm.isLoading}
                            error={!!vm.validationErrors.password}
                            helperText={vm.validationErrors.password}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label={t('toggle-password-visibility')}
                                            onClick={() => vm.toggleShowPassword()}
                                        >
                                            {vm.showPassword ? <VisibilityOff /> : <VisibilityOn />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <ErrorMessage appError={vm.error} />
                    <Grid item>
                        <Button
                            size={'large'}
                            variant={'contained'}
                            type={'submit'}
                            color={'primary'}
                            fullWidth
                            disabled={vm.isLoading}
                        >
                            {t('login')}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Typography align={'center'}>
                            <Link to="/register">{t('register')}</Link>
                            &nbsp;|&nbsp;
                            <Link to="/forgotpassword">{t('forgot-password')}</Link>
                        </Typography>
                    </Grid>
                </Grid>
            </form>
        </div>
    );
});

export default centerPaper(SignInScreen);
