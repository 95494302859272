import { action, computed, observable, runInAction } from 'mobx';
import * as uuid from 'uuid';
import i18n from 'i18next';
import Validator from 'validatorjs';
import { BaseModel } from '../stores/model/baseModel';
import { mapTrackingFieldsToModel } from '../helpers/trackingFields';
import { ImageDto, ImageCategoryType, ImageType } from '../../api/dtos/generated/dtos.generated';
import { validateField, validate } from '../helpers/validationHelper';
import { getImagesPath } from '../helpers/storageHelper';
import { AppError } from './appError';

export class Image extends BaseModel {
    private static rules = {
        description: 'max:1000',
    };

    private attributeNames = {
        description: i18n.t('description'),
    };

    private getValidator(data: any, rules: any) {
        const validator = new Validator(data, rules);
        validator.setAttributeNames(this.attributeNames);
        return validator;
    }

    id: string;
    @observable description: string = '';
    @observable validationErrors: { [index: string]: any } = {};
    @observable isDirty: boolean = false;
    @observable isNew: boolean = false;
    @observable changedImage?: string;
    @observable imageHash?: string;
    @observable refId?: string;
    @observable imageCategoryType?: ImageCategoryType;
    @observable isLoading: boolean = false;
    @observable public error?: AppError = undefined;

    public static createFromDto(dto: ImageDto): Image {
        const image = new Image(dto.id);
        runInAction(() => {
            image.isNew = false;
            image.description = dto.description ? dto.description : '';
            image.imageHash = dto.imageHash ? dto.imageHash : undefined;
            image.imageCategoryType = dto.imageCategoryType ? dto.imageCategoryType : undefined;
            image.refId = dto.refId ? dto.refId : undefined;
        });
        mapTrackingFieldsToModel(image, dto);
        return image;
    }

    constructor(id: string = uuid.v4()) {
        super();
        this.id = id;
        runInAction(() => {
            this.isNew = true;
        });
    }

    @action
    updateProperty(field: string, value: any) {
        if (!this.isDirty) {
            this.isDirty = true;
        }

        (this as any)[field] = value;
        if (!!(Image.rules as any)[field]) {
            const validator = this.getValidator({ [field]: value }, { [field]: (Image.rules as any)[field] });
            this.validationErrors = validateField(validator, field, this.validationErrors);
        }
    }

    @action
    validateAll(data: any) {
        const validator = this.getValidator(data, Image.rules);
        this.validationErrors = validate(validator);
    }

    @computed get isValid() {
        return Object.keys(this.validationErrors).length === 0;
    }

    @computed get imagePath(): string | undefined {
        return this.imageHash ? getImagesPath(this.id) : undefined;
    }

    @computed get imageCardPath(): string | undefined {
        return this.imageHash ? getImagesPath(this.id, true, '_300x300') : undefined;
    }

    @computed get imageThumbPath(): string | undefined {
        return this.imageHash ? getImagesPath(this.id, true, '_64x64') : undefined;
    }

    @computed get imageHdPath(): string | undefined {
        return this.imageHash ? getImagesPath(this.id, true, '_1920x1080') : undefined;
    }

    @computed get imageType(): ImageType {
        return this.changedImage!.substring('data:image/'.length, this.changedImage!.indexOf(';base64')) === 'png'
            ? ImageType.Png
            : ImageType.Jpeg;
    }

    toDto(tenantId: string): ImageDto {
        return {
            id: this.id,
            description: this.description,
            imageHash: this.imageHash ? this.imageHash : null,
            refId: this.refId,
            imageCategoryType: this.imageCategoryType,
            tenantId: tenantId,
        };
    }
}
