import { action, computed, observable, reaction, runInAction, toJS } from 'mobx';
import { DashboardStore } from '../../stores/dashboardStore';
import { TaskFilter } from '../../../tasks/models/taskFilter';
import { Task } from '../../../tasks/models/task';
import { calculateTasks, TaskWrapper } from '../../../../common/utils/taskCalculator';

class TaskInfoModel {
    public dashboardStore: DashboardStore;

    @observable public plannedTasks: Task[] = [];

    constructor(dashboardStore: DashboardStore) {
        this.dashboardStore = dashboardStore;

        reaction(
            () => this.dashboardStore.store.tasksStore.latestChange,
            () => this.calculateVirtualTasks()
        );

        reaction(
            () => toJS(this.dashboardStore.filter),
            () => this.calculateVirtualTasks()
        );

        reaction(
            () => toJS(this.filter),
            () => this.calculateVirtualTasks()
        );
    }

    @computed
    public get openCount(): number {
        return this.plannedTasks.filter((x) => x.state === 'open').length;
    }

    @computed
    public get inProcessCount(): number {
        return this.plannedTasks.filter((x) => x.state === 'inprocess').length;
    }

    @computed
    public get hasTasks(): boolean {
        return this.plannedTasks.length > 0;
    }

    @computed
    public get doneCount(): number {
        return this.plannedTasks.filter((x) => x.state === 'done').length;
    }

    @computed
    public get filter() {
        return new TaskFilter(this.dashboardStore.filter.startDate, this.dashboardStore.filter.endDate, [
            'open',
            'inprocess',
            'done',
        ]);
    }

    @computed
    public get donePercentText() {
        const sum = this.plannedTasks.length;
        const percent = (this.doneCount / sum) * 100;
        const fixed = percent > 1 || Number.isInteger(percent) ? 0 : 2;
        return `${percent.toFixed(fixed)} %`;
    }

    @action
    private async calculateVirtualTasks() {
        const virtualTasks = await calculateTasks(this.dashboardStore.store.tasksStore.tasks, this.filter);

        const convert = (taskWrappers: TaskWrapper[]): Promise<Task[]> => {
            const tasks = taskWrappers.map((taskWrapper: TaskWrapper) => {
                const task = Task.createFromDto(taskWrapper.task);
                task.isVirtual = taskWrapper.isCalculated;
                return task;
            });

            return Promise.resolve(tasks);
        };

        const plannedTasks = await convert(virtualTasks);

        runInAction(() => {
            this.plannedTasks = plannedTasks;
        });
    }
}

export default TaskInfoModel;
