import { endOfMonth, endOfWeek, isSameDay, startOfMonth, startOfWeek } from 'date-fns';

export type DateRangeSelectionType = 'today' | 'calendarWeek' | 'calendarMonth' | 'customized';

export const getDateRangeSelectionType = (start: Date, end: Date): DateRangeSelectionType => {
    const today = new Date();

    if (isSameDay(start, end) && isSameDay(start, today)) {
        return 'today';
    }

    if (
        isSameDay(startOfWeek(today, { weekStartsOn: 1 }), start) &&
        isSameDay(endOfWeek(today, { weekStartsOn: 1 }), end)
    ) {
        return 'calendarWeek';
    }

    if (isSameDay(startOfMonth(today), start) && isSameDay(endOfMonth(today), end)) {
        return 'calendarMonth';
    }

    return 'customized';
};
