import { Grid, Theme } from '@material-ui/core';
import { Palette } from '@material-ui/core/styles/createPalette';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import WarningIcon from 'mdi-material-ui/AlertOutline';
import ErrorIcon from 'mdi-material-ui/AlertCircleOutline';
import SuccessIcon from 'mdi-material-ui/Check';
import InfoIcon from 'mdi-material-ui/InformationOutline';
import React from 'react';

export type InfoType = 'info' | 'warning' | 'error' | 'success';

const getColor = (infoType: InfoType, palette: Palette) => {
    switch (infoType) {
        case 'error':
            return palette.error.main;
        case 'warning':
            return '#ff9800';
        case 'success':
            return '#2E7D32';
        default:
            return palette.primary.main;
    }
};

export const useStyles = makeStyles(({ spacing, palette }: Theme) =>
    createStyles({
        root: ({ infoType }: { infoType: InfoType }) => ({
            padding: spacing(1),
            border: '1px solid ' + getColor(infoType, palette),
            borderRadius: spacing(0.5)
        }),
        icon: ({ infoType }: { infoType: InfoType }) => ({
            color: getColor(infoType, palette),
            marginRight: spacing(1)
        })
    })
);

interface IInfoBoxProps {
    message?: string;
    control?: JSX.Element;
    infoType: InfoType;
}

export const NotificationBox = ({ infoType, message, control }: IInfoBoxProps) => {
    const classes = useStyles({ infoType });

    return (
        <Grid container={true} className={classes.root} wrap={'nowrap'} alignItems={'center'}>
            <Grid item={true}>
                {infoType === 'info' && <InfoIcon color={'inherit'} className={classes.icon} data-testid={infoType} />}
                {infoType === 'warning' && (
                    <WarningIcon color={'inherit'} className={classes.icon} data-testid={infoType} />
                )}
                {infoType === 'error' && (
                    <ErrorIcon color={'inherit'} className={classes.icon} data-testid={infoType} />
                )}
                {infoType === 'success' && (
                    <SuccessIcon color={'inherit'} className={classes.icon} data-testid={infoType} />
                )}
            </Grid>
            <Grid item={true}>
                {message && <Typography variant={'body1'}>{message}</Typography>}
                {control && control}
            </Grid>
        </Grid>
    );
};
