import { action, observable, toJS, computed } from 'mobx';
import { validate } from '../../../common/helpers/validationHelper';
import i18n from 'i18next';
import Firebase from '../../../api/firebase';
import { AuthStore } from '../stores/authStore';
import { BaseViewModel } from '../../../common/models/baseViewModel.vm';
import { v4 } from 'uuid';
import { getLanguage } from '../../../common/utils/languageHelper';

export class SignUpScreenVm extends BaseViewModel {
    private authStore: AuthStore;
    @observable public email: string = '';
    @observable public password: string = '';
    @observable public tenantName: string = '';
    @observable public showPassword: boolean = false;
    @observable public tos: boolean = false;
    @observable subscriptionPrices: any[] = [];
    @observable planId: string = '';
    @observable planName: string = 'Starter';
    @observable planAmount: number = 0;
    @observable planCurrency: string = '';
    @observable planUnit: number = 0;
    @observable planTrialPeriodDays: number = 0;
    @observable planInterval: string = '';
    @observable promotionId: string | undefined = undefined;

    constructor(authStore: AuthStore, params: any) {
        super();
        this.authStore = authStore;
        if (params !== undefined && params.p !== undefined) {
            this.planName = params.p;
        }
    }

    @action
    public toggleShowPassword() {
        this.showPassword = !this.showPassword;
    }

    @action
    public setEmail(email: string) {
        this.email = email;
    }

    @action
    public setPassword(password: string) {
        this.password = password;
    }

    @action
    public setTenantName(tenantName: string) {
        this.tenantName = tenantName;
    }

    @action
    public setTos(checked: boolean) {
        this.tos = checked;
    }

    @action
    public setPromotionId(promotionId: string | undefined) {
        this.promotionId = promotionId;
    }

    @action
    private validateAll(data: any) {
        let rules = {
            tenantName: 'required|max:255',
            email: 'required|email|max:255',
            password: 'required|min:6|max:255',
            tos: 'accepted',
        };

        const attributeNames = {
            tenantName: i18n.t('organization'),
            email: i18n.t('email'),
            password: i18n.t('password'),
            tos: i18n.t('tos'),
        };

        const validator = SignUpScreenVm.getValidator(data, rules, attributeNames);
        this.validationErrors = validate(validator);
    }

    @action
    public async signUp() {
        this.validateAll(toJS(this));
        if (this.isValid) {
            console.log('vm promotionId: ' + this.promotionId);

            const signup = Firebase.functions.httpsCallable('signup');
            const result = signup({
                tenantName: this.tenantName,
                email: this.email,
                password: this.password,
                lang: getLanguage(i18n.language),
                subscriptionPlanId: this.planId,
                employeeId: v4(),
                employeeCompletedProfile: false,
                role: 'admin',
                promotionId: this.promotionId,
            });
            const resp = await this.handle(result);
            if (resp !== undefined) {
                this.successMessage = resp.details;
            }
        }
    }

    @computed get price() {
        return this.subscriptionPrices.find((plan: any) => plan.name === this.planName);
    }

    @computed
    get hasSubscriptionPlans() {
        return this.subscriptionPrices.length > 0;
    }

    @action
    public async getSubscriptionPlans() {
        const plans = Firebase.functions.httpsCallable('getSubscriptionPrices');
        const result = plans();
        const resp = await this.handle(result);
        if (resp !== undefined) {
            this.subscriptionPrices = resp.data.map((price: any) => {
                return {
                    id: price.id,
                    name: price.nickname,
                    amount: price.unit_amount,
                    currency: price.currency,
                    trialPeriodDays: price.recurring.trial_period_days,
                    interval: price.interval,
                    unit: price.metadata.unit,
                };
            });
            const plan = this.price;
            this.planId = plan.id;
            this.planAmount = plan.amount / 100;
            this.planCurrency = plan.currency;
            this.planInterval = plan.interval;
            this.planUnit = plan.unit;
            this.planTrialPeriodDays = plan.trialPeriodDays;
        }
    }
}
