import Firebase from '../../api/firebase';

export const getResourcesPath = (id: string, resized?: boolean): string => {
    return getPath('resources', id, resized, '_64x64');
};

export const getTenantPath = (id: string, resized?: boolean): string => {
    return getPath('company', id, resized, '_64x64');
};

export const getEmployeesPath = (id: string, resized?: boolean): string => {
    return getPath('employees', id, resized, '_64x64');
};

export const getProjectsPath = (id: string, resized?: boolean): string => {
    return getPath('projects', id, resized, '_64x64');
};

export const getImagesPath = (id: string, resized?: boolean, size?: string): string => {
    return getPath('images', id, resized, size);
};

export const getPath = (path: string, id: string, resized?: boolean, size?: string): string => {
    return `tenants/${Firebase.tenantId}/${path}/${!!resized && resized ? id + size : id}`;
};
