import Firebase from '../api/firebase';
import { AppError } from '../common/models/appError';
import { ImageDto } from './dtos/generated/dtos.generated';
import BaseService from './baseService';
import { imagesCollectionName } from '../common/helpers/collectionNames';

export const imageService = {
    getLatestImages: async (): Promise<ImageDto[]> => {
        try {
            const querySnapshot = await Firebase.firestore
                .collection('tenants')
                .doc(Firebase.tenantId)
                .collection(imagesCollectionName)
                .orderBy('createdAt', 'desc')
                .limit(10)
                .get();
            const items: ImageDto[] = querySnapshot.docs.map((x) => x.data() as ImageDto);
            return items;
        } catch (error) {
            return Promise.reject(new AppError('images-not-found'));
        }
    },

    getImagesByRef: async (refId: string): Promise<ImageDto[]> => {
        try {
            const querySnapshot = await Firebase.firestore
                .collection('tenants')
                .doc(Firebase.tenantId)
                .collection(imagesCollectionName)
                .where('refId', '==', refId)
                .orderBy('createdAt', 'desc')
                .get();
            const items: ImageDto[] = [];
            if (!querySnapshot.empty) {
                for (const doc of querySnapshot.docs) {
                    const model = doc.data() as ImageDto;
                    items.push(model);
                }
                return items;
            } else {
                return items;
            }
        } catch (error) {
            console.error(error);
            return Promise.reject(new AppError('images-not-found'));
        }
    },

    getImage: async (imageId: string): Promise<ImageDto | undefined> => {
        try {
            const snapshot = await Firebase.firestore
                .collection('tenants')
                .doc(Firebase.tenantId)
                .collection(imagesCollectionName)
                .doc(imageId)
                .get();

            if (snapshot.exists) {
                return snapshot.data() as ImageDto;
            }

            return undefined;
        } catch (error) {
            return Promise.reject(new AppError('image-not-found'));
        }
    },

    upsertImage: (image: ImageDto): Promise<void> => {
        return BaseService.upsertEntity(image.id, image, imagesCollectionName);
    },
};
