import { BaseDto } from '../../api/dtos/generated/dtos.generated';
import { BaseModel } from '../stores/model/baseModel';
import { parseDate } from '../utils/dateHelper';

export function mapTrackingFieldsToModel(model: BaseModel, dto: BaseDto): void {
    model.createdBy = dto.createdBy ? dto.createdBy : undefined;
    model.createdAt = dto.createdAt ? parseDate(dto.createdAt) : undefined;
    model.modifiedBy = dto.modifiedBy ? dto.modifiedBy : undefined;
    model.modifiedAt = dto.modifiedAt ? parseDate(dto.modifiedAt) : undefined;
    model.deletedBy = dto.deletedBy ? dto.deletedBy : undefined;
    model.deletedAt = dto.deletedAt ? parseDate(dto.deletedAt) : undefined;
}

export function mapTrackingFieldsToDto(dto: BaseDto, model: BaseModel): void {
    dto.createdBy = model.createdBy ? model.createdBy : undefined;
    dto.createdAt = model.createdAt ? model.createdAt : undefined;
    dto.modifiedBy = model.modifiedBy ? model.modifiedBy : undefined;
    dto.modifiedAt = model.modifiedAt ? model.modifiedAt : undefined;
    dto.deletedBy = model.deletedBy ? model.deletedBy : undefined;
    dto.deletedAt = model.deletedAt ? model.deletedAt : undefined;
}
