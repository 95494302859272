import { computed } from 'mobx';
import { Address } from '../../../../common/models/address';
import { RootStore } from '../../../../stores/rootStore';
import { Customer } from '../../../customers/models/customer';

export interface CustomerAddressMapMarker {
    id: string;
    name: string;
    address: Address;
}

export class CustomerMapWidgetVm {
    private rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
    }

    @computed
    public get customersMapMarker() {
        const customers = this.rootStore.customersStore.sortedCustomers;
        if (customers.length === 0) {
            return [];
        }

        const customersWithGeopoint: Customer[] = [];
        customers.forEach((customer) => {
            const customerWithGeo: Customer = customer;
            const addressesWithGeo = customerWithGeo.addresses.filter((address) => address.geopoint);
            customerWithGeo.addresses = addressesWithGeo;
            return customersWithGeopoint.push(customerWithGeo);
        });

        const customersMapMarker: CustomerAddressMapMarker[] = [];
        customersWithGeopoint.forEach((customer) => {
            const id = customer.id;
            const name = customer.displayName;
            return customer.addresses.forEach((address) => customersMapMarker.push({ id, name, address }));
        });

        return customersMapMarker;
    }

    @computed
    public get getFirstPosition() {
        return {
            lat: this.customersMapMarker[0]?.address?.geopoint?.latitude!,
            lng: this.customersMapMarker[0]?.address?.geopoint?.longitude!,
        };
    }

    @computed
    public get hasCustomers() {
        return this.customersMapMarker.length > 0;
    }
}
