import { BaseDto } from '../../../api/dtos/generated/dtos.generated';

export const mapTrackingFields = <T extends BaseDto>(entity: T, loginId?: string, isNew?: boolean) => {
    const now = new Date();

    if (isNew) {
        entity.createdBy = loginId;
        entity.createdAt = now;
    }

    entity.modifiedBy = loginId;
    entity.modifiedAt = now;
    entity.deletedAt = entity.deletedAt ? entity.deletedAt : null;
    entity.deletedBy = entity.deletedBy ? entity.deletedBy : null;
};

export const updateTrackingFields = <T extends BaseDto>(source: T, target: T) => {
    target.createdBy = source.createdBy;
    target.createdAt = source.createdAt;
    target.modifiedBy = source.modifiedBy;
    target.modifiedAt = source.modifiedAt;
    target.deletedAt = source.deletedAt;
    target.deletedBy = source.deletedBy;
};
