import { Drawer, Theme, createStyles, IconButton, Grid, Typography, Divider, makeStyles, Box } from '@material-ui/core';
import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { runInAction } from 'mobx';
import NavItem from './NavItem';
import MenuList from '@material-ui/core/MenuList';
import PlanuxLogo from '../../assets/planux_logo.svg';
import { Link } from '@reach/router';
import { Link as MuiLink } from '@material-ui/core';
import {
    DashboardIcon,
    PlanningIcon,
    ProjectsIcon,
    TasksIcon,
    CustomersIcon,
    EmployeesIcon,
    ResourceIcon,
    QuestionMarkIcon,
} from '../../common/components/SvgIcons';
import DefaultEmployeeAvatar from '../../assets/icons/defaultEmployeeAvatar.svg';
import CloseMenu from 'mdi-material-ui/ChevronLeft';
import AvatarItem from '../../common/components/AvatarItem';
import { LogoutIcon } from '../../common/components/SvgIcons';
import EditIcon from 'mdi-material-ui/Pencil';
import { StoreContext } from '../../App';
import { serviceDeskUrl } from '../../environmentConstants';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        drawer: {
            width: '80%',
            display: 'flex',
        },
        logoSection: {
            display: 'flex',
            padding: '1rem 2.6rem',
            justifyContent: 'flex-start',
            '& img': {
                maxWidth: '100px',
                width: '100%',
            },
        },
        closeIcon: {
            position: 'absolute',
            right: '0.5rem',
            top: '0.25rem',
        },
        bottomSection: {
            display: 'flex',
            justifyContent: 'center',
            height: '100%',
            alignItems: 'flex-end',
        },
        logoutSection: {
            padding: theme.spacing(0.5),
            width: '100%',
            borderTop: '1px solid rgba(0, 0, 0, 0.08)',
        },
        avatar: {
            width: '74px',
            height: '74px',
            borderRadius: '50%',
            border: '3px solid',
            borderColor: theme.palette.primary.main,
            background: '#fff',
            padding: '3px',
            margin: '1rem auto',
            '& img': {
                borderRadius: '50%',
            },
        },
        menu: {
            '& ul': {
                '& a:focus': {
                    outline: 'none',
                },
            },
            '& li': {
                fontSize: '0.8rem',
                minHeight: '35px',
            },
        },
        avatarContainer: {
            display: 'flex',
            justifyContent: 'center',
            position: 'relative',
            width: '74px',
            height: 'auto',
            margin: 'auto',
        },
        companyNameEmailConainer: {
            paddingBottom: '1rem',
        },
        companyName: {
            fontSize: '15px',
            fontWeight: 500,
            fontStyle: 'normal',
            color: '#398bf7',
        },
        companyEmail: {
            color: '#707070',
        },
        organizationButton: {
            position: 'absolute',
            bottom: 15,
            right: 0,
            width: 22,
            height: 22,
            boxShadow: '0 3px 15px 0 rgba(180, 192, 210, 0.47)',
            backgroundColor: '#ffffff',
            borderRadius: '50%',
            padding: 0,
            '&:hover': {
                backgroundColor: theme.palette.primary.main,
                '& svg': {
                    fill: '#ffffff',
                },
            },
        },
    })
);

const SidebarMobile = observer(() => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { uiStore, authStore, tenantStore } = useContext(StoreContext);

    const handleMenuClose = () => {
        runInAction(() => (uiStore!.anchorEl = undefined));
        runInAction(() => (uiStore!.isMobileMenuOpen = !uiStore!.isMobileMenuOpen));
    };

    const handleLogout = () => {
        runInAction(() => authStore!.logout() && (uiStore!.isMobileMenuOpen = false));
    };

    const handleOpenUserProfile = (id: string, tab?: number) => {
        uiStore.setSelectedEmployeeId(id, tab);
    };

    return (
        <Drawer open={uiStore.isMobileMenuOpen} onClose={handleMenuClose} classes={{ paper: classes.drawer }}>
            <IconButton onClick={handleMenuClose} className={classes.closeIcon}>
                <CloseMenu />
            </IconButton>

            <div className={classes.logoSection}>
                <img src={PlanuxLogo} alt="logo" />
            </div>

            <Divider variant={'middle'} light />
            {authStore.isUser ? (
                <div className={classes.avatarContainer}>
                    <AvatarItem
                        defaultImage={DefaultEmployeeAvatar}
                        imagePath={
                            tenantStore!.tenant
                                ? uiStore.isMobileMenuOpen
                                    ? tenantStore!.tenant.imagePath
                                    : tenantStore!.tenant.imageThumbPath
                                : undefined
                        }
                        imageHash={tenantStore!.tenant ? tenantStore!.tenant.imageHash : undefined}
                        className={classes.avatar}
                    />
                    <IconButton
                        onClick={() => handleOpenUserProfile(authStore.currentEmployee?.id!)}
                        className={classes.organizationButton}
                    >
                        <EditIcon style={{ fontSize: 15 }} color="primary" />
                    </IconButton>
                </div>
            ) : (
                <div className={classes.avatarContainer}>
                    <AvatarItem
                        defaultImage={DefaultEmployeeAvatar}
                        imagePath={
                            tenantStore!.tenant
                                ? uiStore.isMobileMenuOpen
                                    ? tenantStore!.tenant.imagePath
                                    : tenantStore!.tenant.imageThumbPath
                                : undefined
                        }
                        imageHash={tenantStore!.tenant ? tenantStore!.tenant.imageHash : undefined}
                        className={classes.avatar}
                    />
                    <Link to="/mytenant" style={{ textDecoration: 'none' }}>
                        <IconButton className={classes.organizationButton} onClick={handleMenuClose}>
                            <EditIcon style={{ fontSize: 15 }} color="primary" />
                        </IconButton>
                    </Link>
                </div>
            )}

            <Grid
                container
                direction={'column'}
                justify={'center'}
                alignItems={'center'}
                className={classes.companyNameEmailConainer}
                style={uiStore.isMobileMenuOpen ? { display: 'flex' } : { display: 'none' }}
            >
                <Grid item>
                    <Typography variant="body2" className={classes.companyName}>
                        {tenantStore!.tenant ? tenantStore!.tenant.name : ''}
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="body2" className={classes.companyEmail}>
                        {authStore!.tenantEmail}
                    </Typography>
                </Grid>
            </Grid>

            <Divider variant={'middle'} light />

            <MenuList onClick={handleMenuClose}>
                <NavItem path={'/'} icon={<DashboardIcon color={'primary'} />} text={t('dashboard')} />
                <NavItem path={'/planning'} icon={<PlanningIcon color={'primary'} />} text={t('planning')} />
                <NavItem path={'/task'} icon={<TasksIcon color={'primary'} />} text={t('tasks')} />
                <Box marginBottom={1} marginTop={1}>
                    <Divider variant={'middle'} light />
                </Box>
                {authStore.isAdmin && (
                    <NavItem path={'/projects'} icon={<ProjectsIcon color={'primary'} />} text={t('projects')} />
                )}
                <NavItem path={'/customers'} icon={<CustomersIcon color={'primary'} />} text={t('customers')} />
                {authStore.isAdmin && (
                    <NavItem path={'/employee'} icon={<EmployeesIcon color={'primary'} />} text={t('employees')} />
                )}
                <NavItem path={'/resource'} icon={<ResourceIcon color={'primary'} />} text={t('resources')} />
            </MenuList>

            <div className={classes.bottomSection}>
                <Grid container justify={'space-between'} className={classes.logoutSection}>
                    <Grid item>
                        <Tooltip title={t('help') as any}>
                            <MuiLink href={serviceDeskUrl} target={'_blank'}>
                                <IconButton color={'primary'}>
                                    <QuestionMarkIcon />
                                </IconButton>
                            </MuiLink>
                        </Tooltip>
                    </Grid>
                    <Grid item>
                        <IconButton onClick={handleLogout}>
                            <LogoutIcon color="primary" />
                        </IconButton>
                    </Grid>
                    <Grid item style={{ width: 45 }} />
                </Grid>
            </div>
        </Drawer>
    );
});

export default SidebarMobile;
