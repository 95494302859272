import { computed } from 'mobx';
import { DashboardStore } from '../../stores/dashboardStore';

export class OpenProjectsWidgetVm {
    private dashboardStore: DashboardStore;

    constructor(dashboardStore: DashboardStore) {
        this.dashboardStore = dashboardStore;
    }

    @computed
    public get isLoading() {
        return this.dashboardStore.store.projectsStore.isLoading || this.dashboardStore.store.tenantStore.isLoading;
    }

    @computed
    public get openProjectsCount(): number {
        if (!this.dashboardStore.store.tenantStore.tenant) {
            return 0;
        }

        return this.dashboardStore.store.projectsStore.openProjectsCount;
    }

    @computed
    public get completedProjectCount(): number {
        if (!this.dashboardStore.store.tenantStore.tenant) {
            return 0;
        }

        const isCompleteProjects = this.dashboardStore.store.projectsStore.projects.filter(
            (project) => project.isComplete === true
        );

        return isCompleteProjects.length;
    }

    @computed
    public get openProjectsPercentText() {
        if (!this.dashboardStore.store.tenantStore.tenant) {
            return 0;
        }

        const sum = this.dashboardStore.store.projectsStore.projects.length;
        const percent = (this.openProjectsCount / sum) * 100;
        const fixed = percent > 1 || Number.isInteger(percent) ? 0 : 2;
        return `${percent.toFixed(fixed)} %`;
    }
}
