import Firebase from '../../../api/firebase';
import { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js/pure';
import appConfig from '../../../appConfig';

const stripePromise = loadStripe(appConfig.stripeKey);

const fetchCheckoutSession = async (customerId: string, priceId: string) => {
    const fetchCheckoutSessionCf = Firebase.functions.httpsCallable('getCheckoutSession');
    const result = await fetchCheckoutSessionCf({
        customerId: customerId,
        priceId: priceId,
        returnUrl: window.location.href,
    });
    return result.data.data;
};

const fetchBillingPortalSession = async (customerId: string) => {
    const fetchBillingPortalSessionCf = Firebase.functions.httpsCallable('getBillingPortalSession');
    const result = await fetchBillingPortalSessionCf({
        customerId: customerId,
        returnUrl: window.location.href,
    });
    return result.data.data;
};

export function useStripe() {
    const [openBillingPortalInProgress, setOpenBillingPortalInProgress] = useState<boolean>(false);
    const [checkoutInProgress, setCheckoutInProgress] = useState<boolean>(false);

    const checkout = async (customerId: string, priceId: string) => {
        setCheckoutInProgress(true);
        const checkoutSession = await fetchCheckoutSession(customerId, priceId);
        const stripe = await stripePromise;
        const response = await stripe?.redirectToCheckout({
            sessionId: checkoutSession.id,
        });

        if (response?.error) {
            console.error('checkout error');
        }

        setCheckoutInProgress(false);
    };

    const openBillingPortal = async (customerId: string) => {
        setOpenBillingPortalInProgress(true);
        const session = await fetchBillingPortalSession(customerId);

        window.location.replace(session.url);
    };

    return { checkout, checkoutInProgress, openBillingPortal, openBillingPortalInProgress };
}
