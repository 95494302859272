import { RootStore } from '../../../stores/rootStore';
import { action, IObservableArray, observable, reaction, runInAction } from 'mobx';
import { PlanningBoardItem } from '../models/planningBoardItem';
import Firebase from '../../../api/firebase';
import { PlanningBoardItemDto } from '../../../api/dtos/generated/dtos.generated';
import firebase from 'firebase';

export class PlanningBoardItemsStore {
    public static storeName: string = 'planningBoardItemStore';
    public static planningBoardItemsCollectionName = 'planningBoardItems';

    public store: RootStore;
    @observable public isLoading: boolean = false;
    @observable planningBoardItems: IObservableArray<PlanningBoardItem> = observable.array<PlanningBoardItem>([]);

    private isFirstSnapShotHandled = false;

    private unsubscribePlanningBoardItemChanges?: () => void = undefined;

    constructor(store: RootStore) {
        this.store = store;
        reaction(
            () => this.store.authStore.isAuthenticated,
            () => this.subscribePlanningBoardItemChanges()
        );
    }

    @action
    public subscribePlanningBoardItemChanges() {
        this.isLoading = true;

        const query = Firebase.firestore
            .collection('tenants')
            .doc(Firebase.tenantId)
            .collection(PlanningBoardItemsStore.planningBoardItemsCollectionName)
            .orderBy('date', 'desc');

        this.unsubscribePlanningBoardItemChanges = query.onSnapshot((snapshot: firebase.firestore.QuerySnapshot) => {
            if (this.isFirstSnapShotHandled) {
                snapshot.docChanges().forEach((change: firebase.firestore.DocumentChange) => {
                    if (change.type === 'added') {
                        this.addPlanningBoardItem(change.doc.data() as PlanningBoardItemDto);
                    }
                    if (change.type === 'modified') {
                        this.upsertPlanningBoardItem(change.doc.data() as PlanningBoardItemDto);
                    }
                    if (change.type === 'removed') {
                        this.removePlanningBoardItem(change.doc.data().id);
                    }
                });
            } else {
                runInAction(() => (this.isLoading = true));

                const planningBoardItems: PlanningBoardItem[] = [];
                snapshot.docs.forEach((value: firebase.firestore.QueryDocumentSnapshot) => {
                    const dto = value.data() as PlanningBoardItemDto;
                    planningBoardItems.push(PlanningBoardItem.createFromDto(dto));
                });

                this.planningBoardItems = observable.array<PlanningBoardItem>(planningBoardItems);
                this.isFirstSnapShotHandled = true;

                runInAction(() => (this.isLoading = false));
            }
        });
    }

    @action
    private addPlanningBoardItem(dto: PlanningBoardItemDto) {
        const planningBoardItem = PlanningBoardItem.createFromDto(dto);
        this.planningBoardItems.push(planningBoardItem);
    }

    @action
    private upsertPlanningBoardItem(dto: PlanningBoardItemDto) {
        const item = this.planningBoardItems.find((x) => x.id === dto.id);
        if (item) {
            this.removePlanningBoardItem(dto.id);
        }
        this.addPlanningBoardItem(dto);
    }

    @action
    private async removePlanningBoardItem(id: string) {
        const item = this.planningBoardItems.find((x) => x.id === id);
        if (item) {
            this.planningBoardItems.remove(item);
        }
    }
}
