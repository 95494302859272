import { BaseModel } from '../../../common/stores/model/baseModel';
import { action, computed, observable, runInAction } from 'mobx';
import {
    ReferencedCustomerDto,
    ReferencedEmployeeDto,
    TaskState,
    TaskFilterDto
} from '../../../api/dtos/generated/dtos.generated';
import { format } from 'date-fns';
import i18n from 'i18next';
import { getDateRangeSelectionType } from '../components/filter/selectionType';

export class TaskFilter extends BaseModel {
    @observable startDate: Date;
    @observable endDate: Date;
    @observable states: TaskState[] = ['open', 'inprocess'];
    @observable validationErrors: { [index: string]: any } = {};
    @observable isDirty: boolean = false;
    @observable employeeRef?: ReferencedEmployeeDto;
    @observable customerRef?: ReferencedCustomerDto;

    constructor(startDate: Date, endDate: Date, states?: TaskState[]) {
        super();
        this.startDate = startDate;
        this.endDate = endDate;
        if (states) {
            this.states = states;
        }
    }

    @computed get isValid() {
        return Object.keys(this.validationErrors).length === 0;
    }

    @computed get employeeDisplayName() {
        return this.employeeRef ? this.employeeRef.displayName : '';
    }

    @computed get customerDisplayName() {
        return this.customerRef ? this.customerRef.displayName : '';
    }

    @computed get dateRangeText() {
        const selectionType = getDateRangeSelectionType(this.startDate, this.endDate);

        switch (selectionType) {
            case 'today':
                return i18n.t('today');
            case 'calendarWeek':
                return i18n.t('calendar-week');
            case 'calendarMonth':
                return i18n.t('calendar-month');
            default:
                return `${format(this.startDate, 'd.M.yy')} - ${format(this.endDate, 'd.M.yy')}`;
        }
    }

    @computed get statesText() {
        if (this.states.length < 1) {
            return i18n.t('state');
        }

        const states = this.states.map(x => i18n.t(x.toString()));
        return states.join(' | ');
    }

    @computed get employeeText() {
        return this.employeeRef ? this.employeeRef.displayName : i18n.t('employee');
    }

    @computed get customerText() {
        return this.customerRef ? this.customerDisplayName : i18n.t('customer');
    }

    @computed get dateRange() {
        return { start: this.startDate, end: this.endDate };
    }

    @action
    public setEmployee(employeeRef?: ReferencedEmployeeDto) {
        this.employeeRef = employeeRef;
    }

    @action
    public setCustomer(ref?: ReferencedCustomerDto) {
        this.customerRef = ref;
    }

    @action
    public setStartDate(date: Date) {
        this.startDate = date;
    }

    @action
    public setEndDate(date: Date) {
        this.endDate = date;
    }

    @action
    public addState(state: TaskState) {
        if (this.states.indexOf(state) < 0) {
            this.states.push(state);
        }
    }

    @action
    public removeState(state: TaskState) {
        this.states = this.states.filter(x => x !== state);
    }

    @action
    public setStates(states: TaskState[]) {
        this.states = states;
    }

    @action
    public setDateRange(startDate: Date, endDate: Date) {
        runInAction(() => {
            this.startDate = startDate;
            this.endDate = endDate;
        });
        localStorage.setItem('taskFilterStartDate', startDate.toString());
        localStorage.setItem('taskFilterEndDate', endDate.toString());
    }

    isStateFiltered(state: TaskState) {
        return this.states.findIndex(x => x === state) > -1;
    }

    @action
    updateProperty(field: string, value: any) {
        if (!this.isDirty) {
            this.isDirty = true;
        }

        (this as any)[field] = value;
    }

    toDto(): TaskFilterDto {
        return {
            startDate: this.startDate,
            endDate: this.endDate,
            states: this.states,
            employeeRef: this.employeeRef,
            customerRef: this.customerRef
        };
    }
}
