import Firebase from './firebase';
import { AppError } from '../common/models/appError';
import { TaskDto } from './dtos/generated/dtos.generated';
import { Client } from 'algoliasearch';
import appConfig from '../appConfig';
import baseService from './baseService';
import { tenantsCollectionName, tasksCollectionName } from '../common/helpers/collectionNames';

export const taskService = {
    getTasksFromIndex: async (searchClient: Client): Promise<TaskDto[]> => {
        const index = searchClient.initIndex(appConfig.algolia.index.task);

        try {
            const response = await index.search({ query: '' });
            return response.hits as TaskDto[];
        } catch (e) {
            console.error(e);
            return Promise.reject(e);
        }
    },

    getTask: async (id: string): Promise<TaskDto | undefined> => {
        try {
            const doc = await Firebase.firestore
                .collection(tenantsCollectionName)
                .doc(Firebase.tenantId)
                .collection(tasksCollectionName)
                .doc(id)
                .get();

            if (doc.exists) {
                return doc.data() as TaskDto;
            } else {
                return undefined;
            }
        } catch (error) {
            return Promise.reject(new AppError('task-not-found'));
        }
    },

    upsertTask: (task: TaskDto): Promise<void> => {
        return baseService.upsertEntity(task.id, task, tasksCollectionName);
    },

    softDeleteTask: (task: TaskDto): Promise<void> => {
        return baseService.softDeleteEntity(task.id, task, tasksCollectionName);
    },

    deleteTask: (id: string): Promise<any> => {
        const deleteTask = Firebase.functions.httpsCallable('deleteTask');
        return deleteTask({ id });
    },

    updateTask: (id: string, partialTask: Partial<TaskDto>) => {
        return baseService.updateEntityPartial(id, partialTask, tasksCollectionName);
    },
};
