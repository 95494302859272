import SerieDto, { PlanningBoardItemDto, SerieEmployeeDto, SerieResourceDto } from './dtos/generated/dtos.generated';
import Firebase from '../api/firebase';
import baseService from './baseService';
import { AppError } from '../common/models/appError';
import {
    planningBoardItemsCollectionName,
    planningSerieCollectionName,
    employeeRefsCollectionName,
    resourceRefsCollectionName,
} from '../common/helpers/collectionNames';

export const planningService = {
    getPlanningItemsByDateRange: async (periodStart: Date, periodEnd: Date): Promise<PlanningBoardItemDto[]> => {
        try {
            const querySnapshot = await Firebase.firestore
                .collection('tenants')
                .doc(Firebase.tenantId)
                .collection(planningBoardItemsCollectionName)
                .where('date', '>=', periodStart)
                .where('date', '<=', periodEnd)
                .get();
            const items: PlanningBoardItemDto[] = [];
            if (!querySnapshot.empty) {
                for (const doc of querySnapshot.docs) {
                    const model = doc.data() as PlanningBoardItemDto;
                    items.push(model);
                }
                return items;
            } else {
                return items;
            }
        } catch (error) {
            return Promise.reject(new AppError('planning-board-items-not-found'));
        }
    },

    getSerieItems: async (serieId: string): Promise<PlanningBoardItemDto[]> => {
        try {
            const querySnapshot = await baseService.getCollectionByFilter(
                Firebase.tenantId!,
                planningBoardItemsCollectionName,
                'serieId',
                '==',
                serieId
            );
            const items: PlanningBoardItemDto[] = [];
            if (!querySnapshot.empty) {
                for (const doc of querySnapshot.docs) {
                    const model = doc.data() as PlanningBoardItemDto;
                    items.push(model);
                }
                return items;
            } else {
                return items;
            }
        } catch (error) {
            return Promise.reject(new AppError('planning-board-items-not-found'));
        }
    },

    getSerie: async (id: string): Promise<SerieDto | undefined> => {
        try {
            const serieSnapshot = await baseService.getDocById(id, planningSerieCollectionName);
            if (serieSnapshot.exists) {
                const serie = serieSnapshot.data() as SerieDto;
                let serieEmployeesSnapshot = await baseService.getDocCollectionById(
                    id,
                    planningSerieCollectionName,
                    employeeRefsCollectionName
                );

                let employees: SerieEmployeeDto[] = [];
                for (const snapshot of serieEmployeesSnapshot.docs) {
                    const employee = snapshot.data() as SerieEmployeeDto;
                    employees.push(employee);
                }
                serie.employees = employees;

                let serieResourcesSnapshot = await baseService.getDocCollectionById(
                    id,
                    planningSerieCollectionName,
                    resourceRefsCollectionName
                );

                let resources: SerieResourceDto[] = [];
                for (const snapshot of serieResourcesSnapshot.docs) {
                    const resource = snapshot.data() as SerieResourceDto;
                    resources.push(resource);
                }
                serie.resources = resources;

                const planningBoardItems = await planningService.getSerieItems(serie.id);
                if (planningBoardItems.length > 0) {
                    serie.planningBoardItems = [];
                    serie.planningBoardItems.push(...planningBoardItems);
                }

                return serie;
            } else {
                return undefined;
            }
        } catch (error) {
            return Promise.reject(new AppError(error));
        }
    },

    upsertPlanningBoardItem: async (planningBoardItemDto: PlanningBoardItemDto): Promise<void> => {
        return baseService.upsertEntity(
            planningBoardItemDto.id,
            planningBoardItemDto,
            planningBoardItemsCollectionName
        );
    },

    upsertPlanningSerie: async (serieDto: any, deleteIds: string[]): Promise<void> => {
        if (serieDto !== undefined) {
            try {
                const batch = Firebase.firestore.batch();

                if (deleteIds.length > 0) {
                    let planningBoardCollectionRef = await baseService.getCollectionRef(
                        planningBoardItemsCollectionName
                    );
                    for (const deleteId of deleteIds) {
                        batch.delete(planningBoardCollectionRef.doc(deleteId));
                    }
                }

                for (const planningBoardItemDto of serieDto.planningBoardItems) {
                    let planningBoardDocRef = await baseService.getDocRefById(
                        planningBoardItemDto.id,
                        planningBoardItemsCollectionName
                    );

                    batch.set(planningBoardDocRef, planningBoardItemDto, { merge: true });
                }

                const serieDocRef = await baseService.getDocRefById(serieDto.serie.id, planningSerieCollectionName);

                batch.set(serieDocRef, serieDto.serie, { merge: true });

                const serieEmployeeCollectionRef = await baseService.getDocCollectionRef(
                    serieDto.serie.id,
                    planningSerieCollectionName,
                    employeeRefsCollectionName
                );

                const serieEmployeeSnapShot = await baseService.getDocCollectionById(
                    serieDto.serie.id,
                    planningSerieCollectionName,
                    employeeRefsCollectionName
                );

                if (!serieEmployeeSnapShot.empty) {
                    for (const serieEmployeeSnapshot of serieEmployeeSnapShot.docs) {
                        batch.delete(serieEmployeeCollectionRef.doc(serieEmployeeSnapshot.data().id));
                    }
                }

                serieDto.employees.forEach((employee: any) => {
                    batch.set(serieEmployeeCollectionRef.doc(employee.id), employee, {});
                });

                const serieResourceCollectionRef = await baseService.getDocCollectionRef(
                    serieDto.serie.id,
                    planningSerieCollectionName,
                    resourceRefsCollectionName
                );

                const serieResourceSnapShot = await baseService.getDocCollectionById(
                    serieDto.serie.id,
                    planningSerieCollectionName,
                    resourceRefsCollectionName
                );

                if (!serieResourceSnapShot.empty) {
                    for (const serieResourceSnapshot of serieResourceSnapShot.docs) {
                        batch.delete(serieResourceCollectionRef.doc(serieResourceSnapshot.data().id));
                    }
                }

                serieDto.resources.forEach((resource: any) => {
                    batch.set(serieResourceCollectionRef.doc(resource.id), resource, {});
                });

                batch.commit();
            } catch (error) {
                console.log(error);
                return Promise.reject(new AppError(error));
            }
        }
    },
    deletePlanningBoardItem: async (id: string): Promise<void> => {
        return baseService.deleteEntity(id, planningBoardItemsCollectionName);
    },
    deleteSerie: async (id: string): Promise<void> => {
        try {
            const serieDoc = await baseService.getDocById(id, planningSerieCollectionName);
            if (serieDoc.exists) {
                const serieDocRef = await baseService.getDocRefById(id, planningSerieCollectionName);
                const employeeCollection = await serieDocRef.collection('employees').get();
                const resourceCollection = await serieDocRef.collection('resources').get();

                const batch = Firebase.firestore.batch();

                if (!employeeCollection.empty) {
                    employeeCollection.docs.forEach((doc) => {
                        batch.delete(serieDocRef.collection('employees').doc(doc.id));
                    });
                }

                if (!resourceCollection.empty) {
                    resourceCollection.docs.forEach((doc) => {
                        batch.delete(serieDocRef.collection('resources').doc(doc.id));
                    });
                }

                batch.delete(serieDocRef);
                const planningBoardItemsSnapshot = await Firebase.firestore
                    .collection('tenants')
                    .doc(Firebase.tenantId)
                    .collection('planningBoardItems')
                    .where('serieId', '==', id)
                    .get();

                if (!planningBoardItemsSnapshot.empty) {
                    let planningBoardCollectionRef = await baseService.getCollectionRef(
                        planningBoardItemsCollectionName
                    );
                    planningBoardItemsSnapshot.docs.forEach((doc) => {
                        batch.delete(planningBoardCollectionRef.doc(doc.id));
                    });
                }
                batch.commit();
            }
        } catch (error) {
            console.log(error);
            return Promise.reject(new AppError(error));
        }
    },
};
