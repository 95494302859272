import { action, computed, observable, runInAction } from 'mobx';
import { RecurringDto, RecurringIntervalUnit } from '../../../api/dtos/generated/dtos.generated';
import i18n from 'i18next';
import { parseDate } from '../../../common/utils/dateHelper';

export class Recurring {
    @observable interval: number = 1;
    @observable unit: RecurringIntervalUnit = 'daily';
    @observable start: Date;
    @observable end?: Date;

    @action
    setInterval(interval: number) {
        this.interval = interval;
    }

    @action
    setUnit(unit: RecurringIntervalUnit) {
        this.unit = unit;
    }

    @computed get unitDisplayName() {
        return i18n.t(this.unit.toString());
    }

    @computed get intervalDisplayName() {
        switch (this.unit) {
            case 'weekly':
                return i18n.t('recurring-week');
            case 'monthly':
                return i18n.t('recurring-month');
            case 'yearly':
                return i18n.t('recurring-year');
            default:
                return i18n.t('recurring-day');
        }
    }

    public static createFromDto(dto: RecurringDto, start?: Date): Recurring {
        const recurring = new Recurring(parseDate(start ? start : dto.start)!);
        runInAction(() => {
            recurring.interval = dto.interval;
            recurring.unit = dto.unit;
            recurring.end = parseDate(dto.end);
        });

        return recurring;
    }

    constructor(start: Date) {
        this.start = start;
    }

    public toDto(): RecurringDto {
        return {
            interval: this.interval,
            unit: this.unit,
            start: this.start,
            end: this.end ? this.end : null
        };
    }

    cloneWithNewStartDate(start: Date): Recurring {
        const dto = this.toDto();
        return Recurring.createFromDto(dto, start);
    }
}
