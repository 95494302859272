import { observable, computed } from 'mobx';
import {v4}  from 'uuid';
import { BaseModel } from '../../../common/stores/model/baseModel';
import { mapTrackingFieldsToDto, mapTrackingFieldsToModel } from '../../../common/helpers/trackingFields';
import {
    PlanningBoardItemDto,
    ReferencedProjectDto,
    PlanningBoardItemGfDto,
    PeriodDto,
    AbsenceDto
} from '../../../api/dtos/generated/dtos.generated';
import { absences } from '../../../common/types/commonTypes';
import Serie from './serie';

export class PlanningBoardItem extends BaseModel {
    id: string;
    @observable isNew: boolean;
    @observable employeeId?: string;
    @observable resourceId?: string;
    @observable projectRef?: ReferencedProjectDto;
    @observable absence?: AbsenceDto;
    @observable date: Date;
    @observable period: PeriodDto = 'allDay';
    @observable serieId?: string;

    constructor(date: Date, id: string = v4()) {
        super();
        this.id = id;
        this.isNew = true;
        this.date = date;
    }

    public static createFromDto(dto: PlanningBoardItemDto): PlanningBoardItem {
        const planningBoardItem = new PlanningBoardItem(dto.date);
        planningBoardItem.id = dto.id;
        planningBoardItem.isNew = false;
        planningBoardItem.employeeId = dto.employeeId ? dto.employeeId : undefined;
        planningBoardItem.resourceId = dto.resourceId ? dto.resourceId : undefined;
        planningBoardItem.projectRef = dto.projectRef ? dto.projectRef : undefined;
        planningBoardItem.absence = dto.absence ? absences.find(absence => absence === dto.absence) : undefined;
        planningBoardItem.date = new Date((dto.date as any).toDate().valueOf());
        planningBoardItem.period =
            dto.period === 'allDay' ? 'allDay' : dto.period === 'afternoon' ? 'afternoon' : 'morning';
        planningBoardItem.serieId = dto.serieId ? dto.serieId : undefined;

        mapTrackingFieldsToModel(planningBoardItem, dto);

        return planningBoardItem;
    }

    public static createEmployeeSerieItem(
        date: Date,
        serie: Serie,
        period: PeriodDto,
        employeeId: string
    ): PlanningBoardItem {
        const item = new PlanningBoardItem(date);
        item.employeeId = employeeId;
        item.projectRef = {
            id: serie.projectRef!.id,
            color: serie.projectRef!.color
        };
        item.period = period;
        item.serieId = serie.id;
        return item;
    }

    public static createResourceSerieItem(
        date: Date,
        serie: Serie,
        period: PeriodDto,
        resourceId: string
    ): PlanningBoardItem {
        const item = new PlanningBoardItem(date);
        item.resourceId = resourceId;
        item.projectRef = {
            id: serie.projectRef!.id,
            color: serie.projectRef!.color
        };
        item.period = period;
        item.serieId = serie.id;
        return item;
    }

    public static createAbsenceEmployeeSerieItem(
        date: Date,
        serie: Serie,
        period: PeriodDto,
        employeeId: string
    ): PlanningBoardItem {
        const item = new PlanningBoardItem(date);
        item.employeeId = employeeId;
        item.absence = serie.absence;
        item.period = period;
        item.serieId = serie.id;
        return item;
    }

    public static createAbsenceResourceSerieItem(
        date: Date,
        serie: Serie,
        period: PeriodDto,
        resourceId: string
    ): PlanningBoardItem {
        const item = new PlanningBoardItem(date);
        item.resourceId = resourceId;
        item.absence = serie.absence;
        item.period = period;
        item.serieId = serie.id;
        return item;
    }

    toDto(tenantId: string): PlanningBoardItemDto {
        const dto = {
            id: this.id,
            tenantId: tenantId,
            employeeId: this.employeeId ? this.employeeId : null,
            resourceId: this.resourceId ? this.resourceId : null,
            projectRef: this.projectRef ? this.projectRef : null,
            absence: this.absence ? this.absence : null,
            date: this.date,
            period: this.period,
            serieId: this.serieId ? this.serieId : null
        };

        mapTrackingFieldsToDto(dto, this);

        return dto;
    }

    toServerDto(tenantId: string): PlanningBoardItemGfDto {
        return {
            id: this.id,
            tenantId: tenantId,
            employeeId: this.employeeId ? this.employeeId : null,
            resourceId: this.resourceId ? this.resourceId : null,
            projectRef: this.projectRef ? this.projectRef : null,
            absence: this.absence ? this.absence : null,
            date: this.date.valueOf(),
            period: this.period,
            serieId: this.serieId ? this.serieId : null
        };
    }

    @computed
    public get getDate() {
        return this.date;
    }

    @computed
    public get getProjectColor() {
        return this.projectRef !== undefined && this.projectRef !== null ? this.projectRef.color : '';
    }

    @computed
    public get isMorningPeriod() {
        return this.period === 'morning';
    }

    @computed
    public get isAfternoonPeriod() {
        return this.period === 'afternoon';
    }

    @computed
    public get isAllDayPeriod() {
        return this.period === 'allDay';
    }
}
