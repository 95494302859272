import { RootStore } from '../../../stores/rootStore';
import { action, computed, IObservableArray, observable, reaction, runInAction, toJS } from 'mobx';
import { PlanningBoardItem } from '../models/planningBoardItem';
import Firebase from '../../../api/firebase';
import { AbsenceDto } from '../../../api/dtos/generated/dtos.generated';
import { addDays, differenceInBusinessDays, endOfDay, isSameDay, isWeekend, startOfDay, subDays } from 'date-fns';
import { PlanningProject } from '../models/planningProject';
import { planningService } from '../../../api/planningService';
import i18n from 'i18next';
import { PlanningBoardRow } from '../models/planningBoardRow';
import { PlanningBoardRowDay } from '../models/planningBoardRowDay';
import { PlanningBoardRowFilter } from '../../../common/types/commonTypes';

const avatarItemWidth = 320;
const planningBoardItemWidth = 85;
const planningScreenPadding = 64;
const planningBoardPadding = 64;

export class PlanningStore {
    public static storeName: string = 'planningStore';
    public static planningBoardItemsCollectionName = 'planningBoardItems';

    public store: RootStore;
    @observable public isLoading: boolean = false;

    // Project planning & absences
    @observable public isPlannedProjectInProgress: boolean = false;
    @observable public selectedPlanningProjects: IObservableArray<PlanningProject> = observable.array<PlanningProject>(
        []
    );
    @observable public selectedProject?: PlanningProject = undefined;
    @observable public selectedAbsence?: AbsenceDto = undefined;

    // Filter
    @observable public rowFilter: PlanningBoardRowFilter = 'all';
    @observable public filterPlanned: boolean = false;
    @observable public showWeekends: boolean = false;
    @observable public periodStart: Date = startOfDay(new Date());

    // Mobile View Settings
    @observable public isDateBarFixed: boolean = false;
    @observable public isProjectsMobileVisible: boolean = true;
    @observable public mobileMoreOptionsButton: boolean = false;

    @computed get planningBoardItems() {
        if (this.store.planningBoardItemsStore?.planningBoardItems?.length < 1) {
            return [];
        }
        const itemsInRange = this.store.planningBoardItemsStore.planningBoardItems.filter(
            (x: PlanningBoardItem) => this.rangeDates.findIndex((y) => isSameDay(y, x.date)) > -1
        );

        return itemsInRange;
    }

    constructor(store: RootStore) {
        this.store = store;
        window.addEventListener('scroll', () => this.handleFixedDateBar());

        reaction(
            () => toJS(this.store.employeesStore.sortedEmployees),
            () => {
                this.recalculatePlanningBoardRows();
            }
        );

        reaction(
            () => toJS(this.store.resourcesStore.resources),
            () => {
                this.recalculatePlanningBoardRows();
            }
        );

        reaction(
            () => toJS(this.planningBoardItems),
            () => {
                this.recalculatePlanningBoardRows();
            }
        );
    }

    @action addPlanningProject(projectId: string) {
        const project = this.store.projectsStore.sortedProjects.find((x) => x.id === projectId);
        if (project) {
            const planningProject = new PlanningProject(project, false);
            this.selectedPlanningProjects.unshift(planningProject);
            this.selectedProject = planningProject;
        }
    }

    @observable planningBoardRows: IObservableArray<PlanningBoardRow> = observable.array<PlanningBoardRow>([]);

    @action
    public recalculatePlanningBoardRows() {
        console.log('rowshandling - recalculatePlanningBoardRows');

        this.planningBoardRows = observable.array<PlanningBoardRow>([]);

        if (this.rowFilter === 'all' || this.rowFilter === 'employees') {
            for (const employee of this.store.employeesStore.sortedEmployees) {
                const planningBoardRow = new PlanningBoardRow();
                planningBoardRow.employee = employee;

                for (const date of this.rangeDates) {
                    const day = new PlanningBoardRowDay(date);
                    const employeeItemsByDate = this.planningBoardItems.filter(
                        (item: PlanningBoardItem) =>
                            item.employeeId === planningBoardRow.employee!.id && isSameDay(item.date, date)
                    );

                    if (employeeItemsByDate.length > 0) {
                        if (employeeItemsByDate.length > 1) {
                            for (const employeeItem of employeeItemsByDate) {
                                day.items.push(employeeItem);
                            }
                        } else {
                            if (employeeItemsByDate[0].period === 'morning') {
                                day.items.push(employeeItemsByDate[0]);
                                const planningItemAfternoon = new PlanningBoardItem(date);
                                planningItemAfternoon.employeeId = planningBoardRow.employee.id;
                                planningItemAfternoon.period = 'afternoon';
                                day.items.push(planningItemAfternoon);
                            } else if (employeeItemsByDate[0].period === 'afternoon') {
                                day.items.push(employeeItemsByDate[0]);
                                const planningItemMorning = new PlanningBoardItem(date);
                                planningItemMorning.employeeId = planningBoardRow.employee.id;
                                planningItemMorning.period = 'morning';
                                day.items.push(planningItemMorning);
                            } else {
                                day.items.push(employeeItemsByDate[0]);
                            }
                        }
                    } else {
                        const planningBoardItem = new PlanningBoardItem(date);
                        planningBoardItem.employeeId = planningBoardRow.employee.id;
                        day.items.push(planningBoardItem);
                    }

                    planningBoardRow.days.push(day);
                }

                if (this.filterPlanned) {
                    const filter = planningBoardRow.days.every((day) =>
                        day.items.every((item) => item.projectRef === undefined)
                    );
                    if (!filter) {
                        this.planningBoardRows.push(planningBoardRow);
                    }
                } else {
                    this.planningBoardRows.push(planningBoardRow);
                }
            }
        }

        if (this.rowFilter === 'all' || this.rowFilter === 'resources') {
            for (const resource of this.store.resourcesStore.resources) {
                const planningBoardRow = new PlanningBoardRow();
                planningBoardRow.resource = resource;

                for (const date of this.rangeDates) {
                    const day = new PlanningBoardRowDay(date);
                    const resourceItemsByDate = this.planningBoardItems.filter(
                        (item: PlanningBoardItem) =>
                            item.resourceId === planningBoardRow.resource!.id && isSameDay(item.date, date)
                    );

                    if (resourceItemsByDate.length > 0) {
                        if (resourceItemsByDate.length > 1) {
                            for (const resourceItem of resourceItemsByDate) {
                                day.items.push(resourceItem);
                            }
                        } else {
                            if (resourceItemsByDate[0].period === 'morning') {
                                day.items.push(resourceItemsByDate[0]);
                                const planningItemAfternoon = new PlanningBoardItem(date);
                                planningItemAfternoon.resourceId = planningBoardRow.resource.id;
                                planningItemAfternoon.period = 'afternoon';
                                day.items.push(planningItemAfternoon);
                            } else if (resourceItemsByDate[0].period === 'afternoon') {
                                day.items.push(resourceItemsByDate[0]);
                                const planningItemMorning = new PlanningBoardItem(date);
                                planningItemMorning.resourceId = planningBoardRow.resource.id;
                                planningItemMorning.period = 'morning';
                                day.items.push(planningItemMorning);
                            } else {
                                day.items.push(resourceItemsByDate[0]);
                            }
                        }
                    } else {
                        const planningBoardItem = new PlanningBoardItem(date);
                        planningBoardItem.resourceId = planningBoardRow.resource.id;
                        day.items.push(planningBoardItem);
                    }

                    planningBoardRow.days.push(day);
                }

                if (this.filterPlanned) {
                    const filter = planningBoardRow.days.every((day) =>
                        day.items.every((item) => item.projectRef === undefined)
                    );
                    if (!filter) {
                        this.planningBoardRows.push(planningBoardRow);
                    }
                } else {
                    this.planningBoardRows.push(planningBoardRow);
                }
            }
        }
    }

    @computed
    public get isDesktop() {
        return this.store.uiStore.width > 960;
    }

    @computed
    public get visibleDays() {
        if (this.isDesktop) {
            // desktop
            if (this.store.uiStore!.isDrawerOpen) {
                let result =
                    (this.store.uiStore.width -
                        (avatarItemWidth +
                            this.store.uiStore.drawerWidth +
                            planningScreenPadding +
                            planningBoardPadding)) /
                    planningBoardItemWidth;
                return Math.floor(result);
            } else {
                const result =
                    (this.store.uiStore.width -
                        (avatarItemWidth +
                            this.store.uiStore.drawerWidthMinimized +
                            planningScreenPadding +
                            planningBoardPadding)) /
                    planningBoardItemWidth;
                let additionalDays = 0;
                return Math.floor(result) - additionalDays;
            }
        } else {
            // mobile
            const result = (this.store.uiStore.width - 32) / planningBoardItemWidth;
            return Math.floor(result);
        }
    }

    @computed
    public get periodEnd() {
        return endOfDay(this.rangeDates[this.rangeDates.length - 1]);
    }

    @computed
    public get rangeDates(): Date[] {
        const dates: Date[] = [];
        let end = this.visibleDays;

        let index = 0;
        while (index < end) {
            const date = addDays(this.periodStart, index);

            if (!this.showWeekends && isWeekend(date)) {
                end++;
            } else {
                dates.push(date);
            }

            index++;
        }
        return dates;
    }

    @computed
    public get selectedProjectId() {
        return this.selectedProject !== undefined ? this.selectedProject!.project.id : '';
    }

    @computed
    public get sortedPlanningProjects(): PlanningProject[] {
        const planningProjects = [...this.selectedPlanningProjects];
        this.plannedProjectsInPeriod.forEach((planned) => {
            const existingProjectIndex = planningProjects.findIndex(
                (selected) => selected.project.id === planned.project.id
            );
            if (existingProjectIndex === -1) {
                planningProjects.push(planned);
            } else {
                planningProjects[existingProjectIndex] = planned;
            }
        });

        return planningProjects.sort((a: PlanningProject, b: PlanningProject) => {
            return a.project.isComplete < b.project.isComplete ? 1 : -1;
        });
    }

    @computed
    public get planningProjectsFilter() {
        let filter = `isComplete:false`;
        this.sortedPlanningProjects.forEach((planningProject) => {
            filter += ` AND NOT id:${planningProject.project.id}`;
        });
        return filter;
    }

    @action
    public hideMobileMoreOptionsButton() {
        this.mobileMoreOptionsButton = false;
    }

    @action
    public toggleMobileMoreOptionsButton() {
        this.mobileMoreOptionsButton = !this.mobileMoreOptionsButton;
    }

    @action
    public toggleExpandProjects() {
        this.isProjectsMobileVisible = !this.isProjectsMobileVisible;
    }

    public assignToItem(rowId: string, dayId: string, itemId: string) {
        const row = this.planningBoardRows.find((row) => row.id === rowId);
        if (row) {
            const day = row.days.find((day) => day.id === dayId);
            if (day) {
                const item = day.items.find((item) => item.id === itemId);
                if (item) {
                    item.date.setHours(0, 0, 0, 0);
                    if (this.selectedProject) {
                        this.addProjectToItem(item);
                    } else if (this.selectedAbsence) {
                        if (item.employeeId) {
                            this.addAbsenceToItem(item);
                        } else {
                            if (this.selectedAbsence === 'otherAbsence') {
                                this.addAbsenceToItem(item);
                            } else {
                                this.store.uiStore.enqueueNotification({
                                    message: i18n.t('info-planningboard-absence-only-otherAbsence'),
                                    type: 'info',
                                    persist: false,
                                });
                            }
                        }
                    } else {
                        this.removeProjectOrAbsence(item);
                    }
                }
            }
        }
    }

    @action
    public splitItem(rowId: string, dayId: string, employeeId?: string, resourceId?: string) {
        const row = this.planningBoardRows.find((row) => row.id === rowId);

        if (row) {
            let day = row.days.find((day) => day.id === dayId);

            if (day) {
                const dayIndex = row.days.indexOf(day);
                const allDayItem = day.items[0];
                if (day.items.length === 1 && allDayItem.projectRef === undefined) {
                    allDayItem.period = 'morning';
                    const morningItem = allDayItem;
                    day.items[0] = morningItem;
                    let afternoonItem = new PlanningBoardItem(day.items[0].date);
                    if (employeeId) {
                        afternoonItem.employeeId = employeeId;
                    } else {
                        afternoonItem.resourceId = resourceId;
                    }
                    afternoonItem.period = 'afternoon';
                    day.items.push(afternoonItem);
                } else {
                    const morningItem = day.items[0];
                    const afternoonItem = day.items[1];
                    if (morningItem.projectRef === undefined && afternoonItem.projectRef === undefined) {
                        morningItem.period = 'allDay';
                        day.items[0] = morningItem;
                        day.items.pop();
                    }
                }
                row.days[dayIndex] = day;
            }
        }
    }

    public async removeProjectOrAbsence(item: PlanningBoardItem) {
        if (item.absence || item.projectRef) {
            await planningService.deletePlanningBoardItem(item.id);
        } else {
            this.store.uiStore!.enqueueNotification({
                message: i18n.t('planningboard-select-project-or-absence'),
                type: 'info',
                persist: false,
            });
        }
    }

    public async addProjectToItem(item: PlanningBoardItem) {
        if (item.projectRef || item.absence) {
            this.upsertProjectToAssignedItem(item);
        } else {
            this.upsertProjectToUnassignedItem(item);
        }
    }

    public async addAbsenceToItem(item: PlanningBoardItem) {
        if (item.projectRef || item.absence) {
            this.upsertAbsenceToAssignedItem(item);
        } else {
            this.upsertAbsenceToUnassignedItem(item);
        }
    }

    public async upsertAbsenceToUnassignedItem(item: PlanningBoardItem) {
        if (!this.selectedAbsence) {
            return;
        }

        const employeeId = item.employeeId;
        const resourceId = item.resourceId;

        const date = item.date;

        let dto = undefined;

        const existingItems = employeeId
            ? this.planningBoardItems.filter(
                  (x: PlanningBoardItem) => x.employeeId === employeeId && isSameDay(x.date, date)
              )
            : this.planningBoardItems.filter(
                  (x: PlanningBoardItem) => x.resourceId === resourceId && isSameDay(x.date, date)
              );

        if (existingItems.length === 1) {
            const existingItem = existingItems[0];
            if (existingItem && existingItem.absence && existingItem.absence === this.selectedAbsence) {
                dto = existingItem.toDto(Firebase.tenantId!);
                dto.period = 'allDay';
            }
        }

        if (!dto) {
            dto = item.toDto(Firebase.tenantId!);
            dto.absence = this.selectedAbsence;
        }

        await planningService.upsertPlanningBoardItem(dto);
    }

    public async upsertAbsenceToAssignedItem(item: PlanningBoardItem) {
        if (!this.selectedAbsence) {
            return;
        }

        if (item.absence && this.selectedAbsence && item.absence === this.selectedAbsence) {
            await planningService.deletePlanningBoardItem(item.id);
        } else {
            const employeeId = item.employeeId;
            const date = item.date;

            let dto = undefined;

            const existingItems = this.planningBoardItems.filter(
                (x: PlanningBoardItem) => x.employeeId === employeeId && isSameDay(x.date, date) && x.id !== item.id
            );
            if (existingItems.length === 1) {
                const existingItem = existingItems[0];

                if (existingItem && existingItem.absence === this.selectedAbsence) {
                    await planningService.deletePlanningBoardItem(item.id);
                    dto = existingItem.toDto(Firebase.tenantId!);
                    dto.period = 'allDay';
                }
            }

            if (!dto) {
                dto = item.toDto(Firebase.tenantId!);
                dto.projectRef = null;
                dto.absence = this.selectedAbsence;
                dto.serieId = null;
            }

            await planningService.upsertPlanningBoardItem(dto);
        }
    }

    public async upsertProjectToUnassignedItem(item: PlanningBoardItem) {
        if (!this.selectedProject) {
            return;
        }

        let dto = undefined;

        let existingItems: PlanningBoardItem[] = [];
        if (item.employeeId) {
            existingItems = this.planningBoardItems.filter(
                (x: PlanningBoardItem) => x.employeeId === item.employeeId && isSameDay(x.date, item.date)
            );
        } else {
            existingItems = this.planningBoardItems.filter(
                (x: PlanningBoardItem) => x.resourceId === item.resourceId && isSameDay(x.date, item.date)
            );
        }

        if (existingItems.length === 1) {
            const existingItem = existingItems[0];
            if (
                existingItem &&
                existingItem.projectRef &&
                existingItem.projectRef!.id === this.selectedProject.project.id
            ) {
                dto = existingItem.toDto(Firebase.tenantId!);
                dto.period = 'allDay';
            }
        }

        if (!dto) {
            dto = item.toDto(Firebase.tenantId!);
            dto.projectRef = {
                id: this.selectedProject.project.id,
                color: this.selectedProject.project.color,
            };
        }

        await planningService.upsertPlanningBoardItem(dto);
    }

    async upsertProjectToAssignedItem(item: PlanningBoardItem) {
        if (!this.selectedProject) {
            return;
        }

        if (item.projectRef && item.projectRef.id === this.selectedProject.project.id) {
            await planningService.deletePlanningBoardItem(item.id);
        } else {
            let dto = undefined;

            if (item.absence) {
                await planningService.deletePlanningBoardItem(item.id);
            }

            let otherExistingItems: PlanningBoardItem[] = [];

            if (item.employeeId) {
                otherExistingItems = this.planningBoardItems.filter(
                    (x: PlanningBoardItem) =>
                        x.employeeId === item.employeeId && isSameDay(x.date, item.date) && x.id !== item.id
                );
            } else {
                otherExistingItems = this.planningBoardItems.filter(
                    (x: PlanningBoardItem) =>
                        x.resourceId === item.resourceId && isSameDay(x.date, item.date) && x.id !== item.id
                );
            }

            if (otherExistingItems.length === 1) {
                const otherExistingItem = otherExistingItems[0];
                if (otherExistingItem) {
                    if (otherExistingItem.projectRef!.id === this.selectedProject.project.id) {
                        await planningService.deletePlanningBoardItem(item.id);
                        dto = otherExistingItem.toDto(Firebase.tenantId!);
                        dto.period = 'allDay';
                    }
                }
            }

            if (!dto) {
                dto = item.toDto(Firebase.tenantId!);
                dto.absence = null;
                dto.projectRef = {
                    id: this.selectedProject!.project.id,
                    color: this.selectedProject!.project.color,
                };
                dto.serieId = null;
            }

            await planningService.upsertPlanningBoardItem(dto);
        }
    }

    @action
    public async setFilterRows(filter: PlanningBoardRowFilter) {
        this.rowFilter = filter;
        this.recalculatePlanningBoardRows();
    }

    @action
    public async setFilterPlanned(checked: boolean) {
        this.filterPlanned = checked;
        this.recalculatePlanningBoardRows();
    }

    @action
    public async setShowWeekends(checked: boolean) {
        this.showWeekends = checked;
    }

    @action
    public async setFilterToday() {
        this.periodStart = startOfDay(new Date());
    }

    @action
    public async setFilterSpecificDate(date: Date) {
        this.periodStart = startOfDay(date);
    }

    @action
    public async deletePlanningProject(planningProject: PlanningProject) {
        this.selectedPlanningProjects.remove(planningProject);
    }

    @computed
    public get anyUnplannedProjects() {
        return this.sortedPlanningProjects.length !== this.store.projectsStore.sortedProjects.length;
    }

    @action
    public selectProject(id: string) {
        const planningProject = this.sortedPlanningProjects.find(
            (planningProject) => planningProject.project.id === id
        );

        if (planningProject) {
            if (this.selectedProject !== undefined && this.selectedProject.project.id === planningProject.project.id) {
                runInAction(() => (this.selectedProject = undefined));
            } else {
                runInAction(() => {
                    this.selectedProject = planningProject;
                    this.selectedAbsence = undefined;
                });
            }
        }
    }

    @action setAbsence(absence: AbsenceDto) {
        runInAction(() => {
            this.selectedProject = undefined;
            this.selectedAbsence = this.selectedAbsence !== absence ? absence : undefined;
        });
    }

    /*
    @action
    public subscribePlanningBoardItemChanges() {
        this.isLoading = true;

        const query = Firebase.firestore
            .collection('tenants')
            .doc(Firebase.tenantId)
            .collection(PlanningStore.planningBoardItemsCollectionName)
            .where('date', '>=', this.periodStart)
            .where('date', '<=', this.periodEnd);

        this.unsubscribePlanningBoardItemChanges = query.onSnapshot(
            { includeMetadataChanges: true },
            (snapshot: firebase.firestore.QuerySnapshot) => {
                if (this.isFirstSnapShotHandled) {
                    snapshot.docChanges().forEach((change: firebase.firestore.DocumentChange) => {
                        if (change.type === 'added') {
                            this.addPlanningBoardItem(change.doc.data() as PlanningBoardItemDto);
                        }
                        if (change.type === 'modified') {
                            this.upsertPlanningBoardItem(change.doc.data() as PlanningBoardItemDto);
                        }
                        if (change.type === 'removed') {
                            this.removePlanningBoardItem(change.doc.data().id);
                        }
                    });
                    this.recalculatePlanningBoardRows();
                } else {
                    this.setIsLoading(true);
                    if (!snapshot.metadata.fromCache) {
                        const planningBoardItems: PlanningBoardItem[] = [];
                        snapshot.docs.forEach((value: firebase.firestore.QueryDocumentSnapshot) => {
                            const dto = value.data() as PlanningBoardItemDto;
                            planningBoardItems.push(PlanningBoardItem.createFromDto(dto));
                        });

                        this.planningBoardItems = observable.array<PlanningBoardItem>(planningBoardItems);
                        this.isFirstSnapShotHandled = true;

                        this.recalculatePlanningBoardRows();
                    } else {
                        this.planningBoardItems = observable.array<PlanningBoardItem>([]);
                        this.recalculatePlanningBoardRows();
                    }
                    this.setIsLoading(false);
                }
            }
        );
    }

     */

    @action setIsLoading(isLoading: boolean) {
        this.isLoading = isLoading;
    }

    @action
    public setCurrentPeriod() {
        this.periodStart = startOfDay(new Date());
    }

    @action
    public setPreviousPeriod() {
        const newStartDate = startOfDay(subDays(this.periodStart, this.visibleDays));
        if (this.showWeekends) {
            this.periodStart = newStartDate;
        } else {
            const businessDays = differenceInBusinessDays(this.periodStart, newStartDate);
            const reduceDays = this.visibleDays - businessDays;
            this.periodStart = subDays(newStartDate, reduceDays);
        }
    }

    @action
    public setNextPeriod() {
        let newStartDate = startOfDay(addDays(this.periodEnd, 1));
        if (!this.showWeekends) {
            while (isWeekend(newStartDate)) {
                newStartDate = addDays(newStartDate, 1);
            }
        }
        this.periodStart = newStartDate;
    }

    /*

    @action
    private async removePlanningBoardItem(id: string) {
        const item = this.planningBoardItems.find((x) => x.id === id);
        if (item) {
            this.planningBoardItems.remove(item);
            this.removeItemFromPlanningBoardRow(item);
        }
    }

    @action
    private addPlanningBoardItem(dto: PlanningBoardItemDto) {
        const planningBoardItem = PlanningBoardItem.createFromDto(dto);
        this.planningBoardItems.push(planningBoardItem);
        this.addIemToPlanningBoardRow(planningBoardItem);
    }

     */

    @action
    private removeItemFromPlanningBoardRow(planningBoardItem: PlanningBoardItem) {
        const row = this.planningBoardRows.find(
            (x) =>
                (x.employee && x.employee.id === planningBoardItem.employeeId) ||
                (x.resource && x.resource.id === planningBoardItem.resourceId)
        );
        if (row) {
            const day = row.days.find((x) => x.date && isSameDay(x.date, planningBoardItem.date));
            if (day) {
                const otherItems = day.items.filter((x) => x.id !== planningBoardItem.id);
                const placeholderItem = new PlanningBoardItem(planningBoardItem.date);
                if (planningBoardItem.employeeId) {
                    placeholderItem.employeeId = planningBoardItem.employeeId;
                } else if (planningBoardItem.resourceId) {
                    placeholderItem.resourceId = planningBoardItem.resourceId;
                }
                if (otherItems.filter((x) => x.projectRef || x.absence).length === 0) {
                    placeholderItem.period = 'allDay';
                    day.items = [placeholderItem];
                } else {
                    placeholderItem.period = planningBoardItem.period;
                    day.items = [...otherItems, placeholderItem];
                }
            } else {
                console.error('No day item specified -> check implementation');
            }
        }
    }

    @action
    private addIemToPlanningBoardRow(planningBoardItem: PlanningBoardItem) {
        const row = this.planningBoardRows.find(
            (x) =>
                (x.employee && x.employee.id === planningBoardItem.employeeId) ||
                (x.resource && x.resource.id === planningBoardItem.resourceId)
        );
        if (row) {
            const day = row.days.find((x) => x.date && isSameDay(x.date, planningBoardItem.date));
            if (day) {
                if (planningBoardItem.period === 'allDay') {
                    day.items = [planningBoardItem];
                } else if (planningBoardItem.period === 'morning') {
                    const items = day.items.filter((x) => x.period !== 'morning');
                    day.items = [...items, planningBoardItem];
                } else if (planningBoardItem.period === 'afternoon') {
                    const items = day.items.filter((x) => x.period !== 'afternoon');
                    day.items = [...items, planningBoardItem];
                }
            } else {
                console.error('No day item specified -> check implementation');
            }
        }
    }

    /*
    @action
    private upsertPlanningBoardItem(dto: PlanningBoardItemDto) {
        const item = this.planningBoardItems.find((x) => x.id === dto.id);
        if (item) {
            this.removePlanningBoardItem(dto.id);
        }
        this.addPlanningBoardItem(dto);
    }

     */
    @action
    private async removePlanningProject(id: string) {
        const item = this.selectedPlanningProjects.find((x) => x.project.id === id);
        if (item) {
            this.selectedPlanningProjects.remove(item);
        }
    }

    @computed
    public get getEmployeesNumber() {
        return this.store.employeesStore.employeesCount;
    }

    @computed
    public get getResourcesNumber() {
        return this.store.resourcesStore.resourceCount;
    }

    @computed
    public get plannedProjectsInPeriod(): PlanningProject[] {
        if (!this.store.projectsStore.hasProjects) {
            return [];
        }

        const planningProjects: PlanningProject[] = [];
        this.planningBoardItems.forEach((planningBoardItem: PlanningBoardItem) => {
            if (planningBoardItem.projectRef) {
                if (
                    planningProjects.findIndex(
                        (planningProject: PlanningProject) =>
                            planningProject.project.id === planningBoardItem.projectRef!.id
                    ) === -1
                ) {
                    const project = this.store.projectsStore.sortedProjects.find(
                        (x) => x.id === planningBoardItem.projectRef!.id
                    );
                    if (project) {
                        planningProjects.push(new PlanningProject(project, true));
                    }
                }
            }
        });

        return planningProjects;
    }

    private handleFixedDateBar() {
        if (this.isProjectsMobileVisible) {
            if (window.pageYOffset > 168) {
                return runInAction(() => {
                    this.isDateBarFixed = true;
                });
            } else {
                return runInAction(() => {
                    this.isDateBarFixed = false;
                });
            }
        } else {
            if (window.pageYOffset > 62) {
                return runInAction(() => {
                    this.isDateBarFixed = true;
                });
            } else {
                return runInAction(() => {
                    this.isDateBarFixed = false;
                });
            }
        }
    }
}
