import TenantStore from '../stores/tenantStore';
import { observable, action, runInAction, computed, toJS } from 'mobx';
import Validator from 'validatorjs';
import { validateField, validate } from '../../../common/helpers/validationHelper';
import { Address } from '../../../common/models/address';
import i18n from 'i18next';
import * as uuid from 'uuid';
import { AddressDto, TenantDto } from '../../../api/dtos/generated/dtos.generated';
import { TenantPresence } from './tenantPresence';
import { TenantSubscription } from './tenantSubscription';
import { getTenantPath } from '../../../common/helpers/storageHelper';

export class Tenant {
    private static rules = {
        name: 'required|max:100',
    };

    store: TenantStore;
    id: string;
    @observable isNew: boolean;
    @observable name: string = '';
    @observable address: Address = new Address();
    @observable validationErrors: { [index: string]: any } = {};
    @observable isDirty: boolean = false;
    @observable changedImage?: string;
    @observable imageHash?: string;
    @observable tenantId?: string;

    @observable presence: TenantPresence = new TenantPresence();
    @observable subscription: TenantSubscription = new TenantSubscription();

    @observable _lastTaskChanges?: any;
    @observable _lastProjectChanges?: any;
    @observable _lastPlanningProjectChanges?: any;

    public static createFromDto(store: TenantStore, dto: TenantDto): Tenant {
        const tenant = new Tenant(store, dto.id);
        tenant.tenantId = store.store.authStore.tenantId;
        tenant.name = dto.name;
        tenant.address = dto.address ? Address.createFromDto(dto.address as AddressDto) : new Address();
        tenant.presence = dto.presence ? TenantPresence.createFromDto(dto.presence) : new TenantPresence();
        tenant.subscription = dto.subscription
            ? TenantSubscription.createFromDto(dto.subscription)
            : new TenantSubscription();
        tenant.imageHash = dto.imageHash ? dto.imageHash : undefined;
        tenant._lastProjectChanges = dto._lastProjectChanges ? dto._lastProjectChanges : undefined;
        tenant._lastTaskChanges = dto._lastTaskChanges ? dto._lastTaskChanges : undefined;
        tenant._lastPlanningProjectChanges = dto._lastPlanningProjectChanges
            ? dto._lastPlanningProjectChanges
            : undefined;
        return tenant;
    }

    private static getValidator(data: any) {
        const validator = new Validator(data, Tenant.rules);
        validator.setAttributeNames({
            name: i18n.t('name'),
        });

        return validator;
    }

    constructor(store: TenantStore, id: string = uuid.v4()) {
        this.id = id;
        this.store = store;
        this.isNew = true;
    }

    public toDto(): TenantDto {
        return {
            id: this.id,
            name: this.name,
            imageHash: this.imageHash ? this.imageHash : null,
            address: this.address.toDto(),
            presence: this.presence.toDto(),
            subscription: this.subscription.toDto(),
        };
    }

    @action
    updateProperty(field: string, value: any) {
        if (!this.isDirty) {
            this.isDirty = true;
        }
        (this as any)[field] = value;
        const validator = Tenant.getValidator({ [field]: value });
        this.validationErrors = validateField(validator, field, this.validationErrors);
    }

    @action
    validateAll(data: any) {
        const validator = Tenant.getValidator(data);
        this.validationErrors = validate(validator);
    }

    @computed get isValid() {
        return (
            Object.keys(this.validationErrors).length === 0 &&
            this.address.isValid &&
            this.presence.isValid &&
            this.subscription.isValid
        );
    }

    @computed get imagePath(): string | undefined {
        return this.imageHash ? getTenantPath(this.id) : undefined;
    }

    @computed get imageThumbPath(): string | undefined {
        return this.imageHash ? getTenantPath(this.id, true) : undefined;
    }

    @computed get isTenantDirty(): boolean {
        return this.isDirty || this.address.isDirty || this.presence.isDirty;
    }

    @action
    async save() {
        this.validateAll(toJS(this));
        this.address.validateAll(toJS(this.address));
        this.presence.validateAll();
        this.subscription.validateAll(toJS(this.subscription));
        if (this.isValid) {
            await this.store.save(this).then(() =>
                runInAction(() => {
                    this.isDirty = false;
                    this.address.isDirty = false;
                    this.presence.isDirty = false;
                })
            );
        }
    }

    public cloneTenant() {
        const dto = this.toDto();
        return Tenant.createFromDto(this.store, dto);
    }
}
