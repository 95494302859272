import { action, observable, runInAction } from 'mobx';
import Firebase from './api/firebase';

class ImageCache {
    @observable public cache: { path: string; url: string; hash: string }[] = [];

    @action
    public async getUrl(path: string, hash: string): Promise<string | undefined> {
        try {
            const cached = this.cache.find((c) => c.hash === hash && c.path === path);
            if (cached) {
                return cached.url;
            }

            const ref = Firebase.storage.ref();
            const url = await ref.child(path).getDownloadURL();

            runInAction(() => {
                this.cache.push({ path: path, url: url, hash: hash });
            });

            return url;
        } catch (e) {
            return undefined;
        }
    }
}

const instance = new ImageCache();
export default instance;
