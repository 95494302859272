import { observable, action, runInAction, computed } from 'mobx';
import Validator from 'validatorjs';
import { validateField, validate } from '../../../common/helpers/validationHelper';
import i18n from 'i18next';
import { differenceInHours, getDate, getMonth, getYear, setDate, setMonth, setYear, isBefore, isAfter } from 'date-fns';
import { PresenceDto } from '../../../api/dtos/generated/dtos.generated';
import { parseDate } from '../../../common/utils/dateHelper';

export class TenantPresence {
    @observable workPerDay: number = 8;
    @observable amountWorkingDays: number = 5;
    @observable upTimeFrom: Date = new Date(new Date().setHours(7, 0, 0, 0));
    @observable upTimeTo: Date = new Date(new Date().setHours(12, 0, 0, 0));
    @observable afternoonUpTimeFrom: Date = new Date(new Date().setHours(13, 0, 0, 0));
    @observable afternoonUpTimeTo: Date = new Date(new Date().setHours(17, 0, 0, 0));
    @observable isMondayActive: boolean = true;
    @observable isTuesdayActive: boolean = true;
    @observable isWednesdayActive: boolean = true;
    @observable isThursdayActive: boolean = true;
    @observable isFridayActive: boolean = true;
    @observable isSaturdayActive: boolean = false;
    @observable isSundayActive: boolean = false;
    @observable validationErrors: { [index: string]: any } = {};
    @observable isDirty: boolean = false;

    private static rules = {
        upTimeFrom: 'required',
        upTimeTo: 'required',
        afternoonUpTimeFrom: 'required',
        afternoonUpTimeTo: 'required'
    };

    private attributeNames = {
        upTimeFrom: i18n.t('upTimeFrom'),
        upTimeTo: i18n.t('upTimeTo'),
        afternoonUpTimeFrom: i18n.t('upTimeFrom'),
        afternoonUpTimeTo: i18n.t('upTimeTo')
    };

    private getValidator(data: any, rules: any) {
        const validator = new Validator(data, rules);
        validator.setAttributeNames(this.attributeNames);

        return validator;
    }

    public static createFromDto(dto: PresenceDto): TenantPresence {
        const tenantPresence = new TenantPresence();
        tenantPresence.workPerDay = dto.workPerDay;
        tenantPresence.amountWorkingDays = dto.amountWorkingDays;
        tenantPresence.upTimeFrom = parseDate(dto.upTimeFrom)!;
        tenantPresence.upTimeTo = parseDate(dto.upTimeTo)!;
        tenantPresence.afternoonUpTimeFrom = parseDate(dto.afternoonUpTimeFrom)!;
        tenantPresence.afternoonUpTimeTo = parseDate(dto.afternoonUpTimeTo)!;
        tenantPresence.isMondayActive = dto.isMondayActive;
        tenantPresence.isTuesdayActive = dto.isTuesdayActive;
        tenantPresence.isWednesdayActive = dto.isWednesdayActive;
        tenantPresence.isThursdayActive = dto.isThursdayActive;
        tenantPresence.isFridayActive = dto.isFridayActive;
        tenantPresence.isSaturdayActive = dto.isSaturdayActive;
        tenantPresence.isSundayActive = dto.isSundayActive;

        return tenantPresence;
    }

    @action
    updateDateProperty(field: string, date: Date | null) {
        if (!this.isDirty) {
            this.isDirty = true;
        }

        runInAction(() => {
            (this as any)[field] = date;
        });

        if (date) {
            runInAction(() => {
                this.setDateInformation(this.upTimeFrom, date);
                this.setDateInformation(this.upTimeTo, date);
                this.setDateInformation(this.afternoonUpTimeFrom, date);
                this.setDateInformation(this.afternoonUpTimeTo, date);
            });
        }

        const validator = this.getValidator({ [field]: date }, { [field]: (TenantPresence.rules as any)[field] });
        this.validationErrors = validateField(validator, field, this.validationErrors);

        this.validateUpTime(field, date);
    }

    @action
    validateUpTime(field: string, date: Date | null) {
        if (field === 'upTimeFrom') {
            isAfter(date!, this.upTimeTo) ||
            isAfter(date!, this.afternoonUpTimeFrom) ||
            isAfter(date!, this.afternoonUpTimeTo)
                ? (this.validationErrors.upTimeFrom = i18n.t('invalid-morning-upTime'))
                : this.removeUpTimeErrors();
        } else if (field === 'upTimeTo') {
            isBefore(date!, this.upTimeFrom) ||
            isAfter(date!, this.afternoonUpTimeFrom) ||
            isAfter(date!, this.afternoonUpTimeTo)
                ? (this.validationErrors.upTimeTo = i18n.t('invalid-morning-upTime'))
                : this.removeUpTimeErrors();
        } else if (field === 'afternoonUpTimeFrom') {
            isAfter(date!, this.afternoonUpTimeTo) || isBefore(date!, this.upTimeFrom) || isBefore(date!, this.upTimeTo)
                ? (this.validationErrors.afternoonUpTimeFrom = i18n.t('invalid-afternoon-upTime'))
                : this.removeUpTimeErrors();
        } else if (field === 'afternoonUpTimeTo') {
            isBefore(date!, this.afternoonUpTimeFrom) ||
            isBefore(date!, this.upTimeFrom) ||
            isBefore(date!, this.upTimeTo)
                ? (this.validationErrors.afternoonUpTimeTo = i18n.t('invalid-afternoon-upTime'))
                : this.removeUpTimeErrors();
        }
    }

    validateAllUptime() {
        if (
            isAfter(this.upTimeFrom, this.upTimeTo) ||
            isAfter(this.upTimeFrom, this.afternoonUpTimeFrom) ||
            isAfter(this.upTimeFrom, this.afternoonUpTimeTo)
        ) {
            this.addInvalidUptimeErrors();
        } else if (
            isBefore(this.upTimeTo, this.upTimeFrom) ||
            isAfter(this.upTimeTo, this.afternoonUpTimeFrom) ||
            isAfter(this.upTimeTo, this.afternoonUpTimeTo)
        ) {
            this.addInvalidUptimeErrors();
        } else if (
            isAfter(this.afternoonUpTimeFrom, this.afternoonUpTimeTo) ||
            isBefore(this.afternoonUpTimeFrom, this.upTimeFrom) ||
            isBefore(this.afternoonUpTimeFrom, this.upTimeTo)
        ) {
            this.addInvalidUptimeErrors();
        } else if (
            isBefore(this.afternoonUpTimeTo, this.afternoonUpTimeFrom) ||
            isBefore(this.afternoonUpTimeTo, this.upTimeFrom) ||
            isBefore(this.afternoonUpTimeTo, this.upTimeTo)
        ) {
            this.addInvalidUptimeErrors();
        }
    }

    @action
    addInvalidUptimeErrors() {
        this.validationErrors.upTimeTo = i18n.t('invalid-morning-upTime');
        this.validationErrors.upTimeFrom = i18n.t('invalid-morning-upTime');
        this.validationErrors.afternoonUpTimeTo = i18n.t('invalid-afternoon-upTime');
        this.validationErrors.afternoonUpTimeFrom = i18n.t('invalid-afternoon-upTime');
    }

    @action
    removeUpTimeErrors() {
        delete this.validationErrors.upTimeFrom;
        delete this.validationErrors.upTimeTo;
        delete this.validationErrors.afternoonUpTimeFrom;
        delete this.validationErrors.afternoonUpTimeTo;
    }

    @action
    updateProperty(field: string, value: any) {
        if (!this.isDirty) {
            this.isDirty = true;
        }

        (this as any)[field] = value;
        if (TenantPresence.rules.hasOwnProperty(field)) {
            const validator = this.getValidator({ [field]: value }, { [field]: (TenantPresence.rules as any)[field] });
            this.validationErrors = validateField(validator, field, this.validationErrors);
        }
    }

    @computed get upTime(): number {
        let upTime = differenceInHours(this.upTimeTo!, this.upTimeFrom!);
        upTime += differenceInHours(this.afternoonUpTimeTo!, this.afternoonUpTimeFrom!);
        return upTime;
    }

    @computed get upTimeMorning(): number {
        let upTimeMorning = differenceInHours(this.upTimeTo!, this.upTimeFrom!);
        return upTimeMorning;
    }

    @computed get upTimeAfternoon(): number {
        let upTimeAfternoon = differenceInHours(this.afternoonUpTimeTo!, this.afternoonUpTimeFrom!);
        return upTimeAfternoon;
    }

    @action
    validateAll() {
        const validator = this.getValidator(
            {
                upTimeFrom: this.upTimeFrom,
                upTimeTo: this.upTimeTo,
                afternoonUpTimeFrom: this.afternoonUpTimeFrom,
                afternoonUpTimeTo: this.afternoonUpTimeTo
            },
            TenantPresence.rules
        );
        this.validationErrors = validate(validator);
        this.validateAllUptime();
    }

    @computed get isValid() {
        return Object.keys(this.validationErrors).length === 0;
    }

    public toDto(): PresenceDto {
        return {
            workPerDay: this.workPerDay,
            amountWorkingDays: this.amountWorkingDays,
            upTimeFrom: this.upTimeFrom,
            upTimeTo: this.upTimeTo,
            afternoonUpTimeFrom: this.afternoonUpTimeFrom,
            afternoonUpTimeTo: this.afternoonUpTimeTo,
            isMondayActive: this.isMondayActive,
            isTuesdayActive: this.isTuesdayActive,
            isWednesdayActive: this.isWednesdayActive,
            isThursdayActive: this.isThursdayActive,
            isFridayActive: this.isFridayActive,
            isSaturdayActive: this.isSaturdayActive,
            isSundayActive: this.isSundayActive
        } as PresenceDto;
    }

    private setDateInformation = (date: Date, newDate: Date) => {
        setDate(date, getDate(newDate));
        setMonth(date, getMonth(newDate));
        setYear(date, getYear(newDate));
    };
}
