import { BaseModel } from '../../../common/stores/model/baseModel';
import { action, computed, observable } from 'mobx';
import * as uuid from 'uuid';
import i18n from 'i18next';
import Validator from 'validatorjs';
import { validate, validateField } from '../../../common/helpers/validationHelper';
import {
    ProjectDto,
    ReferencedAddressDto,
    ReferencedCustomerDto,
    ProjectUpsertDto,
} from '../../../api/dtos/generated/dtos.generated';
import { mapTrackingFieldsToDto, mapTrackingFieldsToModel } from '../../../common/helpers/trackingFields';
import { getContrastColor, getRandomColor } from '../../../common/utils/colorUtil';
import { AppError } from '../../../common/models/appError';
import { ProjectsStore } from '../stores/projectsStore';
import { PlanningBoardItem } from '../../planning/models/planningBoardItem';

export class Project extends BaseModel {
    private static rules = {
        name: 'required|max:100',
        budget: 'required|integer|min:0',
        description: 'max:8000',
        customerRef: 'required',
    };
    private readonly projectsStore: ProjectsStore;
    id: string;
    @observable isNew: boolean;
    @observable isComplete: boolean = false;
    @observable name: string = '';
    @observable description: string = '';
    @observable budget: number = 0;
    @observable totalEffortPlanned: number = 0;
    @observable customerRef?: ReferencedCustomerDto;
    @observable addressRef?: ReferencedAddressDto;
    @observable validationErrors: { [index: string]: any } = {};
    @observable isDirty: boolean = false;
    @observable color: string = getRandomColor();
    @observable imageCount: number = 0;
    @observable noteCount: number = 0;
    @observable changedImage?: string;
    @observable public error?: AppError = undefined;

    constructor(projectStore: ProjectsStore, id: string = uuid.v4()) {
        super();
        this.projectsStore = projectStore;
        this.id = id;
        this.isNew = true;
    }

    public static createFromDto(dto: ProjectDto, projectStore: ProjectsStore): Project {
        const project = new Project(projectStore, dto.id);
        project.isNew = false;
        project.name = dto.name;
        project.budget = dto.budget;
        project.customerRef = dto.customerRef!;
        project.addressRef = dto.addressRef ? dto.addressRef : undefined;
        project.description = dto.description;
        project.isComplete = dto.isComplete;
        project.color = dto.color ? dto.color : getRandomColor();
        project.imageCount = dto.imageCount || 0;
        project.noteCount = dto.noteCount || 0;

        mapTrackingFieldsToModel(project, dto);

        return project;
    }

    private static getValidator(data: any) {
        const validator = new Validator(data, Project.rules);
        validator.setAttributeNames({
            name: i18n.t('name'),
            budget: i18n.t('budget'),
            description: i18n.t('description'),
            customerRef: i18n.t('customer'),
            addressRef: i18n.t('address'),
        });

        return validator;
    }

    updateFromDto(dto: ProjectDto): void {
        this.isNew = false;
        this.name = dto.name;
        this.budget = dto.budget;
        this.customerRef = dto.customerRef!;
        this.addressRef = dto.addressRef ? dto.addressRef : undefined;
        this.description = dto.description ? dto.description : '';
        this.color = dto.color ? dto.color : getRandomColor();
        this.isComplete = dto.isComplete;

        mapTrackingFieldsToModel(this, dto);
    }

    toDto(tenantId: string): ProjectUpsertDto {
        const dto = {
            id: this.id,
            name: this.name,
            budget: this.budget ? this.budget : 0,
            customerRef: this.customerRef!,
            addressRef: this.addressRef ? this.addressRef : null,
            description: this.description,
            isComplete: this.isComplete,
            color: this.color,
            tenantId: tenantId,
        };

        mapTrackingFieldsToDto(dto, this);

        return dto;
    }

    @action
    updateProperty(field: string, value: any) {
        if (!this.isDirty) {
            this.isDirty = true;
        }
        (this as any)[field] = value;

        const validator = Project.getValidator({ [field]: value });
        this.validationErrors = validateField(validator, field, this.validationErrors);
    }

    @action
    validateAll(data: any) {
        const validator = Project.getValidator(data);
        this.validationErrors = validate(validator);
    }

    @action
    onToggle(isComplete: boolean) {
        this.isComplete = isComplete;
    }

    @computed get contrastColor() {
        return getContrastColor(this.color);
    }

    @computed get customerDisplayName() {
        return this.customerRef ? this.customerRef.displayName : '';
    }

    @computed get isValid() {
        return Object.keys(this.validationErrors).length === 0;
    }

    @computed get getDescription() {
        return this.description.length < 100 ? this.description : this.description.substring(0, 100) + '...';
    }

    @computed get getIsComplete() {
        return this.isComplete;
    }

    @computed get bookedHours() {
        if (this.projectsStore.store.planningBoardItemsStore.planningBoardItems?.length < 1) {
            return 0;
        }

        const { upTimeMorning, upTimeAfternoon, upTime } = this.projectsStore.store.tenantStore.tenant?.presence!;
        const projectPlanningBoardItems = this.projectsStore.store.planningBoardItemsStore.planningBoardItems.filter(
            (x: PlanningBoardItem) => x.projectRef?.id === this.id
        );
        let bookedHours = 0;

        projectPlanningBoardItems.forEach((pbi: PlanningBoardItem) => {
            if (pbi.period === 'morning') {
                bookedHours += upTimeMorning;
            } else if (pbi.period === 'afternoon') {
                bookedHours += upTimeAfternoon;
            } else if (pbi.period === 'allDay') {
                bookedHours += upTime;
            }
        });

        return bookedHours;
    }

    @computed get isOverbooked() {
        return this.bookedHours > this.budget;
    }

    @computed get plannedPercent(): number {
        let percent = 0;

        if (this.budget > 0) {
            percent = (this.bookedHours / this.budget) * 100;
        }

        return percent;
    }
}
