import React, { Component } from 'react';
import { inject } from 'mobx-react';
import { UiStore } from '../../../stores/uiStore';
import { createStyles, TextField, WithStyles } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import { runInAction } from 'mobx';
import AlgoliaSuggestion from './AlgoliaSuggestion';
import Autosuggest from 'react-autosuggest';
import { connectAutoComplete } from 'react-instantsearch-dom';
import { IndexSearchConfig } from './algoliaSearchIndexConfigurations';

const styles = (theme: any) =>
    createStyles({
        suggestion: {
            display: 'block',
        },
        suggestionsList: {
            margin: 0,
            padding: 0,
            listStyleType: 'none',
        },
        listDetailsLabel: {
            fontSize: '12px',
            fontWeight: 600,
            marginBottom: '0.3rem',
        },
        searchDropdown: {
            boxShadow: '0 3px 15px 0 rgba(180, 192, 210, 0.45)',
            borderRadius: '10px',
            overflow: 'hidden',
            position: 'absolute',
            marginTop: theme.spacing(1),
            zIndex: 1,
            [theme.breakpoints.down('xs')]: {
                boxShadow: 'none',
                marginTop: theme.spacing(3),
            },
        },
        suggestionsContainerOpen: {
            boxShadow: '0 3px 15px 0 rgba(180, 192, 210, 0.45)',
            borderRadius: '10px',
            overflow: 'hidden',
            position: 'absolute',
            marginTop: theme.spacing(1),
            zIndex: 1,
            [theme.breakpoints.down('xs')]: {
                left: 0,
                right: 0,
                width: '100% !important',
                boxShadow: 'none',
                marginTop: theme.spacing(3),
            },
        },
        formControl: {
            width: '100%',
            maxWidth: 450,
            transition: 'all ease 0.32s',
            '& svg': {
                fill: 'rgba(0, 0, 0, 0.23)',
            },
        },
        input: {
            zIndex: 1,
        },
        textField: {
            maxWidth: 450,
            backgroundColor: 'white',
            borderRadius: 30,
        },
    });

function renderInputComponent(inputProps: any) {
    const {
        classes,
        inputRef = () => {},
        ref,
        required,
        error,
        onClick,
        helperText,
        selectHitId,
        ...other
    } = inputProps;

    return (
        <TextField
            classes={{ root: classes.textField }}
            variant={'outlined'}
            required={required}
            error={error}
            helperText={error ? helperText : undefined}
            InputProps={{
                inputRef: (node) => {
                    ref(node);
                    inputRef(node);
                },
                classes: {
                    root: selectHitId ? classes.formControl : undefined,
                    focused: selectHitId ? classes.inputFocused : undefined,
                    input: classes.input,
                },
            }}
            onClick={onClick}
            {...other}
        />
    );
}

interface IAlgoliaAutosuggestProps {
    indexSearchConfigs: IndexSearchConfig[];
    hits: any[];
    error: boolean;
    currentRefinement: string;
    selectHitId: boolean;
    uiStore?: UiStore;
    placeholder?: string;
    helperText?: string;
    required?: boolean;
    refine: (value?: string) => void;
    onHitSelected?: (x: any) => void;
    onHitIdSelected?: (id: string, indexName: any) => void;
}

interface IAlgoliaAutosuggestState {
    value: string;
    alwaysRenderSuggestions: boolean;
}

type Props = IAlgoliaAutosuggestProps & WithStyles;

function algoliaSuggestion(hit: any, config: any, onItemSelected: any) {
    return <AlgoliaSuggestion indexSearchConfigs={config} onItemSelected={onItemSelected} hit={hit} />;
}

@inject(UiStore.storeName)
class AlgoliaAutosuggest extends Component<Props, IAlgoliaAutosuggestState> {
    popperNode: any;

    state = {
        value: this.props.currentRefinement,
        alwaysRenderSuggestions: false,
    };

    onClick = () => {
        this.setState({ alwaysRenderSuggestions: true });
    };

    onBlur = () => {
        this.setState({ alwaysRenderSuggestions: false });
    };

    onChange = (event: any, { newValue }: any) => {
        this.setState({
            value: newValue !== undefined ? newValue : '',
        });
    };

    onSuggestionsFetchRequested = ({ value }: any) => {
        this.props.refine(value);
    };

    onSuggestionsClearRequested = () => {
        this.props.refine();
    };

    getSuggestionValue(hit: any) {
        return hit.name;
    }

    onItemSelected = (item: any, indexName: string) => {
        if (this.props.selectHitId) {
            this.props.onHitIdSelected && this.props.onHitIdSelected(item.id, indexName);
            this.setState({ alwaysRenderSuggestions: false });
            runInAction(() => (this.props.uiStore!.isModalOpen = false));
        } else {
            this.props.refine();
            this.props.onHitSelected && this.props.onHitSelected(item);
            this.setState({ alwaysRenderSuggestions: false });
        }
    };

    renderSectionTitle(section: any) {
        return section.index;
    }

    getSectionSuggestions(section: any) {
        return section.hits;
    }

    render() {
        const { hits, classes, error, helperText, uiStore, selectHitId } = this.props;
        const autosuggestProps = {
            renderInputComponent,
            multiSection: false,
            suggestions: hits,
            alwaysRenderSuggestions: this.state.alwaysRenderSuggestions,
            onSuggestionsFetchRequested: this.onSuggestionsFetchRequested,
            onSuggestionsClearRequested: this.onSuggestionsClearRequested,
            getSuggestionValue: this.getSuggestionValue,
            renderSuggestion: (hit: any) => algoliaSuggestion(hit, this.props.indexSearchConfigs, this.onItemSelected),
        };

        const addProps: any = {
            classes,
            placeholder: this.props.placeholder,
            value: this.state.value,
            onChange: this.onChange,
            onBlur: this.onBlur,
            onClick: this.onClick,
            inputRef: (node: any) => {
                this.popperNode = node;
            },
            InputLabelProps: {
                shrink: true,
            },
            error,
            helperText,
            selectHitId,
        };

        return (
            <Autosuggest
                {...autosuggestProps}
                inputProps={{ ...addProps }}
                theme={{
                    suggestionsList: classes.suggestionsList,
                    suggestionsContainerOpen: classes.suggestionsContainerOpen,
                    suggestion: classes.suggestion,
                }}
                renderSuggestionsContainer={(options) => (
                    <Paper
                        square
                        {...options.containerProps}
                        style={
                            selectHitId
                                ? {
                                      width: 'auto',
                                      maxHeight: 490,
                                      overflowY: 'auto',
                                  }
                                : uiStore!.isMobile
                                ? { position: 'relative', width: this.popperNode ? this.popperNode.clientWidth : null }
                                : { position: 'absolute', width: this.popperNode ? this.popperNode.clientWidth : null }
                        }
                    >
                        {options.children}
                    </Paper>
                )}
            />
        );
    }
}

const styledComponent = withStyles(styles, { withTheme: true })(AlgoliaAutosuggest);
const AutoComplete = connectAutoComplete(styledComponent);

export default AutoComplete;
