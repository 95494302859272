import { UiStore } from './uiStore';
import { ResourcesStore } from '../modules/resources/stores/resourcesStore';
import { AuthStore } from '../modules/auth/stores/authStore';
import EmployeesStore from '../modules/employees/stores/employeesStore';
import CustomersStore from '../modules/customers/stores/customersStore';
import TenantStore from '../modules/tenant/stores/tenantStore';
import { TasksStore } from '../modules/tasks/stores/tasksStore';
import { ProjectsStore } from '../modules/projects/stores/projectsStore';
import { PlanningStore } from '../modules/planning/stores/planningStore';
import { DashboardStore } from '../modules/dashboard/stores/dashboardStore';
import { PlanningBoardItemsStore } from '../modules/planning/stores/planningBoardItemsStore';

export class RootStore {
    public static storeName = 'rootStore';

    uiStore: UiStore;
    authStore: AuthStore;
    tenantStore: TenantStore;
    employeesStore: EmployeesStore;
    resourcesStore: ResourcesStore;
    tasksStore: TasksStore;
    customersStore: CustomersStore;
    projectsStore: ProjectsStore;
    planningStore: PlanningStore;
    dashboardStore: DashboardStore;
    planningBoardItemsStore: PlanningBoardItemsStore;

    constructor() {
        this.authStore = new AuthStore(this);
        this.uiStore = new UiStore(this);
        this.tenantStore = new TenantStore(this);
        this.planningBoardItemsStore = new PlanningBoardItemsStore(this);
        this.employeesStore = new EmployeesStore(this);
        this.resourcesStore = new ResourcesStore(this);
        this.tasksStore = new TasksStore(this);
        this.customersStore = new CustomersStore(this);
        this.projectsStore = new ProjectsStore(this);
        this.planningStore = new PlanningStore(this);
        this.dashboardStore = new DashboardStore(this);
    }
}
