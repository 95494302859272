import React from 'react';
import { createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PlanuxLogo from '../../assets/planux-logo.svg';
import PlanuxLogoWhite from '../../assets/planux-logo-white.svg';

const useStyles = makeStyles(({ spacing, palette, transitions }: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh'
        },
        inner: {
            maxWidth: '300px',
            height: '300px',
            padding: spacing(3),
            display: 'flex',
            width: '100%',
            borderRadius: '10px',
            justifyContent: 'center'
        },
        '@keyframes bounceIn': {
            '0%': {
                opacity: 0,
                transform: 'scale(0.3) translate3d(0,0,0)'
            },
            '50%': {
                opacity: 0.9,
                transform: 'scale(1.1)'
            },
            '80%': {
                opacity: 1,
                transform: 'scale(0.89)'
            },
            '100%': {
                opacity: 1,
                transform: 'scale(1) translate3d(0,0,0)'
            }
        },
        logo: {
            maxWidth: '150px',
            animation: `$bounceIn 2500ms ${transitions.easing.easeInOut} 200ms infinite`
        }
    })
);

interface LogoAnimateProps {
    contrast?: Boolean;
}

const LogoAnimate = ({ contrast }: LogoAnimateProps) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.inner}>
                {contrast ? (
                    <img src={PlanuxLogoWhite} alt={PlanuxLogoWhite} className={classes.logo} />
                ) : (
                    <img src={PlanuxLogo} alt={PlanuxLogo} className={classes.logo} />
                )}
            </div>
        </div>
    );
};

export default LogoAnimate;
