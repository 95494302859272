export const handle = (tenant: any) => {
    if (tenant.subscription.status === 'canceled') {
        return {
            data: {
                code: 'subscription-canceled',
                message: 'subscription-canceled',
                details: 'subscription-canceled'
            }
        };
    } else if (tenant.subscription.status === 'past_due') {
        return {
            data: {
                code: 'subscription-past-due',
                message: 'subscription-past-due',
                details: 'subscription-past-due'
            }
        };
    }

    return {
        data: {
            code: '200'
        }
    };
};
