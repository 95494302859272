import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import { Theme, Typography, Button, Zoom, Grid, createStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { centerPaper } from '../../../common/hocs/centerPaper';
import PlanuxLogo from '../../../assets/planux_logo.svg';
import TopProgress from '../../../common/components/TopProgress';
import { StoreContext } from '../../../App';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useStripe } from '../../tenant/components/useStripe';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '370px',
        },
        logo: {
            width: '180px',
            alignSelf: 'center',
            margin: theme.spacing(2),
        },
    })
);

const UnactiveTenantScreen = observer(
    centerPaper(() => {
        const { t } = useTranslation();
        const { tenantStore, authStore } = useContext(StoreContext);
        const { openBillingPortal, openBillingPortalInProgress } = useStripe();
        const classes = useStyles();

        const handleLogout = () => {
            authStore.logout();
        };

        return (
            <div className={classes.root}>
                <TopProgress
                    inProgress={tenantStore.isLoading!}
                    style={{ position: 'absolute', top: '0px', left: '0', right: '0' }}
                />
                <Grid container direction={'column'} spacing={3}>
                    <Grid item container justify={'center'}>
                        <Zoom in timeout={500}>
                            <img className={classes.logo} src={PlanuxLogo} alt="logo" />
                        </Zoom>
                    </Grid>
                    <Grid item>
                        <Typography variant="subtitle1" align={'center'}>
                            {t('add-payment-details-unactive-tenant-title')}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="subtitle2" color="textSecondary" align={'center'}>
                            {t('unactive-tenant-stripe-checkout-note')}
                        </Typography>
                    </Grid>
                    <Grid item style={{ textAlign: 'center' }}>
                        <Button
                            variant="contained"
                            color={'primary'}
                            disabled={tenantStore!.inProgress || openBillingPortalInProgress}
                            onClick={() => openBillingPortal(tenantStore.customerId)}
                        >
                            {t('add-payment-details')}
                        </Button>
                    </Grid>
                    <Grid item style={{ textAlign: 'center' }}>
                        <Button
                            color={'primary'}
                            variant={'outlined'}
                            onClick={() => handleLogout()}
                            disabled={tenantStore!.inProgress}
                        >
                            {t('logout')}
                        </Button>
                    </Grid>
                </Grid>
            </div>
        );
    })
);

export default UnactiveTenantScreen;
