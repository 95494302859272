import { action, observable, runInAction } from 'mobx';
import { AppError } from '../common/models/appError';

class BaseStore {
    @observable public error?: AppError = undefined;
    @observable public isLoading: boolean = false;
    @observable public inProgress?: boolean = false;
    @observable public successMessage?: string = '';

    @action
    public async handle(resp: Promise<any>) {
        try {
            this.error = undefined;
            const result = await resp;
            if (result !== undefined) {
                const data = result.data;
                if (data.code === '200') {
                    if (data['message'] !== undefined) {
                        runInAction(() => (this.successMessage = data.message));
                    }
                    return data;
                } else {
                    throw data;
                }
            }
        } catch (error) {
            runInAction(() => {
                if (error.details != null) {
                    this.error = {
                        key: error.details,
                        name: error.details,
                        message: error.message
                    };
                } else {
                    this.error = {
                        key: error.code,
                        name: error.code,
                        message: error.message
                    };
                }
            });
        }
    }
}

export default BaseStore;
