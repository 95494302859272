import { AppError } from '../common/models/appError';
import Firebase from './firebase';
import { WhereFilterOp } from '@firebase/firestore-types';
import { sessionStorageService } from '../sessionStorageService';
import { BaseDto } from './dtos/generated/dtos.generated';
import { mapTrackingFields } from '../common/utils/generated/trackingFields.generated';

class BaseService {
    public async getAll<T>(collectionName: string, appError: string): Promise<T[]> {
        try {
            const querySnapshot = await Firebase.firestore
                .collection('tenants')
                .doc(Firebase.tenantId)
                .collection(collectionName)
                .get();
            const items: T[] = [];
            if (!querySnapshot.empty) {
                for (const doc of querySnapshot.docs) {
                    const model = doc.data() as T;
                    items.push(model);
                }
                return items;
            } else {
                return items;
            }
        } catch (error) {
            return Promise.reject(new AppError(appError));
        }
    }

    public async getEntity<T>(id: string, collectionName: string, appError: string): Promise<T | undefined> {
        try {
            const doc = await Firebase.firestore
                .collection('tenants')
                .doc(Firebase.tenantId)
                .collection(collectionName)
                .doc(id)
                .get();

            if (doc.exists) {
                return doc.data() as T;
            } else {
                return undefined;
            }
        } catch (error) {
            return Promise.reject(new AppError(appError));
        }
    }

    public upsertEntity<T extends BaseDto>(entityId: string, entity: T, collectionName: string): Promise<void> {
        this.handleTrackingField(entity, !entity.createdBy);

        return Firebase.firestore
            .collection('tenants')
            .doc(Firebase.tenantId)
            .collection(collectionName)
            .doc(entityId)
            .set(entity, { merge: true });
    }

    public updateEntityPartial<T extends BaseDto>(id: string, entity: Partial<T>, collectionName: string) {
        this.handleTrackingField(entity as any, false);

        Firebase.firestore
            .collection('tenants')
            .doc(Firebase.tenantId)
            .collection(collectionName)
            .doc(id)
            .update(entity);
    }

    public upsertEntityDeep<T extends BaseDto>(
        rootEntityId: string,
        entity: T,
        rootCollectionName: string,
        collectionName: string
    ): Promise<void> {
        this.handleTrackingField(entity, !entity.createdBy);

        return Firebase.firestore
            .collection('tenants')
            .doc(Firebase.tenantId)
            .collection(rootCollectionName)
            .doc(rootEntityId)
            .collection(collectionName)
            .doc(entity.id)
            .set(entity);
    }

    public softDeleteEntity<T extends BaseDto>(entityId: string, entity: T, collectionName: string): Promise<void> {
        const loginId = sessionStorageService.getLoginId();

        entity.deletedBy = loginId;
        entity.deletedAt = new Date();

        return this.upsertEntity(entityId, entity, collectionName);
    }

    public deleteEntity(entityId: string, collectionName: string): Promise<void> {
        return Firebase.firestore
            .collection('tenants')
            .doc(Firebase.tenantId)
            .collection(collectionName)
            .doc(entityId)
            .delete();
    }

    public deleteEntityDeep(
        rootEntityId: string,
        entityId: string,
        rootCollectionName: string,
        collectionName: string
    ): Promise<void> {
        return Firebase.firestore
            .collection('tenants')
            .doc(Firebase.tenantId)
            .collection(rootCollectionName)
            .doc(rootEntityId)
            .collection(collectionName)
            .doc(entityId)
            .delete();
    }

    public async getDocById(id: string, collectionName: string) {
        return await Firebase.firestore
            .collection('tenants')
            .doc(Firebase.tenantId)
            .collection(collectionName)
            .doc(id)
            .get();
    }

    public async getDocRefById(id: string, collectionName: string) {
        return await Firebase.firestore.collection('tenants').doc(Firebase.tenantId).collection(collectionName).doc(id);
    }

    public async getDocCollectionById(id: string, primaryCollectionName: string, collectionName: string) {
        return await Firebase.firestore
            .collection('tenants')
            .doc(Firebase.tenantId)
            .collection(primaryCollectionName)
            .doc(id)
            .collection(collectionName)
            .get();
    }

    public async getDocCollectionRef(id: string, primaryCollectionName: string, collectionName: string) {
        return Firebase.firestore
            .collection('tenants')
            .doc(Firebase.tenantId)
            .collection(primaryCollectionName)
            .doc(id)
            .collection(collectionName);
    }

    public async getCollectionRef(collectionName: string) {
        return Firebase.firestore.collection('tenants').doc(Firebase.tenantId).collection(collectionName);
    }

    public async getCollectionByFilter(
        tenantId: string,
        collectionName: string,
        fieldPath: string,
        opStr: WhereFilterOp,
        value: any
    ) {
        return await Firebase.firestore
            .collection('tenants')
            .doc(tenantId)
            .collection(collectionName)
            .where(fieldPath, opStr, value)
            .get();
    }

    private handleTrackingField<T extends BaseDto>(entity: T, isNew?: boolean) {
        const loginId = sessionStorageService.getLoginId();

        mapTrackingFields(entity, loginId ? loginId : undefined, isNew);
    }
}

export default new BaseService();
