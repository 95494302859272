import React, { Component } from 'react';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import AvatarEditor from 'react-avatar-editor';
import { Avatar, Button, Grid, Typography } from '@material-ui/core';
import { computed, observable, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import { WithTranslation, withTranslation } from 'react-i18next';
import Slider from '@material-ui/core/Slider';
import MinusIcon from 'mdi-material-ui/Minus';
import PlusIcon from 'mdi-material-ui/Plus';
import imageCache from '../../imageCache';
import { ImageType } from '../../api/dtos/generated/dtos.generated';

export interface IAvatarProps {
    imagePath?: string;
    imageHash?: string;
    imageChanged: (image: string) => void;
    defaultImage: any;
    imageType?: ImageType;
    inProgress: boolean;
    readonly?: boolean;
}

export const styles = (theme: Theme) =>
    createStyles({
        root: {
            marginBottom: theme.spacing(1),
        },
        avatar: {
            margin: 'auto',
            width: 250,
            height: 250,
            marginBottom: '1rem',
            [theme.breakpoints.down('xs')]: {
                margin: 'auto',
                width: 100,
                height: 100,
                marginBottom: '1rem',
            },
        },
        input: {
            display: 'none',
        },
        zoom: {
            width: 250,
        },
        avatarContainer: {
            textAlign: 'center',
        },
        upload: {
            cursor: 'pointer',
        },
    });

type Props = IAvatarProps & WithStyles<typeof styles> & WithTranslation;

@observer
class AvatarChooser extends Component<Props> {
    @observable inEditMode: boolean = false;
    @observable editor: any;
    @observable file?: any;
    @observable image: any;
    @observable zoom: number = 100;
    @observable imageUrl?: string;

    @computed
    public get imageToDisplay() {
        if (this.imageUrl) {
            if (this.image && this.image !== this.props.defaultImage) {
                return this.image;
            }

            return this.imageUrl;
        }

        if (this.image && this.image !== this.props.defaultImage) {
            return this.image;
        }
        return this.props.defaultImage;
    }

    @computed public get fileType() {
        const contentType = this.props.imageType === ImageType.Jpeg ? 'image/jpeg' : 'image/png';
        return contentType;
    }

    render() {
        const { classes, t, inProgress, readonly } = this.props;

        if (!this.inEditMode) {
            return (
                <Grid container direction="column" alignItems={'center'} className={classes.root}>
                    <Grid item>
                        <div className={classes.avatarContainer}>
                            <Avatar alt="image" src={this.imageToDisplay} className={classes.avatar} />
                            {readonly === false && (
                                <Button
                                    variant="contained"
                                    color={'primary'}
                                    onClick={this.handleClick}
                                    disabled={inProgress}
                                >
                                    {t('changeImage')}
                                </Button>
                            )}
                        </div>
                    </Grid>
                </Grid>
            );
        }

        return (
            <Grid container direction="column" alignItems={'center'} className={classes.root}>
                <Grid item>
                    <AvatarEditor
                        image={this.imageToDisplay}
                        border={0}
                        color={[255, 255, 255, 0.6]} // RGBA
                        scale={this.zoom / 100}
                        rotate={0}
                        borderRadius={255}
                        ref={this.setEditorRef}
                        width={250}
                        height={250}
                    />
                </Grid>
                <Grid item>
                    <div className={classes.zoom}>
                        <Typography id="zoom" gutterBottom>
                            {t('zoom')}
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item>
                                <MinusIcon />
                            </Grid>
                            <Grid item xs>
                                <Slider
                                    value={this.zoom}
                                    onChange={this.handleZoomChanged}
                                    aria-labelledby="zoom"
                                    min={10}
                                    max={200}
                                    step={1}
                                />
                            </Grid>
                            <Grid item>
                                <PlusIcon />
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
                <Grid item container justify="center" spacing={1}>
                    <Grid item>
                        <Button variant="contained" onClick={this.delete}>
                            {t('cancel')}
                        </Button>
                    </Grid>
                    <Grid item>
                        <input
                            accept="image/*"
                            className={classes.input}
                            id="icon-button-file"
                            type="file"
                            onChange={this.handleFileChange}
                        />

                        <Button
                            variant="contained"
                            color={this.file ? 'default' : 'primary'}
                            className={classes.upload}
                        >
                            <label htmlFor="icon-button-file">{t('browse')}</label>
                        </Button>
                    </Grid>
                    {this.file && (
                        <Grid item>
                            <Button variant="contained" color={'primary'} onClick={this.confirm}>
                                {t('apply')}
                            </Button>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        );
    }

    public componentDidMount(): void {
        if (this.props.imagePath && this.props.imageHash) {
            imageCache.getUrl(this.props.imagePath, this.props.imageHash).then((c) => {
                this.imageUrl = c;
            });
        }
    }

    public UNSAFE_componentWillReceiveProps(nextProps: Readonly<Props>, nextContext: any): void {
        if (nextProps.imagePath && nextProps.imageHash) {
            imageCache.getUrl(nextProps.imagePath, nextProps.imageHash).then((c) => {
                this.imageUrl = c;
            });
        }
    }

    private handleZoomChanged = (event: object, value: any) => {
        const zoom = parseInt(value, 10);
        runInAction(() => (this.zoom = zoom));
    };

    private setEditorRef = (editor: any) => (this.editor = editor);

    private handleClick = () => {
        runInAction(() => (this.inEditMode = true));
    };

    private delete = () => {
        runInAction(() => (this.inEditMode = false));
    };

    private confirm = () => {
        if (this.editor) {
            // This returns a HTMLCanvasElement, it can be made into a data URL or a blob,
            // drawn on another canvas, or added to the DOM.
            //const canvas = this.editor.getImage();

            // If you want the image resized to the canvas size (also a HTMLCanvasElement)
            const canvasScaled = this.editor.getImageScaledToCanvas();
            const image = canvasScaled.toDataURL(this.fileType, 1);

            runInAction(() => {
                this.inEditMode = false;
                this.image = image;
                this.props.imageChanged(this.image);
            });
        }
    };

    private handleFileChange = (event: any) => {
        event.stopPropagation();
        event.preventDefault();
        const reader = new FileReader();

        runInAction(() => {
            if (event.target.files && event.target.files.length > 0) {
                this.file = event.target.files[0];
            }
        });

        reader.onloadend = (e: any) => {
            runInAction(() => {
                this.image = reader.result;
            });
        };

        reader.readAsDataURL(this.file);
    };
}

const styledComponent = withStyles(styles, { withTheme: true })(AvatarChooser);
const translatedComponent = withTranslation()(styledComponent);
export default translatedComponent;
