import * as React from 'react';
import { createStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            [theme.breakpoints.up('sm')]: {
                height: '100vh'
            },
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '100vh'
        },
        paper: {
            paddingLeft: theme.spacing(8),
            paddingRight: theme.spacing(8),
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(4),
            position: 'relative',
            overflow: 'hidden',
            [theme.breakpoints.down('xs')]: {
                padding: theme.spacing(2),
                width: '100%',
                boxShadow: 'none !important',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '100vh',
                background: 'transparent'
            }
        }
    })
);

const CenterPaper = ({ children }: any) => {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <Paper elevation={0} className={classes.paper}>
                {children}
            </Paper>
        </div>
    );
};

export default CenterPaper;
