import { createMuiTheme } from '@material-ui/core';
import red from '@material-ui/core/colors/red';
import createPalette from '@material-ui/core/styles/createPalette';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import createTypography from '@material-ui/core/styles/createTypography';
import responsiveFontSizes from '@material-ui/core/styles/responsiveFontSizes';

const palette = createPalette({
    primary: {
        main: '#398BF7',
        dark: '#0058cb',
        light: '#36b1ef',
        contrastText: '#fff',
    },
    secondary: {
        main: '#E9F0FF',
        light: '#a2deff',
    },
    background: {
        default: '#fff',
    },
    info: {
        main: '#636A75',
        light: '#f9f9f9',
        contrastText: '#999999',
        dark: 'rgba(0,0,0,0.5)',
    },
    error: {
        light: red[50],
        main: red[800],
        dark: red[900],
    },
    common: {
        black: 'rgba(0,0,0,0.85)',
    },
});

const breakpoints = createBreakpoints({});

const typography = createTypography(palette, {
    fontFamily: "'Quicksand', sans-serif !important",
    h1: {
        fontSize: '2em',
    },
    h2: {
        fontSize: '1.8em',
    },
    h6: {
        fontWeight: 400,
        fontSize: '1.15rem',
        lineHeight: 1.2,
    },
});

const defaultTheme = createMuiTheme({
    palette: palette,
    typography: typography,
    dashboard: {
        primary: '#053361',
        secondary: '#45678A',
        light: '#E8E8E8',
        info: '#8CA0B3',
        success: '#5CD18B',
        background: '#e6f6ff',
    },
    overrides: {
        MuiCssBaseline: {
            '@global': {
                body: {
                    fontFamily: "'Quicksand', sans-serif",
                },
            },
        },
        MuiPaper: {
            rounded: {
                borderRadius: 10,
                boxShadow: '0 3px 15px 0 rgba(180, 192, 210, 0.25) !important',
                [breakpoints.down('xs')]: {
                    borderRadius: 0,
                },
            },
        },
        MuiSelect: {
            select: {
                '&:focus': {
                    backgroundColor: 'transparent',
                },
            },
        },
        MuiOutlinedInput: {
            root: {
                padding: '0 10px !important',
            },
            input: {
                padding: '16px 12px !important',
                fontWeight: 400,
                '&:-webkit-autofill': {
                    transitionDelay: '9999s',
                    transitionProperty: 'background-color, color',
                },
                fontSize: '15px',
            },
            multiline: {
                padding: '16px 12px',
                fontWeight: 400,
                '&:-webkit-autofill': {
                    transitionDelay: '9999s',
                    transitionProperty: 'background-color, color',
                },
                fontSize: '15px',
            },
            notchedOutline: {
                borderRadius: 30,
                borderWidth: 0.5,
                paddingLeft: '16px !important',
            },
        },
        MuiInputLabel: {
            outlined: {
                transform: 'translate(16px, 16px) scale(1)',
                '&.MuiInputLabel-shrink': {
                    transform: 'translate(22px, -5px) scale(0.75)',
                },
            },
        },
        MuiButton: {
            root: {
                lineHeight: 2.3,
                [breakpoints.down('xs')]: {
                    padding: '3px 25px',
                },
            },
            outlined: {
                borderRadius: 50,
            },
            contained: {
                borderRadius: 50,
                boxShadow: 'none',
            },
            text: {
                borderRadius: 50,
                color: palette.info.main,
                '& span': {
                    fontWeight: 600,
                },
            },
            label: {
                fontSize: '14px',
                textTransform: 'initial',
                fontWeight: 400,
            },
            sizeLarge: {
                padding: '8px 35px',
                [breakpoints.down('xs')]: {
                    padding: '3px 25px',
                },
            },
        },
        MuiDialogTitle: {
            root: {
                '& h6': {
                    color: palette.primary.main,
                },
                textAlign: 'center',
                borderBottom: '1px solid ' + palette.secondary.main,
                marginLeft: '24px',
                marginRight: '24px',
            },
        },
        MuiDialogContent: {
            root: {
                padding: '24px',
            },
        },
        MuiDialogActions: {
            root: {
                margin: '24px',
            },
        },
        MuiTableCell: {
            root: {
                padding: '0.7rem',
            },
            paddingCheckbox: {
                padding: '0.7rem',
            },
        },
        MuiCheckbox: {
            colorPrimary: {
                color: palette.primary.main,
            },
        },
        MuiFormControl: {
            root: {
                width: '100%',
            },
        },
        MuiTable: {
            root: {
                tableLayout: 'fixed',
            },
        },
        MuiTab: {
            root: {
                textTransform: 'capitalize',
            },
        },
    },
});

export default responsiveFontSizes(defaultTheme);
