import { action, computed, IObservableArray, observable, runInAction } from 'mobx';
import { RootStore } from './rootStore';
import i18n from 'i18next';
import { Task } from '../modules/tasks/models/task';
import { Project } from '../modules/projects/models/project';
import { Language } from '../api/dtos/generated/dtos.generated';
import { Customer } from '../modules/customers/models/customer';
import { Employee } from '../modules/employees/models/employee';
import { Resource } from '../modules/resources/models/resource';
import { Address } from '../common/models/address';

export type NotificationType = 'default' | 'error' | 'success' | 'warning' | 'info';

export interface IToastNotification {
    key: number;
    type: NotificationType;
    message: string;
    persist: boolean;
}

export class UiStore {
    public static storeName = 'uiStore';
    public drawerWidth: number = 270;
    public drawerWidthMinimized: number = 72;
    @observable public width: number = window.innerWidth;
    @observable isDrawerOpen: boolean = true;
    @observable anchorEl: any = null;
    @observable notifications: any[] = [];
    @observable language: Language = Language.De;
    @observable isMobileMenuOpen: boolean = false;
    @observable isModalOpen: boolean = false;
    @observable isChangePasswordDialogOpen: boolean = false;
    @observable isGoogleMapDialogOpen: boolean = false;
    @observable selectedTask?: Task;
    @observable selectedTaskId?: string;
    @observable selectedTaskTab: number = 0;
    @observable selectedProject?: Project;
    @observable selectedProjectId?: string;
    @observable selectedProjectTab: number = 0;
    @observable selectedResource?: Resource;
    @observable selectedResourceId?: string;
    @observable selectedResourceTab: number = 0;
    @observable selectedCustomer?: Customer;
    @observable selectedCustomerId?: string;
    @observable selectedCustomerTab: number = 0;
    @observable selectedAddresses?: IObservableArray<Address> = observable.array<Address>([]);
    @observable selectedAddressId?: string;
    @observable selectedEmployee?: Employee;
    @observable selectedEmployeeId?: string;
    @observable selectedEmployeeTab: number = 0;
    @observable selectedEmployeeLogin?: Employee;
    @observable selectedEmployeeLoginId?: string;

    xs: number = 0;
    sm: number = 800;
    md: number = 960;
    lg: number = 1280;
    xl: number = 1920;

    @computed
    public get screenWidth() {
        if (this.width < this.sm) return 'xs';
        else if (this.width >= this.sm && this.width < this.md) return 'sm';
        else if (this.width >= this.md && this.width < this.lg) return 'md';
        else if (this.width >= this.lg && this.width < this.xl) return 'lg';
        else return 'xl';
    }

    @computed
    public get contentWidth() {
        let cWidth =
            this.width -
            (this.isMobile || this.isTablet ? 0 : this.isDrawerOpen ? this.drawerWidth : this.drawerWidthMinimized);

        if (!this.isMobile) {
            cWidth = cWidth - 64;
        }
        return cWidth;
    }

    @computed
    public get isMobile() {
        return this.width < this.sm;
    }

    @computed
    public get isTablet() {
        return this.width >= this.sm && this.width < this.md;
    }

    @computed
    public get isDesktop() {
        return this.width >= this.md && this.width < this.lg;
    }

    @computed
    public get isLargeDesktop() {
        return this.width >= this.lg;
    }

    protected readonly rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        window.addEventListener('resize', () => this.handleResize());
    }

    @action
    toggleDrawer() {
        this.isDrawerOpen = !this.isDrawerOpen;
    }

    @action
    setIsModalOpen(open: boolean) {
        this.isModalOpen = open;
    }

    @action
    setChangePasswordDialogOpen(open: boolean) {
        this.isChangePasswordDialogOpen = open;
    }

    @action
    setGoogleMapDialogOpen(open: boolean) {
        this.isGoogleMapDialogOpen = open;
    }

    @action
    enqueueNotification(notification: Partial<IToastNotification>) {
        notification.key = new Date().getMilliseconds();
        this.notifications = [...this.notifications, notification];
    }

    @action.bound
    removeNotification(key: number) {
        this.notifications = this.notifications.filter((x: IToastNotification) => x.key !== key);
    }

    @action
    setLanguage(language: Language) {
        this.language = language;
        i18n.changeLanguage(this.languageAsString);
    }

    @computed
    public get languageAsString() {
        return Language[this.language].toLowerCase();
    }

    @computed
    public get isTaskDetailOpen(): boolean {
        return !!this.selectedTask || !!this.selectedTaskId;
    }

    @action
    setSelectedTask(task: Task, tab: number = 0) {
        runInAction(() => {
            this.selectedTask = task;
            this.selectedTaskTab = tab;
        });
    }

    @action
    setSelectedTaskId(taskId?: string, tab: number = 0) {
        runInAction(() => {
            this.selectedTaskId = taskId;
            this.selectedTaskTab = tab;
        });
    }

    @action.bound
    resetTaskSelection() {
        runInAction(() => {
            this.selectedTaskId = undefined;
            this.selectedTask = undefined;
        });
    }

    @computed
    public get isResourceDetailOpen(): boolean {
        return !!this.selectedResource || !!this.selectedResourceId;
    }

    @action
    setSelectedResource(resource?: Resource) {
        this.selectedResource = resource;
    }

    @action
    setSelectedResourceId(resourceId?: string, tab: number = 0) {
        runInAction(() => {
            this.selectedResourceId = resourceId;
            this.selectedResourceTab = tab;
        });
    }

    @action
    resetResourceSelection() {
        runInAction(() => {
            this.selectedResourceId = undefined;
            this.selectedResource = undefined;
        });
    }

    @computed
    public get isProjectDetailOpen(): boolean {
        return !!this.selectedProject || !!this.selectedProjectId;
    }

    @action
    setSelectedProject(project?: Project) {
        runInAction(() => (this.selectedProject = project));
    }

    @action
    setSelectedProjectId(projectId?: string, tab: number = 0) {
        runInAction(() => {
            this.selectedProjectId = projectId;
            this.selectedProjectTab = tab;
        });
    }

    @action.bound
    resetProjectSelection() {
        runInAction(() => {
            this.selectedProjectId = undefined;
            this.selectedProject = undefined;
        });
    }

    @computed
    public get isCustomerDetailOpen(): boolean {
        return !!this.selectedCustomer || !!this.selectedCustomerId;
    }

    @action
    setSelectedCustomer(customer?: Customer) {
        runInAction(() => (this.selectedCustomer = customer));
    }

    @action
    setSelectedCustomerId(customerId?: string, tab: number = 0) {
        runInAction(() => {
            this.selectedCustomerId = customerId;
            this.selectedCustomerTab = tab;
        });
    }

    @action
    setSelectedAddress(addresses: Address[], selectedAddressId?: string) {
        this.selectedAddresses = observable.array<Address>(addresses);
        this.selectedAddressId = selectedAddressId;
    }

    @action.bound
    resetCustomerSelection() {
        runInAction(() => {
            this.selectedCustomerId = undefined;
            this.selectedCustomer = undefined;
        });
    }

    @computed
    public get isEmployeeDetailOpen(): boolean {
        return !!this.selectedEmployee || !!this.selectedEmployeeId;
    }

    @computed
    public get isEmployeeLoginOpen(): boolean {
        return !!this.selectedEmployeeLogin || !!this.selectedEmployeeLoginId;
    }

    @action
    setSelectedEmployee(employee?: Employee) {
        runInAction(() => (this.selectedEmployee = employee));
    }

    @action
    setSelectedEmployeeId(employeeId?: string, tab: number = 0) {
        runInAction(() => {
            this.selectedEmployeeId = employeeId;
            this.selectedEmployeeTab = tab;
        });
    }

    @action
    setSelectedEmployeeLogin(employee?: Employee) {
        runInAction(() => (this.selectedEmployeeLogin = employee));
    }

    @action
    setSelectedEmployeeLoginId(employeeId?: string) {
        runInAction(() => (this.selectedEmployeeLoginId = employeeId));
    }

    @action.bound
    resetEmployeeSelection() {
        runInAction(() => {
            this.selectedEmployeeId = undefined;
            this.selectedEmployee = undefined;
        });
    }

    @action.bound
    resetEmployeeLoginSelection() {
        runInAction(() => {
            this.selectedEmployeeLoginId = undefined;
            this.selectedEmployeeLogin = undefined;
        });
    }

    private handleResize() {
        runInAction(() => {
            this.width = window.innerWidth;
        });
    }
}
