import React from 'react';
import { createStyles, ListItem, ListItemIcon, ListItemText, Theme, useMediaQuery, useTheme } from '@material-ui/core';
import { Link, Match } from '@reach/router';
import { ChevronRight } from '../../common/components/SvgIcons';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        navItem: {
            width: '95%',
            height: theme.spacing(6.5),
            borderTopRightRadius: 50,
            borderBottomRightRadius: 50,
            color: theme.palette.grey['600'],
            '@media (max-height:780px)': {
                height: theme.spacing(5.2),
            },
        },
        navItemSmall: {
            width: '95%',
            height: theme.spacing(6),
            borderTopRightRadius: 50,
            borderBottomRightRadius: 50,
            paddingLeft: '1.45rem',
            '& svg': {
                fill: 'rgb(109, 152, 209)',
                fontSize: 25,
            },
        },
        selected: {
            color: theme.palette.primary.dark + ' !important',
            borderTopRightRadius: 50,
            borderBottomRightRadius: 50,
            width: '95%',
            backgroundColor: ' #f3f7fd !important',
            boxShadow: 'none',
            '&::before': {
                content: "''",
                position: 'absolute',
                width: '4px',
                height: '100%',
                background: theme.palette.primary.dark,
                left: 0,
            },
        },
        selectedSmall: {
            color: theme.palette.primary.main + ' !important',
            borderTopRightRadius: 50,
            borderBottomRightRadius: 50,
            width: '95%',
            backgroundColor: theme.palette.secondary.main + ' !important',
            boxShadow: 'none',
            '& svg': {
                fill: theme.palette.primary.dark,
                fontSize: 25,
            },
        },
        listItemIcon: {
            marginRight: 0,
            marginLeft: theme.spacing(3),
            '& svg': {
                fill: 'rgb(109, 152, 209)',
                fontSize: 25,
            },
        },
        listItemSmallIcon: {
            marginRight: 0,
        },
        listItemIconSelected: {
            marginRight: 0,
            marginLeft: theme.spacing(3),
            '& svg': {
                fill: theme.palette.primary.dark,
                fontSize: 25,
            },
        },
        listItemIconSelectedSmall: {},
        listItemIconSelectedRight: {
            justifyContent: 'flex-end',
            '&:svg': {
                fill: theme.palette.primary.dark,
            },
        },
        font: {
            fontSize: '15px',
        },
    })
);

interface INavItemProps {
    path: string;
    icon: any;
    text: string;
    onClick?: () => void;
    small?: boolean;
}

type Props = INavItemProps;

const NavItem = ({ path, icon, text, onClick, small }: Props) => {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

    return (
        <Match path={path}>
            {(props) => (
                <ListItem
                    component={Link}
                    {...{ to: path }}
                    button={true}
                    selected={!!props.match && !onClick}
                    onClick={!!onClick ? onClick : undefined}
                    classes={{
                        selected: small ? classes.selectedSmall : classes.selected,
                        root: small ? classes.navItemSmall : classes.navItem,
                    }}
                >
                    <ListItemIcon
                        classes={{
                            root: !!props.match
                                ? small
                                    ? classes.listItemIconSelectedSmall
                                    : classes.listItemIconSelected
                                : small
                                ? classes.listItemSmallIcon
                                : classes.listItemIcon,
                        }}
                    >
                        {icon}
                    </ListItemIcon>
                    <ListItemText
                        primary={text}
                        primaryTypographyProps={{ color: 'inherit' }}
                        classes={isMobile ? { primary: classes.font } : {}}
                    />
                    {props.match && (
                        <ListItemIcon classes={{ root: classes.listItemIconSelectedRight }}>
                            <ChevronRight color={'primary'} viewBox="0 0 30 20" />
                        </ListItemIcon>
                    )}
                </ListItem>
            )}
        </Match>
    );
};

export default NavItem;
