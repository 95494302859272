import { BaseModel } from '../../../common/stores/model/baseModel';
import { action, computed, observable, runInAction } from 'mobx';
import * as uuid from 'uuid';
import i18n from 'i18next';
import Validator from 'validatorjs';
import { validate, validateField } from '../../../common/helpers/validationHelper';
import {
    ReferencedCustomerDto,
    ReferencedEmployeeDto,
    TaskDto,
    TaskState,
    ReferencedAddressDto,
} from '../../../api/dtos/generated/dtos.generated';
import {
    addDays,
    addYears,
    endOfDay,
    endOfMonth,
    endOfWeek,
    endOfYear,
    isBefore,
    min,
    setMinutes,
    startOfDay,
    startOfMonth,
    startOfWeek,
    startOfYear,
} from 'date-fns';
import { applyTime, formatDate, parseDate } from '../../../common/utils/dateHelper';
import { Recurring } from './recurring';
import { mapTrackingFieldsToDto, mapTrackingFieldsToModel } from '../../../common/helpers/trackingFields';

export class Task extends BaseModel {
    private static rules = {
        name: 'required|max:100',
        description: 'max:8000',
        state: 'required',
        maxDate: 'date',
        minDate: 'date',
        dueDate: 'required|date|before_or_equal:maxDate|after_or_equal:minDate',
        dueTime: 'required|date',
    };
    id: string;
    @observable name: string = '';
    @observable description: string = '';
    @observable dueDate?: Date;
    @observable dueTime?: Date;
    @observable state: TaskState = 'open';
    @observable validationErrors: { [index: string]: any } = {};
    @observable isDirty: boolean = false;
    @observable isNew: boolean = false;
    @observable employeeRef?: ReferencedEmployeeDto;
    @observable customerRef?: ReferencedCustomerDto;
    @observable addressRef?: ReferencedAddressDto;
    @observable recurring?: Recurring;
    @observable parentTaskId?: string;
    @observable isVirtual: boolean = false;
    @observable imageCount: number = 0;
    @observable noteCount: number = 0;

    private lastSavedDueDate?: Date; // Will be used to do date validation checks

    constructor(id: string = uuid.v4()) {
        super();
        this.id = id;
        runInAction(() => (this.isNew = true));
    }

    @computed get isOverdue() {
        return this.dueDate && isBefore(this.dueDate, new Date());
    }

    @computed get isValid() {
        return Object.keys(this.validationErrors).length === 0;
    }

    @computed get employeeDisplayName() {
        return this.employeeRef ? this.employeeRef.displayName : '';
    }

    @computed get customerDisplayName() {
        return this.customerRef ? this.customerRef.displayName : '';
    }

    @computed get formattedDueDate() {
        return this.dueDate ? formatDate(this.dueDate, 'dd.MM.yyyy') : '';
    }

    @computed get formattedFullDueDate() {
        return this.dueDate ? formatDate(this.dueDate, 'dd.MM.yyyy HH:mm') : '';
    }

    @computed get formattedDueTime() {
        return this.dueDate ? formatDate(this.dueDate, 'HH:mm') : '';
    }

    @computed get isDateChangePossible() {
        if (this.parentTaskId) {
            return !!(this.recurring && this.recurring.unit !== 'daily');
        }

        return true;
    }

    @computed get minDate(): Date {
        if (!this.isNew) {
            const checkDate = this.lastSavedDueDate || new Date();

            if (this.recurring) {
                switch (this.recurring.unit) {
                    case 'daily': {
                        return startOfDay(checkDate);
                    }
                    case 'weekly': {
                        return startOfWeek(checkDate, { weekStartsOn: 1 });
                    }
                    case 'monthly': {
                        return startOfMonth(checkDate);
                    }
                    default: {
                        return startOfYear(checkDate);
                    }
                }
            }
            return startOfDay(min([checkDate, new Date()]));
        }
        return startOfDay(new Date());
    }

    @computed
    get maxDate(): Date {
        if (!this.isNew) {
            if (this.recurring) {
                const checkDate = this.lastSavedDueDate || new Date();

                switch (this.recurring.unit) {
                    case 'daily': {
                        return endOfDay(checkDate);
                    }
                    case 'weekly': {
                        return endOfWeek(checkDate, { weekStartsOn: 1 });
                    }
                    case 'monthly': {
                        return endOfMonth(checkDate);
                    }
                    default: {
                        return endOfYear(checkDate);
                    }
                }
            }
        }

        return addYears(new Date(), 50);
    }

    @computed get isExpired() {
        return this.dueDate && isBefore(this.dueDate, new Date()) && this.state !== 'done';
    }

    public static createNew(): Task {
        const now = new Date();
        const newDate = addDays(setMinutes(now, 0), 1);

        const task = new Task();
        task.setDueDate(newDate);
        task.setDueTime(newDate);

        return task;
    }

    public static createFromDto(dto: TaskDto): Task {
        const task = new Task(dto.id);
        runInAction(() => {
            task.isNew = false;
            task.name = dto.name;
            task.description = dto.description ? dto.description : '';
            task.dueDate = parseDate(dto.dueDate);
            task.dueTime = parseDate(dto.dueDate);
            task.state = dto.state ? dto.state : 'open';
            task.employeeRef = dto.employeeRef ? dto.employeeRef : undefined;
            task.customerRef = dto.customerRef ? dto.customerRef : undefined;
            task.addressRef = dto.addressRef ? dto.addressRef : undefined;
            task.parentTaskId = dto.parentTaskId ? dto.parentTaskId : undefined;
            task.lastSavedDueDate = task.dueDate;
            task.imageCount = dto.imageCount || 0;
            task.noteCount = dto.noteCount || 0;

            if (dto.recurring) {
                task.recurring = Recurring.createFromDto(dto.recurring);
            }

            mapTrackingFieldsToModel(task, dto);
        });

        return task;
    }

    private static getValidator(data: any, rules: any) {
        const validator = new Validator(data, rules);

        validator.setAttributeNames({
            name: i18n.t('name'),
            description: i18n.t('description'),
            state: i18n.t('description'),
            dueDate: i18n.t('dueDate'),
            addressRef: i18n.t('addressRef'),
        });

        return validator;
    }

    public updateFromDto(dto: TaskDto): void {
        runInAction(() => {
            this.isNew = false;
            this.name = dto.name;
            this.description = dto.description ? dto.description : '';
            this.dueDate = dto.dueDate ? (dto.dueDate as any).toDate() : undefined;
            this.dueTime = dto.dueDate ? (dto.dueDate as any).toDate() : undefined;
            this.state = dto.state ? dto.state : 'open';
            this.employeeRef = dto.employeeRef ? dto.employeeRef : undefined;
            this.customerRef = dto.customerRef ? dto.customerRef : undefined;
            this.addressRef = dto.addressRef ? dto.addressRef : undefined;
            this.parentTaskId = dto.parentTaskId ? dto.parentTaskId : undefined;
            this.recurring = dto.recurring ? Recurring.createFromDto(dto.recurring) : undefined;
            this.imageCount = dto.imageCount || 0;
            this.noteCount = dto.noteCount || 0;

            this.lastSavedDueDate = this.dueDate;

            mapTrackingFieldsToModel(this, dto);
        });
    }

    @action
    public setDueDate(selectedDate: Date | null) {
        this.dueDate = selectedDate ? selectedDate : undefined;
    }

    @action
    public setDueTime(selectedTime: Date | null) {
        this.dueTime = selectedTime ? selectedTime : undefined;
    }

    @action
    updateProperty(field: string, value: any) {
        if (!this.isDirty) {
            this.isDirty = true;
        }

        (this as any)[field] = value;

        const rules: any = (Task.rules as any)[field];
        if (rules) {
            const validator = Task.getValidator({ [field]: value }, { [field]: rules });
            this.validationErrors = validateField(validator, field, this.validationErrors);
        }
    }

    @action
    validateAll(data: any) {
        const dataToValidate = { ...data, minDate: this.minDate, maxDate: this.maxDate };

        console.log(dataToValidate);
        const validator = Task.getValidator(dataToValidate, Task.rules);
        this.validationErrors = validate(validator);
    }

    @action
    setDeleteTrackingFields(uid: string) {
        this.deletedAt = new Date();
        this.deletedBy = uid;
    }

    toDto(tenantId: string, removeRecurring: boolean = false): TaskDto {
        let dueDate = null;
        let recurringDto = null;

        if (this.dueDate && this.dueTime) {
            dueDate = applyTime(this.dueTime!, this.dueDate!);

            if (this.recurring) {
                recurringDto = { ...this.recurring.toDto(), start: dueDate };
            }
        }

        const dto = {
            id: this.id,
            tenantId: tenantId,
            name: this.name,
            description: this.description,
            dueDate: dueDate,
            state: this.state,
            employeeRef: this.employeeRef
                ? {
                      id: this.employeeRef!.id,
                      displayName: this.employeeRef!.displayName,
                      imageHash: this.employeeRef!.imageHash ? this.employeeRef!.imageHash : null,
                  }
                : null,
            customerRef: this.customerRef ? this.customerRef : null,
            addressRef: this.addressRef ? this.addressRef : null,
            recurring: removeRecurring ? null : recurringDto,
            parentTaskId: this.parentTaskId ? this.parentTaskId : null,
        };

        mapTrackingFieldsToDto(dto, this);

        return dto;
    }
}
