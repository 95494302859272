import { action, computed, observable, reaction, runInAction } from 'mobx';
import { RootStore } from '../../../stores/rootStore';
import { ProjectFilter } from '../models/projectFilter';
import { SortDirection } from '../../../common/models/sortDirection';
import { SortSetting } from '../../../common/models/sortSetting';
import { AppError } from '../../../common/models/appError';
import Firebase from '../../../api/firebase';
import { ProjectDto } from '../../../api/dtos/generated/dtos.generated';
import { Project } from '../models/project';
import { Log } from '../../../log';
import i18n from 'i18next';
import { createPdfDocDefinition, openOrDownloadPdf } from '../../../common/helpers/pdfHelper';
import { format } from 'date-fns';
import firebase from 'firebase';

export class ProjectsStore {
    public static storeName: string = 'projectsStore';

    public store: RootStore;
    @observable public projects: Project[] = [];
    @observable public filter: ProjectFilter = new ProjectFilter();
    @observable public sortSetting: SortSetting = {
        fieldName: 'name',
        direction: SortDirection.Asc,
    };
    @observable public isLoading: boolean = false;
    @observable public initialLoadDone: boolean = false;
    @observable error?: AppError = undefined;
    @observable public successMessage?: string = '';
    @observable public inAction: boolean = false;
    @observable public isExportInProgress = false;

    private unsubsribeProjectChangesFunc?: () => void;
    private isFirstSnapShotHandled = false;

    constructor(store: RootStore) {
        this.store = store;

        reaction(
            () => this.store.authStore.isAuthenticated,
            () => this.subscribeProjectChanges()
        );
    }

    @action
    public async subscribeProjectChanges() {
        runInAction(() => (this.isLoading = true));
        const query = Firebase.firestore
            .collection('tenants')
            .doc(this.store.authStore.tenantId)
            .collection('projects');

        this.unsubsribeProjectChangesFunc = query.onSnapshot(async (snapshot: firebase.firestore.QuerySnapshot) => {
            try {
                runInAction(() => (this.isLoading = true));

                if (this.isFirstSnapShotHandled) {
                    snapshot.docChanges().forEach((change: firebase.firestore.DocumentChange) => {
                        if (change.type === 'added') {
                            this.addProject(change.doc.data() as ProjectDto);
                        }
                        if (change.type === 'modified') {
                            this.upsertProject(change.doc.data() as ProjectDto);
                        }
                        if (change.type === 'removed') {
                            this.removeProject(change.doc.data().id);
                        }
                    });
                } else {
                    const projects: Project[] = [];
                    snapshot.docs.forEach((value: firebase.firestore.QueryDocumentSnapshot) => {
                        const dto = value.data() as ProjectDto;
                        const model = Project.createFromDto(dto, this);
                        projects.push(model);
                    });

                    this.projects = projects;
                    this.isFirstSnapShotHandled = true;
                }
            } finally {
                runInAction(() => (this.isLoading = false));
            }
        });
    }

    @action
    private async addProject(dto: ProjectDto) {
        const model = Project.createFromDto(dto, this);
        this.projects.push(model);
    }

    @action
    private async upsertProject(dto: ProjectDto) {
        const project = this.projects.find((x) => x.id === dto.id);
        if (project) {
            this.removeProject(dto.id);
        }

        this.addProject(dto);
    }

    @action
    private async removeProject(id: string) {
        const index = this.projects.findIndex((x) => x.id === id);
        if (index > -1) {
            this.projects.splice(index, 1);
        }
    }

    @action
    setFilter(filter: ProjectFilter) {
        this.filter = filter;
    }

    @action
    setSortSetting(sortSetting: SortSetting) {
        this.sortSetting = sortSetting;
    }

    @action
    public createPdf = async () => {
        this.isExportInProgress = true;
        this.error = undefined;

        Log.export('projectList', 'pdf');

        const pdfMake = (await import(/* webpackChunkName: "pdfMake" */ 'pdfmake/build/pdfmake')).default;
        const pdfFonts = (await import(/* webpackChunkName: "pdfFonts" */ 'pdfmake/build/vfs_fonts')).default;

        const { vfs } = pdfFonts.pdfMake;
        pdfMake.vfs = vfs;

        const tenant = this.store.tenantStore.tenant;

        const tableHeadColumns: { field: string; header: string }[] = [
            { field: 'name', header: i18n.t('name') },
            { field: 'customer', header: i18n.t('customer') },
            { field: 'address', header: i18n.t('address') },
            { field: 'budget', header: i18n.t('budget') },
            { field: 'isComplete', header: i18n.t('completed') },
        ];

        const tableWidths = ['auto', 'auto', 'auto', 'auto', '*'];

        const pdfTitle = 'Projects';

        if (tenant) {
            const docDefinition = createPdfDocDefinition(
                tableWidths,
                pdfTitle,
                this.buildTableBody(this.projects, tableHeadColumns),
                tenant.toDto()
            );

            const pdf = pdfMake.createPdf(docDefinition as any);
            openOrDownloadPdf(pdf, `${pdfTitle}_${format(new Date(), 'd.M.yyyy')}.pdf`);

            runInAction(() => (this.isExportInProgress = false));
        }
    };

    public buildTableBody = (projects: Project[], columns: { field: string; header: string }[]) => {
        const body = [];

        const headerRow = columns.map((x) => {
            return {
                text: x.header,
                style: 'header',
            };
        });
        body.push(headerRow);

        projects.forEach((project: Project) => {
            const row: any[] = [];

            columns.forEach((column: { field: string; header: string }) => {
                let rowData = '';
                if (column.field === 'customer') {
                    rowData = project.customerRef ? project.customerRef.displayName : '';
                } else if (column.field === 'address') {
                    rowData = project.addressRef ? project.addressRef.displayName : '';
                } else if (column.field === 'isComplete') {
                    rowData = (project as any)[column.field] === true ? 'Yes' : 'No';
                } else {
                    rowData = (project as any)[column.field];
                }

                row.push(rowData);
            });

            body.push(row);
        });

        return body;
    };

    @computed
    public get sortedProjects() {
        return this.filteredProjects.slice().sort((a: Project, b: Project) => {
            const aValue = this.sortSetting.fieldName.split('.').reduce((x: any, y) => x[y].toLowerCase(), a);
            const bValue = this.sortSetting.fieldName.split('.').reduce((x: any, y) => x[y].toLowerCase(), b);

            return this.sortSetting.direction === SortDirection.Desc
                ? aValue < bValue
                    ? 1
                    : -1
                : aValue > bValue
                ? 1
                : -1;
        });
    }

    @computed
    public get hasProjects() {
        return this.projects.length > 0;
    }

    @computed
    public get openProjectsCount() {
        let count = 0;
        this.projects.forEach((p) => {
            if (!p.isComplete) {
                count++;
            }
        });

        return count;
    }

    @computed
    public get inProgress() {
        return this.isLoading || this.inAction;
    }

    @computed
    public get filteredProjects() {
        let filteredProjectItems = this.projects;

        const filterOpen = this.filter.isStateFiltered('open');
        const filterComplete = this.filter.isStateFiltered('complete');

        if (this.filter.states.length === 1) {
            if (filterOpen) {
                filteredProjectItems = filteredProjectItems.filter((p) => !p.isComplete);
            }

            if (filterComplete) {
                filteredProjectItems = filteredProjectItems.filter((p) => p.isComplete);
            }
        }

        if (this.filter.customerRef) {
            filteredProjectItems = filteredProjectItems.filter(
                (x) => x.customerRef?.id === this.filter.customerRef?.id
            );
        }

        return filteredProjectItems;
    }
}
