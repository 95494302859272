import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { RouteComponentProps } from '@reach/router';
import { center } from '../hocs/center';
import { CircularProgress } from '@material-ui/core';

export const styles = (theme: Theme) =>
    createStyles({
        loadingInfo: {
            padding: theme.spacing(2)
        }
    });

type Props = WithStyles<typeof styles> & WithTranslation & RouteComponentProps;

class LoadingInfo extends React.Component<Props> {
    render() {
        const { classes, t } = this.props;
        return (
            <div>
                <Grid container={true} justify={'center'}>
                    <div>
                        <CircularProgress color="primary" size={80} />
                    </div>
                    <Grid item={true} xs={12} className={classes.loadingInfo}>
                        <Typography variant="h4" align="center" color={'textSecondary'}>
                            {t('loading')}
                        </Typography>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

const styledComponent = withStyles(styles, { withTheme: true })(LoadingInfo);
const translatedComponent = withTranslation()(styledComponent);
const centeredComponent = center(translatedComponent);
export default centeredComponent;
