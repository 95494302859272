import * as Validator from 'validatorjs';
import i18n from 'i18next';
import 'validatorjs/dist/lang/de';
import 'validatorjs/dist/lang/fr';
import 'validatorjs/dist/lang/it';

const LOWER_CASE_CHARS_REGEX = new RegExp('[a-z]');
const UPPER_CASE_CHARS_REGEX = new RegExp('[A-Z]');
const NUMBERS_REGEX = new RegExp('[0-9]');
const SPECIAL_CHARACTERS = new RegExp('[!@#$%^&*()_+}{":;\'?/><,]');

Validator.register('strongPassword', strongPassword, i18n.t('strongPasswordValidationMessage'));

export function validateField(
    validator: any,
    field: string,
    validationErrors: { [index: string]: any }
): { [index: string]: any } {
    if (validator.fails()) {
        const error = validator.errors.first(field);
        return { ...validationErrors, [field]: error };
    } else {
        const errors = { ...validationErrors };
        delete errors[field];
        return errors;
    }
}

export function validate(validator: any): { [index: string]: any } {
    if (validator.fails()) {
        return validator.errors.all();
    } else {
        return {};
    }
}

function strongPassword(value: string | number | boolean, requirement: any, attribute: any): boolean {
    if (typeof value !== 'string') {
        return false;
    }

    if (!value || value.length < 8) {
        return false;
    }

    let groupMatches = 0;

    if (LOWER_CASE_CHARS_REGEX.test(value)) {
        groupMatches++;
    }

    if (UPPER_CASE_CHARS_REGEX.test(value)) {
        groupMatches++;
    }

    if (NUMBERS_REGEX.test(value)) {
        groupMatches++;
    }

    if (SPECIAL_CHARACTERS.test(value)) {
        groupMatches++;
    }

    return groupMatches >= 3;
}
