import { action, computed, observable, runInAction } from 'mobx';
import * as uuid from 'uuid';
import i18n from 'i18next';
import Validator from 'validatorjs';
import { BaseModel } from '../stores/model/baseModel';
import { mapTrackingFieldsToModel } from '../helpers/trackingFields';
import { NoteDto } from '../../api/dtos/generated/dtos.generated';
import { validateField, validate } from '../helpers/validationHelper';
import { AppError } from './appError';

export const noteDescriptionLength = 2000;

export class Note extends BaseModel {
    private static rules = {
        title: 'max:100',
        description: `required|max:${noteDescriptionLength}`,
    };

    private attributeNames = {
        title: i18n.t('title'),
        description: i18n.t('description'),
    };

    private getValidator(data: any, rules: any) {
        const validator = new Validator(data, rules);
        validator.setAttributeNames(this.attributeNames);
        return validator;
    }

    id: string;
    @observable validationErrors: { [index: string]: any } = {};
    @observable title: string = '';
    @observable description: string = '';
    @observable isDirty: boolean = false;
    @observable isNew: boolean = false;
    @observable isLoading: boolean = false;
    @observable public error?: AppError = undefined;

    public static createFromDto(dto: NoteDto): Note {
        const note = new Note(dto.id);
        runInAction(() => {
            note.isNew = false;
            note.title = dto.title ? dto.title : '';
            note.description = dto.description;
        });
        mapTrackingFieldsToModel(note, dto);
        return note;
    }

    constructor(id: string = uuid.v4()) {
        super();
        this.id = id;
        runInAction(() => {
            this.isNew = true;
        });
    }

    @action
    updateProperty(field: string, value: any) {
        if (!this.isDirty) {
            this.isDirty = true;
        }

        (this as any)[field] = value;
        if (!!(Note.rules as any)[field]) {
            const validator = this.getValidator({ [field]: value }, { [field]: (Note.rules as any)[field] });
            this.validationErrors = validateField(validator, field, this.validationErrors);
        }
    }

    @action
    validateAll(data: any) {
        const validator = this.getValidator(data, Note.rules);
        this.validationErrors = validate(validator);
    }

    @computed get isValid() {
        return Object.keys(this.validationErrors).length === 0;
    }

    toDto(tenantId: string): NoteDto {
        let dto: NoteDto = {
            id: this.id,
            title: this.title,
            description: this.description,
            tenantId: tenantId,
        };

        dto.createdBy = this.createdBy ? this.createdBy : null;
        dto.createdAt = this.createdAt ? this.createdAt : null;
        dto.modifiedBy = this.modifiedBy ? this.modifiedBy : null;
        dto.modifiedAt = this.modifiedAt ? this.modifiedAt : null;
        dto.deletedBy = this.deletedBy ? this.deletedBy : null;
        dto.deletedAt = this.deletedAt ? this.deletedAt : null;
        return dto;
    }
}
