import { action, computed, observable, reaction, runInAction } from 'mobx';
import { AppError } from '../../../common/models/appError';
import { RootStore } from '../../../stores/rootStore';
import Firebase from '../../../api/firebase';
import { EmployeeDto, TenantDto } from '../../../api/dtos/generated/dtos.generated';
import { Employee } from '../../employees/models/employee';
import { Tenant } from '../../tenant/models/tenant';
import { Log } from '../../../log';
import appConfig from '../../../appConfig';
import algoliasearch from 'algoliasearch';
import { sessionStorageService } from '../../../sessionStorageService';
import firebase from 'firebase';

export type SubscriptionStatus =
    | 'incomplete'
    | 'incomplete_expired'
    | 'trialing'
    | 'active'
    | 'past_due'
    | 'canceled'
    | 'unpaid';

export class AuthStore {
    static storeName: string = 'authStore';

    @observable error?: AppError = undefined;
    @observable inProgress: boolean = false;
    @observable isAuthenticated: boolean = false;
    @observable isEmailVerified: boolean = false;
    @observable initialLoginAttemptInProgress: boolean = true;
    @observable isEmployeeProfileCompleted: boolean = false;
    @observable tenantId?: string;
    @observable tenantEmail?: string;
    @observable employeeId?: string;
    @observable private searchKey?: string;
    @observable user?: string;
    @observable role?: string;
    @observable token?: any;
    @observable lang?: string;
    @observable currentEmployee?: Employee;

    private store: RootStore;

    constructor(store: RootStore) {
        this.store = store;

        reaction(
            () => this.token,
            (token: any) => {
                if (token) {
                    this.loadSearchKey(token);
                }
            }
        );

        Firebase.auth.onAuthStateChanged(async (user: firebase.User | null) => {
            if (user) {
                Log.login();
                const idTokenResult = await user.getIdTokenResult(true);
                const claims = idTokenResult.claims;
                Firebase.tenantId = claims.tenantId;
                this.loadTenant();
                const employee = await this.getEmployee(claims.tenantId, claims.employeeId);

                if (employee) {
                    sessionStorageService.setLoginId(user.uid);

                    runInAction(() => {
                        this.currentEmployee = Employee.createFromDto(employee);
                        this.user = JSON.stringify(user);
                        this.employeeId = claims.employeeId;
                        this.role = claims.role;
                        this.isAuthenticated = true;
                        this.initialLoginAttemptInProgress = false;
                        this.isEmailVerified = user.emailVerified;
                        this.isEmployeeProfileCompleted = employee.isProfileCompleted;
                        this.token = idTokenResult.token;
                        this.tenantEmail = claims.email;
                        this.tenantId = claims.tenantId;
                        this.lang = claims.lang;

                        this.store.uiStore.setLanguage(idTokenResult.claims.lang);
                    });
                } else {
                    this.reset();
                }
            } else {
                runInAction(() => {
                    this.reset();
                });
            }
        });
    }

    @action
    public async changePassword(currentPassword: string, newPassword: string) {
        try {
            const user = Firebase.auth.currentUser;

            if (user) {
                const credential = Firebase.emailAuthProvider.credential(user.email, currentPassword);
                const userCredentials = await user.reauthenticateWithCredential(credential);
                if (userCredentials.user) {
                    userCredentials.user.updatePassword(newPassword);
                    const idTokenResult = await userCredentials.user.getIdTokenResult(true);
                    this.token = idTokenResult.token;
                    this.store.uiStore.setChangePasswordDialogOpen(false);
                }
            }
        } catch (error) {
            if (error.code === 'auth/wrong-password') {
                this.error = {
                    name: 'invalid-password',
                    key: 'invalid-password',
                    message: 'invalid-password',
                };
            } else {
                this.error = error;
            }
        }
    }

    @action
    public setIsEmployeeCompletedProfile(completed: boolean) {
        this.isEmployeeProfileCompleted = completed;
    }

    public async getEmployee(tenantId: string, employeeId: string): Promise<EmployeeDto | undefined> {
        const doc = await Firebase.firestore
            .collection('tenants')
            .doc(tenantId)
            .collection('employees')
            .doc(employeeId)
            .get();
        if (doc.exists) {
            return doc.data() as EmployeeDto;
        }
        return undefined;
    }

    @action
    public reset() {
        this.error = undefined;
        this.inProgress = false;
        this.isAuthenticated = false;
        this.isAuthenticated = this.isEmployeeProfileCompleted;
        this.initialLoginAttemptInProgress = false;
        this.tenantId = undefined;
        this.employeeId = undefined;
        this.searchKey = undefined;
        this.user = undefined;
        this.role = undefined;
        this.token = undefined;
        this.isEmailVerified = false;
    }

    @action
    public async loadSearchKey(token: string) {
        try {
            const searchKeyResponse = await fetch(appConfig.searchKeyUrl, {
                headers: { Authorization: 'Bearer ' + token },
            });

            const result = await searchKeyResponse.json();
            const searchKey = result.key;

            Firebase.searchKey = searchKey;

            runInAction(() => {
                this.searchKey = searchKey;
            });
        } catch (error) {
            runInAction(() => {
                this.searchKey = undefined;
            });
        }
    }

    @computed
    public get searchClient() {
        if (this.searchKey) {
            return algoliasearch(appConfig.algolia.applicationId, this.searchKey);
        }

        return undefined;
    }

    @action
    public async logout() {
        this.inProgress = true;
        this.error = undefined;

        try {
            await Firebase.signOut();
            window.location.href = '/';
        } catch (error) {
            runInAction(() => {
                this.error = {
                    key: error.code,
                    name: error.code,
                    message: error.message,
                };
            });
        } finally {
            runInAction(() => {
                this.inProgress = false;
            });
        }
    }

    @computed
    public get currentEmployeeName() {
        return this.currentEmployee ? this.currentEmployee.displayName : 'unknown';
    }

    @computed
    public get isAdmin(): boolean {
        return this.currentEmployee !== undefined && this.role !== undefined && this.role === 'admin';
    }

    @computed
    public get isUser() {
        return this.currentEmployee && this.role === 'user';
    }

    public loadTenant() {
        runInAction(() => (this.inProgress = true));

        Firebase.firestore
            .collection('tenants')
            .doc(Firebase.tenantId)
            .onSnapshot((snapshot: firebase.firestore.DocumentSnapshot) => {
                try {
                    runInAction(() => (this.inProgress = true));
                    const tenantDto = snapshot.data() as TenantDto;
                    this.store.tenantStore.tenant = Tenant.createFromDto(this.store.tenantStore, tenantDto);
                } finally {
                    runInAction(() => (this.inProgress = false));
                }
            });
    }
}
