import { observable, runInAction, action, computed } from 'mobx';
import { CustomerDto } from '../../../api/dtos/generated/dtos.generated';
import { validateField, validate } from '../../../common/helpers/validationHelper';
import * as uuid from 'uuid';
import i18n from 'i18next';
import { BaseModel } from '../../../common/stores/model/baseModel';
import Validator from 'validatorjs';
import { Address } from '../../../common/models/address';
import { mapTrackingFieldsToDto, mapTrackingFieldsToModel } from '../../../common/helpers/trackingFields';

export class Customer extends BaseModel {
    id: string;
    @observable companyName: string = '';
    @observable firstName: string = '';
    @observable lastName: string = '';
    @observable email: string = '';
    @observable phone?: string = '';
    @observable mobilePhone?: string = '';
    @observable addresses: Address[] = [];
    @observable imageCount: number = 0;
    @observable noteCount: number = 0;
    @observable validationErrors: { [index: string]: any } = {};
    @observable isDirty: boolean = false;
    @observable isNew: boolean = false;

    private static rules = {
        companyName: 'max:255',
        firstName: 'required|max:255',
        lastName: 'required|max:255',
        email: 'email|max:255',
        phone: 'max:50',
        mobilePhone: 'max:50',
    };

    private static getValidator(data: any, rules: any) {
        const validator = new Validator(data, rules);
        validator.setAttributeNames({
            companyName: i18n.t('companyName'),
            firstName: i18n.t('firstName'),
            lastName: i18n.t('lastName'),
            email: i18n.t('email'),
            phone: i18n.t('phone'),
            mobilePhone: i18n.t('mobile-phone'),
        });
        return validator;
    }

    public static createFromDto(dto: CustomerDto): Customer {
        const customer = new Customer(dto.id);
        runInAction(() => {
            customer.isNew = false;
            customer.companyName = dto.companyName;
            customer.firstName = dto.firstName;
            customer.lastName = dto.lastName;
            customer.email = dto.email;
            customer.phone = dto.phone ? dto.phone : '';
            customer.mobilePhone = dto.mobilePhone ? dto.mobilePhone : '';
            customer.addresses = dto.addresses ? dto.addresses.map((x) => Address.createFromDto(x)) : [];
            customer.imageCount = dto.imageCount || 0;
            customer.noteCount = dto.noteCount || 0;
        });
        mapTrackingFieldsToModel(customer, dto);
        return customer;
    }

    constructor(id: string = uuid.v4()) {
        super();
        this.id = id;
        runInAction(() => {
            this.isNew = true;
        });
    }

    updateFromDto(dto: CustomerDto): void {
        this.isNew = false;
        this.companyName = dto.companyName ? dto.companyName : '';
        this.firstName = dto.firstName ? dto.firstName : '';
        this.lastName = dto.lastName ? dto.lastName : '';
        this.phone = dto.phone ? dto.phone : '';
        this.mobilePhone = dto.mobilePhone ? dto.mobilePhone : '';
        this.email = dto.email ? dto.email : '';
        this.addresses = dto.addresses.map((x) => Address.createFromDto(x));
        mapTrackingFieldsToModel(this, dto);
    }

    @action
    updateProperty(field: string, value: any) {
        if (!this.isDirty) {
            this.isDirty = true;
        }

        (this as any)[field] = value;
        const validator = Customer.getValidator({ [field]: value }, { [field]: (Customer.rules as any)[field] });
        this.validationErrors = validateField(validator, field, this.validationErrors);
    }

    @action
    validateAll(data: any) {
        const validator = Customer.getValidator(data, Customer.rules);
        this.validationErrors = validate(validator);
    }

    @action
    addOrUpdateAddress(address: Address) {
        const existingAddress = this.addresses.find((x) => x.id === address.id);
        if (existingAddress) {
            existingAddress.fullAddress = address.fullAddress;
            existingAddress.isPrimary = address.isPrimary;
            existingAddress.isCustomAddress = address.isCustomAddress;
            existingAddress.addition = address.addition;
            existingAddress.street = address.street;
            existingAddress.city = address.city;
            existingAddress.country = address.country;
            existingAddress.countryCode = address.countryCode;
            existingAddress.geopoint = address.geopoint;
            existingAddress.placeId = address.placeId;
            existingAddress.zip = address.zip;
            existingAddress.description = address.description;
        } else {
            this.addresses.push(address);
        }

        if (!this.addresses.find((x) => x.isPrimary && x.id !== address.id)) {
            const currentAddress = this.addresses.find((x) => x.id === address.id);
            if (currentAddress) {
                currentAddress.isPrimary = true;
            }
        } else if (address.isPrimary) {
            this.addresses.forEach((a) => {
                if (a.id !== address.id) {
                    a.isPrimary = false;
                }
            });
        }
        this.isDirty = true;
    }

    @action
    deleteAddress(id: string) {
        this.addresses = this.addresses.filter((x) => x.id !== id);
        if (this.addresses.length > 0) {
            if (!this.hasPrimaryAddress) {
                this.addresses[0].isPrimary = true;
            }
        }
        this.isDirty = true;
    }

    @computed get isValid() {
        return Object.keys(this.validationErrors).length === 0;
    }

    @computed get displayName() {
        return `${this.firstName} ${this.lastName} ${this.companyName ? '(' + this.companyName + ')' : ''}`;
    }

    @computed get hasPrimaryAddress() {
        return !!this.addresses.find((a) => a.isPrimary);
    }

    public toDto(tenantId: string): CustomerDto {
        const customer = {
            id: this.id,
            tenantId: tenantId,
            companyName: this.companyName,
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email,
            phone: this.phone,
            mobilePhone: this.mobilePhone,
            addresses: this.addresses ? this.addresses.map((x) => x.toDto()) : [],
        };

        mapTrackingFieldsToDto(customer, this);

        return customer;
    }
}
