import React from 'react';
import { ListItem, createStyles, Theme } from '@material-ui/core';
import { IndexSearchConfig } from './algoliaSearchIndexConfigurations';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

const useStyles = makeStyles(({ palette, spacing, breakpoints }: Theme) =>
    createStyles({
        searchList: {
            minWidth: 300,
            flexWrap: 'wrap',
            padding: '1rem',
            position: 'relative',
            '&:hover': {
                background: 'rgba(57,139,247,0.05)',
                color: palette.primary.main,
                '&:before': {
                    content: "''",
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    bottom: 0,
                    background: palette.primary.main,
                    width: '4px'
                }
            }
        },
        listItemIcon: {
            marginRight: 0,
            marginLeft: spacing(1),
            '& svg': {
                fontSize: 25
            }
        }
    })
);

interface AlgoliaSuggestionProps {
    hit: any;
    indexSearchConfigs: IndexSearchConfig[];
    onItemSelected: any;
}

const AlgoliaSuggestion = ({ hit, indexSearchConfigs, onItemSelected }: AlgoliaSuggestionProps) => {
    const classes = useStyles();

    if (indexSearchConfigs.length === 0) {
        return null;
    }

    let indexSearchConfig =
        indexSearchConfigs.length > 1 ? indexSearchConfigs.find(x => x.indexName === hit.index) : indexSearchConfigs[0];

    let hits = [];
    if (hit.hits === undefined) {
        // handle single index search result
        hits.push(hit);
    } else {
        // handle multi index search result
        hits = hit.hits;
    }

    return (
        <>
            {hits.map((x: any, index: number) => {
                return (
                    <div key={index}>
                        {index === 0 && hit.hits !== undefined && (
                            <ListItem divider={true} component={'div'}>
                                {indexSearchConfig!.icon && (
                                    <ListItemIcon className={classes.listItemIcon}>
                                        {indexSearchConfig!.icon}
                                    </ListItemIcon>
                                )}
                                <ListItemText
                                    primaryTypographyProps={{ variant: 'subtitle1', color: 'textSecondary' }}
                                    primary={indexSearchConfig!.indexLabel}
                                ></ListItemText>
                            </ListItem>
                        )}
                        <ListItem
                            button
                            className={classes.searchList}
                            onClick={() => onItemSelected(x, indexSearchConfig!.indexName)}
                            divider={false}
                        >
                            {indexSearchConfig!.renderer && indexSearchConfig!.renderer(x)}
                        </ListItem>
                    </div>
                );
            })}
        </>
    );
};

export default AlgoliaSuggestion;
