import { action, computed, observable, toJS } from 'mobx';
import { validate } from '../../../common/helpers/validationHelper';
import i18n from 'i18next';
import Firebase from '../../../api/firebase';
import { AuthStore } from '../stores/authStore';
import { BaseViewModel } from '../../../common/models/baseViewModel.vm';

export class SignInScreenVm extends BaseViewModel {
    private authStore: AuthStore;
    @observable public email: string = '';
    @observable public password: string = '';
    @observable public showPassword: boolean = false;

    constructor(authStore: AuthStore) {
        super();
        this.authStore = authStore;
    }

    @action
    public toggleShowPassword() {
        this.showPassword = !this.showPassword;
    }

    @action
    public setEmail(email: string) {
        this.email = email;
    }

    @action
    public setPassword(password: string) {
        this.password = password;
    }

    @action
    private validateAll(data: any) {
        const rules = {
            email: 'required|email|max:255',
            password: 'required|max:255'
        };

        const attributeNames = {
            email: i18n.t('email'),
            password: i18n.t('password')
        };

        const validator = SignInScreenVm.getValidator(data, rules, attributeNames);
        this.validationErrors = validate(validator);
    }

    @action
    public async signIn() {
        this.validateAll(toJS(this));

        if (this.isValid) {
            const resultSignIn = Firebase.signInWithEmailAndPassword(this.email, this.password);
            await this.handle(resultSignIn);
        }
    }

    @computed
    public get initialLoginAttemptInProgress() {
        return this.authStore.initialLoginAttemptInProgress;
    }
}
