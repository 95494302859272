import * as React from 'react';
import { Button, Chip, CircularProgress, Grid, Paper, Theme } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useStripe } from './useStripe';
import { StoreContext } from '../../../App';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import { formatDate } from '../../../common/utils/dateHelper';
import { createStyles, makeStyles } from '@material-ui/styles';
import Firebase from '../../../api/firebase';
import LoadingInfo from '../../../common/components/LoadingInfo';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        activePlan: {
            padding: theme.spacing(3),
        },
        planTitle: {
            color: theme.palette.primary.main,
            fontWeight: 400,
            textTransform: 'uppercase',
            marginBottom: 0,
        },
        discount: {
            backgroundColor: theme.palette.success.dark,
            color: theme.palette.getContrastText(theme.palette.success.dark),
            ...theme.typography.subtitle1,
        },
        originalPrice: {
            textDecoration: 'line-through',
        },
    })
);

const fetchActiveSubscription = async (subscriptionId: string) => {
    const fetchActiveSubscriptionCf = Firebase.functions.httpsCallable('getActiveSubscription');
    const result = await fetchActiveSubscriptionCf({ subscriptionId });
    return result.data.data;
};

type StripeSubscriptionStatus =
    | 'active'
    | 'past_due'
    | 'unpaid'
    | 'canceled'
    | 'incomplete'
    | 'incomplete_expired'
    | 'trialing';

interface StripeSubscriptionInfo {
    cancelAtPeriodEnd: boolean;
    status: StripeSubscriptionStatus;
    trialEnd: any;
    currentPeriodEnd: any;
    name: string;
    unitAmount: number;
    currency: string;
    numOfEmployees: number;
    discountPercent: number;
}

const SubscriptionScreen = observer(() => {
    const { tenantStore } = React.useContext(StoreContext);
    const { openBillingPortal, openBillingPortalInProgress } = useStripe();
    const { t } = useTranslation();
    const classes = useStyles();
    const [isLoadingStripeSubscription, setIsLoadingStripeSubscription] = React.useState<boolean>();
    const [stripeSubscription, setStripeSubscription] = React.useState<StripeSubscriptionInfo | undefined>();

    const loadCurrentSubscription = async () => {
        if (tenantStore.tenant?.subscription.id) {
            setIsLoadingStripeSubscription(true);
            const subscription = await fetchActiveSubscription(tenantStore.tenant?.subscription?.id);
            setStripeSubscription({
                cancelAtPeriodEnd: subscription.cancel_at_period_end,
                status: subscription.status,
                trialEnd: subscription.trial_end,
                currentPeriodEnd: subscription.current_period_end,
                name: subscription.items.data[0]?.price.nickname,
                unitAmount: subscription.items.data[0]?.price.unit_amount_decimal / 100,
                currency: subscription.items.data[0]?.price.currency,
                numOfEmployees: parseInt(subscription.items.data[0]?.price.metadata.unit),
                discountPercent: subscription.customer?.discount?.coupon
                    ? subscription.customer.discount.coupon.percent_off
                    : 0,
            });
            setIsLoadingStripeSubscription(false);
        }
    };

    React.useEffect(() => {
        loadCurrentSubscription();
    }, []);

    if (!tenantStore.tenant || !stripeSubscription || isLoadingStripeSubscription) {
        return <LoadingInfo />;
    }

    return (
        <Grid container justify={'center'} alignItems={'center'} direction={'column'} spacing={2}>
            <Grid item>
                <Paper className={classes.activePlan}>
                    <Grid container spacing={3} alignItems={'center'} justify={'space-between'}>
                        {stripeSubscription.cancelAtPeriodEnd && (
                            <Grid item xs={12}>
                                <Alert severity={'error'}>{t('subscription-cancel-at-period-end-title')}</Alert>
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <Typography variant="subtitle1" color={'textPrimary'} align={'center'}>
                                {stripeSubscription.status === 'trialing'
                                    ? `${t('trial-period-end-label')}: ${formatDate(
                                          new Date(stripeSubscription.trialEnd! * 1000),
                                          'dd.MM.yyy'
                                      )}`
                                    : `${
                                          stripeSubscription.cancelAtPeriodEnd
                                              ? t('subscription-period-end-label')
                                              : t('subscription-next-renewal-date')
                                      }: ${formatDate(
                                          new Date(stripeSubscription.currentPeriodEnd! * 1000),
                                          'dd.MM.yyy'
                                      )}`}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography className={classes.planTitle} gutterBottom variant="h6">
                                {stripeSubscription.name}
                            </Typography>
                            <Typography gutterBottom variant="subtitle1" color={'primary'}>
                                {(stripeSubscription.unitAmount / 100) * (100 - stripeSubscription?.discountPercent)}
                                &nbsp;
                                {stripeSubscription.discountPercent > 0 && (
                                    <span className={classes.originalPrice}>{stripeSubscription.unitAmount}&nbsp;</span>
                                )}
                                {stripeSubscription.currency.toUpperCase()}/{t('month')}
                            </Typography>
                            {stripeSubscription.discountPercent > 0 && (
                                <Chip
                                    label={`- ${stripeSubscription?.discountPercent} %`}
                                    className={classes.discount}
                                />
                            )}
                        </Grid>
                        <Grid item>
                            <strong>
                                {t('max')} {stripeSubscription.numOfEmployees}
                            </strong>
                            &nbsp;
                            {t('employees')}
                        </Grid>
                        <Grid item>
                            <Button
                                disabled={openBillingPortalInProgress}
                                color={'primary'}
                                variant={'contained'}
                                onClick={() => openBillingPortal(tenantStore.customerId)}
                                startIcon={openBillingPortalInProgress ? <CircularProgress size={24} /> : null}
                            >
                                {tenantStore.tenant &&
                                (tenantStore.tenant.subscription.status === 'active' ||
                                    tenantStore.tenant.subscription.status === 'trialing') &&
                                tenantStore.tenant.subscription.cancelAtPeriodEnd
                                    ? t('reactivate')
                                    : t('manageSubscription')}
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
});

export default SubscriptionScreen;
