import { Typography } from '@material-ui/core';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

interface PromotionInformationProps {
    promotion: any;
}

export default function PromotionInformation({ promotion }: PromotionInformationProps) {
    const { t } = useTranslation();

    if (!promotion) {
        return null;
    }

    return (
        <Typography variant={'subtitle1'}>
            {' '}
            {t('promotion-code-valid-text', {
                percentOff: promotion?.coupon.percent_off,
                durationMonth: promotion?.coupon.duration_in_months,
            })}
        </Typography>
    );
}
