import { RouteComponentProps, Link } from '@reach/router';
import { Theme, Grid, Zoom, Button, createStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import React from 'react';
import SuccessMessage from '../../../common/components/SuccessMessage';
import PlanuxLogo from '../../../assets/planux_logo.svg';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { observer } from 'mobx-react-lite';
import { centerPaper } from '../../../common/hocs/centerPaper';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '550px'
        },
        logo: {
            width: '180px',
            alignSelf: 'center',
            margin: theme.spacing(2)
        }
    })
);

interface ISuccessScreenProps {
    successMessage?: string;
}

type Props = ISuccessScreenProps & RouteComponentProps;

const SuccessScreen = observer((props: Props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Grid container direction="column" justify="center" alignItems="center" spacing={3}>
                <Grid item container justify={'center'}>
                    <Zoom in timeout={500}>
                        <img className={classes.logo} src={PlanuxLogo} alt="logo" />
                    </Zoom>
                </Grid>
                <Grid item>
                    <SuccessMessage successMessage={props.successMessage} />
                </Grid>
                <Grid item>
                    <Link to="/" style={{ textDecoration: 'none' }}>
                        <Button fullWidth variant={'contained'} type={'submit'} color={'primary'}>
                            {t('login')}
                        </Button>
                    </Link>
                </Grid>
            </Grid>
        </div>
    );
});

export default centerPaper(SuccessScreen);
