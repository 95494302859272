import {
    IconButton,
    Theme,
    createStyles,
    makeStyles,
    useScrollTrigger,
    Grid,
    Typography,
    Box,
    Fade,
} from '@material-ui/core';
import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import Divider from '@material-ui/core/Divider';
import PlanuxLogo from '../../assets/planux_logo.svg';
import PlanuxLogoMin from '../../assets/favicon.svg';
import EditIcon from 'mdi-material-ui/Pencil';
import { Link } from '@reach/router';
import AvatarItem from '../../common/components/AvatarItem';
import defaultTenantAvatar from '../../assets/icons/defaultCompanyAvatar.svg';
import { StoreContext } from '../../App';

const useStyles = makeStyles(({ palette, spacing }: Theme) =>
    createStyles({
        logo: {
            width: '100px',
            alignSelf: 'center',
            margin: 'auto auto',
        },
        logoMin: {
            width: '100%',
            maxWidth: '28px',
            alignSelf: 'center',
            margin: 'auto auto',
        },
        avatarContainer: {
            display: 'flex',
            justifyContent: 'center',
            position: 'relative',
            width: 74,
            height: 74,
            margin: spacing(2),
            marginBottom: spacing(1),
            alignItems: 'center',
        },
        avatarContainerMin: {
            margin: '1rem auto !important',
            position: 'relative',
            width: 45,
            height: 45,
            display: 'flex',
        },
        avatarMainImageContainer: {
            border: 'solid 4px',
            backgroundImage: 'linear-gradient(228deg, #58c3f2, #398bf7)',
            borderRadius: '50%',
        },
        organizationButton: {
            position: 'absolute',
            bottom: '0',
            right: '0',
            width: 25,
            height: 25,
            boxShadow: '0 3px 15px 0 rgba(180, 192, 210, 0.47)',
            backgroundColor: '#ffffff',
            borderRadius: '50%',
            border: `2px solid ${palette.primary.contrastText}`,
            padding: 0,
            '&:hover': {
                backgroundColor: palette.primary.main,
                '& svg': {
                    fill: '#ffffff',
                },
            },
        },
        avatar: {
            width: '74px',
            height: '74px',
            borderRadius: '50%',
            border: '3px solid',
            borderColor: palette.primary.main,
            background: '#fff',
            padding: '3px',
            margin: '1rem auto',
            '& img': {
                borderRadius: '50%',
            },
        },
        avatarMin: {
            borderRadius: '50%',
            width: 38,
            height: 38,
        },
        companyName: {
            fontSize: 16,
            fontWeight: 500,
            fontStyle: 'normal',
        },
        companyEmail: {
            color: '#707070',
            fontSize: 15,
        },
        menu: {
            '& ul': {
                '& a:focus': {
                    outline: 'none',
                },
            },
        },
    })
);

interface SidebarHeaderProps {
    window?: () => Window;
}

const SidebarHeader = observer(({ window }: SidebarHeaderProps) => {
    const classes = useStyles();
    const { uiStore, tenantStore, authStore } = useContext(StoreContext);
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
        disableHysteresis: true,
        threshold: 1,
    });

    const handleOpenUserProfile = (id: string, tab?: number) => {
        uiStore.setSelectedEmployeeId(id, tab);
    };

    return (
        <Box mb={uiStore.isDrawerOpen ? 2 : 0} display={'flex'} flexDirection={'column'} alignItems={'center'}>
            <Box minHeight={65} display={'flex'} width={'100%'} flexDirection={'column'}>
                <Fade in={uiStore.isDrawerOpen} mountOnEnter unmountOnExit>
                    <img className={classes.logo} src={PlanuxLogo} alt="logo" />
                </Fade>
                <Fade in={!uiStore.isDrawerOpen} mountOnEnter unmountOnExit>
                    <img className={classes.logoMin} src={PlanuxLogoMin} alt="logo" />
                </Fade>
                <Divider
                    light
                    style={trigger ? { marginLeft: 0, marginRight: 0 } : { marginLeft: 16, marginRight: 16 }}
                />
            </Box>

            {authStore.isUser ? (
                <Box className={uiStore.isDrawerOpen ? classes.avatarContainer : classes.avatarContainerMin}>
                    <AvatarItem
                        defaultImage={defaultTenantAvatar}
                        imagePath={
                            uiStore.isDrawerOpen
                                ? authStore.currentEmployee?.imagePath
                                : authStore.currentEmployee?.imageThumbPath
                        }
                        imageHash={authStore.currentEmployee?.imageHash}
                        className={uiStore.isDrawerOpen ? classes.avatar : classes.avatarMin}
                    />
                    <IconButton
                        onClick={() => handleOpenUserProfile(authStore.currentEmployee?.id!, 0)}
                        className={classes.organizationButton}
                    >
                        <EditIcon style={{ fontSize: 15 }} color="primary" />
                    </IconButton>
                </Box>
            ) : (
                <Box className={uiStore.isDrawerOpen ? classes.avatarContainer : classes.avatarContainerMin}>
                    <AvatarItem
                        defaultImage={defaultTenantAvatar}
                        imagePath={
                            tenantStore!.tenant
                                ? uiStore.isDrawerOpen
                                    ? tenantStore!.tenant.imagePath
                                    : tenantStore!.tenant.imageThumbPath
                                : undefined
                        }
                        imageHash={tenantStore!.tenant ? tenantStore!.tenant.imageHash : undefined}
                        className={uiStore.isDrawerOpen ? classes.avatar : classes.avatarMin}
                    />

                    <Link to="/mytenant" style={{ textDecoration: 'none' }}>
                        <IconButton className={classes.organizationButton}>
                            <EditIcon style={{ fontSize: 15 }} color="primary" />
                        </IconButton>
                    </Link>
                </Box>
            )}

            <Grid
                container
                direction={'column'}
                justify={'center'}
                alignItems={'center'}
                style={uiStore.isDrawerOpen ? { display: 'flex' } : { display: 'none' }}
            >
                <Grid item>
                    <Typography variant={'subtitle1'} style={{ fontWeight: 500 }}>
                        {authStore.isUser
                            ? authStore.currentEmployee?.displayName
                            : tenantStore!.tenant
                            ? tenantStore!.tenant.name
                            : ''}
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant={'subtitle2'} color={'textSecondary'}>
                        {authStore!.tenantEmail}
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    );
});

export default SidebarHeader;
