import { RootStore } from '../../../stores/rootStore';
import { observable } from 'mobx';
import { DateFilter } from '../models/dateFilter';
import { endOfWeek, startOfWeek } from 'date-fns';
import TaskInfoModel from '../components/tasks/TaskInfoModel';
import { CapacityUtilizationModelData } from '../components/capacityUtilization/CapacityUtilizationModel';
import EmployeeAbsencesVm from '../components/employeeAbsences/employeeAbsencesVm';
import { ProjectsWidgetVm } from '../components/projects/ProjectsWidgetVm';
import { CapacityUtilizationWidgetVm } from '../components/capacityUtilization/capacityUtilizationWidgetVm';
import { OpenProjectsWidgetVm } from '../components/openProjects/openProjectsWidgetVm';
import { CustomerMapWidgetVm } from '../components/customerMap/customerMapWidgetVm';

export enum DashboardPeriod {
    Today,
    ThisWeek,
    NextWeek,
}

export class DashboardStore {
    public static storeName: string = 'dashboardStore';
    public store: RootStore;

    @observable public filter: DateFilter = new DateFilter(
        startOfWeek(new Date(), { weekStartsOn: 1 }),
        endOfWeek(new Date(), { weekStartsOn: 1 })
    );

    @observable public capacityUtilizationWidget: CapacityUtilizationModelData[] = [];
    @observable public taskInfoModel: TaskInfoModel;
    @observable public employeeAbsenceModel: EmployeeAbsencesVm;
    @observable public projectWidgetModel: ProjectsWidgetVm;
    @observable public capacityUtilizationModel: CapacityUtilizationWidgetVm;
    @observable public openProjectsModel: OpenProjectsWidgetVm;
    @observable public customerMapWidget: CustomerMapWidgetVm;

    constructor(store: RootStore) {
        this.store = store;
        this.taskInfoModel = new TaskInfoModel(this);
        this.employeeAbsenceModel = new EmployeeAbsencesVm(store);
        this.projectWidgetModel = new ProjectsWidgetVm(store);
        this.capacityUtilizationModel = new CapacityUtilizationWidgetVm(store);
        this.openProjectsModel = new OpenProjectsWidgetVm(this);
        this.customerMapWidget = new CustomerMapWidgetVm(store);
    }
}
