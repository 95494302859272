export interface IAlgoliaIndexConfig {
    task: string;
    resource: string;
    planningboarditem: string;
    project: string;
    customer: string;
    employee: string;
    images: string;
}

export interface IAlgoliaConfig {
    applicationId: string;
    index: IAlgoliaIndexConfig;
}

export interface IGoogleConfig {
    mapsbrowserapikey: string;
}

export interface IAppConfig {
    appTitle: string;
    apiKey: string;
    authDomain: string;
    databaseURL: string;
    projectId: string;
    storageBucket: string;
    messagingSenderId: string;
    algolia: IAlgoliaConfig;
    appId: string;
    measurementId: string;
    stripeKey: string;
    searchKeyUrl: string;
    google: IGoogleConfig;
}

const devConfig: IAppConfig = {
    appTitle: 'Planux DEV',
    apiKey: 'AIzaSyB3qnm2BrMQlh070zEpCb115T-bWnlgRC8',
    authDomain: 'planux-saas-dev.firebaseapp.com',
    databaseURL: 'https://planux-saas-dev.firebaseio.com',
    projectId: 'planux-saas-dev',
    storageBucket: 'gs://planux-saas-dev.appspot.com',
    searchKeyUrl: 'https://europe-west1-planux-saas-dev.cloudfunctions.net/getSearchKey/',
    messagingSenderId: '466090854143',
    appId: '1:466090854143:web:f6378c70fe3d87b9b26eca',
    measurementId: 'G-VYG8XQES20',
    algolia: {
        applicationId: 'IKI315ZB2N',
        index: {
            task: 'dev_tasks',
            resource: 'dev_resources',
            planningboarditem: 'dev_planning_board_items',
            project: 'dev_projects',
            customer: 'dev_customers',
            employee: 'dev_employees',
            images: 'dev_images',
        },
    },
    google: {
        mapsbrowserapikey: 'AIzaSyB3qnm2BrMQlh070zEpCb115T-bWnlgRC8',
    },
    stripeKey: 'pk_test_LJgFtmKkiIkNBuzfa0ryQ5mO',
};

const stagingConfig: IAppConfig = {
    appTitle: 'Planux STAGING',
    apiKey: 'AIzaSyDT7Ebl2Y7H9sUygjnT0BpQckia1uNx11k',
    authDomain: 'planux-staging.firebaseapp.com',
    databaseURL: 'https://planux-staging.firebaseio.com',
    projectId: 'planux-staging',
    storageBucket: 'gs://planux-staging.appspot.com',
    searchKeyUrl: 'https://europe-west1-planux-staging.cloudfunctions.net/getSearchKey/',
    messagingSenderId: '319449310634',
    appId: '1:319449310634:web:8523f2e25faf45e731b7d3',
    measurementId: 'G-5XDJYJSKHE',
    algolia: {
        applicationId: 'IKI315ZB2N',
        index: {
            task: 'staging_tasks',
            resource: 'staging_resources',
            planningboarditem: 'staging_planning_board_items',
            project: 'staging_projects',
            customer: 'staging_customers',
            employee: 'staging_employees',
            images: 'staging_images',
        },
    },
    google: {
        mapsbrowserapikey: 'AIzaSyB3qnm2BrMQlh070zEpCb115T-bWnlgRC8',
    },
    stripeKey: 'pk_test_LJgFtmKkiIkNBuzfa0ryQ5mO',
};

const prodConfig: IAppConfig = {
    appTitle: 'Planux',
    apiKey: 'AIzaSyCi-P9URvT7AOVXPlmCuX0oflu_zbENdKk',
    authDomain: 'planux-prod.firebaseapp.com',
    databaseURL: 'https://planux-prod.firebaseio.com',
    projectId: 'planux-prod',
    storageBucket: 'planux-prod.appspot.com',
    searchKeyUrl: 'https://europe-west1-planux-prod.cloudfunctions.net/getSearchKey/',
    messagingSenderId: '919232222365',
    appId: '1:919232222365:web:f318686b644366a9477074',
    measurementId: 'G-7KHDCWX7ZC',
    algolia: {
        applicationId: 'IKI315ZB2N',
        index: {
            task: 'tasks',
            resource: 'resources',
            planningboarditem: 'planning_board_items',
            project: 'projects',
            customer: 'customers',
            employee: 'employees',
            images: 'images',
        },
    },
    google: {
        mapsbrowserapikey: 'AIzaSyB3qnm2BrMQlh070zEpCb115T-bWnlgRC8',
    },
    stripeKey: 'pk_live_a1WGnYwLX4tTSXE7C6i4rHoe',
};

const appConfig: IAppConfig =
    process.env.REACT_APP_TARGET === 'prod'
        ? prodConfig
        : process.env.REACT_APP_TARGET === 'stage'
        ? stagingConfig
        : devConfig;

export default appConfig;
