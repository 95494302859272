import * as React from 'react';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import Firebase from '../../api/firebase';
import { useEffect } from 'react';
import { TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { CountryDto } from '../../api/dtos/generated/dtos.generated';

interface CountryAutocompleteProps {
    countryCode: string | undefined;
    onChange: (country: CountryDto | undefined) => void;
    disabled?: boolean;
    error?: boolean;
    required?: boolean;
    helperText?: React.ReactNode;
}

const filterOptions = createFilterOptions({
    ignoreCase: true,
});

export default function CountryAutocomplete({
    countryCode,
    disabled = false,
    onChange,
    error,
    helperText,
    required = false,
}: CountryAutocompleteProps) {
    const { t, i18n } = useTranslation();
    const [selectedCountry, setSelectedCountry] = React.useState<CountryDto | null>(null);
    const [countries, setCountries] = React.useState<CountryDto[]>([]);

    const loadCountries = async () => {
        const allCountries: CountryDto[] = [];
        const countriesSnapshot = await Firebase.firestore.collection('countries').get();
        countriesSnapshot.forEach((doc) => {
            allCountries.push(doc.data() as CountryDto);
        });

        setCountries(allCountries);
    };

    useEffect(() => {
        if (countryCode && countries?.length > 0) {
            const foundCountry = countries.find((x) => x.Code.toLowerCase() === countryCode.toLowerCase());
            if (foundCountry) {
                setSelectedCountry(foundCountry);
            }
        }
    }, [countries]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        loadCountries();
    }, []);

    useEffect(() => {
        if (countries.length > 0) {
            onChange(selectedCountry !== null ? selectedCountry : undefined);
        }
    }, [selectedCountry]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Autocomplete
            fullWidth
            id="country"
            disabled={disabled}
            options={countries}
            filterOptions={filterOptions}
            value={selectedCountry}
            onChange={(event: any, value: any | null, reason) => {
                if (reason === 'clear' || reason === 'select-option') {
                    setSelectedCountry(value);
                }
            }}
            getOptionLabel={(option: any) => {
                return option['Text' + i18n.language.charAt(0).toUpperCase() + i18n.language.slice(1)];
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    required={required}
                    label={t('country')}
                    variant="outlined"
                    error={error}
                    helperText={helperText}
                />
            )}
        />
    );
}
