import { addWeeks, endOfWeek, isSameDay, startOfWeek } from 'date-fns';

export type DateRangeSelectionType = 'today' | 'calendarWeek' | 'nextWeek' | 'customized';

export const getDateRangeSelectionType = (start: Date, end: Date): DateRangeSelectionType => {
    const today = new Date();

    if (isSameDay(start, end) && isSameDay(start, today)) {
        return 'today';
    }

    if (
        isSameDay(startOfWeek(today, { weekStartsOn: 1 }), start) &&
        isSameDay(endOfWeek(today, { weekStartsOn: 1 }), end)
    ) {
        return 'calendarWeek';
    }

    if (
        isSameDay(addWeeks(startOfWeek(today, { weekStartsOn: 1 }), 1), start) &&
        isSameDay(addWeeks(endOfWeek(today, { weekStartsOn: 1 }), 1), end)
    ) {
        return 'nextWeek';
    }

    return 'customized';
};
