export interface BaseDto {
    id: string;
    objectID?: string | null;
    createdBy?: string | null;
    createdAt?: Date | null;
    modifiedBy?: string | null;
    modifiedAt?: Date | null;
    deletedBy?: string | null;
    deletedAt?: Date | null;
}

export interface ReferencedDataDto {
    id: string;
}

export enum ImageCategoryType {
    Tenant = 0,
    Employee = 1,
    Resource = 2,
    Project = 3,
    Task = 4,
    Customer = 5,
}
export enum ImageType {
    Png = 0,
    Jpeg = 1,
}
export enum Language {
    De = 0,
    Fr = 1,
    It = 2,
    En = 3,
    Hr = 4,
}
export type AbsenceDto = 'vacation' | 'holiday' | 'unpaidLeave' | 'illness' | 'otherAbsence';

export type AbsenceTagretDto = 'employees' | 'resources';

export interface AddressDto extends BaseDto {
    fullAddress: string;
    description: string;
    addition: string;
    street: string;
    zip: string;
    city: string;
    country: string;
    countryCode: string | null;
    isPrimary: boolean;
    isCustomAddress: boolean;
    geopoint: any;
    placeId: string;
}

export interface CountryDto {
    Code: string;
    TextDe: string;
    TextEn: string;
    TextFr: string;
    TextIt: string;
}

export interface CustomerAlgoliaDto extends BaseDto {
    tenantId: string;
    companyName: string;
    firstName: string;
    lastName: string;
    email: string;
    phone?: string;
    mobilePhone?: string;
    addresses: AddressDto[];
    imageCount: number;
    noteCount: number;
    notes: NoteDto[];
}

export interface CustomerDto extends BaseDto {
    tenantId: string;
    companyName: string;
    firstName: string;
    lastName: string;
    email: string;
    phone?: string;
    mobilePhone?: string;
    addresses: AddressDto[];
    imageCount?: number;
    noteCount?: number;
}

export interface EmployeeAlgoliaDto extends BaseDto {
    tenantId: string;
    firstName: string;
    lastName: string;
    email: string | null;
    phone: string;
    jobTitle?: string;
    description?: string;
    loginId: string | null;
    loginExists: boolean;
    role: string | null;
    isProfileCompleted: boolean;
    imageHash: string | null;
    language: Language | null;
    imageCount: number;
    noteCount: number;
    notes: NoteDto[];
}

export interface EmployeeDto extends BaseDto {
    tenantId: string;
    firstName: string;
    lastName: string;
    email: string | null;
    phone: string;
    jobTitle?: string;
    description?: string;
    loginId: string | null;
    loginExists: boolean;
    role: string | null;
    isProfileCompleted: boolean;
    imageHash: string | null;
    language: Language | null;
    imageCount?: number;
    noteCount?: number;
}

export interface ExportTasksRequestDto {
    startDate: number;
    endDate: number;
    filter: TaskFilterDto;
    columns: TableColumnDto[];
}

export interface ImageDto extends BaseDto {
    description?: string;
    imageHash: string | null;
    refId?: string;
    imageCategoryType?: ImageCategoryType;
    tenantId: string;
}

export interface InvoiceDto {
    id: string;
    amountPaid: number;
    currency: string;
    invoicePdfUrl: string;
    invoiceNumber: string;
    created: number;
}

export interface NoteDto extends BaseDto {
    title?: string;
    description: string;
    tenantId: string;
}

export type PeriodDto = 'morning' | 'afternoon' | 'allDay';

export interface PlanningBoardItemDto extends BaseDto {
    tenantId: string;
    employeeId: string | null;
    resourceId: string | null;
    projectRef: ReferencedProjectDto | null;
    absence: 'vacation' | 'holiday' | 'unpaidLeave' | 'illness' | 'otherAbsence' | null;
    date: Date;
    period: 'morning' | 'afternoon' | 'allDay';
    serieId: string | null;
}

export interface PlanningBoardItemGfDto {
    id: string;
    tenantId: string;
    employeeId: string | null;
    resourceId: string | null;
    projectRef: ReferencedProjectDto | null;
    absence: 'vacation' | 'holiday' | 'unpaidLeave' | 'illness' | 'otherAbsence' | null;
    date: number;
    period: 'morning' | 'afternoon' | 'allDay';
    serieId: string | null;
}

export interface PlanningEmployeeDto extends BaseDto {
    tenantId: string;
    employeeId: string;
    firstName: string;
    lastName: string;
}

export interface PlanningResourceDto extends BaseDto {
    tenantId: string;
    resourceId: string;
    name: string;
}

export interface PresenceDto {
    workPerDay: number;
    amountWorkingDays: number;
    upTimeFrom: Date;
    upTimeTo: Date;
    afternoonUpTimeFrom: Date;
    afternoonUpTimeTo: Date;
    isMondayActive: boolean;
    isTuesdayActive: boolean;
    isWednesdayActive: boolean;
    isThursdayActive: boolean;
    isFridayActive: boolean;
    isSaturdayActive: boolean;
    isSundayActive: boolean;
}

export interface ProjectAlgoliaDto extends BaseDto {
    tenantId: string;
    name: string;
    description: string;
    customerRef: ReferencedCustomerDto | null;
    addressRef: ReferencedAddressDto | null;
    budget: number;
    isComplete: boolean;
    color: string;
    imageCount: number;
    noteCount: number;
    notes: NoteDto[];
}

export interface ProjectDto extends BaseDto {
    tenantId: string;
    name: string;
    description: string;
    customerRef: ReferencedCustomerDto | null;
    addressRef: ReferencedAddressDto | null;
    budget: number;
    isComplete: boolean;
    color: string;
    imageCount?: number;
    noteCount?: number;
}

export interface ProjectRefDto {
    id: string;
    name: string;
    color: string;
    client: string;
    budget: string;
}

export interface ProjectUpsertDto extends BaseDto {
    tenantId: string;
    name: string;
    description: string;
    customerRef: ReferencedCustomerDto | null;
    addressRef: ReferencedAddressDto | null;
    budget: number;
    isComplete: boolean;
    color: string;
    imageCount?: number;
    noteCount?: number;
}

export type RecurringIntervalUnit = 'daily' | 'weekly' | 'monthly' | 'yearly';

export interface RecurringDto {
    unit: RecurringIntervalUnit;
    interval: number;
    start: Date;
    end: Date | null;
}

export interface ReferencedAddressDto extends ReferencedDataDto {
    displayName: string;
    deleted?: boolean;
    geopoint?: any;
}

export interface ReferencedCustomerDto extends ReferencedDataDto {
    displayName: string;
}

export interface ReferencedEmployeeDto extends ReferencedDataDto {
    displayName: string;
    imageHash: string | null;
}

export interface ReferencedProjectDto extends ReferencedDataDto {
    color: string;
}

export interface ReferencedResourceDto extends ReferencedDataDto {
    displayName: string;
    imageHash: string | null;
}

export interface ReferencedTrackingEmployeeDto extends ReferencedDataDto {
    displayName: string;
}

export interface ResourceAlgoliaDto extends BaseDto {
    tenantId: string;
    name: string;
    description?: string;
    imageHash: string | null;
    imageCount: number;
    noteCount: number;
    notes: NoteDto[];
}

export interface ResourceDto extends BaseDto {
    tenantId: string;
    name: string;
    description?: string;
    imageHash: string | null;
    imageCount?: number;
    noteCount?: number;
}

export interface SerieDeleteDto {
    serieId: string;
    tenantId: string;
    batchSize: number;
}

export default interface SerieDto extends BaseDto {
    projectRef: ProjectRefDto | null;
    absence: AbsenceDto | null;
    startDate: Date;
    endDate: Date;
    workingDays: SerieWorkingDaysDto;
    employees: SerieEmployeeDto[];
    resources: SerieResourceDto[];
    planningBoardItems: PlanningBoardItemDto[];
    tenantId: string;
}

export interface SerieEmployeeDto extends BaseDto {
    tenantId: string;
    employeeId: string;
    firstName: string;
    lastName: string;
    imageHash: string | null;
}

export interface SerieFormDto extends BaseDto {
    projectId: string;
    projectName: string;
    projectColor: string;
    projectClient: string;
    projectBudget: string;
    startDate: Date;
    endDate: Date;
    workingDays: SerieWorkingDaysDto;
    tenantId: string;
}

export interface SerieFormGfDto {
    id: string;
    tenantId: string;
    projectRef: ProjectRefDto | null;
    absence: AbsenceDto | null;
    startDate: number;
    endDate: number;
    workingDays: SerieWorkingDaysDto;
}

export interface SerieResourceDto extends BaseDto {
    tenantId: string;
    resourceId: string;
    name: string;
    imageHash: string | null;
}
export type SerieTypeDto = 'project' | 'absence';

export interface SerieUpsertGfDto {
    isNew: boolean;
    serie: SerieFormGfDto;
    employees: SerieEmployeeDto[];
    resources: SerieResourceDto[];
    planningBoardItems: PlanningBoardItemGfDto[];
    itemIdsToRemove: string[];
    batchSize: number;
}

export interface SerieWorkingDaysDto {
    monday: PeriodDto | null;
    tuesday: PeriodDto | null;
    wednesday: PeriodDto | null;
    thursday: PeriodDto | null;
    friday: PeriodDto | null;
    saturday: PeriodDto | null;
    sunday: PeriodDto | null;
}
export interface SubscriptionDto {
    cancelAtPeriodEnd: boolean;
    currentPeriodEnd: number;
    customerId: string;
    id: string;
    priceId: string;
    productId: string;
    status: string;
    trialEnd: number;
    maxNumberOfEmployees: number;
}

export interface SubscriptionPriceDto {
    id: string;
    name: string;
    unit_amount: number;
    currency: string;
    trialPeriodDays: number;
    interval: number;
    unit: number;
}

export interface TableColumnDto {
    field: string;
    header: string;
}

export interface TableHeadColumnsDto {
    field: string;
    header: string;
}

export interface TaskAlgoliaDto extends BaseDto {
    tenantId: string;
    name: string;
    description?: string;
    dueDate: Date | null;
    state: TaskState;
    employeeRef: ReferencedEmployeeDto | null;
    customerRef: ReferencedCustomerDto | null;
    addressRef: ReferencedAddressDto | null;
    recurring: RecurringDto | null;
    parentTaskId: string | null;
    imageCount: number;
    noteCount: number;
    notes: NoteDto[];
}

export type TaskState = 'open' | 'inprocess' | 'done';

export interface TaskDto extends BaseDto {
    tenantId: string;
    name: string;
    description?: string;
    dueDate: Date | null;
    state: TaskState;
    employeeRef: ReferencedEmployeeDto | null;
    customerRef: ReferencedCustomerDto | null;
    addressRef: ReferencedAddressDto | null;
    recurring: RecurringDto | null;
    parentTaskId: string | null;
    imageCount?: number;
    noteCount?: number;
}

export interface TaskFilterDto {
    employeeRef?: ReferencedEmployeeDto;
    customerRef?: ReferencedCustomerDto;
    startDate: Date;
    endDate: Date;
    states: TaskState[];
}

export interface TenantDto extends BaseDto {
    name: string;
    address: AddressDto;
    presence: PresenceDto;
    imageHash: string | null;
    subscription: SubscriptionDto;
    _lastTaskChanges?: Date | null;
    _lastProjectChanges?: Date | null;
    _lastPlanningProjectChanges?: Date | null;
}

export interface UploadImageDto extends BaseDto {
    type: ImageType;
    content: string;
    category: ImageCategoryType;
}

export interface UploadImageGalleryDto extends BaseDto {
    type: ImageType;
    content: string;
    description: string;
    imageHash: string;
    refId: string;
    imageCategoryType: ImageCategoryType;
}

export interface UpsertDocDto {
    docRef: any;
    data: any;
    merge: boolean;
}
