import { AppError } from '../common/models/appError';
import Firebase from './firebase';
import { TenantDto } from './dtos/generated/dtos.generated';
import firebase from 'firebase';

const tenantCollectionName = 'tenants';

const options: firebase.firestore.SetOptions = {
    merge: true,
};
export const tenantService = {
    getTenant: async (): Promise<any> => {
        try {
            const doc = await Firebase.firestore.collection(tenantCollectionName).doc(Firebase.tenantId).get();

            if (doc.exists) {
                return { data: { code: '200', data: doc.data() as TenantDto } };
            } else {
                return Promise.reject(new AppError('not-found'));
            }
        } catch (error) {
            return Promise.reject(new AppError(error));
        }
    },

    upsertTenant: async (entity: any): Promise<any> => {
        try {
            await Firebase.firestore.collection(tenantCollectionName).doc(Firebase.tenantId).set(entity, options);
            return {
                data: {
                    code: '200',
                    message: 'successfully-updated',
                },
            };
        } catch (error) {
            return Promise.reject(new AppError(error));
        }
    },
};
