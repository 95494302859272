import { action, computed, observable } from 'mobx';
import * as uuid from 'uuid';
import Validator from 'validatorjs';
import i18n from 'i18next';
import { BaseModel } from '../stores/model/baseModel';
import { mapTrackingFieldsToModel } from '../helpers/trackingFields';
import { validateField, validate } from '../helpers/validationHelper';
import { AddressDto, CountryDto } from '../../api/dtos/generated/dtos.generated';
import firebase from 'firebase';

export class Address extends BaseModel {
    private static customAddressRules = {
        description: 'max:100',
        addition: 'max:100',
        street: 'required|max:100',
        zip: 'required|max:12',
        city: 'required|max:100',
        country: 'required|max:100',
    };

    private static rules = {
        fullAddress: 'required|max:1000',
        description: 'max:100',
    };

    private attributeNames = {
        fullAddress: i18n.t('fullAddress'),
        description: i18n.t('description'),
        addition: i18n.t('addition'),
        street: i18n.t('street'),
        zip: i18n.t('zip'),
        city: i18n.t('city'),
        country: i18n.t('country'),
        isPrimary: i18n.t('isPrimary'),
        isCustomAddress: i18n.t('isCustomAddress'),
    };

    id: string;
    @observable isNew: boolean;
    @observable fullAddress?: string = '';
    @observable description?: string = '';
    @observable addition: string = '';
    @observable street: string = '';
    @observable zip: string = '';
    @observable city: string = '';
    @observable country: string = '';
    @observable countryCode?: string = undefined;
    @observable geopoint?: firebase.firestore.GeoPoint;
    @observable placeId?: string = '';
    @observable isPrimary: boolean = false;
    @observable isCustomAddress?: boolean = false;
    @observable validationErrors: { [index: string]: any } = {};
    @observable isDirty: boolean = false;

    public static createFromDto(dto: AddressDto): Address {
        const address = new Address(dto.id);
        address.isNew = false;
        address.fullAddress = dto.fullAddress ? dto.fullAddress : '';
        address.description = dto.description ? dto.description : '';
        address.addition = dto.addition ? dto.addition : '';
        address.street = dto.street;
        address.zip = dto.zip;
        address.city = dto.city;
        address.country = dto.country ? dto.country : '';
        address.countryCode = dto.countryCode ? dto.countryCode : undefined;
        address.isPrimary = dto.isPrimary;
        address.isCustomAddress = dto.isCustomAddress ? dto.isCustomAddress : undefined;
        address.geopoint = dto.geopoint ? dto.geopoint : undefined;
        address.placeId = dto.placeId ? dto.placeId : '';

        mapTrackingFieldsToModel(address, dto);

        return address;
    }

    private getValidator(data: any, rules: any) {
        const validator = new Validator(data, rules);
        validator.setAttributeNames(this.attributeNames);
        return validator;
    }

    constructor(id: string = uuid.v4()) {
        super();
        this.id = id;
        this.isNew = true;
        this.isPrimary = false;
    }

    updateFromDto(dto: AddressDto): void {
        this.fullAddress = dto.fullAddress ? dto.fullAddress : '';
        this.description = dto.description ? dto.description : '';
        this.addition = dto.addition ? dto.addition : '';
        this.street = dto.street;
        this.zip = dto.zip;
        this.city = dto.city;
        this.country = dto.country ? dto.country : '';
        this.countryCode = dto.countryCode ? dto.countryCode : undefined;
        this.isPrimary = dto.isPrimary;
        this.isCustomAddress = dto.isCustomAddress;
        this.geopoint = dto.geopoint ? dto.geopoint : undefined;
        this.placeId = dto.placeId ? dto.placeId : '';

        mapTrackingFieldsToModel(this, dto);
    }

    @action
    updateProperty(field: string, value: any) {
        if (!this.isDirty) {
            this.isDirty = true;
        }

        (this as any)[field] = value;
        const rules = this.isCustomAddress ? (Address.customAddressRules as any) : (Address.rules as any);
        if (!!rules[field]) {
            const validator = this.getValidator({ [field]: value }, { [field]: rules[field] });
            this.validationErrors = validateField(validator, field, this.validationErrors);
        }
    }

    @action
    updateCountry(country?: CountryDto) {
        this.updateProperty('countryCode', country ? country.Code : undefined);
        this.updateProperty(
            'country',
            country ? (country as any)['Text' + i18n.language.charAt(0).toUpperCase() + i18n.language.slice(1)] : ''
        );
    }

    @action
    updateGeopoint(lat: number, lng: number) {
        this.geopoint = new firebase.firestore.GeoPoint(lat, lng);
    }

    @action
    updatePlaceId(placeId: string) {
        this.placeId = placeId;
    }

    @action
    reset() {
        this.street = '';
        this.addition = '';
        this.zip = '';
        this.city = '';
        this.countryCode = undefined;
        this.country = '';
        this.description = '';
    }

    @action
    validateAll(data: any) {
        const rules = this.isCustomAddress ? Address.customAddressRules : Address.rules;
        const validator = this.getValidator(data, rules);
        this.validationErrors = validate(validator);
    }

    @computed get displayName(): string {
        const addressItems: string[] = [];
        addressItems.push(this.street);
        if (this.addition) {
            addressItems.push(this.addition);
        }
        addressItems.push(this.zip + ' ' + this.city);
        addressItems.push(this.country);

        const textElements = addressItems.filter((x) => x !== undefined && x.length > 0);
        return textElements.length > 0 ? (textElements.join(', ') as string) : '';
    }

    @computed get displayNameExlStreet(): string {
        const addressItems: string[] = [];
        if (this.addition) {
            addressItems.push(this.addition);
        }
        addressItems.push(this.zip + ' ' + this.city);
        addressItems.push(this.country);

        const textElements = addressItems.filter((x) => x !== undefined && x.length > 0);
        return textElements.length > 0 ? (textElements.join(', ') as string) : '';
    }

    @computed get isValid() {
        return Object.keys(this.validationErrors).length === 0;
    }

    toDto(): AddressDto {
        return {
            id: this.id,
            fullAddress: this.fullAddress ? this.fullAddress : '',
            description: this.description ? this.description : '',
            addition: this.addition,
            street: this.street,
            zip: this.zip,
            city: this.city,
            country: this.country ? this.country : '',
            countryCode: this.countryCode ? this.countryCode : null,
            isPrimary: this.isPrimary,
            isCustomAddress: this.isCustomAddress ? this.isCustomAddress : false,
            geopoint: this.geopoint ? this.geopoint : null,
            placeId: this.placeId ? this.placeId : '',
        };
    }
}
