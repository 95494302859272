import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import { createStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            //fontSize: 21
        },
    });

type Props = WithStyles & any;

export const DashboardIcon = withStyles(styles)((props: Props) => (
    <SvgIcon {...props} viewBox={'0 0 24 24'}>
        <path
            style={{ fill: '#58c3f2' }}
            id="Path_275"
            d="M122.06 67.841a.735.735 0 0 0-.71-.19l-5.748 1.54a.735.735 0 0 0-.329 1.229l.561.561-3.755 3.755a.735.735 0 0 0 0 1.039l2.046 2.047a.735.735 0 0 0 1.039 0l2.726-2.722a.735.735 0 0 0-1.039-1.039l-2.2 2.2-1.007-1.007 3.235-3.235 2.608 2.608a.735.735 0 0 0 1.229-.329l1.54-5.748a.735.735 0 0 0-.196-.709zm-2.44 4.848l-2.408-2.408 3.288-.881z"
            data-name="Path 275"
            transform="translate(-106.653 -64.476)"
        />
        <path
            id="Path_276"
            d="M23.117 19.836a.737.737 0 0 0 .735-.739v-6.284a.737.737 0 0 0-.735-.739h-3.633a.737.737 0 0 0-.735.739v9.708h-1.615v-5.3a.737.737 0 0 0-.735-.739h-3.633a.737.737 0 0 0-.735.739v5.3H6.783V20.98h2.9a.739.739 0 0 0 0-1.479H6.048a.737.737 0 0 0-.735.739v2.28H1.469V.739A.735.735 0 1 0 0 .739v22.522A.737.737 0 0 0 .735 24h22.382a.739.739 0 0 0 0-1.479h-2.9v-8.969h2.164V19.1a.737.737 0 0 0 .736.736zM13.5 22.521v-4.56h2.164v4.56H13.5z"
            data-name="Path 276"
        />
    </SvgIcon>
));

export const PlanningIcon = withStyles(styles)((props: Props) => (
    <SvgIcon {...props} viewBox={'0 0 23.4 24'}>
        <g id="plan" transform="translate(-6.485)">
            <g id="Group_44" data-name="Group 44" transform="translate(6.485)">
                <path
                    id="Path_110"
                    d="M196.014 4.244c-1.623 0-2.8.323-2.8-1.3a2.944 2.944 0 0 1 5.888 0c-.002 1.623-1.464 1.3-3.088 1.3zm.145-2.906a1.606 1.606 0 1 0 1.606 1.606 1.608 1.608 0 0 0-1.606-1.606z"
                    data-name="Path 110"
                    transform="translate(-184.463)"
                />
                <path
                    id="Path_111"
                    d="M26.36 90.906H8.162a1.677 1.677 0 0 1-1.676-1.676V71.873A1.677 1.677 0 0 1 8.162 70.2H28.2a1.677 1.677 0 0 1 1.676 1.675V86.42a.669.669 0 0 1-1.338 0V71.873a.338.338 0 0 0-.338-.338H8.162a.338.338 0 0 0-.338.338v17.358a.338.338 0 0 0 .338.338h18.2a.669.669 0 1 1 0 1.338z"
                    data-name="Path 111"
                    transform="translate(-6.486 -66.906)"
                />
            </g>
            <path
                id="Path_113"
                d="M29.208 74.95H7.154a.669.669 0 0 1-.669-.669v-2.408A1.678 1.678 0 0 1 8.161 70.2H28.2a1.678 1.678 0 0 1 1.676 1.676v2.407a.669.669 0 0 1-.668.667zM7.823 73.612h20.716v-1.739a.338.338 0 0 0-.338-.338H8.161a.338.338 0 0 0-.338.338z"
                data-name="Path 113"
                transform="translate(0 -66.906)"
            />
            <g id="Group_45" data-name="Group 45" transform="translate(9.481 9.769)">
                <path
                    style={{ fill: '#58c3f2' }}
                    id="Path_115"
                    d="M72.87 212.679h-.357a2.13 2.13 0 0 1 0-4.26h.357a2.13 2.13 0 0 1 0 4.26zm-.357-2.922a.792.792 0 0 0 0 1.584h.357a.792.792 0 0 0 0-1.584z"
                    data-name="Path 115"
                    transform="translate(-70.383 -208.419)"
                />
                <path
                    style={{ fill: '#58c3f2' }}
                    id="Path_116"
                    d="M210.718 212.678a.669.669 0 0 1-.669-.669v-2.253h-1.94v2.253a.669.669 0 1 1-1.338 0v-2.922a.669.669 0 0 1 .669-.669h3.279a.669.669 0 0 1 .669.669v2.922a.669.669 0 0 1-.67.669z"
                    data-name="Path 116"
                    transform="translate(-200.375 -208.418)"
                />
            </g>
            <path
                style={{ fill: '#58c3f2' }}
                id="Path_117"
                d="M347.108 212.678h-3.279a.669.669 0 0 1-.669-.669v-2.922a.669.669 0 0 1 .669-.669h3.279a.669.669 0 0 1 .669.669v2.922a.669.669 0 0 1-.669.669zm-2.608-1.338h1.94v-1.584h-1.94z"
                data-name="Path 117"
                transform="translate(-320.896 -198.649)"
            />
            <path
                style={{ fill: '#58c3f2' }}
                id="Path_118"
                d="M74.329 343.957H71.05a.669.669 0 0 1-.669-.669v-2.922a.669.669 0 0 1 .669-.669h3.279a.669.669 0 0 1 .669.669v2.922a.669.669 0 0 1-.669.669zm-2.609-1.338h1.94v-1.584h-1.94z"
                data-name="Path 118"
                transform="translate(-60.9 -323.775)"
            />
            <g id="Group_46" data-name="Group 46" transform="translate(15.869 15.922)">
                <path
                    style={{ fill: '#58c3f2' }}
                    id="Path_120"
                    d="M209.259 343.958h-.359a2.13 2.13 0 0 1 0-4.26h.357a2.13 2.13 0 0 1 0 4.26zm-.357-2.922a.792.792 0 0 0 0 1.584h.357a.792.792 0 0 0 0-1.584z"
                    data-name="Path 120"
                    transform="translate(-206.772 -339.698)"
                />
                <path
                    style={{ fill: '#58c3f2' }}
                    id="Path_121"
                    d="M347.106 343.957a.669.669 0 0 1-.669-.669v-2.253H344.5v2.253a.669.669 0 1 1-1.338 0v-2.922a.669.669 0 0 1 .669-.669h3.279a.669.669 0 0 1 .669.669v2.922a.669.669 0 0 1-.673.669z"
                    data-name="Path 121"
                    transform="translate(-336.763 -339.697)"
                />
            </g>
        </g>
    </SvgIcon>
));

// .cls-1{fill:#cee8fa}.cls-2{fill:#58c3f2}.cls-3{fill:#398bf7}

export const ProjectsIcon = withStyles(styles)((props: Props) => (
    <SvgIcon {...props} viewBox={'0 0 27.65 24'}>
        <g id="edit_1_" data-name="edit (1)" transform="translate(2 -16.501)">
            <g id="Group_53" data-name="Group 53" transform="translate(3.739 25.952)">
                <path
                    id="Path_135"
                    d="M103.254 87.988H93.235a.831.831 0 0 1-.887-.762v-3.1a.831.831 0 0 1 .887-.762h10.019a.831.831 0 0 1 .887.762v3.1a.831.831 0 0 1-.887.762zm-9.133-1.523h8.246v-1.574h-8.246z"
                    style={{ fill: '#58c3f2' }}
                    data-name="Path 135"
                    transform="translate(-92.348 -83.238)"
                />
            </g>
            <path
                id="Path_134"
                d="M17.138 40.5H2.233A2.236 2.236 0 0 1 0 38.268V18.734A2.236 2.236 0 0 1 2.233 16.5h14.905a2.235 2.235 0 0 1 2.233 2.233v6.444a.761.761 0 1 1-1.522 0v-6.443a.712.712 0 0 0-.711-.711H2.233a.712.712 0 0 0-.711.711v19.534a.712.712 0 0 0 .711.711h14.905a.712.712 0 0 0 .711-.711V35.73a.761.761 0 1 1 1.522 0v2.537a2.236 2.236 0 0 1-2.233 2.233z"
                data-name="Path 134"
            />
            <g id="Group_50" data-name="Group 50" transform="translate(19.538 21.448)">
                <path
                    id="Path_136"
                    d="M395.38 99.327a.759.759 0 0 1-.538-.223l-1.97-1.97-1.546 1.546a.762.762 0 1 1-1.077-1.077l2.084-2.084a.762.762 0 0 1 1.077 0l2.509 2.509a.761.761 0 0 1-.539 1.3z"
                    data-name="Path 136"
                    transform="translate(-390.026 -95.296)"
                />
            </g>
            <g id="Group_52" data-name="Group 52" transform="translate(3.739 19.854)">
                <path
                    id="Path_135-2"
                    d="M103.254 87.988H93.235a.831.831 0 0 1-.887-.762v-3.1a.831.831 0 0 1 .887-.762h10.019a.831.831 0 0 1 .887.762v3.1a.831.831 0 0 1-.887.762zm-9.133-1.523h8.246v-1.574h-8.246z"
                    style={{ fill: '#58c3f2' }}
                    data-name="Path 135"
                    transform="translate(-92.348 -83.368)"
                />
            </g>
            <g id="Group_51" data-name="Group 51" transform="translate(4.612 23.525)">
                <path
                    id="Path_139"
                    d="M98.035 387.852H93.11a.762.762 0 1 1 0-1.523h4.926a.762.762 0 0 1 0 1.523z"
                    style={{ fill: '#58c3f2' }}
                    data-name="Path 139"
                    transform="translate(-92.348 -373.82)"
                />
                <path
                    id="Path_138"
                    d="M191.351 150.892a.761.761 0 0 1-.754-.865l.4-2.909a.761.761 0 0 1 .216-.435l9.6-9.6a.762.762 0 0 1 1.077 0l2.509 2.509a.761.761 0 0 1 0 1.077l-9.6 9.6a.762.762 0 0 1-.435.216l-2.909.4a.773.773 0 0 1-.104.007zm1.12-3.313l-.229 1.66 1.66-.229 8.88-8.88-1.432-1.432z"
                    data-name="Path 138"
                    transform="translate(-185.664 -136.86)"
                />
            </g>
        </g>
    </SvgIcon>
));

export const ProjectsIconDefault = withStyles(styles)((props: Props) => (
    <SvgIcon {...props} viewBox={'0 0 27.65 24'}>
        <g id="edit_1_" data-name="edit (1)" transform="translate(2 -16.501)">
            <g id="Group_53" data-name="Group 53" transform="translate(3.739 25.952)">
                <path
                    id="Path_135"
                    d="M103.254 87.988H93.235a.831.831 0 0 1-.887-.762v-3.1a.831.831 0 0 1 .887-.762h10.019a.831.831 0 0 1 .887.762v3.1a.831.831 0 0 1-.887.762zm-9.133-1.523h8.246v-1.574h-8.246z"
                    data-name="Path 135"
                    transform="translate(-92.348 -83.238)"
                />
            </g>
            <path
                id="Path_134"
                d="M17.138 40.5H2.233A2.236 2.236 0 0 1 0 38.268V18.734A2.236 2.236 0 0 1 2.233 16.5h14.905a2.235 2.235 0 0 1 2.233 2.233v6.444a.761.761 0 1 1-1.522 0v-6.443a.712.712 0 0 0-.711-.711H2.233a.712.712 0 0 0-.711.711v19.534a.712.712 0 0 0 .711.711h14.905a.712.712 0 0 0 .711-.711V35.73a.761.761 0 1 1 1.522 0v2.537a2.236 2.236 0 0 1-2.233 2.233z"
                data-name="Path 134"
            />
            <g id="Group_50" data-name="Group 50" transform="translate(19.538 21.448)">
                <path
                    id="Path_136"
                    d="M395.38 99.327a.759.759 0 0 1-.538-.223l-1.97-1.97-1.546 1.546a.762.762 0 1 1-1.077-1.077l2.084-2.084a.762.762 0 0 1 1.077 0l2.509 2.509a.761.761 0 0 1-.539 1.3z"
                    data-name="Path 136"
                    transform="translate(-390.026 -95.296)"
                />
            </g>
            <g id="Group_52" data-name="Group 52" transform="translate(3.739 19.854)">
                <path
                    id="Path_135-2"
                    d="M103.254 87.988H93.235a.831.831 0 0 1-.887-.762v-3.1a.831.831 0 0 1 .887-.762h10.019a.831.831 0 0 1 .887.762v3.1a.831.831 0 0 1-.887.762zm-9.133-1.523h8.246v-1.574h-8.246z"
                    data-name="Path 135"
                    transform="translate(-92.348 -83.368)"
                />
            </g>
            <g id="Group_51" data-name="Group 51" transform="translate(4.612 23.525)">
                <path
                    id="Path_139"
                    d="M98.035 387.852H93.11a.762.762 0 1 1 0-1.523h4.926a.762.762 0 0 1 0 1.523z"
                    data-name="Path 139"
                    transform="translate(-92.348 -373.82)"
                />
                <path
                    id="Path_138"
                    d="M191.351 150.892a.761.761 0 0 1-.754-.865l.4-2.909a.761.761 0 0 1 .216-.435l9.6-9.6a.762.762 0 0 1 1.077 0l2.509 2.509a.761.761 0 0 1 0 1.077l-9.6 9.6a.762.762 0 0 1-.435.216l-2.909.4a.773.773 0 0 1-.104.007zm1.12-3.313l-.229 1.66 1.66-.229 8.88-8.88-1.432-1.432z"
                    data-name="Path 138"
                    transform="translate(-185.664 -136.86)"
                />
            </g>
        </g>
    </SvgIcon>
));

export const TasksIcon = withStyles(styles)((props: Props) => (
    <SvgIcon {...props} viewBox={'0 0 20 24'}>
        <g id="list" transform="translate(-46.914)">
            <g id="Group_40" data-name="Group 40" transform="translate(46.914)">
                <path
                    id="Path_96"
                    d="M51.6 5.255h-3.995a.691.691 0 0 1-.481-1.187L51.118.195a.691.691 0 0 1 1.172.5v3.869a.691.691 0 0 1-.69.691zm-2.29-1.382h1.6V2.324z"
                    data-name="Path 96"
                    transform="translate(-46.914)"
                />
                <path
                    id="Path_97"
                    d="M212.541 14.96a.691.691 0 0 1-.691-.691V1.69a.324.324 0 0 0-.338-.307h-9.942a.691.691 0 0 1 0-1.382h9.941a1.707 1.707 0 0 1 1.72 1.689v12.579a.691.691 0 0 1-.69.691z"
                    data-name="Path 97"
                    transform="translate(-193.63 -.001)"
                />
                <path
                    id="Path_98"
                    d="M64.793 24H48.641a1.711 1.711 0 0 1-1.725-1.694V4.577a.693.693 0 0 1 .21-.5L51.13.2a.693.693 0 1 1 .965.994L48.3 4.87v17.437a.325.325 0 0 0 .339.308h16.154a.325.325 0 0 0 .339-.308v-4.344a.693.693 0 1 1 1.386 0v4.344A1.711 1.711 0 0 1 64.793 24z"
                    data-name="Path 98"
                    transform="translate(-46.916)"
                />
                <path
                    id="Path_99"
                    d="M212.54 5.257h-10.971a.691.691 0 0 1 0-1.382h10.28v-2.01a.482.482 0 0 0-.481-.481h-9.8a.691.691 0 1 1 0-1.382h9.8a1.865 1.865 0 0 1 1.863 1.863v2.7a.691.691 0 0 1-.691.692z"
                    data-name="Path 99"
                    transform="translate(-193.629 -.002)"
                />
            </g>
            <g id="Group_41" data-name="Group 41" transform="translate(49.636 8.204)">
                <path
                    id="Path_100"
                    d="M111.616 179.206h-5.8a.691.691 0 0 1-.691-.691v-3.04a.691.691 0 0 1 .691-.691h5.8a.691.691 0 0 1 .691.691v3.04a.691.691 0 0 1-.691.691zm-5.113-1.382h4.422v-1.658H106.5z"
                    style={{ fill: '#58c3f2' }}
                    data-name="Path 100"
                    transform="translate(-105.121 -174.784)"
                />
                <path
                    id="Path_101"
                    d="M299.043 183.1a.691.691 0 0 1-.489-.2l-1.105-1.106a.691.691 0 1 1 .977-.977l.617.617 2.15-2.15a.691.691 0 0 1 .977.977l-2.638 2.638a.691.691 0 0 1-.489.201z"
                    style={{ fill: '#58c3f2' }}
                    data-name="Path 101"
                    transform="translate(-288.213 -178.88)"
                />
            </g>
            <g id="Group_42" data-name="Group 42" transform="translate(49.636 15.542)">
                <path
                    id="Path_102"
                    d="M111.616 335.794h-5.8a.691.691 0 0 1-.691-.691v-3.04a.691.691 0 0 1 .691-.691h5.8a.691.691 0 0 1 .691.691v3.04a.691.691 0 0 1-.691.691zm-5.113-1.382h4.422v-1.658H106.5z"
                    style={{ fill: '#58c3f2' }}
                    data-name="Path 102"
                    transform="translate(-105.121 -331.372)"
                />
                <path
                    id="Path_103"
                    d="M299.043 339.69a.691.691 0 0 1-.489-.2l-1.105-1.106a.691.691 0 0 1 .977-.977l.617.617 2.15-2.15a.691.691 0 0 1 .977.977l-2.638 2.638a.692.692 0 0 1-.489.201z"
                    style={{ fill: '#58c3f2' }}
                    data-name="Path 103"
                    transform="translate(-288.213 -335.469)"
                />
            </g>
        </g>
    </SvgIcon>
));

export const MenuIcon = withStyles(styles)((props: Props) => (
    <SvgIcon {...props} viewBox={'0 0 30 24'}>
        <g id="menu" transform="translate(0 -98.622)">
            <path
                id="Path_299"
                d="M29.287 336.182H.832a.833.833 0 0 1-.832-.834v-3.167a.833.833 0 0 1 .832-.834h20.135a.834.834 0 0 1 0 1.667H1.664v1.5h26.791v-1.5h-2.5a.834.834 0 0 1 0-1.667h3.328a.833.833 0 0 1 .832.834v3.167a.833.833 0 0 1-.828.834z"
                data-name="Path 299"
                transform="translate(0 -213.961)"
            />
            <path
                id="Path_300"
                d="M29.287 218.271H.832a.833.833 0 0 1-.832-.834v-3.167a.833.833 0 0 1 .832-.834h28.456a.833.833 0 0 1 .832.834v3.167a.833.833 0 0 1-.833.834zM1.664 216.6h26.791v-1.5H1.664z"
                data-name="Path 300"
                transform="translate(0 -105.557)"
            />
            <path
                id="Path_301"
                d="M29.287 103.457H.832a.833.833 0 0 1-.832-.834v-3.167a.833.833 0 0 1 .832-.834h28.456a.833.833 0 0 1 .832.834v3.167a.833.833 0 0 1-.833.834zm-27.623-1.668h26.791v-1.5H1.664z"
                data-name="Path 301"
            />
        </g>
    </SvgIcon>
));

export const RepeatIcon = withStyles(styles)((props: Props) => (
    <SvgIcon {...props} viewBox={'0 0 30 24'}>
        <g id="repeat" transform="translate(0 -15.136)">
            <g id="Group_85" data-name="Group 85" transform="translate(0 18.257)">
                <path
                    id="Path_308"
                    d="M.6 88.993a.672.672 0 0 1-.6-.725V83.5c0-3.228 2.181-5.854 4.862-5.854H17.7a.737.737 0 0 1 0 1.45H4.862C2.845 79.1 1.2 81.076 1.2 83.5v4.764a.672.672 0 0 1-.6.729z"
                    data-name="Path 308"
                    transform="translate(0 -77.65)"
                />
                <path
                    id="Path_309"
                    d="M64.138 147.067a.725.725 0 0 1-.725-.725v-2.589a2.724 2.724 0 0 1 2.688-2.688h8.216a.725.725 0 0 1 0 1.45H66.1a1.27 1.27 0 0 0-1.238 1.238v2.589a.725.725 0 0 1-.724.725z"
                    data-name="Path 309"
                    transform="translate(-60.247 -137.899)"
                />
            </g>
            <g id="Group_86" data-name="Group 86" transform="translate(7.07 15.136)">
                <path
                    id="Path_311"
                    d="M347.832 26.093a.725.725 0 0 1-.725-.725v-9.507a.725.725 0 0 1 1.141-.594l6.783 4.753a.725.725 0 0 1 0 1.187l-6.783 4.753a.724.724 0 0 1-.416.133zm.725-8.838v6.72l4.795-3.36z"
                    data-name="Path 311"
                    transform="translate(-336.902 -15.136)"
                />
                <path
                    id="Path_312"
                    d="M84.162 218.5H71.229a.736.736 0 0 1 0-1.45h12.933c2.032 0 3.685-1.976 3.685-4.4v-4.764a.616.616 0 1 1 1.213 0v4.764c0 3.227-2.197 5.85-4.898 5.85z"
                    data-name="Path 312"
                    transform="translate(-70.622 -197.624)"
                />
                <path
                    id="Path_313"
                    d="M224.618 256.723H216.4a.725.725 0 0 1 0-1.45h8.216a1.27 1.27 0 0 0 1.238-1.238v-2.589a.725.725 0 1 1 1.45 0v2.589a2.724 2.724 0 0 1-2.686 2.688z"
                    data-name="Path 313"
                    transform="translate(-212.034 -238.999)"
                />
            </g>
            <path
                id="Path_315"
                d="M7.508 288.378a.724.724 0 0 1-.416-.131l-6.783-4.754a.725.725 0 0 1 0-1.187l6.783-4.753a.725.725 0 0 1 1.141.594v9.507a.725.725 0 0 1-.725.725zm-5.52-5.478l4.795 3.36v-6.72z"
                data-name="Path 315"
                transform="translate(0 -249.242)"
            />
        </g>
    </SvgIcon>
));

export const ChevronRight = withStyles(styles)((props: Props) => (
    <SvgIcon {...props}>
        <g transform="translate(-12.439 -1.293)">
            <path d="M13.155 25.293l12-12-12-12-.716.716 11.284 11.284-11.284 11.284z" data-name="Path 484" />
        </g>
    </SvgIcon>
));

export const ChevronLeft = withStyles(styles)((props: Props) => (
    <SvgIcon {...props}>
        <g transform="translate(-12.439 -1.293)">
            <path d="M25.155 24.577L13.87 13.293 25.155 2.009l-.716-.716-12 12 12 12z" data-name="Path 521" />
        </g>
    </SvgIcon>
));

// .cls-1{fill:#cee8fa}.cls-2{fill:#398bf7}

export const EditIcon = withStyles(styles)((props: Props) => (
    <SvgIcon {...props} viewBox={'0 0 27 27'}>
        <g id="writing" transform="translate(67.978 -114)">
            <path
                id="Path_193"
                d="M22.707 1.315a4.49 4.49 0 0 0-6.349 0L13 4.669 1.351 16.321l-.465.465a.721.721 0 0 0-.207.431L.026 23.2a.721.721 0 0 0 .717.8.732.732 0 0 0 .078 0l5.979-.658a.721.721 0 0 0 .431-.207l.465-.465 8.084-8.084a.721.721 0 0 0-1.019-1.019l-7.1 7.1-4.306-4.31L13.513 6.2l4.82 4.82a.721.721 0 0 0 1.019 0l3.354-3.354a4.5 4.5 0 0 0 .001-6.351zm-16.29 20.6l-4.861.55.529-4.839.021-.021zm15.27-15.27l-2.844 2.844-4.31-4.31c3.268-3.37 3.051-3.022 2.844-2.845a3.048 3.048 0 0 1 4.311 4.311z"
                data-name="Path 193"
                transform="translate(-68 114)"
            />
        </g>
    </SvgIcon>
));

export const MailIcon = withStyles(styles)((props: Props) => (
    <SvgIcon {...props} viewBox={'0 0 30 26'}>
        <g id="mail" transform="translate(0 -58.14)">
            <path
                id="Path_215"
                d="M28.105 81.14H3.1a3.1 3.1 0 0 1-3.1-3.1V61.237a3.1 3.1 0 0 1 3.1-3.1h23.562a3.1 3.1 0 0 1 3.1 3.1v12.95a.827.827 0 1 1-1.653 0v-12.95a1.445 1.445 0 0 0-1.443-1.443H3.1a1.445 1.445 0 0 0-1.443 1.443v16.806A1.445 1.445 0 0 0 3.1 79.487h25.005a.827.827 0 0 1 0 1.653z"
                data-name="Path 215"
            />
            <g id="Group_71" data-name="Group 71" transform="translate(0 58.14)">
                <path
                    id="Path_217"
                    d="M14.879 72.859a.82.82 0 0 1-.5-.169L.328 61.935A.838.838 0 0 1 0 61.269a3.116 3.116 0 0 1 3.1-3.129h23.562a3.116 3.116 0 0 1 3.1 3.129.838.838 0 0 1-.328.666L15.378 72.689a.82.82 0 0 1-.499.17zM1.7 60.891l13.179 10.085 13.177-10.085a1.448 1.448 0 0 0-1.394-1.081H3.1a1.449 1.449 0 0 0-1.4 1.081z"
                    data-name="Path 217"
                    transform="translate(0 -58.14)"
                />
                <path
                    id="Path_218"
                    d="M376.457 355.909a.831.831 0 0 1-.5-.17l-5.847-4.428a.835.835 0 1 1 1.009-1.332l5.847 4.428a.835.835 0 0 1-.5 1.5z"
                    data-name="Path 218"
                    transform="translate(-348.369 -332.921)"
                />
                <path
                    id="Path_219"
                    d="M21.127 355.642a.835.835 0 0 1-.5-1.5l5.49-4.158a.835.835 0 1 1 1.009 1.332l-5.49 4.158a.832.832 0 0 1-.509.168z"
                    data-name="Path 219"
                    transform="translate(-19.099 -332.923)"
                />
            </g>
        </g>
    </SvgIcon>
));

export const DeleteIcon = withStyles(styles)((props: Props) => (
    <SvgIcon {...props} viewBox={'0 0 26 26'}>
        <g id="delete" transform="translate(-47)">
            <path
                id="Path_212"
                d="M92.856 169.224H80.729a.7.7 0 0 1-.718-.6l-1.444-13.84a.624.624 0 0 1 .185-.506.754.754 0 0 1 .534-.216H94.3a.662.662 0 1 1 0 1.318H80.079l1.306 12.522H92.2l.925-8.867a.707.707 0 0 1 .787-.594.678.678 0 0 1 .65.719l-.987 9.464a.7.7 0 0 1-.719.6z"
                data-name="Path 212"
                transform="translate(-27.21 -145.224)"
            />
            <g id="Group_70" data-name="Group 70" transform="translate(49.923)">
                <path
                    id="Path_213"
                    d="M68.513 97.6h-17.86a.755.755 0 0 1-.73-.778v-4.23a.755.755 0 0 1 .73-.778h17.86a.755.755 0 0 1 .73.778v4.229a.755.755 0 0 1-.73.779zm-17.131-1.557h16.4v-2.674h-16.4z"
                    data-name="Path 213"
                    transform="translate(-49.923 -87.226)"
                />
                <path
                    id="Path_214"
                    d="M173.98 3.188a.778.778 0 0 1-.778-.778v-.855h-5.91v.855a.778.778 0 1 1-1.555 0V.778a.778.778 0 0 1 .777-.778h7.466a.778.778 0 0 1 .778.778v1.633a.778.778 0 0 1-.778.777z"
                    data-name="Path 214"
                    transform="translate(-160.587)"
                />
            </g>
        </g>
    </SvgIcon>
));

export const PhoneIcon = withStyles(styles)((props: Props) => (
    <SvgIcon {...props} viewBox={'0 0 26 26'}>
        <g id="phone-call" transform="translate(87.814 38.955)">
            <g id="Group_74" data-name="Group 74" transform="translate(-370.074 -321.199)">
                <path
                    id="Path_228"
                    d="M18.634 24.159c-1.389 0-6.378-.458-12.273-6.351C1.4 12.848-.417 9.146.079 5.031A6.71 6.71 0 0 1 .49 3.377v-.008a6.617 6.617 0 0 1 .933-1.644.785.785 0 0 1 .061-.069L2 1.139a3.285 3.285 0 0 1 4.664-.018l1.902 1.907a3.285 3.285 0 0 1 0 4.645L8.44 7.8l-.018.017-.768.722a25.434 25.434 0 0 0 3.478 4.5 25.324 25.324 0 0 0 4.568 3.513l.873-.873a3.285 3.285 0 0 1 4.645 0l1.835 1.835a3.285 3.285 0 0 1-.02 4.665l-.517.508a.8.8 0 0 1-.067.059 6.563 6.563 0 0 1-1.644.937 7.007 7.007 0 0 1-1.7.454 4.367 4.367 0 0 1-.471.022zM2 3.954a5.114 5.114 0 0 0-.315 1.258v.008C1.254 8.783 2.94 12.1 7.5 16.665c5.455 5.453 9.9 5.878 11.129 5.878.191 0 .291-.011.292-.011a5.436 5.436 0 0 0 1.293-.355 4.922 4.922 0 0 0 1.2-.675l.483-.475a1.668 1.668 0 0 0 .01-2.369l-1.835-1.835a1.668 1.668 0 0 0-2.359 0l-1.3 1.3a.808.808 0 0 1-.958.138l-.13-.069c-.076-.04-.154-.081-.232-.125a26.445 26.445 0 0 1-5.106-3.882A26.924 26.924 0 0 1 6.1 9.075a2.715 2.715 0 0 1-.108-.211c-.015-.031-.029-.062-.049-.1a.808.808 0 0 1 .154-.98l1.209-1.136.117-.118a1.668 1.668 0 0 0 0-2.359L5.516 2.264a1.668 1.668 0 0 0-2.368.009l-.475.482A4.99 4.99 0 0 0 2 3.954z"
                    data-name="Path 228"
                    transform="translate(282.26 282.085)"
                />
            </g>
        </g>
    </SvgIcon>
));

export const LocationIcon = withStyles(styles)((props: Props) => (
    <SvgIcon {...props} viewBox={'0 0 17.2 24'}>
        <g id="placeholder" transform="translate(-72.296)">
            <path
                id="Path_316"
                d="M147.916 76.589a5.311 5.311 0 1 1 5.319-5.311 5.321 5.321 0 0 1-5.319 5.311zm0-9.2a3.885 3.885 0 1 0 3.891 3.885 3.892 3.892 0 0 0-3.891-3.881z"
                data-name="Path 316"
                transform="translate(-67.008 -62.878)"
            />
            <g id="Group_87" data-name="Group 87" transform="translate(72.296)">
                <path
                    id="Path_318"
                    d="M89.482 109.431l-1.582-4.754a.714.714 0 0 0-.677-.488h-3.412a.713.713 0 0 0-.522.227L80.882 107c-1.677-1.678-6.928-7.324-6.928-12.25a6.885 6.885 0 0 1 .728-3.1.714.714 0 1 0-1.282-.64 8.3 8.3 0 0 0-.878 3.738c0 3.327 2.009 6.807 4 9.441H74.6a.714.714 0 0 0-.677.488l-1.586 4.755a.714.714 0 0 0 .677.94H88.8a.714.714 0 0 0 .677-.94zM74 108.944l1.11-3.327h2.56a34.247 34.247 0 0 0 2.755 2.927l.054.045.021.014.037.025.027.015.033.018.03.013.032.013.032.011.032.01.032.007.033.007h.232l.034-.006.031-.007.033-.009.031-.01.032-.012.031-.013.03-.015.031-.017.028-.018.03-.02.027-.021.027-.022.006-.005a34.18 34.18 0 0 0 2.755-2.928H86.7l1.11 3.327H74z"
                    data-name="Path 318"
                    transform="translate(-72.296 -86.371)"
                />
                <path
                    id="Path_319"
                    d="M145.19 2.77a6.948 6.948 0 0 1 11.055 5.6c0 2.09-.931 4.532-2.769 7.258a.713.713 0 1 0 1.182.8c2-2.966 3.012-5.676 3.012-8.055a8.373 8.373 0 0 0-13.325-6.752.713.713 0 1 0 .844 1.149z"
                    data-name="Path 319"
                    transform="translate(-140.679)"
                />
            </g>
        </g>
    </SvgIcon>
));

export const CreateNewIcon = withStyles(styles)((props: Props) => (
    <SvgIcon {...props} viewBox={'0 0 22.8 24'}>
        <g id="file_1_" data-name="file (1)" transform="translate(-13.132)">
            <g id="Group_88" data-name="Group 88" transform="translate(13.132)">
                <path
                    id="Path_321"
                    d="M34.041 219.633a.695.695 0 0 0-1.015.949A5.514 5.514 0 1 1 29 218.834a.695.695 0 1 0 0-1.389 6.9 6.9 0 0 0-6.6 8.914h-7.878v-7.849a.7.7 0 0 0-1.39 0v8.543a.7.7 0 0 0 .7.695h9.167a6.9 6.9 0 1 0 11.047-8.115z"
                    data-name="Path 321"
                    transform="translate(-13.132 -207.244)"
                />
                <path
                    id="Path_322"
                    d="M31.007 0H20.543a.688.688 0 0 0-.088.011.686.686 0 0 0-.084.023l-.022.008a.7.7 0 0 0-.079.035.7.7 0 0 0-.076.048l-.019.014a.679.679 0 0 0-.07.061l-6.76 6.826a.683.683 0 0 0-.046.052l-.028.038-.012.017-.028.048-.006.01-.024.051v.011c-.007.016-.012.033-.018.049l-.005.015c0 .015-.008.031-.012.046s0 .013 0 .02 0 .029-.006.043 0 .016 0 .024v.255a.7.7 0 0 0 .695.695h6.76a.7.7 0 0 0 .695-.695V1.389h9.027V8.1a.695.695 0 1 0 1.389 0V.695A.7.7 0 0 0 31.007 0zm-15.7 7.016l4.268-4.309.321-.324v4.633z"
                    data-name="Path 322"
                    transform="translate(-13.137)"
                />
            </g>
            <path
                id="Path_324"
                d="M261.535 276.582h-2.163a.7.7 0 0 1-.695-.695v-2.009h-2.009a.7.7 0 0 1-.695-.695v-2.163a.7.7 0 0 1 .695-.695h2.009v-2.009a.7.7 0 0 1 .695-.695h2.163a.7.7 0 0 1 .695.695v2.009h2.009a.7.7 0 0 1 .695.695v2.163a.7.7 0 0 1-.695.695h-2.009v2.009a.7.7 0 0 1-.695.695zm-1.468-1.389h.774v-2.009a.7.7 0 0 1 .695-.695h2.009v-.774h-2.009a.7.7 0 0 1-.695-.695v-2.009h-.774v2.009a.7.7 0 0 1-.695.695h-2.009v.774h2.009a.7.7 0 0 1 .695.695z"
                data-name="Path 324"
                transform="translate(-231.452 -255.071)"
            />
        </g>
    </SvgIcon>
));

export const LogoutIcon = withStyles(styles)((props: Props) => (
    <SvgIcon {...props} viewBox={'0 0 26 26'}>
        <g id="logout" transform="translate(0 -17.005)">
            <g id="Group_79" data-name="Group 79" transform="translate(0 17.005)">
                <path
                    id="Path_278"
                    d="M14.583 157.537h-5.6v-1.551a.737.737 0 0 0-1.106-.638l-7.508 4.333a.737.737 0 0 0 0 1.277l7.507 4.334a.737.737 0 0 0 1.106-.638V163.1h5.6a.737.737 0 0 0 .737-.737v-4.091a.737.737 0 0 0-.736-.735zm-7.076 5.84l-5.3-3.057 5.3-3.057v6.115zm6.339-1.749H8.981v-2.617h4.865v2.617z"
                    data-name="Path 278"
                    transform="translate(0 -148.319)"
                />
                <path
                    id="Path_279"
                    d="M57.271 41H34.745a.735.735 0 0 1-.735-.735v-5.117a.735.735 0 1 1 1.47 0v4.386h21.055V18.476H35.48v4.386a.735.735 0 1 1-1.47 0V17.74a.735.735 0 0 1 .735-.74h22.526a.735.735 0 0 1 .735.735V40.27a.735.735 0 0 1-.735.73z"
                    data-name="Path 279"
                    transform="translate(-32.298 -17.005)"
                />
            </g>
        </g>
    </SvgIcon>
));

export const FullscreenIcon = withStyles(styles)((props: Props) => (
    <SvgIcon {...props} viewBox={'0 0 26 26'}>
        <g id="fullscreen" transform="translate(-2.269)">
            <g id="Group_83" data-name="Group 83" transform="translate(2.269)">
                <path
                    id="Path_293"
                    d="M25.323 125H8.651a.734.734 0 0 0-.734.733V135.8H3.738v-2.777a.734.734 0 0 0-1.468 0v3.51a.734.734 0 0 0 .734.733h4.913v5.12a.734.734 0 0 0 .734.733h16.672a.734.734 0 0 0 .734-.733v-16.651a.734.734 0 0 0-.734-.735zm-.734 16.656H9.385v-15.187h15.2v15.189z"
                    data-name="Path 293"
                    transform="translate(-2.27 -119.124)"
                />
                <path
                    id="Path_294"
                    d="M3.005 9.124a.736.736 0 0 0 .736-.736V1.473h15.252v1.742a.736.736 0 1 0 1.473 0V.736A.736.736 0 0 0 19.73 0H3.005a.736.736 0 0 0-.736.736v7.651a.736.736 0 0 0 .736.737z"
                    data-name="Path 294"
                    transform="translate(-2.269)"
                />
                <path
                    id="Path_295"
                    d="M302.886 215.475a.734.734 0 0 0 .521-.216l3.049-3.049v1.71a.736.736 0 0 0 1.473 0v-3.487a.736.736 0 0 0-.736-.736H303.7a.736.736 0 0 0 0 1.473h1.71l-3.049 3.049a.736.736 0 0 0 .521 1.257z"
                    data-name="Path 295"
                    transform="translate(-288.107 -199.877)"
                />
                <path
                    id="Path_296"
                    d="M208.161 326.783h3.487a.736.736 0 1 0 0-1.473h-1.71l1.006-1.006a.736.736 0 1 0-1.041-1.041l-1.006 1.006v-1.71a.736.736 0 0 0-1.473 0v3.487a.736.736 0 0 0 .737.737z"
                    data-name="Path 296"
                    transform="translate(-197.816 -306.75)"
                />
            </g>
        </g>
    </SvgIcon>
));

export const FullscreenExitIcon = withStyles(styles)((props: Props) => (
    <SvgIcon {...props} viewBox={'0 0 26 26'}>
        <path
            id="Path_293"
            d="M3 143.124h16.677a.734.734 0 0 0 .734-.733v-10.069h4.179v2.778a.734.734 0 0 0 1.468 0v-3.51a.734.734 0 0 0-.734-.733h-4.913v-5.12a.734.734 0 0 0-.734-.733H3a.734.734 0 0 0-.734.733v16.656a.734.734 0 0 0 .734.731zm.734-16.656h15.2v15.189H3.738v-15.188z"
            data-name="Path 293"
            transform="translate(-2.27 -125.002)"
        />
        <path
            id="Path_294"
            d="M19.73 0a.736.736 0 0 0-.736.736v6.915H3.742V5.909a.736.736 0 1 0-1.473 0v2.478a.736.736 0 0 0 .736.736H19.73a.736.736 0 0 0 .736-.736V.736A.736.736 0 0 0 19.73 0z"
            data-name="Path 294"
            transform="translate(3.321 14.876)"
        />
        <path
            id="Path_297"
            d="M302.886 215.475a.734.734 0 0 0 .521-.216l3.049-3.049v1.71a.736.736 0 0 0 1.473 0v-3.487a.736.736 0 0 0-.736-.736H303.7a.736.736 0 0 0 0 1.473h1.71l-3.049 3.049a.736.736 0 0 0 .521 1.257z"
            data-name="Path 297"
            transform="translate(-298.311 -200.877)"
        />
        <path
            id="Path_298"
            d="M208.161 326.783h3.487a.736.736 0 1 0 0-1.473h-1.71l1.006-1.006a.736.736 0 1 0-1.041-1.041l-1.006 1.006v-1.71a.736.736 0 0 0-1.473 0v3.487a.736.736 0 0 0 .737.737z"
            data-name="Path 298"
            transform="translate(-197.02 -318.75)"
        />
    </SvgIcon>
));

export const AddLoginIcon = withStyles(styles)((props: Props) => (
    <SvgIcon {...props} viewBox={'0 0 30 24'}>
        <g id="hotel-key" transform="translate(-3.757 -24.812)">
            <g id="Group_76" data-name="Group 76" transform="translate(3.757 24.812)">
                <path
                    id="Path_239"
                    d="M80.939 249.526a7.99 7.99 0 0 0-7.981 7.981.964.964 0 0 0 1.929 0 6.052 6.052 0 1 1 6.052 6.052 6 6 0 0 1-4.388-1.885.964.964 0 0 0-1.4 1.328 7.91 7.91 0 0 0 5.787 2.485 7.981 7.981 0 0 0 0-15.961z"
                    data-name="Path 239"
                    transform="translate(-72.958 -249.526)"
                />
                <path
                    id="Path_240"
                    d="M288.034 326.116h-5.885a.964.964 0 1 0 0 1.929h5.885a.964.964 0 0 0 0-1.929z"
                    data-name="Path 240"
                    transform="translate(-267.177 -317.226)"
                />
                <path
                    id="Path_241"
                    d="M297.519 264.514h-11.962a.964.964 0 1 0 0 1.929h11.961a.224.224 0 0 1 .223.223v5.474a.334.334 0 0 1-.334.334h-.838a.334.334 0 0 1-.334-.334v-2.57a.964.964 0 1 0-1.929 0v2.57a2.265 2.265 0 0 0 2.263 2.263h.838a2.265 2.265 0 0 0 2.263-2.263v-5.474a2.155 2.155 0 0 0-2.151-2.152z"
                    data-name="Path 241"
                    transform="translate(-270.232 -259.491)"
                />
            </g>
            <path
                id="Path_242"
                d="M141.111 313.387a3.708 3.708 0 1 0 3.708 3.708 3.712 3.712 0 0 0-3.708-3.708zm0 5.486a1.779 1.779 0 1 1 1.779-1.779 1.781 1.781 0 0 1-1.779 1.779z"
                data-name="Path 242"
                transform="translate(-129.434 -284.158)"
            />
            <path
                id="Path_243"
                d="M354.668 109.592h1.937v-1.937a1.047 1.047 0 0 1 2.094 0v1.937h1.937a1.047 1.047 0 0 1 0 2.094H358.7v1.937a1.047 1.047 0 0 1-2.094 0v-1.937h-1.937a1.047 1.047 0 1 1 0-2.094z"
                data-name="Path 243"
                transform="translate(-338.161 -65.858)"
            />
        </g>
    </SvgIcon>
));

export const KeyIcon = withStyles(styles)((props: Props) => (
    <SvgIcon {...props} viewBox={'0 0 30 16'}>
        <g id="hotel-key" transform="translate(-3.757 -24.812)">
            <g id="Group_76" data-name="Group 76" transform="translate(3.757 24.812)">
                <path
                    id="Path_239"
                    d="M80.939 249.526a7.99 7.99 0 0 0-7.981 7.981.964.964 0 0 0 1.929 0 6.052 6.052 0 1 1 6.052 6.052 6 6 0 0 1-4.388-1.885.964.964 0 0 0-1.4 1.328 7.91 7.91 0 0 0 5.787 2.485 7.981 7.981 0 0 0 0-15.961z"
                    data-name="Path 239"
                    transform="translate(-72.958 -249.526)"
                />
                <path
                    id="Path_240"
                    d="M288.034 326.116h-5.885a.964.964 0 1 0 0 1.929h5.885a.964.964 0 0 0 0-1.929z"
                    data-name="Path 240"
                    transform="translate(-267.177 -317.226)"
                />
                <path
                    id="Path_241"
                    d="M297.519 264.514h-11.962a.964.964 0 1 0 0 1.929h11.961a.224.224 0 0 1 .223.223v5.474a.334.334 0 0 1-.334.334h-.838a.334.334 0 0 1-.334-.334v-2.57a.964.964 0 1 0-1.929 0v2.57a2.265 2.265 0 0 0 2.263 2.263h.838a2.265 2.265 0 0 0 2.263-2.263v-5.474a2.155 2.155 0 0 0-2.151-2.152z"
                    data-name="Path 241"
                    transform="translate(-270.232 -259.491)"
                />
            </g>
            <path
                id="Path_242"
                d="M141.111 313.387a3.708 3.708 0 1 0 3.708 3.708 3.712 3.712 0 0 0-3.708-3.708zm0 5.486a1.779 1.779 0 1 1 1.779-1.779 1.781 1.781 0 0 1-1.779 1.779z"
                data-name="Path 242"
                transform="translate(-129.434 -284.158)"
            />
        </g>
    </SvgIcon>
));

export const PdfIcon = withStyles(styles)((props: Props) => (
    <SvgIcon {...props} viewBox={'0 0 21.4 24'}>
        <g id="export" transform="translate(-28.385)">
            <g id="Group_90" data-name="Group 90" transform="translate(28.385)">
                <path
                    id="Path_328"
                    d="M48.957 128.495a.763.763 0 0 0-.763.763v12.205H32.015V134.3l3.291 2.669a.763.763 0 0 0 1.244-.593v-2.407h5.81a.763.763 0 0 0 .763-.763v-4.579a.763.763 0 0 0-.763-.763h-5.81v-2.254a.763.763 0 0 0-1.244-.593l-6.639 5.383a.763.763 0 0 0 0 1.186l1.821 1.477v9.165a.763.763 0 0 0 .763.763h17.706a.763.763 0 0 0 .763-.763v-12.97a.763.763 0 0 0-.763-.763zm-13.934-1.285v1.416a.763.763 0 0 0 .763.763H41.6v3.053h-5.813a.763.763 0 0 0-.763.763v1.569l-4.663-3.782z"
                    data-name="Path 328"
                    transform="translate(-28.385 -118.994)"
                />
                <path
                    id="Path_329"
                    d="M74.024 6.483a.763.763 0 0 0 .763-.763V1.526h10.568v4.193a.763.763 0 0 0 .763.763h5.492a.763.763 0 0 0 .521-1.321L86.816.205 86.8.19l-.029-.023-.029-.022-.031-.021-.028-.018-.035-.02-.027-.012-.039-.018-.027-.01-.042-.014-.029-.008-.041-.01L86.4.009 86.37 0a.744.744 0 0 0-.07 0H74.024a.763.763 0 0 0-.763.763v4.956a.763.763 0 0 0 .763.764zm12.857-1.527v-2.6l2.792 2.6z"
                    data-name="Path 329"
                    transform="translate(-71.158)"
                />
            </g>
        </g>
    </SvgIcon>
));

// .cls-1{fill:#398bf7}.cls-2{fill:#cee8fa}.cls-3{fill:#58c3f2}

export const SerieIcon = withStyles(styles)((props: Props) => (
    <SvgIcon {...props} viewBox={'0 0 26 24'}>
        <g id="calendar_4_" data-name="calendar (4)" transform="translate(0 -19.009)">
            <path
                id="Path_202"
                d="M36.4 137.274H15.65a1.813 1.813 0 0 1-1.892-1.718v-14.723a.777.777 0 0 1 1.548 0v14.724a.33.33 0 0 0 .344.312H36.4a.33.33 0 0 0 .344-.312v-9.1a.777.777 0 0 1 1.548 0v9.1a1.813 1.813 0 0 1-1.892 1.717z"
                data-name="Path 202"
                transform="translate(-13.06 -94.265)"
            />
            <g id="Group_69" data-name="Group 69" transform="translate(0 19.009)">
                <path
                    id="Path_204"
                    d="M25.151 52.083H.774A.775.775 0 0 1 0 51.307v-4.166a1.9 1.9 0 0 1 1.892-1.9h22.141a1.9 1.9 0 0 1 1.892 1.9v4.167a.775.775 0 0 1-.774.775zm-23.6-1.551h22.826v-3.391a.345.345 0 0 0-.344-.345H1.892a.345.345 0 0 0-.344.345z"
                    data-name="Path 204"
                    transform="translate(0 -43.914)"
                />
                <path
                    id="Path_205"
                    d="M62.264 23.326a.776.776 0 0 1-.775-.775v-2.767a.776.776 0 0 1 1.551 0v2.766a.776.776 0 0 1-.776.776z"
                    data-name="Path 205"
                    transform="translate(-58.369 -19.009)"
                />
                <path
                    id="Path_206"
                    d="M151.877 23.326a.776.776 0 0 1-.775-.775v-2.767a.775.775 0 0 1 1.551 0v2.766a.776.776 0 0 1-.776.776z"
                    data-name="Path 206"
                    transform="translate(-143.452 -19.009)"
                />
                <path
                    id="Path_207"
                    d="M331.1 23.326a.775.775 0 0 1-.775-.775v-2.767a.775.775 0 1 1 1.551 0v2.766a.776.776 0 0 1-.776.776z"
                    data-name="Path 207"
                    transform="translate(-313.604 -19.009)"
                />
                <path
                    id="Path_208"
                    d="M241.489 23.326a.776.776 0 0 1-.775-.775v-2.767a.775.775 0 1 1 1.551 0v2.766a.775.775 0 0 1-.776.776z"
                    data-name="Path 208"
                    transform="translate(-228.527 -19.009)"
                />
                <path
                    id="Path_209"
                    d="M420.717 23.326a.776.776 0 0 1-.775-.775v-2.767a.775.775 0 0 1 1.551 0v2.766a.776.776 0 0 1-.776.776z"
                    data-name="Path 209"
                    transform="translate(-398.687 -19.009)"
                />
                <path
                    id="Path_210"
                    d="M194.675 254.591a.776.776 0 0 1-.548-.227l-2.4-2.4a.776.776 0 1 1 1.1-1.1l1.855 1.856 3.533-3.533a.776.776 0 0 1 1.1 1.1l-4.081 4.081a.776.776 0 0 1-.559.223z"
                    data-name="Path 210"
                    transform="translate(-181.808 -237.34)"
                />
                <path
                    id="Path_211"
                    d="M147.44 232.4h-10.622a.776.776 0 0 1-.775-.775v-8.292a.775.775 0 0 1 .775-.775h4.819a.775.775 0 1 1 0 1.551h-4.044v6.741h9.07v-3.32a.775.775 0 1 1 1.551 0v4.1a.775.775 0 0 1-.774.77z"
                    style={{ fill: '#58c3f2' }}
                    data-name="Path 211"
                    transform="translate(-129.167 -212.275)"
                />
            </g>
        </g>
    </SvgIcon>
));

export const SerieIconDefault = withStyles(styles)((props: Props) => (
    <SvgIcon {...props} viewBox={'0 0 26 24'}>
        <g id="calendar_4_" data-name="calendar (4)" transform="translate(0 -19.009)">
            <path
                id="Path_202"
                d="M36.4 137.274H15.65a1.813 1.813 0 0 1-1.892-1.718v-14.723a.777.777 0 0 1 1.548 0v14.724a.33.33 0 0 0 .344.312H36.4a.33.33 0 0 0 .344-.312v-9.1a.777.777 0 0 1 1.548 0v9.1a1.813 1.813 0 0 1-1.892 1.717z"
                data-name="Path 202"
                transform="translate(-13.06 -94.265)"
            />
            <g id="Group_69" data-name="Group 69" transform="translate(0 19.009)">
                <path
                    id="Path_204"
                    d="M25.151 52.083H.774A.775.775 0 0 1 0 51.307v-4.166a1.9 1.9 0 0 1 1.892-1.9h22.141a1.9 1.9 0 0 1 1.892 1.9v4.167a.775.775 0 0 1-.774.775zm-23.6-1.551h22.826v-3.391a.345.345 0 0 0-.344-.345H1.892a.345.345 0 0 0-.344.345z"
                    data-name="Path 204"
                    transform="translate(0 -43.914)"
                />
                <path
                    id="Path_205"
                    d="M62.264 23.326a.776.776 0 0 1-.775-.775v-2.767a.776.776 0 0 1 1.551 0v2.766a.776.776 0 0 1-.776.776z"
                    data-name="Path 205"
                    transform="translate(-58.369 -19.009)"
                />
                <path
                    id="Path_206"
                    d="M151.877 23.326a.776.776 0 0 1-.775-.775v-2.767a.775.775 0 0 1 1.551 0v2.766a.776.776 0 0 1-.776.776z"
                    data-name="Path 206"
                    transform="translate(-143.452 -19.009)"
                />
                <path
                    id="Path_207"
                    d="M331.1 23.326a.775.775 0 0 1-.775-.775v-2.767a.775.775 0 1 1 1.551 0v2.766a.776.776 0 0 1-.776.776z"
                    data-name="Path 207"
                    transform="translate(-313.604 -19.009)"
                />
                <path
                    id="Path_208"
                    d="M241.489 23.326a.776.776 0 0 1-.775-.775v-2.767a.775.775 0 1 1 1.551 0v2.766a.775.775 0 0 1-.776.776z"
                    data-name="Path 208"
                    transform="translate(-228.527 -19.009)"
                />
                <path
                    id="Path_209"
                    d="M420.717 23.326a.776.776 0 0 1-.775-.775v-2.767a.775.775 0 0 1 1.551 0v2.766a.776.776 0 0 1-.776.776z"
                    data-name="Path 209"
                    transform="translate(-398.687 -19.009)"
                />
                <path
                    id="Path_210"
                    d="M194.675 254.591a.776.776 0 0 1-.548-.227l-2.4-2.4a.776.776 0 1 1 1.1-1.1l1.855 1.856 3.533-3.533a.776.776 0 0 1 1.1 1.1l-4.081 4.081a.776.776 0 0 1-.559.223z"
                    data-name="Path 210"
                    transform="translate(-181.808 -237.34)"
                />
                <path
                    id="Path_211"
                    d="M147.44 232.4h-10.622a.776.776 0 0 1-.775-.775v-8.292a.775.775 0 0 1 .775-.775h4.819a.775.775 0 1 1 0 1.551h-4.044v6.741h9.07v-3.32a.775.775 0 1 1 1.551 0v4.1a.775.775 0 0 1-.774.77z"
                    data-name="Path 211"
                    transform="translate(-129.167 -212.275)"
                />
            </g>
        </g>
    </SvgIcon>
));

// .cls-1{fill:#cee8fa}.cls-2{fill:#398bf7}.cls-3{fill:#58c3f2}

export const CustomersIcon = withStyles(styles)((props: Props) => (
    <SvgIcon {...props} viewBox={'0 0 21 24'}>
        <g id="user_1_" data-name="user (1)" transform="translate(-40.735)">
            <g id="Group_47" data-name="Group 47" transform="translate(41.952)">
                <path
                    id="Path_123"
                    d="M260.879 287.111h-8.625a.633.633 0 0 1-.616-.649v-5.528a5.309 5.309 0 0 1 3.156-4.934.6.6 0 0 1 .765.277l1.007 1.838 1.007-1.838a.6.6 0 0 1 .765-.277 5.309 5.309 0 0 1 3.156 4.933v5.528a.633.633 0 0 1-.615.65zm-8.009-1.3h7.393v-4.879a4.05 4.05 0 0 0-1.908-3.484l-1.255 2.289a.6.6 0 0 1-1.067 0l-1.255-2.289a4.05 4.05 0 0 0-1.908 3.484v4.879z"
                    data-name="Path 123"
                    transform="translate(-241.531 -263.111)"
                />
                <path
                    id="Path_124"
                    d="M278.735 101.469a4.2 4.2 0 0 1-4.085-3.2.649.649 0 1 1 1.26-.31 2.911 2.911 0 1 0 1.263-3.153.649.649 0 1 1-.7-1.094 4.207 4.207 0 1 1 2.26 7.756z"
                    data-name="Path 124"
                    transform="translate(-263.962 -88.712)"
                />
                <path
                    id="Path_125"
                    d="M71.532 10.107a5.053 5.053 0 1 1 5.053-5.053 5.059 5.059 0 0 1-5.053 5.053zm0-8.809a3.756 3.756 0 1 0 3.756 3.756A3.76 3.76 0 0 0 71.532 1.3z"
                    data-name="Path 125"
                    transform="translate(-66.479)"
                />
            </g>
            <path
                id="Path_127"
                d="M52.627 239.873H41.384a.649.649 0 0 1-.649-.649v-6.842a6.409 6.409 0 0 1 4.018-5.963.649.649 0 0 1 .806.277l1.447 2.506 1.447-2.506a.649.649 0 0 1 .806-.277 6.408 6.408 0 0 1 4.018 5.963v6.842a.649.649 0 0 1-.65.649zm-10.594-1.3h9.945v-6.193a5.112 5.112 0 0 0-2.7-4.521l-1.711 2.964a.649.649 0 0 1-1.124 0l-1.711-2.964a5.112 5.112 0 0 0-2.7 4.521v6.193z"
                style={{ fill: '#58c3f2' }}
                data-name="Path 127"
                transform="translate(0 -215.873)"
            />
        </g>
    </SvgIcon>
));

//.cls-1{fill:#398bf7}.cls-2{fill:#58c3f2}.cls-3{fill:#cee8fa}

export const EmployeesIcon = withStyles(styles)((props: Props) => (
    <SvgIcon {...props} viewBox={'0 0 17 24'}>
        <g id="user_2_" data-name="user (2)" transform="translate(-73.598)">
            <g id="Group_48" data-name="Group 48" transform="translate(74.964)">
                <path
                    id="Path_128"
                    d="M107.744 10.007a5 5 0 1 1 5-5 5.009 5.009 0 0 1-5 5zm0-8.722A3.718 3.718 0 1 0 111.463 5a3.723 3.723 0 0 0-3.719-3.715z"
                    data-name="Path 128"
                    transform="translate(-102.741)"
                />
                <path
                    id="Path_129"
                    d="M229.921 308.522a5 5 0 1 1 5-5 5.009 5.009 0 0 1-5 5zm0-8.722a3.718 3.718 0 1 0 3.718 3.719 3.723 3.723 0 0 0-3.718-3.719z"
                    style={{ fill: '#58c3f2' }}
                    data-name="Path 129"
                    transform="translate(-219.191 -284.522)"
                />
            </g>
            <g id="Group_49" data-name="Group 49" transform="translate(73.598 10.594)">
                <path
                    id="Path_131"
                    d="M85.131 239.387H74.24a.643.643 0 0 1-.642-.642v-6.775a6.345 6.345 0 0 1 3.978-5.9.643.643 0 0 1 .8.274l1.594 2.76 1.594-2.76a.642.642 0 0 1 .8-.274 6.376 6.376 0 0 1 3.619 3.794.643.643 0 0 1-.559.854 3.717 3.717 0 0 0-.205 7.393.643.643 0 0 1-.082 1.28zM74.883 238.1h7.427a5 5 0 0 1 2.143-8.527 5.093 5.093 0 0 0-2.074-2.081l-1.855 3.213a.643.643 0 0 1-1.113 0l-1.855-3.214a5.062 5.062 0 0 0-2.673 4.476v6.133z"
                    data-name="Path 131"
                    transform="translate(-73.598 -226.02)"
                />
                <path
                    id="Path_132"
                    d="M318.6 364.68a.643.643 0 0 1-.642-.643v-2.913a.643.643 0 0 1 1.285 0v2.913a.643.643 0 0 1-.643.643z"
                    style={{ fill: '#58c3f2' }}
                    data-name="Path 132"
                    transform="translate(-306.499 -354.179)"
                />
                <path
                    id="Path_133"
                    d="M290.439 392.836h-2.913a.643.643 0 1 1 0-1.285h2.913a.643.643 0 0 1 0 1.285z"
                    style={{ fill: '#58c3f2' }}
                    data-name="Path 133"
                    transform="translate(-276.886 -383.792)"
                />
            </g>
        </g>
    </SvgIcon>
));

//  .cls-1{fill:#cee8fa}.cls-2{fill:#398bf7}.cls-3{fill:#58c3f2}

export const ResourceIcon = withStyles(styles)((props: Props) => (
    <SvgIcon {...props} viewBox={'0 0 24.4 24'}>
        <g id="settings" transform="translate(-4.128 -24.145)">
            <path
                id="Path_141"
                d="M177.9 260.024a3.056 3.056 0 0 1-2.16-5.217l7.5-7.5a.8.8 0 0 1 1.131 0l3.189 3.191a.8.8 0 0 1 0 1.132l-7.5 7.5a3.034 3.034 0 0 1-2.16.894zM183.8 249l-6.936 6.939a1.456 1.456 0 1 0 2.062 2.061l6.936-6.939z"
                data-name="Path 141"
                transform="translate(-166.367 -211.879)"
            />
            <g id="Group_54" data-name="Group 54" transform="translate(16.391 28.102)">
                <path
                    id="Path_143"
                    d="M291.82 163.737a6.064 6.064 0 1 1 2.037-11.777.8.8 0 0 1 .3 1.32l-2.545 2.545 2.059 2.059 2.545-2.545a.8.8 0 0 1 1.32.3 6.1 6.1 0 0 1-1.425 6.325 6.024 6.024 0 0 1-4.291 1.773zm0-10.528a4.464 4.464 0 1 0 3.157 7.62 4.486 4.486 0 0 0 1.3-3.294l-2.046 2.046a.8.8 0 0 1-1.132 0l-3.19-3.191a.8.8 0 0 1 0-1.132l2.047-2.047z"
                    data-name="Path 143"
                    transform="translate(-285.756 -151.609)"
                />
            </g>
            <path
                style={{ fill: '#58c3f2' }}
                id="Path_149"
                d="M222.677 20.5h-2.089a.553.553 0 0 1-.546-.466l-.133-.833a4.83 4.83 0 0 1-1.29-.746l-.789.3a.553.553 0 0 1-.677-.24l-1.045-1.809a.553.553 0 0 1 .13-.706l.655-.532a4.831 4.831 0 0 1 0-1.49l-.655-.532a.553.553 0 0 1-.13-.706l1.045-1.809a.553.553 0 0 1 .677-.24l.789.3a4.832 4.832 0 0 1 1.29-.746l.133-.833a.553.553 0 0 1 .546-.466h2.089a.553.553 0 0 1 .546.466l.133.834a4.833 4.833 0 0 1 1.29.746l.789-.3a.553.553 0 0 1 .677.24l1.045 1.809a.553.553 0 0 1-.13.706l-.655.532a4.837 4.837 0 0 1 0 1.49l.655.532a.553.553 0 0 1 .13.706l-1.045 1.809a.553.553 0 0 1-.677.24l-.789-.3a4.831 4.831 0 0 1-1.29.746l-.133.833a.553.553 0 0 1-.546.465zm-1.618-1.1h1.141l.111-.695a.553.553 0 0 1 .386-.442 3.7 3.7 0 0 0 1.453-.84.553.553 0 0 1 .576-.113l.658.252.573-.992-.546-.444a.553.553 0 0 1-.19-.555 3.721 3.721 0 0 0 0-1.68.553.553 0 0 1 .19-.555l.546-.444-.573-.992-.658.252a.553.553 0 0 1-.576-.113 3.705 3.705 0 0 0-1.453-.84.553.553 0 0 1-.386-.442l-.111-.7h-1.146l-.111.695a.553.553 0 0 1-.386.442 3.7 3.7 0 0 0-1.453.84.554.554 0 0 1-.576.113l-.658-.252-.573.992.546.444a.553.553 0 0 1 .19.555 3.722 3.722 0 0 0 0 1.681.553.553 0 0 1-.19.555l-.546.444.573.992.658-.252a.553.553 0 0 1 .576.113 3.7 3.7 0 0 0 1.453.84.553.553 0 0 1 .386.442zm.573-2.484a2.186 2.186 0 1 1 2.186-2.186 2.189 2.189 0 0 1-2.186 2.184zm0-3.266a1.079 1.079 0 1 0 1.08 1.079 1.081 1.081 0 0 0-1.08-1.081z"
                data-name="Path 149"
                transform="translate(-211.905 15.194)"
            />
        </g>
    </SvgIcon>
));

export const ResourceIconDefault = withStyles(styles)((props: Props) => (
    <SvgIcon {...props} viewBox={'0 0 24.4 24'}>
        <g id="settings" transform="translate(-4.128 -24.145)">
            <path
                id="Path_141"
                d="M177.9 260.024a3.056 3.056 0 0 1-2.16-5.217l7.5-7.5a.8.8 0 0 1 1.131 0l3.189 3.191a.8.8 0 0 1 0 1.132l-7.5 7.5a3.034 3.034 0 0 1-2.16.894zM183.8 249l-6.936 6.939a1.456 1.456 0 1 0 2.062 2.061l6.936-6.939z"
                data-name="Path 141"
                transform="translate(-166.367 -211.879)"
            />
            <g id="Group_54" data-name="Group 54" transform="translate(16.391 28.102)">
                <path
                    id="Path_143"
                    d="M291.82 163.737a6.064 6.064 0 1 1 2.037-11.777.8.8 0 0 1 .3 1.32l-2.545 2.545 2.059 2.059 2.545-2.545a.8.8 0 0 1 1.32.3 6.1 6.1 0 0 1-1.425 6.325 6.024 6.024 0 0 1-4.291 1.773zm0-10.528a4.464 4.464 0 1 0 3.157 7.62 4.486 4.486 0 0 0 1.3-3.294l-2.046 2.046a.8.8 0 0 1-1.132 0l-3.19-3.191a.8.8 0 0 1 0-1.132l2.047-2.047z"
                    data-name="Path 143"
                    transform="translate(-285.756 -151.609)"
                />
            </g>
            <path
                id="Path_149"
                d="M222.677 20.5h-2.089a.553.553 0 0 1-.546-.466l-.133-.833a4.83 4.83 0 0 1-1.29-.746l-.789.3a.553.553 0 0 1-.677-.24l-1.045-1.809a.553.553 0 0 1 .13-.706l.655-.532a4.831 4.831 0 0 1 0-1.49l-.655-.532a.553.553 0 0 1-.13-.706l1.045-1.809a.553.553 0 0 1 .677-.24l.789.3a4.832 4.832 0 0 1 1.29-.746l.133-.833a.553.553 0 0 1 .546-.466h2.089a.553.553 0 0 1 .546.466l.133.834a4.833 4.833 0 0 1 1.29.746l.789-.3a.553.553 0 0 1 .677.24l1.045 1.809a.553.553 0 0 1-.13.706l-.655.532a4.837 4.837 0 0 1 0 1.49l.655.532a.553.553 0 0 1 .13.706l-1.045 1.809a.553.553 0 0 1-.677.24l-.789-.3a4.831 4.831 0 0 1-1.29.746l-.133.833a.553.553 0 0 1-.546.465zm-1.618-1.1h1.141l.111-.695a.553.553 0 0 1 .386-.442 3.7 3.7 0 0 0 1.453-.84.553.553 0 0 1 .576-.113l.658.252.573-.992-.546-.444a.553.553 0 0 1-.19-.555 3.721 3.721 0 0 0 0-1.68.553.553 0 0 1 .19-.555l.546-.444-.573-.992-.658.252a.553.553 0 0 1-.576-.113 3.705 3.705 0 0 0-1.453-.84.553.553 0 0 1-.386-.442l-.111-.7h-1.146l-.111.695a.553.553 0 0 1-.386.442 3.7 3.7 0 0 0-1.453.84.554.554 0 0 1-.576.113l-.658-.252-.573.992.546.444a.553.553 0 0 1 .19.555 3.722 3.722 0 0 0 0 1.681.553.553 0 0 1-.19.555l-.546.444.573.992.658-.252a.553.553 0 0 1 .576.113 3.7 3.7 0 0 0 1.453.84.553.553 0 0 1 .386.442zm.573-2.484a2.186 2.186 0 1 1 2.186-2.186 2.189 2.189 0 0 1-2.186 2.184zm0-3.266a1.079 1.079 0 1 0 1.08 1.079 1.081 1.081 0 0 0-1.08-1.081z"
                data-name="Path 149"
                transform="translate(-211.905 15.194)"
            />
        </g>
    </SvgIcon>
));

export const AlertIcon = withStyles(styles)((props: Props) => (
    <SvgIcon {...props}>
        <g id="alert-caution-exclamation-mark-error" transform="translate(-.349 -1.842)">
            <path d="M.349 25.74h24.985L12.841 1.842zm1.673-1.014L12.841 4.03l10.819 20.7z" data-name="Path 483" />
            <path d="M0 0h1.014v9.122H0z" data-name="Rectangle 563" transform="translate(12.335 10.03)" />
            <path d="M0 0h1.014v1.52H0z" data-name="Rectangle 564" transform="translate(12.335 20.673)" />
        </g>
    </SvgIcon>
));

export const CheckIcon = withStyles(styles)((props: Props) => (
    <SvgIcon {...props}>
        <path
            d="M30.4 6.94L9.333 28l-7.145-7.14-.895.894 8.04 8.04 21.96-21.96z"
            data-name="Path 555"
            transform="translate(-1.293 -6.94)"
        />
    </SvgIcon>
));

export const CheckRoundedIcon = withStyles(styles)((props: Props) => (
    <SvgIcon {...props} viewBox={'0 0 24 24'}>
        <g id="rec" transform="translate(0 -.032)">
            <path
                id="Path_333"
                d="M12 24.032a11.968 11.968 0 0 1-8.489-3.511.766.766 0 0 1 1.089-1.083 10.469 10.469 0 1 0-2.706-4.672.766.766 0 0 1-1.479.4A12.013 12.013 0 1 1 12 24.032z"
                data-name="Path 333"
            />
            <path
                id="Path_334"
                d="M194.675 254.591a.776.776 0 0 1-.548-.227l-3.216-3.423a.776.776 0 0 1 1.1-1.1l2.668 2.874 5.982-6.2a.776.776 0 0 1 1.1 1.1l-6.531 6.752a.776.776 0 0 1-.555.224z"
                data-name="Path 334"
                transform="translate(-184.786 -238.132)"
            />
        </g>
    </SvgIcon>
));

export const DefaultEmployeeIcon = withStyles(styles)((props: Props) => (
    <SvgIcon {...props}>
        <g transform="translate(-96.4 -91.5)">
            <circle cx="106.4" cy="106.4" r="106.4" transform="translate(96.4 91.5)" />
            <g transform="translate(96.4 91.5)">
                <path
                    d="M282.367,251.03c-9.47-4.572-26.412-12.819-31.311-15.1s-18.268-9.186-18.268-9.186-.643-11.124-4.563-11.778l.66-7.52s4.575-10.456,4.575-16.988c0,0,4.572,3.917,6.205-4.9s3.92-25.149-3.264-21.229c0,0,1.47-13.064.163-20.9s-8-32-38.046-32-36.74,24.167-38.046,32,.163,20.9.163,20.9c-7.184-3.92-4.9,12.41-3.266,21.229s6.207,4.9,6.207,4.9c0,6.532,4.575,16.988,4.575,16.988l.658,7.52c-3.918.654-4.561,11.778-4.561,11.778s-13.371,6.9-18.268,9.186-21.841,10.532-31.312,15.1S104.3,284.474,104.3,284.474l94.219,12.885,94.218-12.885S291.838,255.6,282.367,251.03Z"
                    transform="translate(-92.118 -80.705)"
                />
            </g>
        </g>
    </SvgIcon>
));

export const SearchIcon = withStyles(styles)((props: Props) => (
    <SvgIcon {...props}>
        <g transform="translate(-1 -1)">
            <path
                d="M9.049 1a8.049 8.049 0 1 0 5.316 14.077L24.289 25l.711-.711-9.923-9.923A8.036 8.036 0 0 0 9.049 1zm0 15.092a7.043 7.043 0 1 1 7.043-7.043 7.051 7.051 0 0 1-7.043 7.043z"
                data-name="Path 739"
            />
        </g>
    </SvgIcon>
));

// .cls-1{fill:#398bf7}.cls-2{fill:#cee8fa}.cls-3{fill:#58c3f2}

export const HolidayIcon = withStyles(styles)((props: Props) => (
    <SvgIcon {...props} viewBox={'0 0 25 24'}>
        <g id="star" transform="translate(0 -11.828)">
            <g id="Group_57" data-name="Group 57" transform="translate(0 11.828)">
                <path
                    id="Path_163"
                    d="M6.33 29.612a.7.7 0 0 1-.12-.01.709.709 0 0 1-.575-.819l.251-1.473-5.674-5.572a.711.711 0 0 1 .39-1.21l7.84-1.148 3.506-7.157a.7.7 0 0 1 1.263 0l3.506 7.157 7.84 1.148a.706.706 0 0 1 .569.483.713.713 0 0 1-.178.727l-2.231 2.19a.7.7 0 0 1-1-.013.713.713 0 0 1 .013-1l1.209-1.187-6.795-.995a.7.7 0 0 1-.53-.388l-3.039-6.2-3.039 6.2a.7.7 0 0 1-.53.388l-6.795.995 4.917 4.828a.713.713 0 0 1 .2.628l-.313 1.841a.706.706 0 0 1-.685.587z"
                    data-name="Path 163"
                    transform="translate(0 -11.828)"
                />
                <path
                    id="Path_164"
                    d="M107.87 286.312a.73.73 0 0 1-.331-.079l-7.092-3.611-7.092 3.611a.731.731 0 0 1-.75-.052.68.68 0 0 1-.283-.675l.524-2.958a.71.71 0 0 1 .822-.563.692.692 0 0 1 .582.8l-.28 1.58 6.146-3.13a.733.733 0 0 1 .663 0l6.146 3.13-1.174-6.629a.676.676 0 0 1 .2-.611l1.514-1.43a.729.729 0 0 1 1.007.012.675.675 0 0 1-.013.975l-1.246 1.177 1.355 7.649a.68.68 0 0 1-.283.675.729.729 0 0 1-.415.129z"
                    data-name="Path 164"
                    transform="translate(-87.866 -262.312)"
                />
            </g>
        </g>
    </SvgIcon>
));

// .cls-1{fill:#398bf7}.cls-2{fill:#cee8fa}.cls-3{fill:#58c3f2}

export const VacationIcon = withStyles(styles)((props: Props) => (
    <SvgIcon {...props} viewBox={'0 0 31 24'}>
        <g id="departure" transform="translate(0 -56.323)">
            <path
                id="Path_150"
                d="M20.792 74.891a.845.845 0 0 1-.352-.077.856.856 0 0 1-.462-.529L18.19 68.41a.859.859 0 0 1 .465-1.033l23.7-10.712a3.9 3.9 0 0 1 3.176 7.127L41.087 65.8a.849.849 0 0 1-1.125-.431.86.86 0 0 1 .428-1.133l4.444-2.009a2.2 2.2 0 0 0 1.094-2.9 2.172 2.172 0 0 0-2.876-1.1l-23.015 10.4 1.286 4.223 13.061-5.9a.849.849 0 0 1 1.125.431.86.86 0 0 1-.428 1.133l-13.941 6.3a.845.845 0 0 1-.348.077z"
                data-name="Path 150"
                transform="translate(-17.054)"
            />
            <path
                id="Path_151"
                d="M17.094 189.712l-2.925 1.313 2.9 8.327 5.691-2.552z"
                style={{ fill: '#fff' }}
                data-name="Path 151"
                transform="translate(-13.312 -125.409)"
            />
            <path
                id="Path_152"
                d="M3.757 186.9a.857.857 0 0 1-.81-.575L.048 178a.857.857 0 0 1 .459-1.064l2.925-1.313a.857.857 0 0 1 1.02.247l5.666 7.085a.857.857 0 0 1-.318 1.315l-5.692 2.554a.857.857 0 0 1-.351.076zm-1.832-8.724l2.334 6.7 3.829-1.718-4.56-5.7z"
                data-name="Path 152"
                transform="translate(0 -112.101)"
            />
            <path
                id="Path_153"
                d="M72.728 85.3l-3.692 1.657 10.6 5.212 7.621-3.42z"
                style={{ fill: '#fff' }}
                data-name="Path 153"
                transform="translate(-64.859 -27.221)"
            />
            <g id="Group_55" data-name="Group 55" transform="translate(.883 57.219)">
                <path
                    id="Path_154"
                    d="M66.325 79.714a.858.858 0 0 1-.378-.088l-10.6-5.212a.857.857 0 0 1 .027-1.551l3.692-1.657a.857.857 0 0 1 .549-.052L74.144 74.6a.857.857 0 0 1 .153 1.616l-7.621 3.42a.857.857 0 0 1-.351.078zm-8.584-6.034l8.6 4.229 4.951-2.222L59.5 72.889z"
                    data-name="Path 154"
                    transform="translate(-52.432 -71.131)"
                />
                <path
                    id="Path_155"
                    d="M134.7 429.06h-21.589a.857.857 0 0 1 0-1.714H134.7a.857.857 0 1 1 0 1.714z"
                    data-name="Path 155"
                    transform="translate(-106.556 -405.956)"
                />
                <path
                    id="Path_156"
                    d="M17.348 429.06h-1.907a.857.857 0 0 1 0-1.714h1.907a.857.857 0 0 1 0 1.714z"
                    data-name="Path 156"
                    transform="translate(-14.584 -405.956)"
                />
            </g>
        </g>
    </SvgIcon>
));

// .cls-1{fill:#398bf7}.cls-2{fill:#cee8fa}.cls-3{fill:#f12f3b}

export const IllnessIcon = withStyles(styles)((props: Props) => (
    <SvgIcon {...props} viewBox={'0 0 27 24'}>
        <g id="first-aid-kit" transform="translate(0 -28.012)">
            <g id="Group_56" data-name="Group 56" transform="translate(0 28.012)">
                <path
                    id="Path_157"
                    d="M24.875 126.294H2.074A2.079 2.079 0 0 1 0 124.214V108.45a2.079 2.079 0 0 1 2.074-2.079h22.8a2.079 2.079 0 0 1 2.074 2.079v2.531a.819.819 0 1 1-1.638 0v-2.531a.437.437 0 0 0-.436-.437h-22.8a.437.437 0 0 0-.436.437v15.764a.437.437 0 0 0 .436.437h22.8a.437.437 0 0 0 .436-.437v-8.827a.819.819 0 1 1 1.638 0v8.827a2.079 2.079 0 0 1-2.073 2.08z"
                    data-name="Path 157"
                    transform="translate(0 -102.294)"
                />
                <path
                    id="Path_158"
                    d="M165.724 33.709h-8.773a.81.81 0 0 1-.81-.81v-4.077a.81.81 0 0 1 .81-.81h8.773a.81.81 0 0 1 .81.81V32.9a.81.81 0 0 1-.81.809zm-7.963-1.62h7.153v-2.457h-7.153v2.458z"
                    data-name="Path 158"
                    transform="translate(-147.864 -28.012)"
                />
            </g>
            <path
                style={{ fill: '#fff' }}
                id="Rectangle_6"
                d="M0 0h3.995v5.066H0z"
                data-name="Rectangle 6"
                transform="translate(18.043 31.04)"
            />
            <path
                id="Path_159"
                d="M331.354 77.317h-3.995a.81.81 0 0 1-.81-.81v-5.066a.81.81 0 0 1 .81-.81h3.995a.81.81 0 0 1 .81.81v5.066a.81.81 0 0 1-.81.81zm-3.185-1.62h2.375v-3.446h-2.375z"
                data-name="Path 159"
                transform="translate(-309.309 -40.401)"
            />
            <path
                style={{ fill: '#fff' }}
                id="Rectangle_7"
                d="M0 0h3.995v5.066H0z"
                data-name="Rectangle 7"
                transform="translate(4.911 31.04)"
            />
            <path
                id="Path_160"
                d="M82.357 77.317h-3.995a.81.81 0 0 1-.81-.81v-5.066a.81.81 0 0 1 .81-.81h3.995a.81.81 0 0 1 .81.81v5.066a.81.81 0 0 1-.81.81zM79.172 75.7h2.375v-3.449h-2.375z"
                data-name="Path 160"
                transform="translate(-73.458 -40.401)"
            />
            <path
                id="Path_162"
                d="M150.182 199.134h-3.968a.81.81 0 0 1-.81-.81v-2.314h-2.314a.81.81 0 0 1-.81-.81v-3.968a.81.81 0 0 1 .81-.81h2.31v-2.314a.81.81 0 0 1 .81-.81h3.968a.81.81 0 0 1 .81.81v2.314h2.314a.81.81 0 0 1 .81.81v3.968a.81.81 0 0 1-.81.81h-2.314v2.314a.81.81 0 0 1-.806.81zm-3.158-1.62h2.348V195.2a.81.81 0 0 1 .81-.81h2.318v-2.348h-2.314a.81.81 0 0 1-.81-.81v-2.314h-2.348v2.314a.81.81 0 0 1-.81.81H143.9v2.348h2.314a.81.81 0 0 1 .81.81z"
                data-name="Path 162"
                transform="translate(-134.724 -150.808)"
            />
        </g>
    </SvgIcon>
));

// .cls-1{fill:#398bf7}.cls-2{fill:#cee8fa}.cls-3{fill:none;stroke:#58c3f2;stroke-linecap:round;stroke-width:2px}

export const OtherAbsencesIcon = withStyles(styles)((props: Props) => (
    <SvgIcon {...props} viewBox={'0 0 26 24'}>
        <g id="calendar_3_" data-name="calendar (3)" transform="translate(0 -19.009)">
            <path
                id="Path_178"
                d="M36.4 137.1H15.65a1.806 1.806 0 0 1-1.892-1.7v-14.574a.778.778 0 0 1 1.548 0V135.4a.328.328 0 0 0 .344.309H36.4a.328.328 0 0 0 .344-.309v-9.008a.778.778 0 0 1 1.548 0v9.008a1.806 1.806 0 0 1-1.892 1.7z"
                data-name="Path 178"
                transform="translate(-13.062 -94.092)"
            />
            <g id="Group_59" data-name="Group 59" transform="translate(0 19.009)">
                <path
                    id="Path_180"
                    d="M25.151 52.06H.774A.773.773 0 0 1 0 51.287v-4.153a1.893 1.893 0 0 1 1.892-1.889h22.141a1.893 1.893 0 0 1 1.892 1.889v4.153a.773.773 0 0 1-.774.773zm-23.6-1.546h22.826v-3.38a.344.344 0 0 0-.344-.343H1.892a.344.344 0 0 0-.344.343z"
                    data-name="Path 180"
                    transform="translate(0 -43.918)"
                />
                <path
                    id="Path_181"
                    d="M62.262 23.312a.773.773 0 0 1-.773-.773v-2.757a.773.773 0 0 1 1.546 0v2.757a.773.773 0 0 1-.773.773z"
                    data-name="Path 181"
                    transform="translate(-58.38 -19.009)"
                />
                <path
                    id="Path_182"
                    d="M151.875 23.312a.773.773 0 0 1-.773-.773v-2.757a.773.773 0 1 1 1.546 0v2.757a.773.773 0 0 1-.773.773z"
                    data-name="Path 182"
                    transform="translate(-143.45 -19.009)"
                />
                <path
                    id="Path_183"
                    d="M331.1 23.312a.773.773 0 0 1-.773-.773v-2.757a.773.773 0 1 1 1.546 0v2.757a.773.773 0 0 1-.773.773z"
                    data-name="Path 183"
                    transform="translate(-313.6 -19.009)"
                />
                <path
                    id="Path_184"
                    d="M241.487 23.312a.773.773 0 0 1-.773-.773v-2.757a.773.773 0 1 1 1.546 0v2.757a.773.773 0 0 1-.773.773z"
                    data-name="Path 184"
                    transform="translate(-228.524 -19.009)"
                />
                <path
                    id="Path_185"
                    d="M420.714 23.312a.773.773 0 0 1-.773-.773v-2.757a.773.773 0 1 1 1.546 0v2.757a.773.773 0 0 1-.773.773z"
                    data-name="Path 185"
                    transform="translate(-398.671 -19.009)"
                />
            </g>
            <path
                id="Path_268"
                d="M354.48 289.559l1.617-1.618a1.122 1.122 0 0 0-1.587-1.587l-1.617 1.617-1.617-1.617a1.122 1.122 0 1 0-1.587 1.587l1.617 1.618-1.617 1.617a1.122 1.122 0 1 0 1.587 1.587l1.617-1.617 1.617 1.617a1.122 1.122 0 0 0 1.587-1.587z"
                data-name="Path 268"
                transform="translate(-339.487 -254.704)"
            />
        </g>
    </SvgIcon>
));

// .cls-1{fill:#398bf7}.cls-2{fill:#cee8fa}.cls-3{fill:#58c3f2}.cls-4{fill:none;stroke:#398bf7;stroke-linecap:round;stroke-width:1.5px}

export const UnpaidLeave = withStyles(styles)((props: Props) => (
    <SvgIcon {...props} viewBox={'0 0 26 24'}>
        <g id="calendar_3_" data-name="calendar (3)" transform="translate(0 -19.009)">
            <path
                id="Path_178"
                d="M36.4 137.388H15.65a1.818 1.818 0 0 1-1.892-1.729v-14.822a.777.777 0 0 1 1.548 0v14.821a.331.331 0 0 0 .344.314H36.4a.331.331 0 0 0 .344-.314V126.5a.777.777 0 0 1 1.548 0v9.161a1.818 1.818 0 0 1-1.892 1.727z"
                data-name="Path 178"
                transform="translate(-13.061 -94.379)"
            />
            <g id="Group_59" data-name="Group 59" transform="translate(0 19.009)">
                <path
                    id="Path_180"
                    d="M25.151 52.069H.774A.774.774 0 0 1 0 51.3v-4.163a1.894 1.894 0 0 1 1.892-1.892h22.141a1.894 1.894 0 0 1 1.892 1.892V51.3a.774.774 0 0 1-.774.769zm-23.6-1.548h22.826v-3.384a.344.344 0 0 0-.344-.344H1.892a.344.344 0 0 0-.344.344z"
                    data-name="Path 180"
                    transform="translate(0 -43.917)"
                />
                <path
                    id="Path_181"
                    d="M62.263 23.318a.774.774 0 0 1-.774-.774v-2.761a.774.774 0 0 1 1.548 0v2.761a.774.774 0 0 1-.774.774z"
                    data-name="Path 181"
                    transform="translate(-58.376 -19.009)"
                />
                <path
                    id="Path_182"
                    d="M151.876 23.318a.774.774 0 0 1-.774-.774v-2.761a.774.774 0 1 1 1.548 0v2.761a.774.774 0 0 1-.774.774z"
                    data-name="Path 182"
                    transform="translate(-143.451 -19.009)"
                />
                <path
                    id="Path_183"
                    d="M331.1 23.318a.774.774 0 0 1-.774-.774v-2.761a.774.774 0 0 1 1.548 0v2.761a.774.774 0 0 1-.774.774z"
                    data-name="Path 183"
                    transform="translate(-313.602 -19.009)"
                />
                <path
                    id="Path_184"
                    d="M241.488 23.318a.774.774 0 0 1-.774-.774v-2.761a.774.774 0 1 1 1.548 0v2.761a.774.774 0 0 1-.774.774z"
                    data-name="Path 184"
                    transform="translate(-228.525 -19.009)"
                />
                <path
                    id="Path_185"
                    d="M420.715 23.318a.774.774 0 0 1-.774-.774v-2.761a.774.774 0 1 1 1.548 0v2.761a.774.774 0 0 1-.774.774z"
                    data-name="Path 185"
                    transform="translate(-398.677 -19.009)"
                />
            </g>
            <path
                id="Path_188"
                d="M199.909 163.329c-1.648-.041-2.887-.926-2.887-1.689 0-.381.34-.953.763-.953.518 0 .926.885 2.124 1.021V159c-1.239-.463-2.642-.994-2.642-2.737a2.655 2.655 0 0 1 2.642-2.655v-.381c0-.177.2-.34.463-.34.231 0 .463.163.463.34v.354c.885.027 2.329.286 2.329 1.076 0 .313-.218.94-.722.94-.381 0-.694-.436-1.607-.5v2.4c1.212.449 2.574 1.062 2.574 2.914a2.778 2.778 0 0 1-2.574 2.887v.422c0 .177-.231.34-.463.34-.259 0-.463-.163-.463-.34v-.381zm.109-6.142v-2.056c-.681.109-1.089.436-1.089.953-.001.599.462.858 1.089 1.103zm.708 2.138v2.369a1.113 1.113 0 0 0 1.021-1.117c0-.666-.436-.993-1.021-1.252z"
                data-name="Path 188"
                transform="translate(-186.934 -123.88)"
            />
            <path
                id="Path_273"
                d="M368.158 145.8h-12.367c-1.2 0-2.17-.322-2.17-.719s.971-.719 2.17-.719h12.367c1.2 0 2.17.322 2.17.719s-.972.719-2.17.719z"
                data-name="Path 273"
                transform="rotate(42 331.968 -363.988)"
            />
        </g>
    </SvgIcon>
));

export const TimeIcon = withStyles(styles)((props: Props) => (
    <SvgIcon {...props}>
        <g transform="translate(-10.96 -1.646)">
            <path
                d="M15.354 20.608V7h2.122v13.608a1.646 1.646 0 0 1 1.061 1.44 1.357 1.357 0 0 1-.079.428l9.316 7.341-1.5 1.183-9.316-7.341a2.679 2.679 0 0 1-.543.062 1.938 1.938 0 0 1-2.122-1.672 1.648 1.648 0 0 1 1.061-1.441z"
                data-name="Path 712"
                transform="translate(-3.333 -5.354)"
            />
        </g>{' '}
    </SvgIcon>
));

export const DoneTaskIcon = withStyles(styles)((props: Props) => (
    <SvgIcon {...props}>
        <path
            id="Path_335"
            d="M12 24A12 12 0 0 1 3 4.062.706.706 0 0 1 4.058 5a10.561 10.561 0 1 0 3.33-2.531.706.706 0 0 1-.615-1.27A11.884 11.884 0 0 1 12 0a12 12 0 0 1 0 24z"
            data-name="Path 335"
        />
        <path
            id="Path_338"
            d="M194.675 254.591a.776.776 0 0 1-.548-.227l-2.566-2.759a.776.776 0 0 1 1.1-1.1l2.017 2.21 4.363-4.312a.776.776 0 0 1 1.1 1.1l-4.912 4.86a.776.776 0 0 1-.554.228z"
            data-name="Path 338"
            transform="translate(-183.38 -239.304)"
        />
    </SvgIcon>
));

export const InProgressTaskIcon = withStyles(styles)((props: Props) => (
    <SvgIcon {...props}>
        <g id="round-clock_1_" data-name="round-clock (1)" transform="translate(.001)">
            <path
                id="Path_335"
                d="M12 24A12 12 0 0 1 3 4.062.707.707 0 0 1 4.058 5a10.561 10.561 0 1 0 3.33-2.531.706.706 0 0 1-.615-1.269A12 12 0 1 1 12 24z"
                data-name="Path 335"
            />
            <path
                id="Path_342"
                d="M248.9 201.7l-5.741-2.215a.747.747 0 0 1-.747-.747v-7.867a.747.747 0 1 1 1.494 0v7.12l4.994 2.215a.747.747 0 0 1 0 1.494z"
                data-name="Path 342"
                transform="translate(-231.106 -185.828)"
            />
        </g>
    </SvgIcon>
));

// .cls-1{fill:#398bf7}.cls-2{fill:#cee8fa}.cls-3{fill:#58c3f2}

export const CalendarIcon = withStyles(styles)((props: Props) => (
    <SvgIcon {...props} viewBox={'0 0 29 27'}>
        <g id="calendar_3_" data-name="calendar (3)" transform="translate(0 -19.009)">
            <path
                id="Path_178"
                d="M36.407 137.157H15.651a1.809 1.809 0 0 1-1.893-1.706v-14.623a.778.778 0 0 1 1.548 0v14.623a.329.329 0 0 0 .344.31h20.756a.329.329 0 0 0 .344-.31v-9.038a.778.778 0 0 1 1.549 0v9.038a1.809 1.809 0 0 1-1.892 1.706z"
                data-name="Path 178"
                transform="translate(-13.066 -94.148)"
            />
            <g id="Group_59" data-name="Group 59" transform="translate(0 19.009)">
                <path
                    id="Path_180"
                    d="M25.151 52.023H.774A.771.771 0 0 1 0 51.255v-4.13a1.888 1.888 0 0 1 1.892-1.879h22.141a1.888 1.888 0 0 1 1.892 1.879v4.13a.771.771 0 0 1-.774.768zm-23.6-1.538h22.826v-3.361a.343.343 0 0 0-.344-.342H1.892a.343.343 0 0 0-.344.342z"
                    data-name="Path 180"
                    transform="translate(0 -43.925)"
                />
                <path
                    id="Path_181"
                    d="M62.258 23.289a.769.769 0 0 1-.769-.769v-2.742a.769.769 0 0 1 1.538 0v2.742a.769.769 0 0 1-.769.769z"
                    data-name="Path 181"
                    transform="translate(-58.396 -19.009)"
                />
                <path
                    id="Path_182"
                    d="M151.871 23.289a.769.769 0 0 1-.769-.769v-2.742a.769.769 0 1 1 1.537 0v2.742a.769.769 0 0 1-.768.769z"
                    data-name="Path 182"
                    transform="translate(-143.448 -19.009)"
                />
                <path
                    id="Path_183"
                    d="M331.1 23.289a.769.769 0 0 1-.769-.769v-2.742a.769.769 0 1 1 1.537 0v2.742a.769.769 0 0 1-.768.769z"
                    data-name="Path 183"
                    transform="translate(-313.595 -19.009)"
                />
                <path
                    id="Path_184"
                    d="M241.483 23.289a.769.769 0 0 1-.769-.769v-2.742a.769.769 0 0 1 1.537 0v2.742a.769.769 0 0 1-.768.769z"
                    data-name="Path 184"
                    transform="translate(-228.52 -19.009)"
                />
                <path
                    id="Path_185"
                    d="M420.71 23.289a.769.769 0 0 1-.769-.769v-2.742a.769.769 0 1 1 1.537 0v2.742a.769.769 0 0 1-.768.769z"
                    data-name="Path 185"
                    transform="translate(-398.646 -19.009)"
                />
            </g>
            <g id="Group_61" data-name="Group 61" transform="translate(4.506 29.737)">
                <path
                    id="Path_190"
                    d="M347.1 212.669h-3.271a.668.668 0 0 1-.668-.668v-2.915a.668.668 0 0 1 .668-.668h3.271a.668.668 0 0 1 .668.668V212a.668.668 0 0 1-.668.669zm-2.6-1.335h1.936v-1.58H344.5z"
                    data-name="Path 190"
                    transform="translate(-343.16 -208.418)"
                />
            </g>
            <g id="Group_62" data-name="Group 62" transform="translate(10.777 29.737)">
                <path
                    id="Path_190-2"
                    d="M347.1 212.669h-3.271a.668.668 0 0 1-.668-.668v-2.915a.668.668 0 0 1 .668-.668h3.271a.668.668 0 0 1 .668.668V212a.668.668 0 0 1-.668.669zm-2.6-1.335h1.936v-1.58H344.5z"
                    data-name="Path 190"
                    transform="translate(-343.16 -208.418)"
                />
            </g>
            <g id="Group_63" data-name="Group 63" transform="translate(17.048 29.737)">
                <path
                    id="Path_190-3"
                    d="M347.1 212.669h-3.271a.668.668 0 0 1-.668-.668v-2.915a.668.668 0 0 1 .668-.668h3.271a.668.668 0 0 1 .668.668V212a.668.668 0 0 1-.668.669zm-2.6-1.335h1.936v-1.58H344.5z"
                    data-name="Path 190"
                    transform="translate(-343.16 -208.418)"
                />
            </g>
            <g id="Group_64" data-name="Group 64" transform="translate(4.506 35.112)">
                <path
                    id="Path_190-4"
                    d="M347.1 212.669h-3.271a.668.668 0 0 1-.668-.668v-2.915a.668.668 0 0 1 .668-.668h3.271a.668.668 0 0 1 .668.668V212a.668.668 0 0 1-.668.669zm-2.6-1.335h1.936v-1.58H344.5z"
                    data-name="Path 190"
                    transform="translate(-343.16 -208.418)"
                />
            </g>
            <g id="Group_65" data-name="Group 65" transform="translate(10.777 35.112)">
                <path
                    id="Path_190-5"
                    d="M347.1 212.669h-3.271a.668.668 0 0 1-.668-.668v-2.915a.668.668 0 0 1 .668-.668h3.271a.668.668 0 0 1 .668.668V212a.668.668 0 0 1-.668.669zm-2.6-1.335h1.936v-1.58H344.5z"
                    data-name="Path 190"
                    transform="translate(-343.16 -208.418)"
                />
            </g>
            <g id="Group_66" data-name="Group 66" transform="translate(17.048 35.112)">
                <path
                    id="Path_190-6"
                    d="M347.1 212.669h-3.271a.668.668 0 0 1-.668-.668v-2.915a.668.668 0 0 1 .668-.668h3.271a.668.668 0 0 1 .668.668V212a.668.668 0 0 1-.668.669zm-2.6-1.335h1.936v-1.58H344.5z"
                    data-name="Path 190"
                    transform="translate(-343.16 -208.418)"
                />
            </g>
        </g>
    </SvgIcon>
));

export const ChartIcon = withStyles(styles)((props: Props) => (
    <SvgIcon {...props}>
        <g transform="translate(-1 -1)">
            <path
                d="M6 25h19v-1h-1V1h-4v23h-2V6h-4v18h-2V11H8v13H6v-8H2v8H1v1h5zM21 2h2v22h-2zm-6 5h2v17h-2zm-6 5h2v12H9zm-6 5h2v7H3z"
                data-name="Path 496"
            />
        </g>{' '}
    </SvgIcon>
));

export const PlusIcon = withStyles(styles)((props: Props) => (
    <SvgIcon {...props}>
        <g transform="translate(-1 -1)">
            <path d="M13.5 1h-1v11.5H1v1h11.5V25h1V13.5H25v-1H13.5z" data-name="Path 771" />
        </g>
    </SvgIcon>
));

export const QuestionMarkIcon = withStyles(styles)((props: Props) => (
    <SvgIcon {...props} viewBox={'0 0 24 24'}>
        <g id="question" transform="translate(-.003)">
            <g id="Group_94" data-name="Group 94" transform="translate(.003)">
                <g id="Group_93" data-name="Group 93">
                    <path
                        id="Path_350"
                        d="M23.908 21.734l-1.648-4.8a11.725 11.725 0 1 0-10.522 6.534h.018a11.773 11.773 0 0 0 5.184-1.214l4.8 1.648a1.722 1.722 0 0 0 .56.098 1.711 1.711 0 0 0 1.611-2.266zm-1.414.758a.275.275 0 0 1-.3.068l-5.081-1.746a.711.711 0 0 0-.564.044 10.309 10.309 0 1 1 4.307-4.307.711.711 0 0 0-.044.564l1.751 5.085a.275.275 0 0 1-.069.292z"
                        data-name="Path 350"
                        transform="translate(-.003)"
                    />
                </g>
            </g>
            <g id="Group_96" data-name="Group 96" transform="translate(10.911 17.004)">
                <g id="Group_95" data-name="Group 95">
                    <path
                        id="Path_351"
                        d="M233.431 362.748a.711.711 0 1 0 .711.711.719.719 0 0 0-.711-.711z"
                        data-name="Path 351"
                        transform="translate(-232.719 -362.748)"
                    />
                </g>
            </g>
            <g id="Group_98" data-name="Group 98" transform="translate(8.084 5.984)">
                <g id="Group_97" data-name="Group 97">
                    <path
                        id="Path_352"
                        d="M175.935 127.674a3.544 3.544 0 0 0-3.54 3.54.711.711 0 1 0 1.423 0 2.117 2.117 0 1 1 2.117 2.117.712.712 0 0 0-.711.711v2.9a.711.711 0 1 0 1.423 0v-2.262a3.54 3.54 0 0 0-.711-7.008z"
                        data-name="Path 352"
                        transform="translate(-172.395 -127.674)"
                    />
                </g>
            </g>
        </g>
    </SvgIcon>
));

//  .cls-1{fill:#58c3f2}.cls-2{fill:#cee8fa}.cls-3{fill:#398bf7}

export const FilterIcon = withStyles(styles)((props: Props) => (
    <SvgIcon {...props} viewBox={'0 0 28 27'}>
        <g id="controller_1_" data-name="controller (1)" transform="translate(0 -4.918)">
            <path
                id="Path_244"
                d="M23.7 241.35H.774a.775.775 0 0 1 0-1.549H23.7a.775.775 0 0 1 0 1.549z"
                data-name="Path 244"
                transform="translate(0 -223.658)"
            />
            <path
                style={{ fill: '#fff' }}
                id="Path_252"
                d="M3.331 0A3.331 3.331 0 1 1 0 3.331 3.331 3.331 0 0 1 3.331 0z"
                data-name="Path 252"
                transform="translate(6.169 13.587)"
            />
            <g id="Group_77" data-name="Group 77" transform="translate(0 4.918)">
                <path
                    id="Path_245"
                    d="M117.017 178.358a4.106 4.106 0 1 1 4.106-4.106 4.11 4.11 0 0 1-4.106 4.106zm0-6.662a2.556 2.556 0 1 0 2.556 2.556 2.559 2.559 0 0 0-2.556-2.552z"
                    data-name="Path 245"
                    transform="translate(-107.516 -162.252)"
                />
                <path
                    id="Path_246"
                    d="M15.574 76.122H.775a.775.775 0 0 1 0-1.549h14.8a.775.775 0 1 1 0 1.549z"
                    data-name="Path 246"
                    transform="translate(0 -71.242)"
                />
                <path
                    id="Path_247"
                    d="M451.015 76.122h-1.475a.775.775 0 0 1 0-1.549h1.475a.775.775 0 1 1 0 1.549z"
                    data-name="Path 247"
                    transform="translate(-427.32 -71.242)"
                />
                <path
                    id="Path_248"
                    d="M313.562 13.13a.775.775 0 1 1 0-1.549 2.556 2.556 0 1 0-2.556-2.556.775.775 0 1 1-1.549 0 4.106 4.106 0 1 1 4.106 4.106z"
                    data-name="Path 248"
                    transform="translate(-294.672 -4.918)"
                />
                <path
                    id="Path_249"
                    d="M15.574 406.578H.775a.775.775 0 0 1 0-1.549h14.8a.775.775 0 0 1 0 1.549z"
                    data-name="Path 249"
                    transform="translate(0 -385.909)"
                />
                <path
                    id="Path_250"
                    d="M451.015 406.578h-1.475a.775.775 0 0 1 0-1.549h1.475a.775.775 0 1 1 0 1.549z"
                    data-name="Path 250"
                    transform="translate(-427.32 -385.909)"
                />
                <path
                    id="Path_251"
                    d="M313.562 343.586a.775.775 0 1 1 0-1.549 2.556 2.556 0 1 0-2.556-2.556.775.775 0 1 1-1.549 0 4.106 4.106 0 1 1 4.106 4.106z"
                    data-name="Path 251"
                    transform="translate(-294.672 -319.586)"
                />
            </g>
        </g>
    </SvgIcon>
));

export const ClockIcon = withStyles(styles)((props: Props) => (
    <SvgIcon {...props}>
        <g id="alarm-clock" transform="translate(-2.282 -11.514)">
            <g id="Group_78" data-name="Group 78" transform="translate(2.282 11.514)">
                <path
                    id="Path_257"
                    d="M174.482 199.959a1.215 1.215 0 0 1-.544-.129l-2.784-1.392a.7.7 0 1 1 .623-1.247l2.669 1.334 3.543-3.543a.7.7 0 1 1 .986.986l-3.634 3.634a1.214 1.214 0 0 1-.859.357z"
                    data-name="Path 257"
                    transform="translate(-164.199 -185.796)"
                />
                <path
                    id="Path_258"
                    d="M47.25 82.972a10.768 10.768 0 1 1 9.875-15.084.693.693 0 0 1-1.27.556 9.387 9.387 0 1 0 .784 3.759.693.693 0 0 1 1.387 0A10.784 10.784 0 0 1 47.25 82.972z"
                    data-name="Path 258"
                    transform="translate(-36.475 -58.972)"
                />
                <path
                    id="Path_259"
                    d="M223.72 270.285a.694.694 0 0 1-.333-.085l-6.319-3.438a.7.7 0 0 1 .666-1.225l6.319 3.438a.7.7 0 0 1-.334 1.31z"
                    data-name="Path 259"
                    transform="translate(-207.885 -253.006)"
                />
                <path
                    id="Path_260"
                    d="M242.567 65.328a.7.7 0 0 1-.7-.7v-2.5a.7.7 0 1 1 1.394 0v2.5a.7.7 0 0 1-.694.7z"
                    data-name="Path 260"
                    transform="translate(-231.792 -58.972)"
                />
                <path
                    id="Path_261"
                    d="M242.567 425.479a.7.7 0 0 1-.7-.7v-2.5a.7.7 0 1 1 1.394 0v2.5a.7.7 0 0 1-.694.7z"
                    data-name="Path 261"
                    transform="translate(-231.792 -401.479)"
                />
                <path
                    id="Path_262"
                    d="M399.822 268.224h-2.5a.7.7 0 0 1 0-1.394h2.5a.7.7 0 1 1 0 1.394z"
                    data-name="Path 262"
                    transform="translate(-378.969 -254.302)"
                />
                <path
                    id="Path_263"
                    d="M39.671 268.224h-2.5a.7.7 0 0 1 0-1.394h2.5a.7.7 0 0 1 0 1.394z"
                    data-name="Path 263"
                    transform="translate(-36.475 -254.302)"
                />
                <path
                    id="Path_264"
                    d="M201.6 15.372a.7.7 0 0 1-.7-.7v-1.438a.326.326 0 0 0-.325-.325h-2.44a.326.326 0 0 0-.325.325v1.441a.7.7 0 0 1-1.394 0v-1.441a1.722 1.722 0 0 1 1.72-1.72h2.44a1.722 1.722 0 0 1 1.72 1.72v1.441a.7.7 0 0 1-.696.697z"
                    data-name="Path 264"
                    transform="translate(-188.585 -11.514)"
                />
            </g>
        </g>
    </SvgIcon>
));

export const MobilePhone = withStyles(styles)((props: Props) => (
    <SvgIcon {...props} viewBox={'0 0 22.5 24'}>
        <g id="smartphone" transform="translate(-16.308)">
            <path
                id="Path_343"
                d="M17.02 20.6a.712.712 0 0 1-.712-.712V2.691A2.694 2.694 0 0 1 19 0h11.223a2.694 2.694 0 0 1 2.691 2.691v11.158a.712.712 0 0 1-1.424 0V2.691a1.268 1.268 0 0 0-1.267-1.267H19a1.268 1.268 0 0 0-1.267 1.267v17.194a.712.712 0 0 1-.713.715z"
                data-name="Path 343"
            />
            <g id="Group_92" data-name="Group 92" transform="translate(16.308 7.975)">
                <path
                    id="Path_345"
                    d="M30.223 394.057H19a2.694 2.694 0 0 1-2.691-2.691v-2.651a.712.712 0 0 1 .711-.715H32.2a.712.712 0 0 1 .712.712v2.651a2.694 2.694 0 0 1-2.689 2.694zm-12.491-4.63v1.939A1.268 1.268 0 0 0 19 392.634h11.223a1.268 1.268 0 0 0 1.267-1.267v-1.939z"
                    data-name="Path 345"
                    transform="translate(-16.308 -378.033)"
                />
                <path
                    id="Path_346"
                    d="M152 436.6h-2.286a.712.712 0 1 1 0-1.424H152a.712.712 0 0 1 0 1.424z"
                    data-name="Path 346"
                    transform="translate(-142.832 -422.904)"
                />
                <path
                    id="Path_347"
                    d="M327.977 344.073a.7.7 0 0 1-.694-.712V340.1a.694.694 0 1 1 1.388 0v3.261a.7.7 0 0 1-.694.712z"
                    data-name="Path 347"
                    transform="translate(-312.065 -331.488)"
                />
                <path
                    id="Path_348"
                    d="M429.512 172.4a.712.712 0 0 1-.459-1.256c.1-.084.2-.171.284-.26a4.47 4.47 0 0 0 0-6.314.712.712 0 1 1 1.007-1.007 5.9 5.9 0 0 1 0 8.328 6.124 6.124 0 0 1-.373.342.709.709 0 0 1-.459.167z"
                    data-name="Path 348"
                    transform="translate(-409.594 -163.355)"
                />
                <path
                    id="Path_349"
                    d="M389.523 190.764a.712.712 0 0 1-.441-1.271 3.113 3.113 0 0 0 .271-.241 3.063 3.063 0 0 0 0-4.327.712.712 0 0 1 1.007-1.007 4.483 4.483 0 0 1-.4 6.692.709.709 0 0 1-.437.154z"
                    data-name="Path 349"
                    transform="translate(-371.558 -182.717)"
                />
            </g>
        </g>
    </SvgIcon>
));

export const GalleryIcon = withStyles(styles)((props: Props) => (
    <SvgIcon {...props} viewBox="0 0 27.23 24.026">
        <g transform="translate(-4.102 -56.973)">
            <path
                d="M285.828,197.164a3.671,3.671,0,1,1,3.671-3.671A3.675,3.675,0,0,1,285.828,197.164Zm0-5.6a1.924,1.924,0,1,0,1.924,1.924A1.926,1.926,0,0,0,285.828,191.569Z"
                transform="translate(-261.26 -126.823)"
            />
            <path
                d="M107,266.684H94.888a.691.691,0,0,1-.621-.4.747.747,0,0,1,.068-.757l6.672-9.179a.678.678,0,0,1,1.143.056l5.444,9.179a.748.748,0,0,1,.016.731A.689.689,0,0,1,107,266.684ZM96.282,265.24h9.478l-4.259-7.181Z"
                transform="translate(-83.491 -188.681)"
            />
            <g transform="translate(4.102 56.999)">
                <path
                    d="M95.683,148.985H75.655a2.066,2.066,0,0,1-1.9-2.2V130.428a2.066,2.066,0,0,1,1.9-2.2H95.683a2.066,2.066,0,0,1,1.9,2.2V142.62a.75.75,0,1,1-1.485,0V130.428a.448.448,0,0,0-.411-.477H75.655a.448.448,0,0,0-.411.477v16.358a.448.448,0,0,0,.411.477H95.683a.87.87,0,0,1,0,1.722Z"
                    transform="translate(-70.348 -124.985)"
                />
                <path
                    d="M75.2,146.433s-1.44-.688-1.44-1.762V130.178A1.9,1.9,0,0,1,75.6,128.23H95.021c1.014,0,1.839.451,1.839,1.525-.032-.01-1.62,0-1.84,0H75.6a.411.411,0,0,0-.4.422v16.255Z"
                    transform="translate(-73.76 -128.256)"
                />
            </g>
        </g>
    </SvgIcon>
));

export const NotesIcon = withStyles(styles)((props: Props) => (
    <SvgIcon {...props} viewBox="0 0 27.23 24.026">
        <g transform="translate(-1.102 -56.973)">
            <g transform="translate(1.102 56.999)">
                <path
                    d="M95.683,148.985H75.655a2.066,2.066,0,0,1-1.9-2.2V130.428a2.066,2.066,0,0,1,1.9-2.2H95.683a2.066,2.066,0,0,1,1.9,2.2V142.62a.75.75,0,1,1-1.485,0V130.428a.448.448,0,0,0-.411-.477H75.655a.448.448,0,0,0-.411.477v16.358a.448.448,0,0,0,.411.477H95.683a.87.87,0,0,1,0,1.722Z"
                    transform="translate(-70.348 -124.985)"
                />
                <path
                    d="M75.2,146.433s-1.44-.688-1.44-1.762V130.178A1.9,1.9,0,0,1,75.6,128.23H95.021c1.014,0,1.839.451,1.839,1.525-.032-.01-1.62,0-1.84,0H75.6a.411.411,0,0,0-.4.422v16.255Z"
                    transform="translate(-73.76 -128.256)"
                />
                <path
                    d="M115.878,192.8a.732.732,0,0,1-.732-.732V180.331a.732.732,0,0,1,1.464,0v11.735A.732.732,0,0,1,115.878,192.8Z"
                    transform="translate(201.179 -103.076) rotate(90)"
                />
                <path
                    d="M115.878,192.8a.732.732,0,0,1-.732-.732V180.331a.732.732,0,0,1,1.464,0v11.735A.732.732,0,0,1,115.878,192.8Z"
                    transform="translate(201.179 -107.076) rotate(90)"
                />
                <path
                    d="M115.878,192.8a.732.732,0,0,1-.732-.732V180.331a.732.732,0,0,1,1.464,0v11.735A.732.732,0,0,1,115.878,192.8Z"
                    transform="translate(201.179 -99.076) rotate(90)"
                />
            </g>
        </g>
    </SvgIcon>
));

export const PasswordIcon = withStyles(styles)((props: Props) => (
    <SvgIcon {...props} viewBox="0 0 17.754 24">
        <g transform="translate(-93.991 -1)">
            <g transform="translate(93.991 1)">
                <path
                    d="M107.754,240.167H97.981c-2.2,0-3.99-1.468-3.99-3.273v-8.876a.724.724,0,0,1,.784-.643h16.186a.724.724,0,0,1,.784.643v8.876C111.745,238.7,109.955,240.167,107.754,240.167Zm-12.2-11.506v8.233a2.24,2.24,0,0,0,2.422,1.987h9.773a2.24,2.24,0,0,0,2.422-1.987v-8.233Zm7.309,7.468c-1.873,0-3.4-1.25-3.4-2.787s1.524-2.787,3.4-2.787,3.4,1.25,3.4,2.787S104.741,236.129,102.868,236.129Zm0-4.288a1.53,1.53,0,1,0,1.83,1.5A1.692,1.692,0,0,0,102.868,231.841Z"
                    transform="translate(-93.991 -216.167)"
                />
                <path
                    d="M125.634,11.505a.746.746,0,0,1-.817-.643V5.574C124.817,2.5,127.994,0,131.9,0h.818c3.905,0,7.083,2.5,7.083,5.574l.018,2.559a.841.841,0,0,1-1.634,0l-.018-2.559c0-2.364-2.444-4.288-5.448-4.288H131.9c-3,0-5.448,1.923-5.448,4.288v5.288A.746.746,0,0,1,125.634,11.505Z"
                    transform="translate(-123.432)"
                />
            </g>
        </g>
    </SvgIcon>
));

export const UtilizationIcon = withStyles(styles)((props: Props) => (
    <SvgIcon {...props} viewBox="0 0 24 24">
        <g transform="translate(0 -12.785)">
            <path
                d="M385.338,201.026a.667.667,0,0,1-.667-.667V188.632l-1.506,1.438a.667.667,0,1,1-.921-.965l2.633-2.514a.667.667,0,0,1,1.127.482v13.285A.667.667,0,0,1,385.338,201.026Z"
                transform="translate(-364.111 -164.241)"
            />
            <path
                d="M40.783,250.972H39.255v-8.009a.678.678,0,0,0-.007-.089l0-.021a.658.658,0,0,0-.018-.082v0a.666.666,0,0,0-.032-.082l-.008-.016a.665.665,0,0,0-.039-.069l0-.007a.671.671,0,0,0-.052-.067l-.014-.016a.661.661,0,0,0-.054-.053l-.008-.008a.687.687,0,0,0-.07-.05l-.016-.01a.657.657,0,0,0-.079-.041h0a.646.646,0,0,0-.085-.029l-.018,0a.664.664,0,0,0-.08-.015h-.008a.679.679,0,0,0-.071,0h-.037a.662.662,0,0,0-.071.008l-.016,0a.664.664,0,0,0-.084.022l-.012,0a.659.659,0,0,0-.071.03l-.015.007a.66.66,0,0,0-.074.044l-.013.01a.672.672,0,0,0-.059.048l-.009.007-.477.456-2.378-2.526a.667.667,0,0,0-.963-.008l-1.027,1.054c-.014.015-.028.03-.04.046s-.031.027-.045.042l-2.534,2.621a.667.667,0,1,0,.959.927l1.388-1.436v7.315H29.515V244.89c0-.022,0-.043,0-.064s0-.018,0-.027,0-.024-.005-.035,0-.021-.007-.032,0-.019-.007-.029-.007-.022-.011-.033l-.01-.026-.014-.032-.012-.026-.016-.029-.016-.026-.017-.026-.019-.026-.019-.023-.008-.01-.922-1.038a.667.667,0,0,0-.976-.023L23.542,247.4a.667.667,0,0,0-.189.465v3.776a.667.667,0,0,0,.667.667h9.656l.042,0,.042,0h7.023a.667.667,0,1,0,0-1.334Zm-16.1-2.838,3.217-3.3.277.313v5.829H24.686Zm9.74-5.941.352-.361,1.943,2.063a1.276,1.276,0,0,0,1.2.371v6.706H34.427Z"
                transform="translate(-22.261 -215.521)"
            />
            <path
                style={{ fill: '#58c3f2' }}
                d="M407.582,28.184l4.213-1.129-1.129,4.213Z"
                transform="translate(-388.462 -13.603)"
            />
            <path
                style={{ fill: '#58c3f2' }}
                d="M23.8,12.98a.67.67,0,0,0-.646-.173l-4.226,1.129a.666.666,0,0,0-.3,1.116l1.117,1.114-4.292,4.088-2.4-2.542a.67.67,0,0,0-.966-.008L7.614,22.282,6.2,20.7a.67.67,0,0,0-.979-.023L.19,25.827a.668.668,0,0,0,.958.931L5.68,22.119,7.091,23.7a.67.67,0,0,0,.979.023l4.492-4.593,2.383,2.524a.67.67,0,0,0,.949.025l4.8-4.573,1.03,1.027a.67.67,0,0,0,1.119-.3l1.132-4.213A.666.666,0,0,0,23.8,12.98Zm-1.951,3.4L20.4,14.926l1.988-.531Z"
                transform="translate(0)"
            />
        </g>
    </SvgIcon>
));

export const MapMarker = withStyles(styles)((props: Props) => (
    <SvgIcon {...props} viewBox="0 0 18 24">
        <g transform="translate(-68.664)">
            <path
                d="M77.667,24h0a1.193,1.193,0,0,1-.853-.356c-1.911-1.939-8.146-8.717-8.146-14.537A9.111,9.111,0,0,1,71.3,2.526a9.283,9.283,0,0,1,12.732,0,9.111,9.111,0,0,1,2.634,6.581c0,2.953-1.521,6.447-4.521,10.383a.686.686,0,0,1-.945.136.648.648,0,0,1-.139-.922c1.941-2.547,4.254-6.279,4.254-9.6a7.651,7.651,0,1,0-15.3,0c0,5.318,6.128,11.94,7.653,13.505.339-.343.829-.855,1.4-1.5a.687.687,0,0,1,.952-.065.648.648,0,0,1,.067.929c-.664.745-1.221,1.32-1.571,1.671A1.193,1.193,0,0,1,77.667,24Z"
                transform="translate(0)"
            />
            <ellipse cx="2.543" cy="2.398" rx="2.543" ry="2.398" transform="translate(75.12 6.621)" />
            <g transform="translate(74.737 6.146)">
                <path
                    d="M130.67,69.557A2.927,2.927,0,1,1,133.6,66.63,2.93,2.93,0,0,1,130.67,69.557Zm0-5.213a2.286,2.286,0,1,0,2.286,2.286A2.288,2.288,0,0,0,130.67,64.345Z"
                    transform="translate(-127.743 -63.703)"
                />
            </g>
        </g>
    </SvgIcon>
));
