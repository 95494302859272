import React, { useContext } from 'react';
import { InstantSearch, Configure, Index } from 'react-instantsearch-dom';
import { observer } from 'mobx-react';
import AutoComplete from './AlgoliaAutosuggest';
import { IndexSearchConfig } from './algoliaSearchIndexConfigurations';
import { StoreContext } from '../../../App';

interface IAlgoliaAutocompleteProps {
    indexSearchConfigs: IndexSearchConfig[];
    selectHitId: boolean;
    className?: string;
    placeholder?: string;
    restrictSearchableAttributes?: string[];
    filter?: string;
    error?: boolean;
    helperText?: string;
    required?: boolean;
    onHitSelected?: (item: any) => void;
    onHitIdSelected?: (id: string, indexName: any) => void;
}

const AlgoliaAutocomplete = observer((props: IAlgoliaAutocompleteProps) => {
    const { authStore, uiStore } = useContext(StoreContext);

    if (!authStore.searchClient || !props.indexSearchConfigs) {
        return null;
    }
    return (
        <InstantSearch
            searchClient={authStore.searchClient}
            indexName={props.indexSearchConfigs[0].indexName}
            root={{
                Root: 'section',
                props: {
                    style: {
                        width: '100%',
                    },
                },
            }}
        >
            {props.indexSearchConfigs.map((x: any, index: number) => {
                return <Index key={index} indexName={x.indexName} />;
            })}

            <AutoComplete
                placeholder={props.placeholder}
                indexSearchConfigs={props.indexSearchConfigs}
                uiStore={uiStore}
                selectHitId={props.selectHitId}
                error={props.error ? props.error : false}
                helperText={props.helperText ? props.helperText : undefined}
                onHitSelected={props.onHitSelected}
                onHitIdSelected={props.onHitIdSelected}
                required={props.required}
            />
            <Configure
                hitsPerPage={5}
                restrictSearchableAttributes={props.restrictSearchableAttributes}
                filters={props.filter}
            />
        </InstantSearch>
    );
});

export default AlgoliaAutocomplete;
