import React, { SyntheticEvent, useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { createStyles, Theme, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Link, RouteComponentProps } from '@reach/router';
import Button from '@material-ui/core/Button';
import PlanuxLogo from '../../../assets/planux_logo.svg';
import Zoom from '@material-ui/core/Zoom';
import green from '@material-ui/core/colors/green';
import ErrorMessage from '../../../common/components/ErrorMessage';
import { ForgotPasswordScreenVm } from './forgotPasswordScreen.vm';
import SuccessScreen from './SuccessScreen';
import TopProgress from '../../../common/components/TopProgress';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { observer } from 'mobx-react-lite';
import { StoreContext } from '../../../App';
import { useVm } from '../../../common/hooks/useVm';
import { useDocumentSubTitle } from '../../../common/hooks/useDocumentTitle';
import CenterPaper from '../../../common/components/CenterPaper';

const useStyles = makeStyles(({ breakpoints, spacing }: Theme) =>
    createStyles({
        root: {
            width: '370px',
            [breakpoints.down('xs')]: {
                width: '100%',
            },
        },
        logo: {
            width: '100%',
            maxWidth: '154px',
            alignSelf: 'center',
            margin: spacing(2),
        },
        buttonProgress: {
            color: green[500],
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -12,
            marginLeft: -12,
        },
    })
);

type Props = RouteComponentProps;

const ForgotPasswordScreen = observer((props: Props) => {
    const { authStore } = useContext(StoreContext);
    const { t } = useTranslation();
    const classes = useStyles();
    useDocumentSubTitle(t('forgot-password'));

    const vm = useVm(ForgotPasswordScreenVm, authStore) as ForgotPasswordScreenVm;

    if (vm.successMessage !== '') {
        return <SuccessScreen successMessage={vm.successMessage} />;
    }

    const handleForgotPasswordClick = (event: SyntheticEvent<any>) => {
        event.preventDefault();
        vm.sendForgotPasswordEmail();
    };

    const handlerUsernameChange = (event: any): void => {
        const { value } = event.target;
        vm.setEmail(value);
    };

    return (
        <CenterPaper>
            <div className={classes.root}>
                <TopProgress
                    inProgress={vm.isLoading!}
                    style={{ position: 'absolute', top: '0px', left: '0', right: '0' }}
                />
                <form noValidate onSubmit={handleForgotPasswordClick}>
                    <Grid container direction={'column'} spacing={3} wrap={'nowrap'}>
                        <Grid item container justify={'center'}>
                            <Zoom in timeout={500}>
                                <img className={classes.logo} src={PlanuxLogo} alt="logo" />
                            </Zoom>
                        </Grid>
                        <Grid item>
                            <TextField
                                variant={'outlined'}
                                fullWidth={true}
                                id="email"
                                label={t('email')}
                                disabled={vm.isLoading}
                                error={!!vm.validationErrors.email}
                                helperText={vm.validationErrors.email}
                                value={vm.email}
                                onChange={handlerUsernameChange}
                                required={true}
                            />
                        </Grid>
                        <ErrorMessage appError={vm.error} />
                        <Grid item>
                            <Button
                                size={'large'}
                                variant={'contained'}
                                type={'submit'}
                                color={'primary'}
                                fullWidth
                                disabled={vm.isLoading}
                            >
                                {t('send-reset-email')}
                            </Button>
                        </Grid>
                        <Grid item>
                            <Typography align={'center'}>
                                <Link to="/">{t('back-to-login')}</Link>
                            </Typography>
                        </Grid>
                    </Grid>
                </form>
            </div>
        </CenterPaper>
    );
});

export default ForgotPasswordScreen;
