import React, { SyntheticEvent, useContext } from 'react';
import {
    Grid,
    TextField,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    createStyles,
    Typography,
    Divider,
    makeStyles,
    CircularProgress,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import CompleteEmployeeProfileDialogVm from './completeEmployeerProfileDialog.vm';
import ErrorMessage from '../../../common/components/ErrorMessage';
import { StoreContext } from '../../../App';
import { observer, useLocalStore } from 'mobx-react-lite';
import CountryAutocomplete from '../../../common/components/CountryAutocomplete';
import { CountryDto } from '../../../api/dtos/generated/dtos.generated';

const useStyles = makeStyles(() =>
    createStyles({
        formControl: {
            width: '100%',
        },
    })
);

interface ICompleteEmployeeProfileDialog {
    open: boolean;
}

const CompleteEmployeeProfileDialog = observer(({ open }: ICompleteEmployeeProfileDialog) => {
    const { authStore, uiStore } = useContext(StoreContext);
    const { t } = useTranslation();
    const classes = useStyles();
    const vm = useLocalStore(() => new CompleteEmployeeProfileDialogVm(authStore, uiStore), {});

    const handleChange = (event: any): void => {
        const { name, value } = event.target;
        vm.updateProperty(name, value);
    };

    const handleAddressChange = (event: any): void => {
        const { name, value } = event.target;
        vm.address.updateProperty(name, value);
    };

    const handleSubmitClick = (event: SyntheticEvent<any>) => {
        event.preventDefault();
        vm.completeEmployeeProfile();
    };

    const handleAddressCountry = (country: CountryDto | undefined): void => {
        vm.address.updateCountry(country);
    };

    return (
        <Dialog open={open} fullWidth={true} maxWidth={'sm'}>
            <DialogTitle>{t('complete-user-profile')}</DialogTitle>
            <DialogContent>
                <form noValidate onSubmit={handleSubmitClick}>
                    <Grid container direction={'column'} spacing={3}>
                        <Grid item>
                            <Typography gutterBottom>{t('user-details')}</Typography>
                            <Divider />
                        </Grid>
                        <Grid item>
                            <TextField
                                variant={'outlined'}
                                className={classes.formControl}
                                id="firstName"
                                name="firstName"
                                label={t('firstName')}
                                disabled={vm.isLoading}
                                error={!!vm.validationErrors.firstName}
                                helperText={vm.validationErrors.firstName}
                                value={vm.firstName}
                                onChange={(e) => handleChange(e)}
                                required={true}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                variant={'outlined'}
                                className={classes.formControl}
                                id="lastName"
                                name="lastName"
                                label={t('lastName')}
                                disabled={vm.isLoading}
                                error={!!vm.validationErrors.lastName}
                                helperText={vm.validationErrors.lastName}
                                value={vm.lastName}
                                onChange={(e) => handleChange(e)}
                                required={true}
                            />
                        </Grid>
                        <br />
                        <Grid item>
                            <Typography gutterBottom>{t('company-details')}</Typography>
                            <Divider />
                        </Grid>
                        <Grid item>
                            <TextField
                                variant={'outlined'}
                                className={classes.formControl}
                                id="street"
                                name="street"
                                label={t('street')}
                                disabled={vm.isLoading}
                                error={!!vm.address.validationErrors.street}
                                helperText={vm.address.validationErrors.street}
                                value={vm.address.street}
                                onChange={(e) => handleAddressChange(e)}
                                required={true}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                variant={'outlined'}
                                className={classes.formControl}
                                id="addition"
                                name="addition"
                                label={t('addition')}
                                disabled={vm.isLoading}
                                error={!!vm.address.validationErrors.addition}
                                helperText={vm.address.validationErrors.addition}
                                value={vm.address.addition}
                                onChange={(e) => handleAddressChange(e)}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                variant={'outlined'}
                                className={classes.formControl}
                                id="zip"
                                name="zip"
                                label={t('zip')}
                                disabled={vm.isLoading}
                                error={!!vm.address.validationErrors.zip}
                                helperText={vm.address.validationErrors.zip}
                                value={vm.address.zip}
                                onChange={(e) => handleAddressChange(e)}
                                required={true}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                variant={'outlined'}
                                className={classes.formControl}
                                id="city"
                                name="city"
                                label={t('city')}
                                disabled={vm.isLoading}
                                error={!!vm.address.validationErrors.city}
                                helperText={vm.address.validationErrors.city}
                                value={vm.address.city}
                                onChange={(e) => handleAddressChange(e)}
                                required={true}
                            />
                        </Grid>
                        <Grid item>
                            <CountryAutocomplete
                                required
                                countryCode={vm?.address?.countryCode}
                                onChange={handleAddressCountry}
                                disabled={vm.isLoading}
                                error={!!vm.address.validationErrors.country}
                                helperText={vm.address.validationErrors.country}
                            />
                        </Grid>
                        <ErrorMessage appError={vm.error} />
                        <Grid item>
                            <Button
                                size={'large'}
                                variant={'contained'}
                                type={'submit'}
                                color={'primary'}
                                fullWidth
                                disabled={vm.isLoading}
                            >
                                {!vm.isLoading ? t('submit') : <CircularProgress size={32} color="primary" />}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </DialogContent>
        </Dialog>
    );
});

export default CompleteEmployeeProfileDialog;
