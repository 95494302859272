import { action, computed, observable, reaction, runInAction } from 'mobx';
import { RootStore } from '../../../stores/rootStore';
import { SortDirection } from '../../../common/models/sortDirection';
import { SortSetting } from '../../../common/models/sortSetting';
import { AppError } from '../../../common/models/appError';
import { Resource } from '../models/resource';
import Firebase from '../../../api/firebase';
import { ResourceDto } from '../../../api/dtos/generated/dtos.generated';
import { Log } from '../../../log';
import i18n from 'i18next';
import { createPdfDocDefinition, buildTableBody, openOrDownloadPdf } from '../../../common/helpers/pdfHelper';
import { format } from 'date-fns';
import firebase from 'firebase';

export class ResourcesStore {
    public static storeName: string = 'resourcesStore';

    public store: RootStore;
    @observable private internalResources: Resource[] = [];
    @observable public sortSetting: SortSetting = { fieldName: 'name', direction: SortDirection.Asc };
    @observable public isLoading: boolean = false;
    @observable public initialLoadDone: boolean = false;
    @observable error?: AppError = undefined;
    @observable public inAction: boolean = false;
    @observable public detailId?: string;
    @observable public isExportInProgress: boolean = false;

    private unsubscribeResourceChangesFunc?: () => void;
    private isFirstSnapShotHandled = false;

    constructor(store: RootStore) {
        this.store = store;

        reaction(
            () => this.store.authStore.isAuthenticated,
            () => this.subscribeResourceChanges()
        );
    }

    @computed
    public get resources() {
        return this.internalResources.slice().sort((a: Resource, b: Resource) => {
            const aValue = this.sortSetting.fieldName.split('.').reduce((x: any, y) => x[y].toLowerCase(), a);
            const bValue = this.sortSetting.fieldName.split('.').reduce((x: any, y) => x[y].toLowerCase(), b);

            return this.sortSetting.direction === SortDirection.Desc
                ? aValue < bValue
                    ? 1
                    : -1
                : aValue > bValue
                ? 1
                : -1;
        });
    }

    @computed
    public get resourceCount() {
        return this.internalResources.length;
    }

    @computed
    public get hasResources() {
        return this.internalResources.length > 0;
    }

    @computed
    public get inProgress() {
        return this.isLoading || this.inAction;
    }

    @computed
    public get isDetailOpen() {
        return !!this.detailId;
    }

    @action
    public setDetailId(id?: string) {
        this.detailId = id;
    }

    @action
    public async subscribeResourceChanges() {
        this.isLoading = true;

        const query = Firebase.firestore
            .collection('tenants')
            .doc(this.store.authStore.tenantId)
            .collection('resources');

        this.unsubscribeResourceChangesFunc = query.onSnapshot(async (snapshot: firebase.firestore.QuerySnapshot) => {
            try {
                runInAction(() => (this.isLoading = true));

                if (this.isFirstSnapShotHandled) {
                    snapshot.docChanges().forEach((change: firebase.firestore.DocumentChange) => {
                        const dto = change.doc.data() as ResourceDto;
                        if (change.type === 'added') {
                            this.addResource(Resource.createFromDto(dto));
                        }
                        if (change.type === 'modified') {
                            this.upsertResource(Resource.createFromDto(dto));
                        }
                        if (change.type === 'removed') {
                            this.removeResource(dto.id);
                        }
                    });
                } else {
                    const resources: Resource[] = [];
                    snapshot.docs.forEach((value: firebase.firestore.QueryDocumentSnapshot) => {
                        const dto = value.data() as ResourceDto;
                        resources.push(Resource.createFromDto(dto));
                    });

                    this.internalResources = resources;
                    this.isFirstSnapShotHandled = true;
                }
            } finally {
                runInAction(() => (this.isLoading = false));
            }
        });
    }

    @action
    private async addResource(resource: Resource) {
        this.internalResources.push(resource);
    }

    @action
    private async upsertResource(resource: Resource) {
        const project = this.internalResources.find((x) => x.id === resource.id);
        if (project) {
            this.removeResource(resource.id);
        }

        this.addResource(resource);
    }

    @action
    private async removeResource(id: string) {
        const index = this.internalResources.findIndex((x) => x.id === id);
        if (index > -1) {
            this.internalResources.splice(index, 1);
        }
    }

    @action
    setSortSetting(sortSetting: SortSetting) {
        this.sortSetting = sortSetting;
    }

    @action
    public createPdf = async () => {
        this.isExportInProgress = true;
        this.error = undefined;

        Log.export('resourceList', 'pdf');

        const pdfMake = (await import(/* webpackChunkName: "pdfMake" */ 'pdfmake/build/pdfmake')).default;
        const pdfFonts = (await import(/* webpackChunkName: "pdfFonts" */ 'pdfmake/build/vfs_fonts')).default;

        const { vfs } = pdfFonts.pdfMake;
        pdfMake.vfs = vfs;

        const tenant = this.store.tenantStore.tenant;

        const tableHeadColumns = [
            { field: 'name', header: i18n.t('name') },
            { field: 'description', header: i18n.t('description') },
        ];
        const tableWidths = ['auto', '*'];

        const pdfTitle = i18n.t('resources');

        if (tenant) {
            const docDefinition = createPdfDocDefinition(
                tableWidths,
                pdfTitle,
                buildTableBody(this.resources, tableHeadColumns),
                tenant.toDto()
            );

            const pdf = pdfMake.createPdf(docDefinition as any);
            openOrDownloadPdf(pdf, `${pdfTitle}_${format(new Date(), 'd.M.yyyy')}.pdf`);

            runInAction(() => (this.isExportInProgress = false));
        }
    };
}
