import * as React from 'react';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';

const styles = (theme: Theme) =>
    createStyles({
        container: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            zIndex: 9,
            background: `rgba(255,255,255,0.9)`,
            borderRadius: '10px'
        }
    });

type Props = WithStyles<typeof styles>;

export function center(Comp: React.ComponentClass | React.FunctionComponent): any {
    class WrappedComponent extends React.Component<Props> {
        public render() {
            const { classes, ...other } = this.props;
            return (
                <div className={classes.container}>
                    <Comp {...other} />
                </div>
            );
        }
    }

    const styledComponent = withStyles(styles, { withTheme: true })(WrappedComponent);

    return styledComponent;
}
