import { action, computed, observable, reaction, runInAction } from 'mobx';
import { Tenant } from '../models/tenant';
import { tenantService } from '../../../api/tenantService';
import { RootStore } from '../../../stores/rootStore';
import i18n from 'i18next';
import BaseStore from '../../../stores/baseStore';
import hash from 'object-hash';
import { ImageCategoryType, ImageType } from '../../../api/dtos/generated/dtos.generated';
import { storageService } from '../../../api/storageService';

class TenantStore extends BaseStore {
    static storeName: string = 'tenantStore';
    public readonly store: RootStore;
    @observable tenant?: Tenant;

    constructor(store: RootStore) {
        super();
        this.store = store;

        reaction(
            () => this.error,
            (error) => {
                if (error !== undefined) {
                    this.store.uiStore!.enqueueNotification({
                        message: i18n.t(error!.key!),
                        type: 'error',
                        persist: true,
                    });
                    this.error = undefined;
                }
            }
        );
        reaction(
            () => this.successMessage!,
            (successMessage: any) => {
                if (this.successMessage !== undefined) {
                    this.store.uiStore!.enqueueNotification({
                        message: i18n.t(successMessage),
                        type: 'success',
                        persist: false,
                    });
                    this.successMessage = undefined;
                }
            }
        );
    }

    @action
    public async save(tenant: Tenant) {
        this.inProgress = true;

        if (tenant.changedImage) {
            await storageService.uploadImage(tenant.changedImage, tenant.id, ImageType.Png, ImageCategoryType.Tenant);
            tenant.updateProperty('imageHash', hash.sha1(tenant.changedImage));
        }

        await this.handle(tenantService.upsertTenant(tenant.toDto()));
        runInAction(() => {
            this.inProgress = false;
        });
    }

    @computed
    public get customerId() {
        return this.tenant!.subscription.customerId;
    }

    @computed
    public get priceId() {
        return this.tenant!.subscription.priceId;
    }

    @computed
    public get tenantId() {
        return this.store.authStore!.tenantId;
    }

    @computed
    public get lastPlanningItemChange() {
        return this.tenant ? this.tenant._lastPlanningProjectChanges : new Date();
    }

    @computed
    public get lastProjectChange() {
        return this.tenant ? this.tenant._lastProjectChanges : new Date();
    }
}

export default TenantStore;
