import { computed } from 'mobx';
import { PlanningBoardItem } from '../../../planning/models/planningBoardItem';
import { eachDayOfInterval } from 'date-fns/esm';
import {
    isWithinInterval,
    isFriday,
    isMonday,
    isSaturday,
    isSunday,
    isThursday,
    isTuesday,
    isWednesday,
} from 'date-fns';
import { RootStore } from '../../../../stores/rootStore';

export class CapacityUtilizationWidgetVm {
    private rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
    }

    @computed
    public get isLoading() {
        return (
            this.rootStore.planningBoardItemsStore.isLoading ||
            this.rootStore.planningStore.isLoading ||
            this.rootStore.employeesStore.isLoading ||
            this.rootStore.tenantStore.isLoading
        );
    }

    @computed
    public get currentUtilization(): number {
        if (!this.rootStore.tenantStore.tenant || !this.rootStore.employeesStore.hasEmployees) {
            return 0;
        }

        const presence = this.rootStore.tenantStore.tenant.presence;
        let plannedHours = 0;
        let absenceHours = 0;

        const projectPlanningItems: PlanningBoardItem[] = this.rootStore.planningBoardItemsStore.planningBoardItems.filter(
            (item: PlanningBoardItem) =>
                item.employeeId &&
                isWithinInterval(item.date, {
                    start: this.rootStore.dashboardStore.filter.startDate,
                    end: this.rootStore.dashboardStore.filter.endDate,
                })
        );

        projectPlanningItems.forEach((item: PlanningBoardItem) => {
            if (item.period === 'allDay') {
                if (item.absence) {
                    absenceHours += presence.upTime;
                } else {
                    plannedHours += presence.upTime;
                }
            } else if (item.period === 'morning') {
                if (item.absence) {
                    absenceHours += presence.upTimeMorning;
                } else {
                    plannedHours += presence.upTimeMorning;
                }
            } else if (item.period === 'afternoon') {
                if (item.absence) {
                    absenceHours += presence.upTimeAfternoon;
                } else {
                    plannedHours += presence.upTimeAfternoon;
                }
            }
        });

        const allDaysInRange = eachDayOfInterval({
            start: this.rootStore.dashboardStore.filter.startDate,
            end: this.rootStore.dashboardStore.filter.endDate,
        });

        let totalWorkingHoursPerEmployee = 0;

        allDaysInRange.forEach((date) => {
            if (
                (isMonday(date) && presence.isMondayActive) ||
                (isTuesday(date) && presence.isTuesdayActive) ||
                (isWednesday(date) && presence.isWednesdayActive) ||
                (isThursday(date) && presence.isThursdayActive) ||
                (isFriday(date) && presence.isFridayActive) ||
                (isSaturday(date) && presence.isSaturdayActive) ||
                (isSunday(date) && presence.isSundayActive)
            ) {
                totalWorkingHoursPerEmployee += presence.upTime;
            }
        });

        const totalWorkingHours = this.rootStore.employeesStore.employeesCount * totalWorkingHoursPerEmployee;

        return (plannedHours / (totalWorkingHours - absenceHours)) * 100;
    }
}
