import { BaseModel } from '../../../common/stores/model/baseModel';
import { action, computed, observable, runInAction } from 'mobx';
import * as uuid from 'uuid';
import i18n from 'i18next';
import Validator from 'validatorjs';
import { validate, validateField } from '../../../common/helpers/validationHelper';
import { ResourceDto } from '../../../api/dtos/generated/dtos.generated';
import { getResourcesPath } from '../../../common/helpers/storageHelper';
import { mapTrackingFieldsToDto, mapTrackingFieldsToModel } from '../../../common/helpers/trackingFields';

export class Resource extends BaseModel {
    private static rules = {
        name: 'required|max:100',
        description: 'max:8000',
    };

    private static getValidator(data: any, rules: any) {
        const validator = new Validator(data, rules);

        validator.setAttributeNames({
            name: i18n.t('name'),
            description: i18n.t('description'),
        });

        return validator;
    }

    id: string;
    @observable name: string = '';
    @observable description: string = '';
    @observable validationErrors: { [index: string]: any } = {};
    @observable isDirty: boolean = false;
    @observable isNew: boolean = false;
    @observable changedImage?: string;
    @observable imageHash?: string;
    @observable imageCount: number = 0;
    @observable noteCount: number = 0;
    @observable tenantId?: string;

    public static createFromDto(dto: ResourceDto): Resource {
        const resource = new Resource(dto.id);
        runInAction(() => {
            resource.isNew = false;
            resource.name = dto.name;
            resource.description = dto.description ? dto.description : '';
            resource.imageHash = dto.imageHash ? dto.imageHash : undefined;
            resource.tenantId = dto.tenantId;
            resource.imageCount = dto.imageCount || 0;
            resource.noteCount = dto.noteCount || 0;
        });
        mapTrackingFieldsToModel(resource, dto);
        return resource;
    }

    constructor(id: string = uuid.v4()) {
        super();
        this.id = id;
        runInAction(() => {
            this.isNew = true;
        });
    }

    @action
    updateProperty(field: string, value: any) {
        if (!this.isDirty) {
            this.isDirty = true;
        }

        (this as any)[field] = value;

        if (Resource.rules.hasOwnProperty(field)) {
            const validator = Resource.getValidator({ [field]: value }, { [field]: (Resource.rules as any)[field] });
            this.validationErrors = validateField(validator, field, this.validationErrors);
        }
    }

    @action
    validateAll(data: any) {
        const validator = Resource.getValidator(data, Resource.rules);
        this.validationErrors = validate(validator);
    }

    @computed get isValid() {
        return Object.keys(this.validationErrors).length === 0;
    }

    @computed get imagePath(): string | undefined {
        return this.imageHash ? getResourcesPath(this.id) : undefined;
    }

    @computed get imageThumbPath(): string | undefined {
        return this.imageHash ? getResourcesPath(this.id, true) : undefined;
    }

    public toDto(tenantId: string): ResourceDto {
        const dto = {
            id: this.id,
            tenantId: tenantId,
            name: this.name,
            description: this.description,
            imageHash: this.imageHash ? this.imageHash : null,
        };

        mapTrackingFieldsToDto(dto, this);

        return dto;
    }
}
