import { Language } from '../../api/dtos/generated/dtos.generated';

export const getLanguage = (lang: string): Language => {
    let langCode = lang.substring(0, 2);
    langCode = langCode.charAt(0).toUpperCase() + langCode.slice(1);

    if (Object.values(Language).includes(langCode)) {
        const language: Language = Language[langCode as any] as any;

        return language;
    }

    return Language.De; // default language
};
