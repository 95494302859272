import { action, computed, observable, runInAction } from 'mobx';
import { EmployeeDto } from '../../../api/dtos/generated/dtos.generated';
import { validate, validateField } from '../../../common/helpers/validationHelper';
import * as uuid from 'uuid';
import i18n from 'i18next';
import { BaseModel } from '../../../common/stores/model/baseModel';
import Validator from 'validatorjs';
import { getEmployeesPath } from '../../../common/helpers/storageHelper';
import { EmployeeLoginData } from './employeeLoginData';
import { mapTrackingFieldsToModel } from '../../../common/helpers/trackingFields';

export class Employee extends BaseModel {
    id: string;
    @observable firstName: string = '';
    @observable lastName: string = '';
    @observable phone: string = '';
    @observable description?: string = '';
    @observable jobTitle?: string = '';
    @observable isProfileComleted: boolean = false;
    @observable validationErrors: { [index: string]: any } = {};
    @observable isDirty: boolean = false;
    @observable isNew: boolean = false;
    @observable changedImage?: string;
    @observable imageHash?: string;
    @observable imageCount: number = 0;
    @observable noteCount: number = 0;
    @observable tenantId?: string;
    @observable loginData?: EmployeeLoginData;

    private static rules = {
        firstName: 'required|max:255',
        lastName: 'required|max:255',
        phone: 'max:255',
        jobTitle: 'max:255',
        description: 'max:8000',
    };

    private static attributeNames = {
        firstName: i18n.t('firstName'),
        lastName: i18n.t('lastName'),
        phone: i18n.t('phone'),
        jobTitle: i18n.t('jobTitle'),
        description: i18n.t('description'),
    };

    private static getValidator(data: any, rules: any) {
        const validator = new Validator(data, rules);
        validator.setAttributeNames(this.attributeNames);
        return validator;
    }

    public static createFromDto(dto: EmployeeDto): Employee {
        const employee = new Employee(dto.id);
        runInAction(() => {
            employee.isNew = false;
            employee.firstName = dto.firstName;
            employee.lastName = dto.lastName;
            employee.jobTitle = dto.jobTitle;
            employee.description = dto.description ? dto.description : '';
            employee.isProfileComleted = dto.isProfileCompleted;
            employee.imageHash = dto.imageHash ? dto.imageHash : undefined;
            employee.tenantId = dto.tenantId;
            employee.phone = dto.phone ? dto.phone : '';
            employee.imageCount = dto.imageCount || 0;
            employee.noteCount = dto.noteCount || 0;

            if (dto.loginExists) {
                employee.loginData = EmployeeLoginData.createFromDto(dto.loginId, dto.email, dto.role, dto.language);
            }
        });

        mapTrackingFieldsToModel(employee, dto);

        return employee;
    }

    constructor(id: string = uuid.v4()) {
        super();
        this.id = id;
        runInAction(() => {
            this.isNew = true;
        });
    }

    @action
    createLoginData() {
        this.loginData = new EmployeeLoginData();
    }

    @action
    removeLoginData() {
        this.loginData = undefined;
    }

    @action
    updateProperty(field: string, value: any) {
        if (!this.isDirty) {
            this.isDirty = true;
        }
        (this as any)[field] = value;

        if (Employee.rules.hasOwnProperty(field)) {
            const validator = Employee.getValidator({ [field]: value }, { [field]: (Employee.rules as any)[field] });
            this.validationErrors = validateField(validator, field, this.validationErrors);
        }
    }

    @action
    validateAll(data: any) {
        const validator = Employee.getValidator(data, Employee.rules);
        this.validationErrors = validate(validator);
    }

    @computed get isValid() {
        return Object.keys(this.validationErrors).length === 0;
    }

    @computed get imagePath(): string | undefined {
        return this.imageHash ? getEmployeesPath(this.id) : undefined;
    }

    @computed get imageThumbPath(): string | undefined {
        return this.imageHash ? getEmployeesPath(this.id, true) : undefined;
    }

    @computed get displayName(): string {
        return this.firstName + ' ' + this.lastName;
    }

    @computed get roleName() {
        return this.loginData ? i18n.t(this.loginData.role + 'Role') : '';
    }

    public toDto(): EmployeeDto {
        return {
            id: this.id,
            tenantId: this.tenantId ? this.tenantId : '',
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.loginData ? this.loginData.email : null,
            jobTitle: this.jobTitle,
            description: this.description,
            loginId: this.loginData ? this.loginData.loginId : null,
            role: this.loginData ? this.loginData.role : null,
            isProfileCompleted: this.isProfileComleted,
            imageHash: this.imageHash ? this.imageHash : null,
            loginExists: !!this.loginData,
            language: this.loginData ? this.loginData.language : null,
            phone: this.phone,
        };
    }
}
