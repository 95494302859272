import { observable, action, computed } from 'mobx';
import Validator from 'validatorjs';
import { validateField, validate } from '../../../common/helpers/validationHelper';
import { SubscriptionDto } from '../../../api/dtos/generated/dtos.generated';
import * as uuid from 'uuid';

export class TenantSubscription {
    @observable id: string;
    @observable cancelAtPeriodEnd: boolean = false;
    @observable currentPeriodEnd: number = new Date().getDate();
    @observable customerId: string = '';
    @observable priceId: string = '';
    @observable productId: string = '';
    @observable status: string = '';
    @observable maxNumberOfEmployees: number = 1;
    @observable trialEnd: number = new Date().getDate();
    @observable validationErrors: { [index: string]: any } = {};
    @observable isDirty: boolean = false;

    private static rules = {};

    private static attributeNames = {};

    private static getValidator(data: any, rules: any) {
        const validator = new Validator(data, rules);
        validator.setAttributeNames(this.attributeNames);
        return validator;
    }

    public static createFromDto(dto: SubscriptionDto): TenantSubscription {
        const tenantSubscription = new TenantSubscription();
        tenantSubscription.id = dto.id;
        tenantSubscription.cancelAtPeriodEnd = dto.cancelAtPeriodEnd;
        tenantSubscription.currentPeriodEnd = dto.currentPeriodEnd;
        tenantSubscription.customerId = dto.customerId;
        tenantSubscription.priceId = dto.priceId;
        tenantSubscription.productId = dto.productId;
        tenantSubscription.status = dto.status;
        tenantSubscription.trialEnd = dto.trialEnd;
        tenantSubscription.maxNumberOfEmployees = dto.maxNumberOfEmployees;

        return tenantSubscription;
    }

    constructor(id: string = uuid.v4()) {
        this.id = id;
    }

    @action
    updateProperty(field: string, value: any) {
        if (!this.isDirty) {
            this.isDirty = true;
        }

        (this as any)[field] = value;
        if (TenantSubscription.rules.hasOwnProperty(field)) {
            const validator = TenantSubscription.getValidator(
                { [field]: value },
                { [field]: (TenantSubscription.rules as any)[field] }
            );
            this.validationErrors = validateField(validator, field, this.validationErrors);
        }
    }

    @action
    validateAll(data: any) {
        const validator = TenantSubscription.getValidator(data, TenantSubscription.rules);
        this.validationErrors = validate(validator);
    }

    @computed get isValid() {
        return Object.keys(this.validationErrors).length === 0;
    }

    @computed
    public get trialExpiryDays() {
        if (this.trialEnd === undefined) {
            return undefined;
        }

        const now = new Date();
        const trial = new Date(this.trialEnd * 1000);
        const diffTime = trial.getTime() - now.getTime();
        if (diffTime > 0) {
            return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        }
        return 1;
    }

    public toDto(): SubscriptionDto {
        return {
            cancelAtPeriodEnd: this.cancelAtPeriodEnd,
            currentPeriodEnd: this.currentPeriodEnd,
            customerId: this.customerId,
            priceId: this.priceId,
            productId: this.productId,
            status: this.status,
            trialEnd: this.trialEnd,
        } as SubscriptionDto;
    }
}
