import { observable } from 'mobx';

export class BaseModel {
    @observable createdBy?: string;
    @observable createdAt?: Date;
    @observable modifiedBy?: string;
    @observable modifiedAt?: Date;
    @observable deletedBy?: string;
    @observable deletedAt?: Date;
}
