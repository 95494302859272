import Firebase from './firebase';
import { ImageCategoryType, ImageType, UploadImageDto, UploadImageGalleryDto } from './dtos/generated/dtos.generated';
import firebase from 'firebase';

export const storageService = {
    //TODO: Exception handling
    uploadImage: async (
        file: string,
        id: string,
        imageType: ImageType,
        category: ImageCategoryType
    ): Promise<firebase.functions.HttpsCallableResult> => {
        const uploadImageFunction = Firebase.functions.httpsCallable('uploadImage');
        return await uploadImageFunction({
            content: file,
            type: imageType,
            category: category,
            id: id,
        } as UploadImageDto);
    },

    uploadImageToGallery: async (
        id: string,
        content: string,
        description: string,
        refId: string,
        imageCategoryType: ImageCategoryType,
        type: ImageType
    ): Promise<firebase.functions.HttpsCallableResult> => {
        const uploadImageToGalleryFunction = Firebase.functions.httpsCallable('uploadImageToGallery');
        return await uploadImageToGalleryFunction({
            id: id,
            refId: refId,
            imageCategoryType: imageCategoryType,
            content: content,
            type: type,
            description: description,
        } as UploadImageGalleryDto);
    },

    deleteImageFromGallery: async (id: string): Promise<firebase.functions.HttpsCallableResult> => {
        const deleteImageFromGallery = Firebase.functions.httpsCallable('deleteImageFromGallery');
        return await deleteImageFromGallery({ id: id });
    },
};
