import { BaseModel } from '../../../common/stores/model/baseModel';
import { action, computed, observable, runInAction } from 'mobx';
import { format } from 'date-fns';
import i18n from 'i18next';
import { getDateRangeSelectionType } from '../components/filter/selectionType';

export class DateFilter extends BaseModel {
    @observable startDate: Date;
    @observable endDate: Date;

    constructor(startDate: Date, endDate: Date) {
        super();
        this.startDate = startDate;
        this.endDate = endDate;
    }

    @computed get dateRangeText() {
        const selectionType = getDateRangeSelectionType(this.startDate, this.endDate);

        switch (selectionType) {
            case 'today':
                return i18n.t('today');
            case 'calendarWeek':
                return i18n.t('calendar-week');
            case 'nextWeek':
                return i18n.t('next-week');
            default:
                return `${format(this.startDate, 'd.M.yy')} - ${format(this.endDate, 'd.M.yy')}`;
        }
    }

    @action
    public setStartDate(date: Date) {
        this.startDate = date;
    }

    @action
    public setEndTime(date: Date) {
        this.endDate = date;
    }

    @action
    public setDateRange(startDate: Date, endDate: Date) {
        runInAction(() => {
            this.startDate = startDate;
            this.endDate = endDate;
        });
    }
}
