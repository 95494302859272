import { TenantDto } from '../../api/dtos/generated/dtos.generated';
import i18n from 'i18next';
import { format } from 'date-fns';
import { TCreatedPdf } from 'pdfmake/build/pdfmake';

export const createPdfDocDefinition = (tableWidhts: string[], title: string, tableBody: any[], tenant: TenantDto) => {
    const docDefinition = {
        info: {
            title: title
        },
        pageSize: 'A4',
        pageOrientation: 'LANDSCAPE',
        content: [header(tenant, title), createTable(tableWidhts, tableBody)],
        footer: (currentPage: any, pageCount: any) => {
            return {
                text: `${i18n.t('page')} ${currentPage.toString()} ${i18n.t('von')} ${pageCount.toString()}`,
                alignment: 'center',
                fontSize: 7
            };
        },
        styles: {
            header: {
                bold: true
            },
            companyName: {
                bold: true,
                fontSize: 16,
                color: '#000000'
            },
            title: {
                fontSize: 18,
                bold: true,
                margin: [0, 20, 0, 20]
            }
        },
        defaultStyle: {
            fontSize: 10
        }
    };
    return docDefinition;
};

function header(tenant: TenantDto, title: string) {
    const formattedDate = format(new Date(), 'd.M.yyyy');

    const nameAndReportDate = {
        alignment: 'justify',
        columns: [
            {
                text: tenant.name,
                style: 'companyName'
            },
            {
                text: 'Date: ' + formattedDate,
                style: 'titleDate',
                width: '*',
                alignment: 'right'
            }
        ]
    };

    const city = {
        text: tenant.address.city !== '' ? tenant.address.city : ''
    };
    const address = {
        text:
            tenant.address.zip !== '' && tenant.address.street !== ''
                ? tenant.address.zip + ', ' + tenant.address.street
                : ''
    };
    const headerTitle = { text: title, style: 'title', alignment: 'center' };
    return [nameAndReportDate, city, address, headerTitle];
}

function createTable<T>(widths: string[], body: any[]) {
    return {
        table: {
            headerRows: 1,
            widths: widths,
            body: body
        },
        layout: {
            fillColor: function(rowIndex: number) {
                if (rowIndex === 0) {
                    return '#fff';
                } else {
                    return rowIndex % 2 === 1 ? '#f3f7fd' : null;
                }
            },
            vLineColor: function(i: number) {
                return i ? '#fff' : null;
            },
            hLineColor: function(i: number) {
                return i ? '#000' : null;
            },
            vLineWidth: function() {
                return 0;
            },
            hLineWidth: function(i: number, node: any) {
                if (i === 1) {
                    return 1;
                } else {
                    return 0;
                }
            },
            paddingLeft: function() {
                return 10;
            },
            paddingRight: function() {
                return 10;
            },
            paddingTop: function() {
                return 10;
            },
            paddingBottom: function() {
                return 10;
            }
        }
    };
}

export const buildTableBody = <T>(items: T[], columns: { field: string; header: string }[]) => {
    const body = [];

    const headerRow = columns.map(x => {
        return {
            text: x.header,
            style: 'header'
        };
    });
    body.push(headerRow);

    items.forEach((item: T) => {
        const row: any[] = [];

        columns.forEach((column: { field: string; header: string }) => {
            row.push((item as any)[column.field]);
        });

        body.push(row);
    });

    return body;
};

// Required because of bug in pdfmake -> https://github.com/bpampuch/pdfmake/issues/693
export const openOrDownloadPdf = (pdf: TCreatedPdf, filename = 'Download.pdf') => {
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        pdf.download(filename);
    } else {
        pdf.open();
    }
};
