import { action, computed, observable, reaction, runInAction, toJS } from 'mobx';
import { RootStore } from '../../../stores/rootStore';
import { SortDirection } from '../../../common/models/sortDirection';
import { SortSetting } from '../../../common/models/sortSetting';
import { AppError } from '../../../common/models/appError';
import { TaskFilter } from '../models/taskFilter';
import { Task } from '../models/task';
import Firebase from '../../../api/firebase';
import i18n from 'i18next';
import { TaskDto, TaskState } from '../../../api/dtos/generated/dtos.generated';
import { taskService } from '../../../api/taskService';
import { endOfWeek, format, startOfWeek } from 'date-fns';
import { calculateTasks, TaskWrapper } from '../../../common/utils/taskCalculator';
import { Log } from '../../../log';
import { createPdfDocDefinition, buildTableBody, openOrDownloadPdf } from '../../../common/helpers/pdfHelper';
import { getTaskStateLangKey } from '../../../common/utils/translationHelper';
import { sessionStorageService } from '../../../sessionStorageService';
import firebase from 'firebase';

export class TasksStore {
    public static storeName: string = 'tasksStore';

    public store: RootStore;

    @observable public filter: TaskFilter = new TaskFilter(
        startOfWeek(new Date(), { weekStartsOn: 1 }),
        endOfWeek(new Date(), { weekStartsOn: 1 })
    );
    @observable public sortSetting: SortSetting = {
        fieldName: 'dueDate',
        direction: SortDirection.Asc,
    };
    @observable public isLoading: boolean = false;
    @observable public isExportInProgress: boolean = false;
    @observable public initialLoadDone: boolean = false;
    @observable error?: AppError = undefined;
    @observable public inAction: boolean = false;
    @observable public tasks: TaskDto[] = [];
    @observable private plannedTasks: Task[] = [];
    @observable public latestChange?: Date;
    private unsubscribeTaskChangesFunc?: () => void;
    private isFirstSnapShotHandled = false;

    @observable public editTask: boolean = false;

    constructor(store: RootStore) {
        this.store = store;

        reaction(
            () => this.store.authStore.isAuthenticated,
            () => this.subscribeTaskChanges()
        );

        reaction(
            () => toJS(this.filter),
            () => this.calculateVirtualTasks()
        );

        this.setFiltersFromLocalStorage();
    }

    @computed
    get hasTasks() {
        return this.tasks.length > 0;
    }

    @computed
    get tasksCount() {
        return this.plannedTasks.length;
    }

    @computed
    get inProgress() {
        return this.isLoading || this.inAction;
    }

    @action
    setFilter(filter: TaskFilter) {
        this.filter = filter;
    }

    @action
    setSortSetting(sortSetting: SortSetting) {
        this.sortSetting = sortSetting;
    }

    @action
    setFiltersFromLocalStorage() {
        this.setTaskStateFromLocalStorage();
        this.setTaskFilterDateRangeFromLocalStorage();
    }

    @action
    setTaskStateFromLocalStorage() {
        const taskState = localStorage.getItem('taskState');
        if (taskState) {
            this.filter.setStates(taskState.split('|') as TaskState[]);
        }
    }

    @action
    setTaskFilterDateRangeFromLocalStorage() {
        const taskFilterStartDate = localStorage.getItem('taskFilterStartDate');
        if (taskFilterStartDate) {
            this.filter.setStartDate(new Date(taskFilterStartDate));
        }

        const taskFilterEndDate = localStorage.getItem('taskFilterEndDate');
        if (taskFilterEndDate) {
            this.filter.setEndDate(new Date(taskFilterEndDate));
        }
    }

    @action
    async deleteTaskById(id: string) {
        this.inAction = true;
        this.error = undefined;

        try {
            await taskService.deleteTask(id);
        } catch (error) {
            runInAction(() => (this.error = error));
        } finally {
            this.inAction = false;
        }
    }

    @action
    async softDeleteTask(task: Task) {
        this.inAction = true;
        this.error = undefined;

        try {
            await taskService.softDeleteTask(task.toDto(this.store.authStore.tenantId!, true));
        } catch (error) {
            runInAction(() => (this.error = error));
        } finally {
            this.inAction = false;
        }
    }

    @action
    async deleteTasks(ids: string[]) {
        this.inAction = true;
        this.error = undefined;

        try {
            const tasksPath = `tenants/${this.store.authStore.tenantId}/tasks/`;

            const batch = Firebase.firestore.batch();
            ids.forEach((id) => {
                batch.delete(Firebase.firestore.doc(tasksPath + id));
            });

            await batch.commit();
        } catch (error) {
            runInAction(() => (this.error = error));
        } finally {
            this.inAction = false;
        }
    }

    @action
    saveState(id: string, taskState: TaskState): void {
        taskService.updateTask(id, { state: taskState });
    }

    @action
    async saveTask(task: Task) {
        await taskService.upsertTask(task.toDto(this.store.authStore.tenantId!, true));
    }

    @action
    public createPdf = async () => {
        this.isExportInProgress = true;
        this.error = undefined;

        Log.export('taskList', 'pdf');

        const pdfMake = (await import(/* webpackChunkName: "pdfMake" */ 'pdfmake/build/pdfmake')).default;
        const pdfFonts = (await import(/* webpackChunkName: "pdfFonts" */ 'pdfmake/build/vfs_fonts')).default;

        const { vfs } = pdfFonts.pdfMake;
        pdfMake.vfs = vfs;

        const tenant = this.store.tenantStore.tenant;

        const tableHeadColumns = [
            { field: 'dueDate', header: i18n.t('dueDate') },
            { field: 'name', header: i18n.t('name') },
            { field: 'description', header: i18n.t('description') },
            { field: 'customer', header: i18n.t('customer') },
            { field: 'employee', header: i18n.t('employee') },
            { field: 'state', header: i18n.t('state') },
        ];
        const tableWidths = ['auto', 'auto', '*', 'auto', 'auto', 'auto'];

        const pdfTitle = i18n.t('tasks');
        const tableItems = this.plannedTasks.map((plannedTask) => {
            return {
                dueDate: plannedTask.formattedFullDueDate,
                name: plannedTask.name,
                description: plannedTask.description,
                customer: plannedTask.customerDisplayName,
                employee: plannedTask.employeeDisplayName,
                state: i18n.t(getTaskStateLangKey(plannedTask.state)),
            };
        });
        if (tenant) {
            const docDefinition = createPdfDocDefinition(
                tableWidths,
                pdfTitle,
                buildTableBody(tableItems, tableHeadColumns),
                tenant.toDto()
            );

            const pdf = pdfMake.createPdf(docDefinition as any);
            openOrDownloadPdf(pdf, `${pdfTitle}_${format(new Date(), 'd.M.yyyy')}.pdf`);

            runInAction(() => (this.isExportInProgress = false));
        }
    };

    @action
    private async calculateVirtualTasks() {
        const virtualTasks = await calculateTasks(this.tasks, this.filter);

        const convert = (taskWrappers: TaskWrapper[]): Promise<Task[]> => {
            const tasks = taskWrappers.map((taskWrapper: TaskWrapper) => {
                const task = Task.createFromDto(taskWrapper.task);
                task.isVirtual = taskWrapper.isCalculated;
                if (task.parentTaskId) {
                    const parentTask = this.tasks.find((x) => x.id === task.parentTaskId);
                    if (parentTask) {
                        task.noteCount = parentTask.noteCount ? parentTask.noteCount : 0;
                        task.imageCount = parentTask.imageCount ? parentTask.imageCount : 0;
                    }
                }
                return task;
            });

            return Promise.resolve(tasks);
        };

        const plannedTasks = await convert(virtualTasks);

        runInAction(() => {
            this.plannedTasks = plannedTasks;
        });
    }

    @action
    public subscribeTaskChanges() {
        runInAction(() => (this.isLoading = true));

        const query = Firebase.firestore.collection('tenants').doc(this.store.authStore.tenantId).collection('tasks');

        this.unsubscribeTaskChangesFunc = query.onSnapshot(async (snapshot: firebase.firestore.QuerySnapshot) => {
            const loginId = sessionStorageService.getLoginId();
            try {
                runInAction(() => (this.isLoading = true));

                if (this.isFirstSnapShotHandled) {
                    console.log('TaskInfo Merge');
                    snapshot.docChanges().forEach((change: firebase.firestore.DocumentChange) => {
                        const dto = change.doc.data() as TaskDto;
                        if (
                            this.store.authStore.isAdmin ||
                            dto.createdBy === loginId ||
                            dto.employeeRef?.id === this.store.authStore.employeeId
                        ) {
                            if (change.type === 'added') {
                                this.addTask(this.normalizeTaskDto(change.doc.data() as TaskDto));
                            }
                            if (change.type === 'modified') {
                                this.upsertTask(this.normalizeTaskDto(change.doc.data() as TaskDto));
                            }
                            if (change.type === 'removed') {
                                this.removeTask(change.doc.data().id);
                            }
                        }
                    });
                } else {
                    const tasks: TaskDto[] = [];
                    snapshot.docs.forEach((value: firebase.firestore.QueryDocumentSnapshot) => {
                        const dto = value.data() as TaskDto;
                        if (
                            this.store.authStore.isAdmin ||
                            dto.createdBy === loginId ||
                            dto.employeeRef?.id === this.store.authStore.employeeId
                        ) {
                            this.normalizeTaskDto(dto);
                            tasks.push(dto);
                        }
                    });

                    this.tasks = tasks;
                    this.isFirstSnapShotHandled = true;
                }

                await this.calculateVirtualTasks();
            } finally {
                runInAction(() => {
                    this.isLoading = false;
                    this.latestChange = new Date();
                });
            }
        });
    }

    private normalizeTaskDto(dto: TaskDto) {
        dto.dueDate = (dto.dueDate as any).toDate();
        if (dto.recurring) {
            dto.recurring.start = (dto.recurring.start as any).toDate();
            if (dto.recurring.end) {
                dto.recurring.end = (dto.recurring.end as any).toDate();
            }
        }

        return dto;
    }

    @action
    private async removeTask(id: string) {
        const index = this.tasks.findIndex((x) => x.id === id);
        if (index > -1) {
            this.tasks.splice(index, 1);
        }
    }

    @action
    private async addTask(dto: TaskDto) {
        this.tasks.push(dto);
    }

    @action
    private async upsertTask(dto: TaskDto) {
        const task = this.tasks.find((x) => x.id === dto.id);
        if (task) {
            this.removeTask(dto.id);
        }

        this.addTask(dto);
    }

    @computed
    public get sortedPlannedTask() {
        if (this.sortSetting.fieldName === 'dueDate') {
            return this.plannedTasks.sort((a: Task, b: Task) => {
                if (!a.dueDate || !b.dueDate) {
                    return 0;
                }

                return this.sortSetting.direction === SortDirection.Desc
                    ? a.dueDate < b.dueDate
                        ? 1
                        : -1
                    : a.dueDate > b.dueDate
                    ? 1
                    : -1;
            });
        } else {
            return this.plannedTasks.sort((a: Task, b: Task) => {
                const aValue = this.sortSetting.fieldName.split('.').reduce((x: any, y) => x[y].toLowerCase(), a);
                const bValue = this.sortSetting.fieldName.split('.').reduce((x: any, y) => x[y].toLowerCase(), b);

                return this.sortSetting.direction === SortDirection.Desc
                    ? aValue < bValue
                        ? 1
                        : -1
                    : aValue > bValue
                    ? 1
                    : -1;
            });
        }
    }
}
