import { BaseModel } from '../../../common/stores/model/baseModel';
import { observable } from 'mobx';
import {v4} from 'uuid';
import { PlanningBoardRowDay } from './planningBoardRowDay';
import { Employee } from '../../employees/models/employee';
import { Resource } from '../../resources/models/resource';

export class PlanningBoardRow extends BaseModel {
    id: string;
    @observable employee?: Employee;
    @observable resource?: Resource;
    @observable days: PlanningBoardRowDay[] = [];

    constructor(id: string = v4()) {
        super();
        this.id = id;
    }
}
