import { RootStore } from '../../../stores/rootStore';
import { observable, action, runInAction, reaction, computed } from 'mobx';
import { SortDirection } from '../../../common/models/sortDirection';
import { defaultPagingSize } from '../../../environmentConstants';
import { AppError } from '../../../common/models/appError';
import { EmployeeFilter } from '../models/employeeFilter';
import { SortSetting } from '../../../common/models/sortSetting';
import Firebase from '../../../api/firebase';
import { EmployeeDto } from '../../../api/dtos/generated/dtos.generated';
import { Employee } from '../models/employee';
import i18n from 'i18next';
import { Log } from '../../../log';
import { createPdfDocDefinition, buildTableBody, openOrDownloadPdf } from '../../../common/helpers/pdfHelper';
import { format, startOfDay, subDays } from 'date-fns';
import { groupBy, orderBy } from 'lodash';
import firebase from 'firebase';

class EmployeesStore {
    public static storeName: string = 'employeesStore';

    public store: RootStore;
    @observable private employees: Employee[] = [];
    @observable public filter: EmployeeFilter = { searchTerm: '' };
    @observable public sortSetting: SortSetting = { fieldName: 'firstName', direction: SortDirection.Asc };
    @observable public employeesPagingCurrentPage: number = 0;
    @observable public employeesPagingRowsPerPage: number = defaultPagingSize;
    @observable public isLoading: boolean = false;
    @observable public initialLoadDone: boolean = false;
    @observable error?: AppError = undefined;
    @observable public inAction: boolean = false;
    @observable public isExportInProgress = false;
    @observable public detailId?: string;

    private unsubscribeEmployeeChangesFunc?: () => void;
    private isFirstSnapShotHandled = false;

    constructor(store: RootStore) {
        this.store = store;

        reaction(
            () => this.store.authStore.isAuthenticated,
            () => this.subscribeEmployeeChanges()
        );
    }

    @action
    public async subscribeEmployeeChanges() {
        runInAction(() => (this.isLoading = true));

        const query = Firebase.firestore
            .collection('tenants')
            .doc(this.store.authStore.tenantId)
            .collection('employees');

        this.unsubscribeEmployeeChangesFunc = query.onSnapshot(async (snapshot: firebase.firestore.QuerySnapshot) => {
            try {
                runInAction(() => (this.isLoading = true));

                if (this.isFirstSnapShotHandled) {
                    snapshot.docChanges().forEach((change: firebase.firestore.DocumentChange) => {
                        if (change.type === 'added') {
                            this.addEmployee(change.doc.data() as EmployeeDto);
                        }
                        if (change.type === 'modified') {
                            this.upsertEmployee(change.doc.data() as EmployeeDto);
                        }
                        if (change.type === 'removed') {
                            this.removeEmployee(change.doc.data().id);
                        }
                    });
                } else {
                    const employees: Employee[] = [];
                    snapshot.docs.forEach((value: firebase.firestore.QueryDocumentSnapshot) => {
                        const dto = value.data() as EmployeeDto;
                        const model = Employee.createFromDto(dto);
                        employees.push(model);
                    });
                    this.employees = employees;
                    this.isFirstSnapShotHandled = true;
                }
            } finally {
                runInAction(() => (this.isLoading = false));
            }
        });
    }

    @action
    private async addEmployee(dto: EmployeeDto) {
        const model = Employee.createFromDto(dto);
        this.employees.push(model);
    }

    @action
    private async upsertEmployee(dto: EmployeeDto) {
        const employee = this.employees.find((x) => x.id === dto.id);
        if (employee) {
            this.removeEmployee(dto.id);
        }
        this.addEmployee(dto);
    }

    @action
    private async removeEmployee(id: string) {
        const index = this.employees.findIndex((x) => x.id === id);
        if (index > -1) {
            this.employees.splice(index, 1);
        }
    }

    @action
    setFilter(filter: EmployeeFilter) {
        this.filter = filter;
    }

    @action
    setEmployeesPagingCurrentPage(currentPage: number) {
        this.employeesPagingCurrentPage = currentPage;
    }

    @action
    setEmployeesPagingRowsPerPage(rowsPerPage: number) {
        this.employeesPagingRowsPerPage = rowsPerPage;
    }

    @action
    setSortSetting(sortSetting: SortSetting) {
        this.sortSetting = sortSetting;
    }

    @action
    public createPdf = async () => {
        this.isExportInProgress = true;
        this.error = undefined;

        Log.export('employeeList', 'pdf');

        const pdfMake = (await import(/* webpackChunkName: "pdfMake" */ 'pdfmake/build/pdfmake')).default;
        const pdfFonts = (await import(/* webpackChunkName: "pdfFonts" */ 'pdfmake/build/vfs_fonts')).default;

        const { vfs } = pdfFonts.pdfMake;
        pdfMake.vfs = vfs;

        const tenant = this.store.tenantStore.tenant;

        const tableHeadColumns: { field: string; header: string }[] = [
            { field: 'firstName', header: i18n.t('firstName') },
            { field: 'lastName', header: i18n.t('lastName') },
            { field: 'email', header: i18n.t('email') },
        ];

        const tableWidths = ['auto', 'auto', '*'];

        const pdfTitle = i18n.t('employees');

        const items = this.employees.map((employee) => {
            return {
                firstName: employee.firstName,
                lastName: employee.lastName,
                email: employee.loginData && employee.loginData!.email ? employee.loginData!.email : '',
            };
        });

        if (tenant) {
            const docDefinition = createPdfDocDefinition(
                tableWidths,
                pdfTitle,
                buildTableBody(items, tableHeadColumns),
                tenant.toDto()
            );

            const pdf = pdfMake.createPdf(docDefinition as any);
            openOrDownloadPdf(pdf, `${pdfTitle}_${format(new Date(), 'd.M.yyyy')}.pdf`);

            runInAction(() => (this.isExportInProgress = false));
        }
    };

    @computed
    public get sortedEmployees() {
        return this.employees.slice().sort((a: Employee, b: Employee) => {
            const aValue = this.sortSetting.fieldName.split('.').reduce((x: any, y) => x[y].toLowerCase(), a);
            const bValue = this.sortSetting.fieldName.split('.').reduce((x: any, y) => x[y].toLowerCase(), b);

            return this.sortSetting.direction === SortDirection.Desc
                ? aValue < bValue
                    ? 1
                    : -1
                : aValue > bValue
                ? 1
                : -1;
        });
    }

    @computed
    public get employeesPerDayCount(): any {
        const ordered = orderBy(this.employees, ['createdAt'], ['asc']);
        const mapped = ordered.map((x: any) => {
            return { date: startOfDay(x.createdAt ? x.createdAt : new Date(8640000000000000)) };
        });
        const grouped = groupBy(mapped, 'date') as any;

        const result = [];
        if (ordered.length > 0 && ordered[0].createdAt) {
            result.push({ date: subDays(ordered[0].createdAt, 1), count: 0 });
        }

        let sumCount = 0;
        for (const [key, value] of Object.entries(grouped)) {
            sumCount += (value as any).length;
            result.push({ date: key, count: sumCount });
        }

        return result;
    }

    @computed
    public get hasEmployees() {
        return this.employees.length > 0;
    }

    @computed
    public get employeesCount() {
        return this.employees.length;
    }

    @computed
    public get inProgress() {
        return this.isLoading || this.inAction;
    }
}
export default EmployeesStore;
