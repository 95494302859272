import React, { lazy, Suspense, useContext, useEffect } from 'react';
import { createStyles, Theme } from '@material-ui/core';
import { Router } from '@reach/router';
import { observer } from 'mobx-react-lite';
import LoadingInfo from '../common/components/LoadingInfo';
import Sidebar from './components/Sidebar';
import SidebarMobile from './components/SidebarMobile';
import ToastNotifications from '../common/components/ToastNotifications';
import DateFnsUtils from '@date-io/date-fns';
import { getLocale } from '../common/utils/dateHelper';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import CompleteEmployeeProfileDialog from '../modules/employees/components/CompleteEmployeeProfileDialog';
import { lazyWithPreload } from '../common/utils/lazy';
import LogoAnimate from '../common/components/LogoAnimate';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { StoreContext } from '../App';
import appConfig from '../appConfig';

const ResourceDialog = lazyWithPreload(
    () => import(/* webpackChunkName: 'ResourceDialog' */ '../modules/resources/components/ResourceDialog')
);

const TaskDialog = lazyWithPreload(
    () => import(/* webpackChunkName: 'TaskDialog' */ '../modules/tasks/components/TaskDialog')
);

const ProjectDialog = lazyWithPreload(
    () => import(/* webpackChunkName: 'TaskDialog' */ '../modules/projects/components/ProjectDialog')
);

const CustomerDialog = lazyWithPreload(
    () => import(/* webpackChunkName: 'TaskDialog' */ '../modules/customers/components/CustomerDialog')
);

const EmployeeDialog = lazyWithPreload(
    () => import(/* webpackChunkName: 'EmployeeDialog' */ '../modules/employees/components/EmployeeDialog')
);

const EmployeeLoginDialog = lazyWithPreload(
    () => import(/* webpackChunkName: 'EmployeeLoginDialog' */ '../modules/employees/components/EmployeeLoginDialog')
);

const DashboardModule = lazy(
    () => import(/* webpackChunkName: 'DashboardModule' */ '../modules/dashboard/components/DashboardModule')
);
const PlanningModule = lazy(
    () => import(/* webpackChunkName: 'PlanningModule' */ '../modules/planning/components/PlanningModule')
);
const ResourceModule = lazy(
    () => import(/* webpackChunkName: 'ResourceModule' */ '../modules/resources/components/ResourceModule')
);
const NotFoundScreen = lazy(() => import(/* webpackChunkName: 'NotFoundScreen' */ './components/NotFoundScreen'));
const EmployeeModule = lazy(
    () => import(/* webpackChunkName: 'EmployeeModule' */ '../modules/employees/components/EmployeeModule')
);
const TenantModule = lazy(
    () => import(/* webpackChunkName: 'TenantModule' */ '../modules/tenant/components/TenantModule')
);
const TasksModule = lazy(() => import(/* webpackChunkName: 'TasksModule' */ '../modules/tasks/components/TasksModule'));
const CustomerModule = lazy(
    () => import(/* webpackChunkName: 'CustomerModule' */ '../modules/customers/components/CustomerModule')
);
const ProjectModule = lazy(
    () => import(/* webpackChunkName: 'ProjectModule' */ '../modules/projects/components/ProjectModule')
);

const ChangePasswordDialog = lazyWithPreload(
    () => import(/* webpackChunkName: 'ChangePasswordDialog' */ '../modules/auth/components/ChangePasswordDialog')
);

const GoogleMapDialog = lazyWithPreload(
    () => import(/* webpackChunkName: 'GoogleMapDialog' */ '../common/components/GoogleMapDialog')
);

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
        },
        main: {
            position: 'relative',
            flexGrow: 1,
            minHeight: '100vh',
            //overflow: 'auto',
            backgroundImage: `linear-gradient(to bottom, #b0def6, #cfeaf9 12%, #e0f0fb 16%, #f3f7fd 26%)`,
        },
        content: {
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            backgroundSize: '100%',
        },
    })
);

const Shell = observer(() => {
    const { uiStore, authStore, tenantStore } = useContext(StoreContext);
    const classes = useStyles();
    const loaded = React.useRef(false);

    useEffect(() => {
        TaskDialog.preload();
        authStore.isAdmin && ProjectDialog.preload();
        authStore.isAdmin && CustomerDialog.preload();
        authStore.isAdmin && ResourceDialog.preload();
        EmployeeDialog.preload();
        authStore.isAdmin && ChangePasswordDialog.preload();
        authStore.isAdmin && EmployeeLoginDialog.preload();
        GoogleMapDialog.preload();
    }, [authStore.isAdmin]);

    const loadScript = (src: string, position: HTMLElement | null, id: string) => {
        if (!position) {
            return;
        }

        const script = document.createElement('script');
        script.setAttribute('async', '');
        script.setAttribute('id', id);
        script.src = src;
        position.appendChild(script);
    };

    if (typeof window !== 'undefined' && !loaded.current) {
        if (!document.querySelector('#google-maps')) {
            loadScript(
                `https://maps.googleapis.com/maps/api/js?key=${appConfig.google.mapsbrowserapikey}&libraries=places`,
                document.querySelector('head'),
                'google-maps'
            );
        }

        loaded.current = true;
    }

    return (
        <div className={classes.root}>
            <Suspense fallback={<LogoAnimate contrast={true} />}>
                {uiStore.isMobile || uiStore.isTablet ? <SidebarMobile /> : <Sidebar />}
            </Suspense>
            <main className={classes.main}>
                <ToastNotifications />
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={getLocale(uiStore!.languageAsString)}>
                    <div
                        className={classes.content}
                        style={uiStore.isMobile || uiStore.isTablet ? { paddingTop: '56px' } : { paddingTop: '63px' }}
                    >
                        <Suspense fallback={<LoadingInfo />}>
                            {/* Style is needed to use the full height of the content */}
                            <Router style={{ height: 'calc(100% - 75px)' }}>
                                <DashboardModule path={'/'} />
                                {authStore.isAdmin && <TenantModule path={'/mytenant'} />}
                                <PlanningModule path={'/planning'} />
                                {authStore.isAdmin && <ProjectModule path={'projects'} />}
                                <TasksModule path={'/task/*'} />
                                <CustomerModule path={'/customers/*'} />
                                {authStore.isAdmin && <EmployeeModule path={'/employee/*'} />}
                                <ResourceModule path={'/resource/*'} />
                                <NotFoundScreen default />
                            </Router>

                            {uiStore.isTaskDetailOpen && (
                                <TaskDialog
                                    open={uiStore.isTaskDetailOpen}
                                    task={uiStore.selectedTask}
                                    taskId={uiStore.selectedTaskId}
                                    onClose={uiStore.resetTaskSelection}
                                    fullScreen={uiStore.isMobile}
                                    activeTab={uiStore.selectedTaskTab}
                                />
                            )}

                            {uiStore.isProjectDetailOpen && (
                                <ProjectDialog
                                    open={uiStore.isProjectDetailOpen}
                                    project={uiStore.selectedProject}
                                    projectId={uiStore.selectedProjectId}
                                    onClose={uiStore.resetProjectSelection}
                                    fullScreen={uiStore.isMobile}
                                    activeTab={uiStore.selectedProjectTab}
                                />
                            )}

                            {uiStore.isResourceDetailOpen && (
                                <ResourceDialog
                                    open={uiStore.isResourceDetailOpen}
                                    resourceId={uiStore.selectedResourceId}
                                    onClose={() => uiStore.resetResourceSelection()}
                                    fullScreen={uiStore.isMobile}
                                    activeTab={uiStore.selectedResourceTab}
                                />
                            )}

                            {uiStore.isCustomerDetailOpen && (
                                <CustomerDialog
                                    open={uiStore.isCustomerDetailOpen}
                                    customer={uiStore.selectedCustomer}
                                    customerId={uiStore.selectedCustomerId}
                                    onClose={uiStore!.resetCustomerSelection}
                                    fullScreen={uiStore!.isMobile}
                                    activeTab={uiStore.selectedCustomerTab}
                                />
                            )}

                            {uiStore.isEmployeeDetailOpen && (
                                <EmployeeDialog
                                    open={uiStore.isEmployeeDetailOpen}
                                    employee={uiStore.selectedEmployee}
                                    employeeId={uiStore.selectedEmployeeId}
                                    onClose={uiStore.resetEmployeeSelection}
                                    fullScreen={uiStore.isMobile}
                                    activeTab={uiStore.selectedEmployeeTab}
                                />
                            )}
                            {uiStore!.isEmployeeLoginOpen && authStore.isAdmin && (
                                <EmployeeLoginDialog
                                    open={uiStore!.isEmployeeLoginOpen}
                                    employee={uiStore!.selectedEmployeeLogin}
                                    employeeId={uiStore!.selectedEmployeeLoginId}
                                    onClose={uiStore!.resetEmployeeLoginSelection}
                                    fullScreen={uiStore!.isMobile}
                                />
                            )}

                            {uiStore!.isChangePasswordDialogOpen && authStore.isAdmin && (
                                <ChangePasswordDialog
                                    open={uiStore!.isChangePasswordDialogOpen}
                                    onClose={() => uiStore!.setChangePasswordDialogOpen(false)}
                                    fullScreen={uiStore!.isMobile}
                                />
                            )}
                            {uiStore!.isGoogleMapDialogOpen && (
                                <GoogleMapDialog
                                    open={uiStore!.isGoogleMapDialogOpen}
                                    onClose={() => uiStore!.setGoogleMapDialogOpen(false)}
                                    selectedAddressId={uiStore!.selectedAddressId!}
                                    addresses={uiStore!.selectedAddresses}
                                    fullScreen={uiStore!.isMobile}
                                />
                            )}
                        </Suspense>
                    </div>
                </MuiPickersUtilsProvider>
                <CompleteEmployeeProfileDialog
                    open={
                        !authStore.isEmployeeProfileCompleted &&
                        tenantStore.tenant!.address!.street.length === 0 &&
                        tenantStore.tenant!.address!.zip.length === 0 &&
                        tenantStore.tenant!.address!.city.length === 0
                    }
                />
            </main>
        </div>
    );
});

export default Shell;
