import { Language, TaskState } from '../../api/dtos/generated/dtos.generated';

const taskStatePrefix = 'taskstate-';
const languagePrefix = 'language-';

export const getTaskStateLangKey = (status: TaskState) => {
    return (taskStatePrefix + status).toLowerCase();
};

export const getLanguageKey = (lang: any) => {
    return (languagePrefix + Language[lang]).toLowerCase();
};
