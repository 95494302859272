import {
    format,
    getDay,
    getHours,
    getMinutes,
    getMonth,
    getYear,
    isValid,
    setDay,
    setHours,
    setMilliseconds,
    setMinutes,
    setMonth,
    setSeconds,
    setYear
} from 'date-fns';
import { de, enUS, fr, it, hr } from 'date-fns/locale';
import i18n from 'i18next';
import { getLanguage } from './languageHelper';
import { Language } from '../../api/dtos/generated/dtos.generated';

const _MS_PER_DAY = 1000 * 60 * 60 * 24;

export const formatDate = (value: Date, pattern: string = 'dd.MM.yyy') => {
    const language = getLanguage(i18n.language);
    return format(value, pattern, {
        locale:
            language === Language.De
                ? de
                : language === Language.Fr
                ? fr
                : language === Language.It
                ? it
                : language === Language.En
                ? enUS
                : hr
    });
};

//TODO: refactor to use Language Enum
export const getLocale = (language: string): Locale => {
    switch (language) {
        case 'de':
            return de;
        case 'fr':
            return fr;
        case 'en':
            return enUS;
        case 'it':
            return it;

        case 'hr':
            return hr;

        default:
            return de;
    }
};

export const parseDate = (date: any): Date | undefined => {
    return date ? (typeof (date as any).toDate === 'function' ? (date as any).toDate() : date) : undefined;
};

export const parseDateToTimestamp = (date: any): number | undefined => {
    return date
        ? typeof (date as any).toDate === 'function'
            ? (date as any).toDate().valueOf() / 1000
            : date.valueOf() / 1000
        : undefined;
};

export const setTime = (date: Date, hours: number, minutes: number) => {
    date.setHours(hours);
    date.setMinutes(minutes);
    date.setSeconds(0);
    return date;
};

export const dateDiffInDays = (a: Date, b: Date): number => {
    // Discard the time and time-zone information.
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
};

export const addDays = (date: Date, days: number) => {
    let result = new Date(date);
    result.setDate(result.getDate() + days);
    result.setHours(0, 0, 0, 0);
    return result;
};

export const applyTime = (source: Date, target: Date): Date => {
    if (isValid(target)) {
        let newDate = new Date(target.valueOf());
        newDate = setHours(newDate, getHours(source));
        newDate = setMinutes(newDate, getMinutes(source));
        newDate = setSeconds(newDate, 0);
        newDate = setMilliseconds(newDate, 0);
        return newDate;
    } else {
        return source;
    }
};

export const applyDate = (source: Date, target: Date): Date => {
    if (isValid(target)) {
        let newDate = new Date(target.valueOf());
        newDate = setDay(newDate, getDay(source));
        newDate = setMonth(newDate, getMonth(source));
        newDate = setYear(newDate, getYear(source));
        return newDate;
    } else {
        return source;
    }
};
