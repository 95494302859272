import Firebase from './api/firebase';

const appName = 'planux';
type ScreenNames =
    | 'dashboard'
    | 'planning'
    | 'task'
    | 'employee'
    | 'projects'
    | 'resource'
    | 'customer'
    | 'organization'
    | 'unknown';
type Export = 'resourceList' | 'projectList' | 'employeeList' | 'customerList' | 'taskList';
type ExportFileType = 'pdf';

function parseScreenNameFromPath(path: string): ScreenNames {
    switch (path) {
        case '/':
            return 'dashboard';
        case '/customer':
            return 'customer';
        case '/resource':
            return 'resource';
        case '/projects':
            return 'projects';
        case '/task':
            return 'task';
        case '/planning':
            return 'planning';
        case '/employee':
            return 'employee';
        case '/tenant':
            return 'organization';
        default:
            return 'unknown';
    }
}

export const Log = {
    fullscreen: (activate: boolean) => {
        Firebase.analytics.logEvent(activate ? 'activate_fullscreen' : 'deactivate_fullscreen');
    },

    screenView: (path: string) => {
        Firebase.analytics.logEvent('screen_view', {
            app_name: appName,
            screen_name: parseScreenNameFromPath(path)
        });
    },

    export: (name: Export, fileType: ExportFileType) => {
        Firebase.analytics.logEvent('export', {
            name: name,
            fileType: fileType
        });
    },

    exception: (description: string, fatal = false) => {
        Firebase.analytics.logEvent('exception', { description, fatal });
    },

    login: () => {
        Firebase.analytics.logEvent('login', { method: 'email' });
    }
};
