import { computed, observable } from 'mobx';
import {v4} from 'uuid';
import { PlanningBoardItem } from './planningBoardItem';

export class PlanningBoardRowDay {
    id: string;
    @observable date?: Date;
    @observable items: PlanningBoardItem[] = [];

    constructor(date: Date, id = v4()) {
        this.id = id;
        this.date = date;
    }

    @computed
    public get isAllDay() {
        const value = this.items.find(d => d.period === 'allDay');
        return this.items.length === 1 && !!value;
    }

    @computed
    public get allDay() {
        return this.items.find(d => d.period === 'allDay');
    }

    @computed
    public get hasMorningPeriod() {
        const value = this.items.find(d => d.period === 'morning');
        return !!value;
    }

    @computed
    public get morning() {
        return this.items.find(d => d.period === 'morning');
    }

    @computed
    public get hasAfternoonPeriod() {
        const value = this.items.find(d => d.period === 'afternoon');
        return !!value;
    }

    @computed
    public get afternoon() {
        return this.items.find(d => d.period === 'afternoon');
    }

    @computed
    public get showSplit() {
        return this.items.every(i => !i.projectRef && !i.absence);
    }
}
