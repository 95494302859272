import React, { SyntheticEvent, useContext, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import {
    Checkbox,
    createStyles,
    FormControlLabel,
    IconButton,
    InputAdornment,
    Theme,
    Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Link, RouteComponentProps } from '@reach/router';
import VisibilityOff from 'mdi-material-ui/EyeOffOutline';
import VisibilityOn from 'mdi-material-ui/EyeOutline';
import Button from '@material-ui/core/Button';
import PlanuxLogo from '../../../assets/planux_logo.svg';
import Zoom from '@material-ui/core/Zoom';
import green from '@material-ui/core/colors/green';
import ErrorMessage from '../../../common/components/ErrorMessage';
import { SignUpScreenVm } from './signUpScreen.vm';
import { termsUrl } from '../../../environmentConstants';
import SuccessScreen from './SuccessScreen';
import * as qs from 'query-string';
import TopProgress from '../../../common/components/TopProgress';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { observer, useLocalStore } from 'mobx-react-lite';
import { StoreContext } from '../../../App';
import CenterPaper from '../../../common/components/CenterPaper';
import { useDocumentSubTitle } from '../../../common/hooks/useDocumentTitle';
import PromotionCode from './PromotionCode';

const useStyles = makeStyles(({ breakpoints, spacing }: Theme) =>
    createStyles({
        root: {
            width: '370px',
            [breakpoints.down('xs')]: {
                width: '100%',
            },
        },
        formControl: {
            width: '100%',
        },
        logo: {
            width: '100%',
            maxWidth: '154px',
            alignSelf: 'center',
            margin: spacing(2),
        },
        buttonProgress: {
            color: green[500],
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -12,
            marginLeft: -12,
        },
        changePlanButton: {
            margin: spacing(1),
        },
        planDescription: {
            minHeight: spacing(6),
            textAlign: 'center',
        },
    })
);

type Props = RouteComponentProps;

const SignUpScreen = observer(({ location, navigate }: Props) => {
    const classes = useStyles();
    const { authStore } = useContext(StoreContext);
    const { t } = useTranslation();
    useDocumentSubTitle(t('register'));

    const vm = useLocalStore(() => new SignUpScreenVm(authStore, qs.parse(location!.search)), {});

    useEffect(() => {
        vm.getSubscriptionPlans();
    }, [vm]);

    const handleSignUpClick = (event: SyntheticEvent<any>) => {
        event.preventDefault();
        vm.signUp();
    };

    const handleClickShowPassword = (event: SyntheticEvent<any>): void => {
        event.preventDefault();
        vm.toggleShowPassword();
    };

    const handleTenantNameChange = (event: any): void => {
        const { value } = event.target;
        vm.setTenantName(value);
    };

    const handleEmailChange = (event: any): void => {
        const { value } = event.target;
        vm.setEmail(value);
    };

    const handlerPasswordChange = (event: any): void => {
        const { value } = event.target;
        vm.setPassword(value);
    };

    const handleTosClick = (event: any) => {
        const { checked } = event.target;
        vm.setTos(checked);
    };

    if (vm.successMessage !== '') {
        return <SuccessScreen successMessage={vm.successMessage} />;
    }

    const params = qs.parse(location!.search);
    const urlPromotionCode = !!params && params.promotioncode ? (params.promotioncode as string) : undefined;

    return (
        <CenterPaper>
            <div className={classes.root}>
                <TopProgress
                    inProgress={vm.isLoading}
                    style={{ position: 'absolute', top: '0px', left: '0', right: '0' }}
                />
                <form noValidate>
                    <Grid container direction={'column'} spacing={3} wrap={'nowrap'}>
                        <Grid item container justify={'center'}>
                            <Zoom in timeout={500}>
                                <img className={classes.logo} src={PlanuxLogo} alt="logo" />
                            </Zoom>
                        </Grid>
                        <Grid item container justify={'center'} alignItems={'center'} direction={'column'} spacing={1}>
                            <Grid item>
                                <Typography noWrap variant={'h6'}>
                                    {t('registration-plan-title', { planName: vm.planName })}{' '}
                                </Typography>
                            </Grid>
                            <Grid item className={classes.planDescription}>
                                <Typography>
                                    {vm.hasSubscriptionPlans
                                        ? t('registration-plan-description', {
                                              price: vm.planAmount,
                                              currency: vm.planCurrency.toUpperCase(),
                                              interval: t(vm.planInterval),
                                              planUnit: vm.planUnit,
                                              planTrialPeriodDays: vm.planTrialPeriodDays,
                                          })
                                        : t('load-plan-details')}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant={'outlined'}
                                    color={'primary'}
                                    onClick={() => navigate!('/subscriptions')}
                                    disabled={vm.isLoading}
                                >
                                    {t('change-plan')}
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <TextField
                                variant={'outlined'}
                                className={classes.formControl}
                                fullWidth={true}
                                id="tenantName"
                                label={t('organization')}
                                disabled={vm.isLoading}
                                error={!!vm.validationErrors.tenantName}
                                helperText={vm.validationErrors.tenantName}
                                value={vm.tenantName}
                                onChange={handleTenantNameChange}
                                required={true}
                                autoFocus={true}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                variant={'outlined'}
                                className={classes.formControl}
                                fullWidth={true}
                                id="email"
                                label={t('email')}
                                disabled={vm.isLoading}
                                error={!!vm.validationErrors.email}
                                helperText={vm.validationErrors.email}
                                value={vm.email}
                                onChange={handleEmailChange}
                                required={true}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                id="password"
                                fullWidth={true}
                                className={classes.formControl}
                                variant="outlined"
                                type={vm.showPassword ? 'text' : 'password'}
                                label="Password"
                                value={vm.password}
                                disabled={vm.isLoading}
                                error={!!vm.validationErrors.password}
                                helperText={vm.validationErrors.password}
                                onChange={handlerPasswordChange}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="Toggle password visibility"
                                                onClick={handleClickShowPassword}
                                            >
                                                {vm.showPassword ? <VisibilityOff /> : <VisibilityOn />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <PromotionCode
                                initialCode={urlPromotionCode}
                                onChange={(promotionId) => vm.setPromotionId(promotionId)}
                            />
                        </Grid>
                        <ErrorMessage appError={vm.error} />
                        <Grid item>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id="tos"
                                        color="primary"
                                        checked={vm.tos}
                                        onClick={(event) => handleTosClick(event)}
                                        required={true}
                                    />
                                }
                                label={
                                    <div>
                                        <span>{t('tos-checkbox-text')}</span>
                                        <a href={termsUrl} target="_blank" rel="noopener noreferrer">
                                            {t('tos')}
                                        </a>
                                    </div>
                                }
                                htmlFor="tos"
                            />
                            {!!vm.validationErrors.tos && (
                                <Typography variant={'caption'} color={'error'} noWrap>
                                    {vm.validationErrors.tos}
                                </Typography>
                            )}
                        </Grid>
                        <Grid item>
                            <Button
                                size={'large'}
                                variant={'contained'}
                                type={'submit'}
                                color={'primary'}
                                fullWidth
                                disabled={vm.isLoading}
                                onClick={handleSignUpClick}
                            >
                                {t('register')}
                            </Button>
                        </Grid>
                        <Grid item>
                            <Typography align={'center'}>
                                <Link to="/">{t('back-to-login')}</Link>
                            </Typography>
                        </Grid>
                    </Grid>
                </form>
            </div>
        </CenterPaper>
    );
});

export default SignUpScreen;
