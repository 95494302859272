import React, { useContext, useEffect } from 'react';
import { StoreContext } from '../../App';
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import { IToastNotification } from '../../stores/uiStore';
import { Button } from '@material-ui/core';
import i18n from 'i18next';

const ToastNotifications = observer(() => {
    const { uiStore } = useContext(StoreContext);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { notifications, removeNotification } = uiStore;

    useEffect(() => {
        notifications.forEach((notification: IToastNotification) => {
            if (notification.persist === false) {
                enqueueSnackbar(notification.message, { variant: notification.type });
                removeNotification(notification.key);
            } else {
                enqueueSnackbar(notification.message, {
                    variant: notification.type,
                    persist: true,
                    action: (
                        <Button
                            color={'inherit'}
                            onClick={() => {
                                removeNotification(notification.key);
                                closeSnackbar();
                            }}
                        >
                            {i18n.t('close')}
                        </Button>
                    )
                });
            }
        });
    }, [enqueueSnackbar, notifications, removeNotification, closeSnackbar]);

    return null;
});

export default ToastNotifications;
