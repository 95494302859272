import { computed } from 'mobx';
import { PlanningBoardItem } from '../../../planning/models/planningBoardItem';
import { EmployeeAbsenceSummary } from './employeeAbsenceSummary';
import { RootStore } from '../../../../stores/rootStore';
import { isWithinInterval } from 'date-fns';

class EmployeeAbsencesVm {
    private rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
    }

    @computed
    public get employeeAbsenceSummaries(): EmployeeAbsenceSummary[] {
        const employees = this.rootStore.employeesStore.sortedEmployees;
        if (employees.length === 0) {
            return [];
        }

        const summaries: EmployeeAbsenceSummary[] = [];

        const absenceItems: PlanningBoardItem[] = this.rootStore.planningBoardItemsStore.planningBoardItems.filter(
            (item: PlanningBoardItem) =>
                !item.projectRef &&
                item.employeeId &&
                isWithinInterval(item.date, {
                    start: this.rootStore.dashboardStore.filter.startDate,
                    end: this.rootStore.dashboardStore.filter.endDate,
                })
        );

        absenceItems.forEach((item: PlanningBoardItem) => {
            const existingSummary = summaries.find(
                (summary: EmployeeAbsenceSummary) => summary.employeeId === item.employeeId
            );
            const days = item.period === 'allDay' ? 1 : 0.5;

            if (existingSummary) {
                existingSummary.absencePlanningBoardItems.push(item);
                existingSummary.days = existingSummary.days + days;
            } else {
                summaries.push({
                    employeeId: item.employeeId!,
                    absencePlanningBoardItems: [item],
                    days: days,
                });
            }
        });

        return summaries.map((summary: EmployeeAbsenceSummary) => {
            const employee = employees.find((x) => x.id === summary.employeeId);
            return { ...summary, employee: employee ? employee : undefined };
        });
    }

    @computed
    public get hasAbsences() {
        return this.employeeAbsenceSummaries.length > 0;
    }
}

export default EmployeeAbsencesVm;
