import { BaseViewModel } from '../../../common/models/baseViewModel.vm';
import { observable, action, toJS, reaction, computed } from 'mobx';
import i18n from 'i18next';
import { validate, validateField } from '../../../common/helpers/validationHelper';
import Firebase from '../../../api/firebase';
import { UiStore } from '../../../stores/uiStore';
import { AuthStore } from '../../auth/stores/authStore';
import { Address } from '../../../common/models/address';

class CompleteEmployeeProfileDialogVm extends BaseViewModel {
    private authStore: AuthStore;
    private uiStore: UiStore;

    @observable public firstName: string = '';
    @observable public lastName: string = '';
    @observable address: Address = new Address();

    private successMsgFunc: () => void;
    private errorMsgFunc: () => void;

    constructor(authStore: AuthStore, uiStore: UiStore) {
        super();
        this.authStore = authStore;
        this.uiStore = uiStore;
        this.address.isCustomAddress = true;

        this.errorMsgFunc = reaction(
            () => this.error,
            (error) => {
                if (error !== undefined) {
                    this.uiStore!.enqueueNotification({
                        message: i18n.t(error!.key!),
                        type: 'error',
                        persist: false,
                    });
                    this.error = undefined;
                }
            }
        );
        this.successMsgFunc = reaction(
            () => this.successMessage!,
            (successMessage: any) => {
                if (this.successMessage !== undefined) {
                    this.uiStore!.enqueueNotification({
                        message: i18n.t(successMessage),
                        type: 'success',
                        persist: false,
                    });
                    this.successMessage = undefined;
                }
            }
        );
    }

    private static rules = {
        firstName: 'required|max:255',
        lastName: 'required|max:255',
    };

    @action
    updateProperty(field: string, value: any) {
        (this as any)[field] = value;

        const validator = CompleteEmployeeProfileDialogVm.getValidator(
            { [field]: value },
            CompleteEmployeeProfileDialogVm.rules,
            {
                firstName: i18n.t('firstName'),
                lastName: i18n.t('lastName'),
            }
        );
        this.validationErrors = validateField(validator, field, this.validationErrors);
    }

    @action
    private validateAll(data: any) {
        const validator = CompleteEmployeeProfileDialogVm.getValidator(data, CompleteEmployeeProfileDialogVm.rules, {
            firstName: i18n.t('firstName'),
            lastName: i18n.t('lastName'),
        });
        this.validationErrors = validate(validator);
    }

    @computed
    public get isValid() {
        return (
            Object.keys(this.validationErrors).length === 0 && Object.keys(this.address.validationErrors).length === 0
        );
    }

    @action
    public async completeEmployeeProfile() {
        this.validateAll(toJS(this));
        this.address.validateAll(toJS(this.address));
        if (this.isValid) {
            const completeEmployee = Firebase.functions.httpsCallable('completeEmployeeProfile');
            const result = completeEmployee({
                id: this.authStore.employeeId ? this.authStore.employeeId : '',
                tenantId: this.authStore.tenantId ? this.authStore.tenantId : '',
                firstName: this.firstName,
                lastName: this.lastName,
                isProfileCompleted: true,
                address: this.address.toDto(),
            });
            const resp = await this.handle(result);
            if (resp !== undefined) {
                this.authStore.isEmployeeProfileCompleted = true;
                this.errorMsgFunc();
                this.successMsgFunc();
            }
        }
    }
}

export default CompleteEmployeeProfileDialogVm;
