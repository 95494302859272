import { computed } from 'mobx';
import { PlanningBoardItem } from '../../../planning/models/planningBoardItem';
import { Project } from '../../../projects/models/project';
import { RootStore } from '../../../../stores/rootStore';
import { isWithinInterval } from 'date-fns';

export class ProjectsWidgetVm {
    private rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
    }

    @computed
    public get currentProjects() {
        const projects = this.rootStore.projectsStore.sortedProjects;
        if (projects.length === 0) {
            return [];
        }

        const planningBoardItems: PlanningBoardItem[] = this.rootStore.planningBoardItemsStore.planningBoardItems.filter(
            (item: PlanningBoardItem) =>
                item.projectRef &&
                item.employeeId &&
                isWithinInterval(item.date, {
                    start: this.rootStore.dashboardStore.filter.startDate,
                    end: this.rootStore.dashboardStore.filter.endDate,
                })
        );

        const currentProjects: Project[] = [];

        planningBoardItems.forEach((item: PlanningBoardItem) => {
            const project = this.rootStore.projectsStore.sortedProjects.find((x) => x.id === item.projectRef!.id);

            if (project && !currentProjects.includes(project)) {
                currentProjects.push(project);
            }
        });

        return currentProjects;
    }

    @computed
    public get hasProjects() {
        return this.currentProjects.length > 0;
    }
}
