import React from 'react';
import { AppError } from '../models/appError';
import Zoom from '@material-ui/core/Zoom';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import { createStyles, Theme } from '@material-ui/core';
import { WithTranslation, withTranslation } from 'react-i18next';
import { NotificationBox } from '../NoificationBox';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            marginBottom: theme.spacing(1)
        }
    });

type Props = WithStyles & WithTranslation & { appError?: AppError };

const ErrorMessage = withStyles(styles)(
    withTranslation()((props: Props) => {
        const { appError, classes, t } = props;

        if (!appError) {
            return null;
        }

        return (
            <Zoom in={true} timeout={500}>
                <div className={classes.root}>
                    <NotificationBox infoType={'error'} message={t(appError!.key ? appError!.key : 'UnknownError')} />
                </div>
            </Zoom>
        );
    })
);

export default ErrorMessage;
