import { observable, runInAction, action, computed } from 'mobx';
import { validateField, validate } from '../../../common/helpers/validationHelper';
import i18n from 'i18next';
import { BaseModel } from '../../../common/stores/model/baseModel';
import Validator from 'validatorjs';
import { Language } from '../../../api/dtos/generated/dtos.generated';

export class EmployeeLoginData extends BaseModel {
    @observable email: string = '';
    @observable loginId: string | null = null;
    @observable role: string = 'admin';
    @observable validationErrors: { [index: string]: any } = {};
    @observable isDirty: boolean = false;
    @observable isNew: boolean = false;
    @observable language: Language | null = Language.De;

    private static rules = {
        role: 'required',
        email: 'required|email|max:255'
    };

    private static getValidator(data: any, rules: any) {
        const validator = new Validator(data, rules);
        validator.setAttributeNames({
            email: i18n.t('email'),
            role: i18n.t('role')
        });
        return validator;
    }

    public static createFromDto(
        loginId: string | null,
        email: string | null,
        role: string | null,
        lang: Language | null
    ): EmployeeLoginData {
        const loginData = new EmployeeLoginData();
        runInAction(() => {
            loginData.isNew = false;
            loginData.loginId = loginId ? loginId : null;
            loginData.role = role ? role : 'admin';
            loginData.email = email ? email : '';
            loginData.language = lang ? lang : Language.De;
        });
        return loginData;
    }

    constructor() {
        super();
        runInAction(() => {
            this.isNew = true;
        });
    }

    @action
    updateProperty(field: string, value: any) {
        if (!this.isDirty) {
            this.isDirty = true;
        }
        (this as any)[field] = value;

        if (EmployeeLoginData.rules.hasOwnProperty(field)) {
            const validator = EmployeeLoginData.getValidator(
                { [field]: value },
                { [field]: (EmployeeLoginData.rules as any)[field] }
            );
            this.validationErrors = validateField(validator, field, this.validationErrors);
        }
    }

    @action
    validateAll() {
        const validator = EmployeeLoginData.getValidator(this, EmployeeLoginData.rules);
        this.validationErrors = validate(validator);
    }

    @computed get isValid() {
        return Object.keys(this.validationErrors).length === 0;
    }
}
