import {
    IconButton,
    Theme,
    Grid,
    Typography,
    LinearProgress,
    Tooltip,
    createStyles,
    makeStyles,
} from '@material-ui/core';
import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import { Link } from '@reach/router';
import { AlertIcon } from '../../common/components/SvgIcons';
import { StoreContext } from '../../App';
import { useTranslation } from 'react-i18next';
import { TenantScreenTab } from '../../modules/tenant/components/TenantScreen';

const useStyles = makeStyles(({ transitions }: Theme) =>
    createStyles({
        trialPeriodContainer: {
            width: 'auto',
            borderRadius: '4px',
            border: 'solid 1px #e3e3e3',
            backgroundColor: '#ffffff',
            position: 'absolute',
            bottom: '2.5rem',
            padding: '0.7rem',
            left: '2rem',
            right: '2rem',
            '@media (max-height:780px)': {
                position: 'relative',
                margin: '3rem 1rem 1rem 1rem',
                width: 'auto',
                top: 'inherit',
                left: 'inherit',
                right: 'inherit',
            },
            transition: transitions.create('width', {
                easing: transitions.easing.sharp,
                duration: transitions.duration.enteringScreen,
            }),
        },
        trialPeriodProgressBarTitleContainer: {
            marginBottom: '8px',
        },
        trialPeriodProgressBarTitleFirstPart: {
            color: '#1a60dd',
            fontWeight: 300,
        },
        trialPeriodProgressBarTitleSecondPart: {
            color: '#b0c0db',
            fontSize: '14px',
        },
        trialProgressContainer: {
            overflow: 'hidden',
            width: '183px',
        },
        trialProgressColorPrimary: {
            backgroundColor: '#e7effc',
        },
        trialProgressBarColorPrimary: {
            backgroundImage: 'linear-gradient(91deg, #01c8fd, #005ad0)',
        },
        trialProgressColorSecondary: {
            backgroundColor: '#e7effc',
        },
        trialProgressBarColorSecondary: {
            backgroundColor: 'red',
        },
        activateLinkContainer: {
            marginTop: '10px',
        },
        trialPeriodActivateLabel: {
            fontSize: '13px',
            color: '#1a60dd',
        },
        trialPeriodLink: {
            textDecoration: 'none',
        },
        trialPeriodMinContainer: {
            display: 'flex',
            justifyContent: 'center',
            position: 'absolute',
            bottom: '2rem',
        },
    })
);

const MIN = 0;
const MAX = 30;
const countTrialProgress = (value: any) => ((value - MIN) * 100) / (MAX - MIN);

const TrialSection = observer(() => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { tenantStore, uiStore } = useContext(StoreContext);

    const trialEndDays = tenantStore!.tenant ? tenantStore!.tenant.subscription.trialExpiryDays! : 0;

    const trialProgressBarClasses =
        trialEndDays > 10
            ? {
                  colorPrimary: classes.trialProgressColorPrimary,
                  barColorPrimary: classes.trialProgressBarColorPrimary,
              }
            : tenantStore!.tenant && tenantStore!.tenant.subscription.priceId !== undefined
            ? {
                  colorPrimary: classes.trialProgressColorPrimary,
                  barColorPrimary: classes.trialProgressBarColorPrimary,
              }
            : {
                  colorPrimary: classes.trialProgressColorSecondary,
                  barColorPrimary: classes.trialProgressBarColorSecondary,
              };

    return (
        <>
            {tenantStore!.tenant && tenantStore!.tenant.subscription.status === 'trialing' && (
                <>
                    <Grid
                        container
                        direction={'column'}
                        justify={'center'}
                        alignItems={'center'}
                        className={classes.trialPeriodContainer}
                        style={uiStore.isDrawerOpen ? { display: 'flex' } : { display: 'none' }}
                    >
                        <Grid item className={classes.trialPeriodProgressBarTitleContainer}>
                            <Typography>
                                <span className={classes.trialPeriodProgressBarTitleFirstPart}>
                                    {trialEndDays}&nbsp;
                                    {`${trialEndDays === 1 || trialEndDays === 21 ? t('day') + ' ' : t('days') + ' '}`}
                                </span>
                                <span className={classes.trialPeriodProgressBarTitleSecondPart}>
                                    {t('trial-time-left')}
                                </span>
                            </Typography>
                        </Grid>
                        <Grid item>
                            <div className={classes.trialProgressContainer}>
                                <LinearProgress
                                    variant="determinate"
                                    value={countTrialProgress(trialEndDays)}
                                    classes={trialProgressBarClasses}
                                />
                            </div>
                        </Grid>
                        {tenantStore!.tenant && tenantStore!.tenant!.subscription.status === 'trialing' && (
                            <Grid item className={classes.activateLinkContainer}>
                                <Link
                                    className={classes.trialPeriodLink}
                                    to={`/mytenant?t=${TenantScreenTab.Subscription}`}
                                >
                                    <Typography className={classes.trialPeriodActivateLabel}>
                                        {t('add-payment-details')}
                                    </Typography>
                                </Link>
                            </Grid>
                        )}
                    </Grid>
                    <Grid
                        container
                        className={classes.trialPeriodMinContainer}
                        style={uiStore.isDrawerOpen ? { display: 'none' } : { display: 'flex' }}
                    >
                        <Tooltip
                            title={`${trialEndDays} ${
                                trialEndDays === 1 || trialEndDays === 21 ? t('day') + ' ' : t('days') + ' '
                            }${t('trial-time-left')} / ${t('add-payment-details')}`}
                            aria-label="Add"
                        >
                            <Link
                                className={classes.trialPeriodLink}
                                to={`/mytenant?t=${TenantScreenTab.Subscription}`}
                            >
                                <IconButton>
                                    <AlertIcon color="primary" />
                                </IconButton>
                            </Link>
                        </Tooltip>
                    </Grid>
                </>
            )}
        </>
    );
});

export default TrialSection;
