import React from 'react';
import Zoom from '@material-ui/core/Zoom';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import { createStyles, Theme } from '@material-ui/core';
import { WithTranslation, withTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { CheckRoundedIcon } from '../../common/components/SvgIcons';

const styles = ({ spacing }: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            justifyContent: 'center'
        },
        largeIcon: {
            fill: '#91ce4c',
            maxWidth: '100px',
            border: '3px solid #91ce4c',
            height: '100px',
            borderRadius: '50%',
            padding: '18px',
            marginBottom: spacing(3)
        }
    });

type Props = WithStyles & WithTranslation & { successMessage?: string };

const SuccessMessage = withStyles(styles)(
    withTranslation()((props: Props) => {
        const { successMessage, classes, t } = props;

        if (!successMessage) {
            return null;
        }
        return (
            <Zoom in={true} timeout={500}>
                <div className={classes.root}>
                    <Grid container direction="column" justify="center" alignItems="center">
                        <Grid item style={{ marginBottom: 30 }}>
                            <CheckRoundedIcon style={{ fontSize: 80, fill: '#91ce4c' }} />
                        </Grid>
                        <Grid item>
                            <Typography variant={'body1'}>{t(successMessage)}</Typography>
                        </Grid>
                    </Grid>
                </div>
            </Zoom>
        );
    })
);

export default SuccessMessage;
