import { observable } from 'mobx';
import { Project } from '../../projects/models/project';

export class PlanningProject {
    @observable project: Project;
    @observable isPlanned: boolean;

    constructor(project: Project, isPlanned: boolean) {
        this.project = project;
        this.isPlanned = isPlanned;
    }
}
