import * as React from 'react';
import { Button, IconButton, Tooltip, InputAdornment, CircularProgress, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { TextField, Grid } from '@material-ui/core';
import RemoveIcon from 'mdi-material-ui/Close';
import CheckIcon from 'mdi-material-ui/Check';
import InvalidIcon from 'mdi-material-ui/AlertCircleOutline';
import PromotionCodeIcon from 'mdi-material-ui/TicketPercentOutline';
import { useEffect, useState } from 'react';
import Firebase from '../../../api/firebase';
import debounce from 'lodash/debounce';
import PromotionInformation from '../../../common/components/PromotionInformation';
import Alert from '@material-ui/lab/Alert';
import { AlertTitle } from '@material-ui/lab';

interface PromotionCodeProps {
    initialCode?: string;
    onChange: (promotionId: string | undefined) => void;
}

enum ViewMode {
    Init,
    Edit,
    Active,
}

const fetchPromotionCode = async (code: string) => {
    const fetchPromotionCodeCf = Firebase.functions.httpsCallable('checkPromotionCode');
    const result = await fetchPromotionCodeCf({ code: code });
    return result.data;
};

const PromotionCodeSessionStoreKey = 'validPromotionCode';

export default function PromotionCode({ onChange, initialCode }: PromotionCodeProps) {
    const { t } = useTranslation();
    const theme = useTheme();
    const [viewMode, setViewMode] = React.useState<ViewMode>(initialCode ? ViewMode.Edit : ViewMode.Init);
    const [code, setCode] = useState<string | undefined>(initialCode);
    const [promotionCode, setPromotionCode] = React.useState<{ isValid: boolean; promotionCode: any } | undefined>(
        undefined
    );
    const [checkPromotionCodeInProgress, setCheckPromotionCodeInProgress] = useState<boolean>(false);
    const debouncedCheck = React.useRef(debounce((nextValue) => checkPromotionCode(nextValue), 1000)).current;

    const handlePromotionCodeChange = (event: any) => {
        const { value } = event.target;
        setCode(value);
    };
    const handlePromotionCodeDelete = () => {
        setViewMode(ViewMode.Init);
        sessionStorage.removeItem(PromotionCodeSessionStoreKey);
        setPromotionCode(undefined);
        setCode(undefined);
    };

    const checkPromotionCode = async (code?: string) => {
        if (!code) {
            return { isValid: false, promotionCode: undefined };
        }
        setCheckPromotionCodeInProgress(true);
        const promotionCode = await fetchPromotionCode(code);
        const isValidCode = promotionCode.data !== null;
        setPromotionCode({ isValid: isValidCode, promotionCode: promotionCode.data });

        if (isValidCode) {
            sessionStorage.setItem(PromotionCodeSessionStoreKey, promotionCode.data.code);
        } else {
            sessionStorage.removeItem(PromotionCodeSessionStoreKey);
        }

        setCheckPromotionCodeInProgress(false);
    };

    useEffect(() => {
        const tempPromotionCode = sessionStorage.getItem(PromotionCodeSessionStoreKey);
        if (tempPromotionCode != null) {
            setCode(tempPromotionCode);
        }
    }, []);

    useEffect(() => {
        if (code && code.length > 0) {
            debouncedCheck(code);
        }
    }, [code, debouncedCheck]);

    useEffect(() => {
        if (promotionCode?.isValid === true) {
            setViewMode(ViewMode.Active);
        }
        onChange(promotionCode?.isValid ? promotionCode.promotionCode.id : undefined);
    }, [promotionCode, setViewMode]); // eslint-disable-line react-hooks/exhaustive-deps

    const endIcon = () => {
        const closeIcon = (
            <Tooltip title={t('remove-promotion-code') as string}>
                <IconButton disabled={checkPromotionCodeInProgress} onClick={handlePromotionCodeDelete}>
                    <RemoveIcon />
                </IconButton>
            </Tooltip>
        );

        if (checkPromotionCodeInProgress) {
            return <CircularProgress size={36} variant={'indeterminate'} />;
        }

        if (promotionCode?.isValid) {
            return (
                <>
                    <CheckIcon style={{ color: 'green' }} />
                    {closeIcon}
                </>
            );
        }

        if (promotionCode?.isValid === false) {
            return (
                <>
                    <InvalidIcon style={{ color: theme.palette.error.main }} />
                    {closeIcon}
                </>
            );
        }

        return closeIcon;
    };

    if (viewMode === ViewMode.Edit) {
        return (
            <TextField
                fullWidth={true}
                variant="outlined"
                label={t('promotion-code')}
                value={code}
                error={promotionCode?.isValid === false}
                helperText={promotionCode?.isValid === false && t('promotion-code-invalid-text')}
                onChange={handlePromotionCodeChange}
                InputProps={{
                    endAdornment: <InputAdornment position="end">{endIcon()}</InputAdornment>,
                }}
            />
        );
    }

    if (viewMode === ViewMode.Active) {
        return (
            <Alert
                severity="success"
                action={
                    <Tooltip title={t('remove-promotion-code') as string}>
                        <IconButton onClick={handlePromotionCodeDelete}>
                            <RemoveIcon />
                        </IconButton>
                    </Tooltip>
                }
            >
                <AlertTitle>{t('promotion-code-valid-title')}</AlertTitle>
                <PromotionInformation promotion={promotionCode?.promotionCode} />
            </Alert>
        );
    }

    return (
        <Grid container justify={'center'}>
            <Button startIcon={<PromotionCodeIcon />} variant={'outlined'} onClick={() => setViewMode(ViewMode.Edit)}>
                {t('enter-promotion-code')}
            </Button>
        </Grid>
    );
}
