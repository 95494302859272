import { TaskDto, TaskState, TaskFilterDto } from '../../api/dtos/generated/dtos.generated';
import {
    addDays,
    addMonths,
    addWeeks,
    addYears,
    isAfter,
    isBefore,
    isEqual,
    isSameDay,
    isSameMonth,
    isSameWeek,
    isSameYear,
    max,
    min,
} from 'date-fns';
import * as uuid from 'uuid';

export interface TaskWrapper {
    task: TaskDto;
    isCalculated: boolean;
}

const isFilteredState = (state: TaskState, states: TaskState[]) => {
    return states.length === 0 || states.findIndex((x) => x === state) > -1;
};

const calculateDailyTasks = (
    initialTask: TaskDto,
    changedTaskInSeries: TaskDto[],
    dateRangeStart: Date,
    dateRangeEnd: Date,
    states: TaskState[]
): TaskDto[] => {
    const dailyVirtualTasks: TaskDto[] = [];

    let dueDate = initialTask.dueDate!;

    while (isEqual(dueDate, dateRangeEnd) || isBefore(dueDate, dateRangeEnd)) {
        if (isBefore(dueDate, dateRangeStart)) {
            dueDate = addDays(dueDate, initialTask.recurring!.interval);
            continue;
        }

        const taskAlreadyChanged = changedTaskInSeries.some((changedTask) => {
            return isSameDay(changedTask.dueDate!, dueDate);
        });

        if (!taskAlreadyChanged) {
            const virtualTask: TaskDto = { ...initialTask };
            virtualTask.id = uuid.v4();
            virtualTask.dueDate = dueDate;
            virtualTask.parentTaskId = initialTask.id;

            if (isFilteredState(virtualTask.state, states)) {
                dailyVirtualTasks.push(virtualTask);
            }
        }

        dueDate = addDays(dueDate, initialTask.recurring!.interval);
    }

    return dailyVirtualTasks;
};

const calculateWeeklyTasks = (
    initialTask: TaskDto,
    changedTaskInSeries: TaskDto[],
    dateRangeStart: Date,
    dateRangeEnd: Date,
    states: TaskState[]
): TaskDto[] => {
    const weeklyVirtualTasks: TaskDto[] = [];

    let dueDate = initialTask.dueDate!;

    while (isEqual(dueDate, dateRangeEnd) || isBefore(dueDate, dateRangeEnd)) {
        if (isBefore(dueDate, dateRangeStart)) {
            dueDate = addWeeks(dueDate, initialTask.recurring!.interval);
            continue;
        }

        const taskAlreadyChanged = changedTaskInSeries.some((changedTask) => {
            return isSameWeek(changedTask.dueDate!, dueDate, { weekStartsOn: 1 });
        });

        if (!taskAlreadyChanged) {
            const virtualTask: TaskDto = { ...initialTask };
            virtualTask.id = uuid.v4();
            virtualTask.dueDate = dueDate;
            virtualTask.parentTaskId = initialTask.id;

            if (isFilteredState(virtualTask.state, states)) {
                weeklyVirtualTasks.push(virtualTask);
            }
        }

        dueDate = addWeeks(dueDate, initialTask.recurring!.interval);
    }

    return weeklyVirtualTasks;
};

const calculateMonthlyTasks = (
    initialTask: TaskDto,
    changedTaskInSeries: TaskDto[],
    dateRangeStart: Date,
    dateRangeEnd: Date,
    states: TaskState[]
): TaskDto[] => {
    const monthlyVirtualTasks: TaskDto[] = [];

    let dueDate = initialTask.dueDate!;

    while (isEqual(dueDate, dateRangeEnd) || isBefore(dueDate, dateRangeEnd)) {
        if (isBefore(dueDate, dateRangeStart)) {
            dueDate = addMonths(dueDate, initialTask.recurring!.interval);
            continue;
        }

        const taskAlreadyChanged = changedTaskInSeries.some((changedTask) => {
            return isSameMonth(changedTask.dueDate!, dueDate);
        });

        if (!taskAlreadyChanged) {
            const virtualTask: TaskDto = { ...initialTask };
            virtualTask.id = uuid.v4();
            virtualTask.dueDate = dueDate;
            virtualTask.parentTaskId = initialTask.id;

            if (isFilteredState(virtualTask.state, states)) {
                monthlyVirtualTasks.push(virtualTask);
            }
        }

        dueDate = addMonths(dueDate, initialTask.recurring!.interval);
    }

    return monthlyVirtualTasks;
};

const calculateYearlyTasks = (
    initialTask: TaskDto,
    changedTaskInSeries: TaskDto[],
    dateRangeStart: Date,
    dateRangeEnd: Date,
    states: TaskState[]
): TaskDto[] => {
    const yearlyVirtualTasks: TaskDto[] = [];

    let dueDate = initialTask.dueDate!;

    while (isEqual(dueDate, dateRangeEnd) || isBefore(dueDate, dateRangeEnd)) {
        if (isBefore(dueDate, dateRangeStart)) {
            dueDate = addYears(dueDate, initialTask.recurring!.interval);
            continue;
        }

        const taskAlreadyChanged = changedTaskInSeries.some((changedTask) => {
            return isSameYear(changedTask.dueDate!, dueDate);
        });

        if (!taskAlreadyChanged) {
            const virtualTask: TaskDto = { ...initialTask };
            virtualTask.id = uuid.v4();
            virtualTask.dueDate = dueDate;
            virtualTask.parentTaskId = initialTask.id;

            if (isFilteredState(virtualTask.state, states)) {
                yearlyVirtualTasks.push(virtualTask);
            }
        }

        dueDate = addYears(dueDate, initialTask.recurring!.interval);
    }

    return yearlyVirtualTasks;
};

export const calculateTasks = (initialTasks: TaskDto[], filter: TaskFilterDto): Promise<TaskWrapper[]> => {
    let plannedTasks: TaskWrapper[] = [];

    initialTasks.forEach((initialTask: TaskDto) => {
        if (initialTask.deletedAt) {
            return;
        }

        if (filter.employeeRef && (!initialTask.employeeRef || initialTask.employeeRef.id !== filter.employeeRef.id)) {
            return;
        }

        if (filter.customerRef && (!initialTask.customerRef || initialTask.customerRef.id !== filter.customerRef.id)) {
            return;
        }

        if (initialTask.recurring && !initialTask.parentTaskId) {
            const isInDateRange =
                (isEqual(initialTask.recurring.start, filter.endDate) ||
                    isBefore(initialTask.recurring.start, filter.endDate)) &&
                (!initialTask.recurring.end ||
                    isEqual(initialTask.recurring.end, filter.startDate) ||
                    isAfter(initialTask.recurring.end, filter.startDate));

            if (isInDateRange) {
                const start = max([initialTask.recurring.start, filter.startDate]);
                const end = initialTask.recurring.end
                    ? min([initialTask.recurring.end, filter.endDate])
                    : filter.endDate;

                const changedTaskItemsInSeries = initialTasks.filter((t) => t.parentTaskId === initialTask.id);

                if (initialTask.recurring.unit === 'daily') {
                    plannedTasks = plannedTasks.concat(
                        calculateDailyTasks(initialTask, changedTaskItemsInSeries, start, end, filter.states).map(
                            (x: TaskDto) => {
                                return { task: x, isCalculated: true } as TaskWrapper;
                            }
                        )
                    );
                } else if (initialTask.recurring.unit === 'weekly') {
                    plannedTasks = plannedTasks.concat(
                        calculateWeeklyTasks(initialTask, changedTaskItemsInSeries, start, end, filter.states).map(
                            (x: TaskDto) => {
                                return { task: x, isCalculated: true } as TaskWrapper;
                            }
                        )
                    );
                } else if (initialTask.recurring.unit === 'monthly') {
                    plannedTasks = plannedTasks.concat(
                        calculateMonthlyTasks(initialTask, changedTaskItemsInSeries, start, end, filter.states).map(
                            (x: TaskDto) => {
                                return { task: x, isCalculated: true } as TaskWrapper;
                            }
                        )
                    );
                } else if (initialTask.recurring.unit === 'yearly') {
                    plannedTasks = plannedTasks.concat(
                        calculateYearlyTasks(initialTask, changedTaskItemsInSeries, start, end, filter.states).map(
                            (x: TaskDto) => {
                                return { task: x, isCalculated: true } as TaskWrapper;
                            }
                        )
                    );
                }
            }
        } else {
            const dueDate = initialTask.dueDate!;

            const matchFilterDateRange =
                (isEqual(dueDate, filter.startDate) || isAfter(dueDate, filter.startDate)) &&
                (isEqual(dueDate, filter.endDate) || isBefore(dueDate, filter.endDate));

            if (matchFilterDateRange && isFilteredState(initialTask.state, filter.states)) {
                plannedTasks.push({ task: initialTask, isCalculated: false });
            }
        }
    });

    plannedTasks.sort((a: TaskWrapper, b: TaskWrapper) => {
        if (!a.task.dueDate || !b.task.dueDate) {
            return 0;
        }
        return a.task.dueDate > b.task.dueDate ? 1 : -1;
    });

    return Promise.resolve(plannedTasks);
};
