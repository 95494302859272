import React, { Suspense } from 'react';
import { CssBaseline } from '@material-ui/core';
import defaultTheme from './themes/defaultTheme';
import { Provider } from 'mobx-react';
import Shell from './shell/Shell';
import { RootStore } from './stores/rootStore';
import AuthModule from './modules/auth/AuthModule';
import { createHistory, HistoryListenerParameter, LocationProvider } from '@reach/router';
import { MuiThemeProvider } from '@material-ui/core/styles';
import LogoAnimate from './common/components/LogoAnimate';
import { Log } from './log';
import { SnackbarProvider } from 'notistack';

const rootStore = new RootStore();
export const StoreContext = React.createContext<RootStore>(rootStore);
export const appHistory = createHistory(window as any);

appHistory.listen((historyParams: HistoryListenerParameter) => {
    Log.screenView(historyParams.location.pathname);
});

const App = () => {
    return (
        <MuiThemeProvider theme={defaultTheme}>
            <Provider rootStore={rootStore} {...rootStore}>
                <SnackbarProvider
                    maxSnack={3}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right'
                    }}
                >
                    <CssBaseline />
                    <Suspense fallback={<LogoAnimate contrast={true} />}>
                        <AuthModule>
                            {() => (
                                <LocationProvider history={appHistory}>
                                    <Shell />
                                </LocationProvider>
                            )}
                        </AuthModule>
                    </Suspense>
                </SnackbarProvider>
            </Provider>
        </MuiThemeProvider>
    );
};

export default App;
